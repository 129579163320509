import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";

export default function Protected({ Component, setCartCount }) {
  const navigate = useNavigate();
  const [render, setRender] = useState(false);
  const [comingSoons, setComingSoon] = useState([
    "/blogs",
  ]);

  const { pathname } = useLocation();

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (!token) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      navigate("/login");
      return;
    }
    setRender(true);
  }, []);
  return render ? <Component setCartCount={setCartCount} /> : <></>;
}
