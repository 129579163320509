import Modal from "react-bootstrap/Modal";

const Deleteitem = (props) => {
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="delelte--modal"
      >
        <div className="container">
          <Modal.Header className="justify-content-center border-0 pb-0 mt-3">
            <Modal.Title id="contained-modal-title-vcenter">
              {/* <img src="/assets/images/successIcon.svg" style={{ maxWidth: '100px', maxHeight: '100px' }} /> */}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            className="text-center"
            style={{ background: "#FFFFFF", borderRadius: "3px" }}
          >
            <div className="row justify-content-center">
              <div className="col-md-9">
                <h1 style={{ fontFamily: "My Font" }}>Are you sure</h1>

                <h4>{props.deleteText} </h4>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center border-0 mb-3 pt-0">
          <button
              className="get-started"
              onClick={props.onHide}
              style={{ width: "122px", height: "50px" }}
            >
              No
            </button>

            <button
              className="get-started"
              onClick={props.deleteItem}
              style={{ width: "122px", height: "50px" }}
              disabled={props.deleteBtnLock}
            >
              { props.btnname || "Yes"}
            </button>

          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};

export default Deleteitem;
