import { useCallback, useEffect, useState } from "react";
import align from "../../assets/images/icons/custom/AlignCenterHorizontal.svg";
import align1 from "../../assets/images/icons/custom/AlignCenterVertical.svg";
import align5 from "../../assets/images/icons/custom/alignBottom.svg";
import align2 from "../../assets/images/icons/custom/AlignLeft.svg";
import align3 from "../../assets/images/icons/custom/AlignRight.svg";
import align4 from "../../assets/images/icons/custom/AlignTop.svg";
import para from "../../assets/images/icons/custom/TextAlignLeft.svg";
import para1 from "../../assets/images/icons/custom/TextAlignRight.svg";
import para2 from "../../assets/images/icons/custom/TextAlignJustify.svg";
import para3 from "../../assets/images/icons/custom/TextAlignCenter.svg";
import decore from "../../assets/images/icons/custom/TextUnderline.svg";
import decore1 from "../../assets/images/icons/custom/TextItalic.svg";
import decore2 from "../../assets/images/icons/custom/TextBolder.svg";
import decore3 from "../../assets/images/icons/custom/TextStrikethrough.svg";
import lockLayers from "../../assets/images/icons/locklayer.svg";
import paint from "../../assets/images/icons/custom/Eyedropper.svg";
import lock from "../../assets/images/icons/custom/LockKeyOpen.svg";
import eye from "../../assets/images/icons/custom/Group 158.svg";
import { MdKeyboardArrowRight } from "react-icons/md";
import { fabric } from "fabric";

// import {CgArrowsVAlt, CgArrowsHAlt} from "react-icons/cg";
import ResetCanvas from "../modal/ResetCanvas";
import { toast } from "react-toastify";
import e from "@toast-ui/react-image-editor";

const Toolbar = ({
  canvas,
  handleLayer,
  setDownloadCanvasImg,
  makeCanvasImg,
  setReinitCanvas,
  isMobile,
}) => {
  useEffect(() => {
    document.body.style.overflowY = "hidden";
    return () => (document.body.style.overflowY = "auto");
  }, []);

  const colors = ["#DC0310", "#1F32DF", "#1DDB0D", "#FFFFFF", "#131313"];
  const [activeTool, setActiveTool] = useState();
  const [cursor, setCursor] = useState(false);
  const [resetCanvasModal, setShowResetModal] = useState(false);
  const [colorPallete, setColorPallete] = useState("black");
  const [textColor, setTextColor] = useState("black");

  const [pen, setPen] = useState({
    active: false,
    fill: "black",
    width: 2,
    height: 5,
    stroke: "black",
  });
  const [text, setText] = useState({
    color: textColor,
    fontFamily: "My Font",
    fontWeight: "normal",
    fontSize: 22,
    textAlign: "left",
    fontStyle: "none",
    underline: false,
    linethrough: false,
    lineHeight: 5,
  });

  const [showPenSubMenu, setShowPenSubMenu] = useState("");
  const [showDiamondSubMenu, setShowDiamondSubMenu] = useState("");
  const [showCardinal, setShowCardinalSubMenu] = useState("");
  const [showBrushSubMenu, setShowBrushSubMenu] = useState("");
  const [showEraserSubMenu, setShowEraserSubMenu] = useState("");
  const [showTextSubMenu, setShowTextSubMenu] = useState("");
  const [showColorpalleteSubMenu, setShowColorpalleteSubMenu] = useState("");
  const [layerName, setLayerName] = useState("");
  const [showAlignment, setShowAlignment] = useState("");
  const [showBucket, setShowBucket] = useState("");

  const [brush, setBrush] = useState({
    isBrush: false,
    width: 20,
    color: "black",
  });
  const [eraser, setEraser] = useState({
    isEraser: false,
    width: 10,
    opacity: 1,
  });
  const [shape, setShape] = useState({
    width: 0,
    height: 0,
  });

  const toggleCursor = () => {
    alert("");
    console.log(activeTool, "activeTool", "Cursur :::::::: ", cursor);
    document.body.style.cursor = !cursor
      ? "url('/assets/images/toolbox/ArrowsOutCardinal.svg') 12 12, auto"
      : "";
    setCursor(!cursor);
    setActiveTool(activeTool === "cardinal" ? "" : "cardinal");
    if (canvas && canvas.isDrawingMode) {
      canvas.isDrawingMode = false;
    }
  };

  const handlePenOptions = (e, type) => {
    if (!canvas) return;
    const { name, value } = e.target;
    handleToolbar(e, "pen");
    setPen((state) => ({
      ...state,
      [name]: value,
    }));
    switch (type) {
      case "width":
        canvas.freeDrawingBrush.strokeWidth = parseInt(value);
        canvas.freeDrawingBrush.width = parseInt(value, 10);
        break;
      case "height":
        canvas.freeDrawingBrush.height = parseInt(value, 10);
        break;
      case "stroke":
        canvas.freeDrawingBrush.stroke = pen.stroke;
        canvas.freeDrawingBrush.strokeWidth = parseInt(value);
        break;
      case "fill":
        canvas.freeDrawingBrush.color = value;
        canvas.freeDrawingBrush.stroke = value;
        break;
    }
  };

  const showOrHide = (option) => {
    switch (option) {
      case "pen":
        setShowPenSubMenu("tool-active--menu");
        setShowColorpalleteSubMenu("");
        setShowTextSubMenu("");
        setShowBrushSubMenu("");
        setShowCardinalSubMenu("");
        setShowEraserSubMenu("");
        setShowDiamondSubMenu("");
        setShowAlignment("");
        setShowBucket("");
        break;

      case "diamond":
        setShowPenSubMenu("");
        setShowColorpalleteSubMenu("");
        setShowTextSubMenu("");
        setShowBrushSubMenu("");
        setShowCardinalSubMenu("");
        setShowEraserSubMenu("");
        setShowAlignment("");
        setShowBucket("");
        setShowDiamondSubMenu("tool-active--menu");
        break;

      case "cardinal":
        setShowPenSubMenu("");
        setShowColorpalleteSubMenu("");
        setShowTextSubMenu("");
        setShowBrushSubMenu("");
        setShowCardinalSubMenu("tool-active--menu");
        setShowEraserSubMenu("");
        setShowDiamondSubMenu("");
        setShowAlignment("");
        setShowBucket("");
        break;

      case "brush":
        setShowPenSubMenu("");
        setShowColorpalleteSubMenu("");
        setShowTextSubMenu("");
        setShowBrushSubMenu("tool-active--menu");
        setShowCardinalSubMenu("");
        setShowEraserSubMenu("");
        setShowDiamondSubMenu("");
        setShowAlignment("");
        setShowBucket("");
        break;

      case "eraser":
        setShowPenSubMenu("");
        setShowColorpalleteSubMenu("");
        setShowTextSubMenu("");
        setShowBrushSubMenu("");
        setShowCardinalSubMenu("");
        setShowEraserSubMenu("admin-customizeradmin-customizer");
        setShowDiamondSubMenu("");
        setShowAlignment("");
        setShowBucket("");
        break;

      case "text":
        setShowPenSubMenu("");
        setShowColorpalleteSubMenu("");
        setShowTextSubMenu("tool-active--menu");
        setShowBrushSubMenu("");
        setShowCardinalSubMenu("");
        setShowDiamondSubMenu("");
        setShowEraserSubMenu("");
        setShowAlignment("");
        setShowBucket("");
        break;

      case "colorpallete":
        setShowPenSubMenu("");
        setShowColorpalleteSubMenu("tool-active--menu");
        setShowTextSubMenu("");
        setShowBrushSubMenu("");
        setShowCardinalSubMenu("");
        setShowDiamondSubMenu("");
        setShowEraserSubMenu("");
        setShowAlignment("");
        setShowBucket("");
        break;
      case "alignment":
        setShowPenSubMenu("");
        setShowColorpalleteSubMenu("");
        setShowTextSubMenu("");
        setShowBrushSubMenu("");
        setShowCardinalSubMenu("");
        setShowDiamondSubMenu("");
        setShowAlignment("tool-active--menu");
        setShowEraserSubMenu("");
        setShowBucket("");
        break;
      case "Bucket":
        setShowPenSubMenu("");
        setShowColorpalleteSubMenu("");
        setShowTextSubMenu("");
        setShowBrushSubMenu("");
        setShowCardinalSubMenu("");
        setShowDiamondSubMenu("");
        setShowAlignment("");
        setShowEraserSubMenu("");
        setShowBucket("tool-active--menu");
        break;
      default:
        setShowPenSubMenu("");
        setShowColorpalleteSubMenu("");
        setShowTextSubMenu("");
        setShowBrushSubMenu("");
        setShowCardinalSubMenu("");
        setShowEraserSubMenu("");
        setShowDiamondSubMenu("");
        setShowAlignment("");
        setShowBucket("");
        break;
    }
  };

  const [textFonstStyle, setTextFonstStyle] = useState({
    italic: false,
    bold: false,
  });

  const handleTextOptions = (e) => {
    if (!canvas) return;
    const { name } = e.target;
    const activeObj = canvas.getActiveObject();

    if (!activeObj) {
      alert("Select Text You Want To Apply Properties ON");
      return;
    }

    let updatedProperties = {};

    switch (name) {
      case "underline":
        updatedProperties.underline = !text.underline;
        break;
      case "linethrough":
        updatedProperties.linethrough = !text.linethrough;
        break;
      case "italic":
      case "bold":
        // Toggle fontStyle for italic and bold
        let fontStyle = activeObj.fontStyle || "normal";
        if (fontStyle.includes(name)) {
          fontStyle = fontStyle.replace(name, "").trim();
        } else {
          fontStyle += ` ${name}`;
        }
        updatedProperties.fontStyle = fontStyle.trim() || "normal";
        break;
      case "left":
        updatedProperties["textAlign"] = "left";
        break;
      case "right":
        updatedProperties["textAlign"] = "right";
        break;
      case "justify":
        updatedProperties["textAlign"] = "justify";
        break;
      case "center":
        updatedProperties["textAlign"] = "center";
        break;
      default:
        updatedProperties[name] = e.target.value;
        break;
    }

    setTextFonstStyle((state) => ({
      ...state,
      [name]: !state[name],
    }));
    activeObj.set(updatedProperties);
    setText((state) => ({
      ...state,
      ...updatedProperties,
    }));
    canvas.requestRenderAll();
  };

  const handleElementsAlign = (e) => {
    if (!canvas) return;
    const { name } = e.target;
    const activeObj = canvas.getActiveObject();

    if (!activeObj) {
      alert("Select Element You Want To Apply Properties ON");
      return;
    }
    switch (name) {
      case "alignleft":
        activeObj.set({
          left: 0,
        });
        break;
      case "alignright":
        activeObj.set({
          left: canvas.width - activeObj.width * activeObj.scaleX,
        });
        break;
      case "aligntop":
        activeObj.set({
          top: 0,
        });
        break;
      case "centervertically":
        activeObj.set({
          left: canvas.width / 2 - (activeObj.width * activeObj.scaleX) / 2,
        });
        break;
      case "center":
        activeObj.centerV();
        break;
      case "bottom":
        activeObj.set({
          top: canvas.height - activeObj.height * activeObj.scaleY,
        });
        break;
    }

    canvas.requestRenderAll();
  };
  const handleColorPallete = useCallback(
    (e) => {
      if (!canvas) return;

      let obj = canvas?.getActiveObject();
      if (obj) {
        obj?.set({ stroke: e.target.value });
        canvas?.requestRenderAll();
      }
      setColorPallete(e.target.value);
    },
    [canvas]
  );

  const handleToolbar = useCallback(
    (e, type) => {
      if (!canvas) return;
      if (activeTool === type) {
        setActiveTool("");
        if (canvas.isDrawingMode) {
          canvas.isDrawingMode = false;
        }
        if (canvas.hasOwnProperty("eraser")) {
          delete canvas.eraser;
        }
        if (cursor) {
          setCursor(!cursor);
        }
        return;
      }
      setActiveTool(type);
      switch (type) {
        case "pen":
          canvas.eraser = false;
          canvas.isDrawingMode = true;
          canvas.freeDrawingBrush.color = pen.fill;
          canvas.freeDrawingBrush.stroke = pen.stroke;
          canvas.freeDrawingBrush.strokeWidth = parseInt(pen.width);
          canvas.freeDrawingBrush.width = parseInt(pen.width, 10);
          canvas.freeDrawingBrush.height = parseInt(pen.height, 10);
          showOrHide("pen");
          break;
        case "brush":
          canvas.eraser = false;
          canvas.isDrawingMode = true;
          canvas.freeDrawingBrush.color = brush.color;
          canvas.freeDrawingBrush.width = parseInt(brush.width, 10);
          showOrHide("brush");
          break;
        case "eraser":
          canvas.isDrawingMode = true;
          canvas.freeDrawingBrush.color = canvas.backgroundColor;
          canvas.freeDrawingBrush.width = parseInt(brush.width, 5);
          canvas.freeDrawingBrush.globalCompositeOperation = "destination-out";
          canvas.freeDrawingBrush.id = "erasure";
          canvas.eraser = true;
          showOrHide("eraser");
          break;
        case "text":
          showOrHide("text");
          break;
        case "alignment":
          showOrHide("alignment");
          break;
        case "Bucket":
          showOrHide("Bucket");
          break;

        case "colorpallete":
          let obj = canvas.getActiveObject();
          if (
            (obj && obj?.type === "polygon") ||
            obj?.type === "rect" ||
            obj?.type === "circle" ||
            obj?.type === "triangle"
          ) {
            obj?.set({ stroke: colorPallete });
            canvas.requestRenderAll();
          }
          showOrHide("colorpallete");
          break;

        case "diamond":
          canvas.eraser = false;
          showOrHide("diamond");
          break;

        case "cardinal":
          canvas.eraser = false;
          canvas.isDrawingMode = false;
          canvas.selection = true;
          showOrHide("cardinal");
          break;
        default:
          canvas.eraser = false;
          canvas.isDrawingMode = false;
          canvas.selection = true;
          showOrHide("RANDOMONE");
      }
    },
    [canvas, pen, activeTool]
  );

  const addTextToCanvas = useCallback((e) => {
    if (!canvas) return;
    var textBox = new fabric.IText("type here", {
      left: 150,
      top: 200,
      fontSize: text.fontSize,
      fontWeight: text.fontWeight,
      fill: text.color,
      fontFamily: text.fontFamily,
      textAlign: text.textAlign,
      selected: true,
      padding: 10,
    });
    canvas.isDrawingMode = false;
    canvas.eraser = false;
    canvas.add(textBox);
    textBox.setCoords();
    setTimeout(() => canvas.renderAll(), 1000);
    handleToolbar(e, "RANDOMONE");
  });
  const handleCanvasBgColor = useCallback(
    (e) => {
      if (!canvas) return;
      const { value } = e.target;
      // setActiveTool("");
      if (canvas.isDrawingMode) {
        canvas.isDrawingMode = false;
      }
      // if (cursor) {
      //   setCursor(false);
      // }
      if (canvas.getObjects().length > 0) {
        canvas.getObjects().forEach((obj, index) => {
          if (obj.type === "group") {
            for (let i = 0; i < obj._objects.length; i++) {
              if (
                obj._objects[i].type === "path" &&
                obj._objects[i].hasOwnProperty("eraser")
              ) {
                obj.set({
                  stroke: value,
                });
              }
            }
          } else if (
            obj.type === "path" &&
            obj.hasOwnProperty("eraser") &&
            obj.eraser
          ) {
            obj.set({
              stroke: value,
            });
          }
        });
      }
      canvas.setBackgroundColor(value);
      canvas.renderAll();
    },
    [canvas]
  );

  const handleShapes = useCallback(
    (type) => {
      if (!canvas) return;
      switch (type) {
        case "square":
          var square = new fabric.Rect({
            width: 100,
            height: 100,
            stroke: colorPallete,
            strokeWidth: 3,
            left: 200,
            top: 200,
            fill: "transparent",
          });
          canvas.add(square);
          break;

        case "triangle":
          var triangle = new fabric.Triangle({
            width: 100,
            height: 100,
            stroke: colorPallete,
            strokeWidth: 3,
            fill: "transparent",
            opacity: 1,
            left: 200,
            top: 200,
          });
          canvas.add(triangle);
          break;

        case "line":
          var line = new fabric.Line([10, 25, 150, 25], {
            stroke: colorPallete,
            strokeWidth: 3,
            fill: "transparent",
            selectable: true,
            left: 200,
            top: 250,
          });
          canvas.add(line);
          break;

        case "hexa":
          var hexagon = new fabric.Polygon(
            [
              { x: 50, y: 0 },
              { x: 25, y: 43.3 },
              { x: -25, y: 43.301 },
              {
                x: -50,
                y: 0,
              },
              { x: -25, y: -43.301 },
              { x: 25, y: -43.301 },
            ],
            {
              stroke: colorPallete,
              fill: "transparent",
              strokeWidth: 5,
              left: 200,
              top: 200,
            }
          );
          // Adding it to the canvas
          canvas.add(hexagon);
          break;

        case "circle":
          var circle = new fabric.Circle({
            width: 100,
            height: 100,
            radius: 50,
            stroke: colorPallete,
            strokeWidth: 3,
            fill: "transparent",
            opacity: 1,
            left: 200,
            top: 200,
          });
          canvas.add(circle);
          break;

        case "star":
          var points = [
            { x: 349.9, y: 75 },
            { x: 379, y: 160.9 },
            { x: 469, y: 160.9 },
            { x: 397, y: 214.9 },
            {
              x: 423,
              y: 300.9,
            },
            { x: 350, y: 249.9 },
            { x: 276.9, y: 301 },
            { x: 303, y: 215 },
            { x: 231, y: 161 },
            { x: 321, y: 161 },
          ];
          var pol = new fabric.Polygon(points, {
            left: 120,
            top: 120,
            fill: "transparent",
            strokeWidth: 4,
            stroke: colorPallete, // cornerColor: "blue",
          });
          canvas.add(pol);
          break;
        case "heart":
          const heartPath =
            "M433.601,67.001c-24.7-24.7-57.4-38.2-92.3-38.2s-67.7,13.6-92.4,38.3l-12.9,12.9l-13.1-13.1c-24.7-24.7-57.6-38.4-92.5-38.4c-34.8,0-67.6,13.6-92.2,38.2c-24.7,24.7-38.3,57.5-38.2,92.4c0,34.9,13.7,67.6,38.4,92.3l187.8,187.8c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-3.9l188.2-187.5c24.7-24.7,38.3-57.5,38.3-92.4C471.801,124.501,458.301,91.701,433.601,67.001z M414.401,232.701l-178.7,178l-178.3-178.3c-19.6-19.6-30.4-45.6-30.4-73.3s10.7-53.7,30.3-73.2c19.5-19.5,45.5-30.3,73.1-30.3c27.7,0,53.8,10.8,73.4,30.4l22.6,22.6c5.3,5.3,13.8,5.3,19.1,0l22.4-22.4c19.6-19.6,45.7-30.4,73.3-30.4c27.6,0,53.6,10.8,73.2,30.3c19.6,19.6,30.3,45.6,30.3,73.3C444.801,187.101,434.001,213.101,414.401,232.701z";

          // Create a fabric.js Path object for the heart
          const heart = new fabric.Path(heartPath, {
            width: 100,
            height: 100,
            left: 0,
            top: 0,
            fill: "#000",
            stroke: colorPallete,
            strokeWidth: 0.5,
          });

          // Scale the heart to fit within 100x100
          heart.scaleToWidth(100);
          heart.scaleToHeight(100);

          // Position the heart at the desired location
          heart.set({ left: 100, top: 100 });

          // Add the heart to the canvas
          canvas.add(heart);
          break;
      }
      // console.log("canvas.getObjects()", canvas.getObjects())
      canvas.renderAll();
    },
    [canvas]
  );

  const handleShapeOptions = useCallback(
    (e) => {
      if (!canvas) return;
      const { name, value } = e.target;

      // console.log("Name :::::: ", name ,  "Value :::: " , value );
      const obj = canvas.getActiveObject();

      if (!obj) return;
      obj.set({
        [name]: parseInt(value),
      });

      if (!value) {
        setShape((preState) => ({
          ...preState,
          [name]: 0,
        }));
        return;
      }
      setShape((preState) => ({
        ...preState,
        [name]: parseInt(value),
      }));
      // console.log("Name :::::: ", name ,  "Valuecsdsdfsd :::: " , value );

      canvas.renderAll();
    },
    [canvas]
  );

  const resetFabricCanvas = () => {
    if (!canvas) return;
    canvas.clear();
    setShowResetModal(false);
    toast.success("Reset successfully");
    setReinitCanvas((preState) => preState + 1);
  };

  // Handle Lock Layers
  const handleLockLayers = (e) => {
    if (!canvas) return;
    var activeObject = canvas.getActiveObject();
    if (!activeObject) {
      toast.error("No Element is selected");
      return;
    }
    if (activeObject.type === "activeSelection") {
      activeObject._objects.forEach(function (item) {
        item.selectable = false;
        item.evented = false;
        item.hoverCursor = "pointer";
      });
    } else {
      activeObject.selectable = false;
      activeObject.evented = false;
      activeObject.hoverCursor = "default";
    }
    canvas.discardActiveObject().renderAll();
    toast.success("Selection Locked");
  };
  const [editingLayerIndex, setEditingLayerIndex] = useState(null);
  const [layerNames, setLayerNames] = useState(
    canvas ? canvas.getObjects().map((obj) => obj.type) : []
  );

  const toggleEditing = (index) => {
    if (editingLayerIndex === index) {
      setEditingLayerIndex(null); // Save and exit edit mode
    } else {
      setEditingLayerIndex(index); // Enter edit mode
    }
  };

  const handleLayerNameChange = (index, newName) => {
    const updatedLayerNames = [...layerNames];
    updatedLayerNames[index] = newName;
    setLayerNames(updatedLayerNames);
  };

  const handleRenameLayers = (index) => {
    const updatedObjects = canvas.getObjects();
    updatedObjects[index].newName = layerNames[index];
    setEditingLayerIndex(null); // Exit edit mode
    canvas.renderAll(); // Update canvas rendering
  };

  // Handle Unlock Layer
  const handleToggleLockLayers = (objectId) => {
    if (!canvas) return;
    const object = canvas.item(objectId);
    if (object) {
      // If object has no lock state, initialize it to false (unlocked)
      if (object.locked === undefined) {
        object.locked = false;
      }

      // Toggle the lock state
      object.locked = !object.locked;

      object.selectable = !object.locked;
      object.evented = !object.locked;
      object.hoverCursor = object.locked ? "default" : "move";
      if (!object.locked) {
        canvas.setActiveObject(object);
      }
      canvas.renderAll();

      // Display a toast message indicating the lock/unlock state
      toast.success(object.locked ? "Layer Locked" : "Layer Unlocked");
      console.log(object.locked ? "Layer Locked" : "Layer Unlocked");
    }
  };

  const handleToggleVisibility = (objectId) => {
    if (!canvas) return;
    const object = canvas.item(objectId);
    if (object) {
      // Toggle visibility
      // console.log("hide unhide...",object)
      object.visible = !object.visible;
      canvas.renderAll();
    }
    toast.success(object.visible ? "Layer Unhidden" : "Layer Hidden");
  };

  useEffect(() => {
    return () => {
      document.body.style.cursor = "";
    };
  }, []);

  const unGroupElement = (e, type) => {
    if (!canvas) return;
    const { name } = e.target;
    const activeObj = canvas.getActiveObject();

    if (!activeObj) {
      alert("Please select an element to ungroup");
      return;
    }
    if (activeObj.type === "group") {
      // Ungroup
      ungroupAndAddToCanvas(activeObj);
    }
  };
  const ungroupAndAddToCanvas = (group) => {
    // Extract objects from the group
    var items = group._objects;

    // Clear the group to avoid affecting their properties
    group._restoreObjectsState();

    // Remove group from canvas
    canvas.remove(group);

    // Add objects back to the canvas
    for (var i = 0; i < items.length; i++) {
      canvas.add(items[i]);
    }

    canvas.renderAll();
  };

  //group multiple layers
  const createGroupFromSelected = () => {
    if (!canvas) {
      alert("Canvas is not initialized.");
      return;
    }

    const activeObjects = canvas.getActiveObjects();
    if (activeObjects.length === 0) {
      alert("No objects selected.");
      return;
    }

    //group with the selected objects
    const group = new fabric.Group(activeObjects, {
      originX: "left",
      originY: "top",
    });

    const canvasCenter = {
      left: canvas.width / 2,
      top: canvas.height / 2,
    };

    // Calculation the bounding box for the group
    const bound = group.getBoundingRect();

    const groupCenter = {
      left: bound.left + bound.width / 2,
      top: bound.top + bound.height / 2,
    };

    // Calculation of the difference between the canvas center and the group center
    const offset = {
      left: canvasCenter.left - groupCenter.left,
      top: canvasCenter.top - groupCenter.top,
    };

    //group's position to the center of the canvas
    group.set({
      left: group.left + offset.left,
      top: group.top + offset.top,
    });

    canvas.add(group);

    // Remove individual objects from the canvas after group them
    activeObjects.forEach((obj) => {
      canvas.remove(obj);
    });

    // render the canvas to see the changes
    canvas.renderAll();
  };

  const moveLayerUp = (index) => {
    if (index <= 0) return;
    const objects = canvas.getObjects();
    const object = objects[index];
    canvas.remove(object);
    canvas.insertAt(object, index - 1);
    const updatedLayerNames = [...layerNames];
    [updatedLayerNames[index], updatedLayerNames[index - 1]] = [
      updatedLayerNames[index - 1],
      updatedLayerNames[index],
    ];
    setLayerNames(updatedLayerNames);
    canvas.renderAll();
  };

  const moveLayerDown = (index) => {
    const objects = canvas.getObjects();
    if (index >= objects.length - 1) return;
    const object = objects[index];
    canvas.remove(object);
    canvas.insertAt(object, index + 1);
    const updatedLayerNames = [...layerNames];
    [updatedLayerNames[index], updatedLayerNames[index + 1]] = [
      updatedLayerNames[index + 1],
      updatedLayerNames[index],
    ];
    setLayerNames(updatedLayerNames);
    canvas.renderAll();
  };

  return (
    <>
      <div
        className="col-md-1 d-flex justify-content-start flex-column align-items-center px-0 toolbox-customizer "
        style={{ maxWidth: !isMobile ? "3.333333%" : "" }}
      >
        <div
          className="col-md-12 d-flex flex-column align-items-center px-0 text-center customizer-tools  tool-Mobile"
          style={{
            position: "relative",
            backgroundColor: "#F3F3F3",
            height: "100%",
          }}
        >
          <div
            className={
              "sideshow-menu " +
              (activeTool === "RANDOMONE" ? "active-tool" : "") +
              (canvas ? " customBtn" : " without-canvas")
            }
            onClick={(e) => {
              // toggleCursor;
              handleToolbar(e, "RANDOMONE");
            }}
          >
            <img
              className="dropdown-toggle"
              src="/assets/images/toolbox/ArrowsOutCardinal.svg"
            />
          </div>
          <div
            className={
              "sideshow-menu " +
              (activeTool === "alignment" ? "active-tool" : "") +
              (canvas ? " customBtn" : " without-canvas")
            }
            style={{ position: "relative" }}
            onClick={(e) => handleToolbar(e, "alignment")}
          >
            <img
              className=""
              src="/assets/images/toolbox/Alignment.svg"
              id="dropdownMenuLink"
            />

            <ul
              className={"dropdown-menu Aligment-menu " + showAlignment}
              aria-labelledby="dropdownMenuLink"
              style={{ position: "absolute", top: "0px", right: "100%" }}
            >
              <li
                className="px-3 py-1 d-flex justify-content-between"
                style={{ paddingBottom: "0.5rem" }}
                id="show-submenu"
              >
                {" "}
                Align
                <MdKeyboardArrowRight
                  size={30}
                  style={{ paddingBottom: "0.5rem" }}
                  onClick={(e) => {
                    handleToolbar(e, "RANDOMONE");
                  }}
                />
              </li>
              <li className="px-3 py-1">
                <div
                  className=" d-flex justify-content-between"
                  style={{ cursor: "default" }}
                >
                  <img
                    src={align4}
                    name="aligntop"
                    onClick={(e) => handleElementsAlign(e)}
                    style={{ cursor: "pointer" }}
                  />
                  <img
                    src={align3}
                    name="alignright"
                    onClick={(e) => handleElementsAlign(e)}
                    style={{ cursor: "pointer" }}
                  />
                  <img
                    src={align2}
                    name="alignleft"
                    onClick={(e) => handleElementsAlign(e)}
                    style={{ cursor: "pointer" }}
                  />
                  <img
                    src={align}
                    name="centervertically"
                    onClick={(e) => handleElementsAlign(e)}
                    style={{ cursor: "pointer" }}
                  />
                  <img
                    src={align1}
                    name="center"
                    onClick={(e) => handleElementsAlign(e)}
                    style={{ cursor: "pointer" }}
                  />
                  <img
                    src={align5}
                    name="bottom"
                    onClick={(e) => handleElementsAlign(e)}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </li>
            </ul>
          </div>
          <div
            className={
              "sideshow-menu " +
              (activeTool === "diamond" ? "active-tool " : "") +
              (canvas ? "customBtn" : "without-canvas")
            }
            style={{ position: "relative" }}
            onClick={(e) => handleToolbar(e, "diamond")}
          >
            <img
              className=""
              src="/assets/images/toolbox/Diamond.svg"
              id="dropdownMenuLink"
            />

            <ul
              className={"dropdown-menu diamond-menu " + showDiamondSubMenu}
              aria-labelledby="dropdownMenuLink"
              style={{ position: "absolute", top: "0px", right: "100%" }}
            >
              <li
                className="px-3"
                style={{ paddingBottom: "0.5rem" }}
                id="show-submenu"
              >
                <div
                  className="d-flex justify-content-between"
                  style={{ height: "3vh" }}
                >
                  <p style={{ cursor: "pointer" }}>Shape</p>
                  <MdKeyboardArrowRight
                    size={30}
                    style={{ paddingBottom: "0.5rem" }}
                    onClick={(e) => handleToolbar(e, "RANDOMONE")}
                  />
                </div>

                <ul
                  className="dropdown-menu dropdown-sub-menu"
                  aria-labelledby="dropdownMenuLink"
                >
                  <li
                    className="dropdown-item"
                    onClick={(e) => handleShapes("square")}
                  >
                    <a href="#/action-1">
                      <img
                        style={{ paddingRight: "5px" }}
                        src="/assets/images/customizer/Square.png"
                      />
                      Square
                    </a>
                  </li>
                  <li
                    className="dropdown-item"
                    onClick={(e) => handleShapes("triangle")}
                  >
                    <a href="#/action-1">
                      <img
                        style={{ paddingRight: "5px" }}
                        src="/assets/images/customizer/triangle.png"
                      />
                      Triangle
                    </a>
                  </li>
                  <li
                    className="dropdown-item"
                    onClick={(e) => handleShapes("line")}
                  >
                    <a href="#/action-1">
                      <img
                        style={{ paddingRight: "5px" }}
                        src="/assets/images/customizer/LineSegment.png"
                      />
                      Line Segment
                    </a>
                  </li>
                  <li
                    className="dropdown-item"
                    onClick={(e) => handleShapes("hexa")}
                  >
                    <a href="#/action-1">
                      <img
                        style={{ paddingRight: "5px" }}
                        src="/assets/images/customizer/Hexagon.png"
                      />
                      Hexagon
                    </a>
                  </li>
                  <li
                    className="dropdown-item"
                    onClick={(e) => handleShapes("circle")}
                  >
                    <a href="#/action-1">
                      <img
                        style={{ paddingRight: "5px" }}
                        src="/assets/images/customizer/Circle.png"
                      />
                      Circle
                    </a>
                  </li>
                  <li
                    className="dropdown-item"
                    onClick={(e) => handleShapes("star")}
                  >
                    <a href="#/action-1">
                      <img
                        style={{ paddingRight: "5px" }}
                        src="/assets/images/customizer/Star.png"
                      />
                      Star
                    </a>
                  </li>
                  <li
                    className="dropdown-item"
                    onClick={(e) => handleShapes("heart")}
                  >
                    <a href="#/action-1">
                      <img
                        style={{ paddingRight: "7px", paddingLeft: "2px" }}
                        src="/assets/images/customizer/heart.svg"
                      />
                      Heart
                    </a>
                  </li>
                </ul>
              </li>

              <div
                style={{
                  height: "1px",
                  backgroundColor: "#F3F3F3",
                  width: "100%",
                }}
              ></div>
              <li className="px-3 py-1" style={{ cursor: "default" }}>
                Scale
              </li>
              <li
                className="px-3 py-1 d-flex justify-content-between"
                style={{ cursor: "default" }}
              >
                <p
                  className="mb-0"
                  style={{ fontSize: "14px", marginRight: "5px" }}
                >
                  W
                </p>{" "}
                <input
                  type="text"
                  style={{ width: "41px", height: "22px", fontSize: "12px" }}
                  className="px-1"
                  name="width"
                  value={shape.width}
                  onChange={(e) => handleShapeOptions(e)}
                />{" "}
                <img
                  src="/assets/images/shrbw.png"
                  style={{ cursor: "pointer", width: "16px", height: "16px" }}
                />
                <p
                  className="mb-0"
                  style={{ fontSize: "14px", marginRight: "5px" }}
                >
                  H
                </p>{" "}
                <input
                  type="text"
                  style={{ width: "41px", height: "22px", fontSize: "12px" }}
                  className="px-1"
                  name="height"
                  value={shape.height}
                  onChange={(e) => handleShapeOptions(e)}
                />{" "}
              </li>
            </ul>
          </div>

          <div
            className={
              "sideshow-menu " +
              (activeTool === "pen" ? "active-tool " : "") +
              (canvas ? "customBtn" : "without-canvas")
            }
            style={{ position: "relative" }}
            onClick={(e) => {
              handleToolbar(e, "pen");
            }}
          >
            <img src="/assets/images/toolbox/Pen.svg" />
            <ul
              className={"dropdown-menu pen-menu " + showPenSubMenu}
              aria-labelledby="dropdownMenuLink"
              style={{ position: "absolute", top: "0px", right: "100%" }}
            >
              <li className="px-3 py-1" id="show-submenu">
                <div
                  className="d-flex justify-content-between"
                  style={{ cursor: "pointer", height: "3vh" }}
                >
                  <p style={{ cursor: "default" }}>Pen Tool</p>
                  <MdKeyboardArrowRight
                    size={30}
                    style={{ paddingBottom: "0.5rem" }}
                    onClick={(e) => handleToolbar(e, "RANDOMONE")}
                  />
                </div>
              </li>

              <div
                style={{
                  height: "1px",
                  backgroundColor: "#F3F3F3",
                  width: "100%",
                }}
              ></div>
              <li className="px-3 py-1" style={{ cursor: "default" }}>
                Scale
              </li>
              <li
                className="px-3 py-1 d-flex justify-content-between"
                style={{ cursor: "default" }}
              >
                <p
                  className="mb-0"
                  style={{ fontSize: "12px", marginRight: "5px" }}
                >
                  W
                </p>{" "}
                <input
                  type="number"
                  className="px-1"
                  name="width"
                  style={{ fontSize: "12px", width: "41px", height: "22px" }}
                  value={pen.width}
                  onChange={(e) => handlePenOptions(e, "width")}
                />{" "}
                <img
                  src="/assets/images/shrbw.png"
                  style={{ cursor: "pointer", width: "16px", height: "16px" }}
                />
                <p
                  className="mb-0"
                  style={{ fontSize: "14px", marginRight: "5px" }}
                >
                  H
                </p>{" "}
                <input
                  type="text"
                  className="px-1"
                  name="height"
                  value={pen.height}
                  style={{ fontSize: "12px", width: "41px", height: "22px" }}
                  onChange={(e) => handlePenOptions(e, "height")}
                />{" "}
              </li>

              <li className="px-3 py-1 ">
                Fill
                <div
                  className="d-flex justify-content-between align-items-baseline "
                  style={{ height: "4vh", cursor: "default" }}
                >
                  <div>
                    <input
                      type="color"
                      name="fill"
                      style={{
                        width: "16px",
                        height: "16px",
                        margin: "4px",
                        cursor: "pointer",
                      }}
                      onChange={(e) => handlePenOptions(e, "fill")}
                    />
                    <label>{pen.fill}</label>
                  </div>
                  <div>
                    <p className="persentage" style={{ cursor: "pointer" }}>
                      100%
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div
            className={
              "sideshow-menu " +
              (activeTool === "brush" ? "active-tool" : "") +
              (canvas ? " customBtn" : " without-canvas")
            }
            style={{ position: "relative" }}
            onClick={(e) => handleToolbar(e, "brush")}
          >
            <img src="/assets/images/toolbox/PaintBrush.svg" />
            <ul
              className={"dropdown-menu paint-brush " + showBrushSubMenu}
              aria-labelledby="dropdownMenuLink"
              style={{ position: "absolute", top: "0px", right: "100%" }}
            >
              <li
                className="px-3 py-1"
                style={{ cursor: "default" }}
                id="show-submenu"
                // onClick={() => setBrush(state => ({
                //     ...state,
                //     isBrush: !state.isBrush
                // }))}
              >
                <div
                  className="d-flex justify-content-between"
                  style={{ cursor: "default", height: "3vh" }}
                >
                  <p style={{ cursor: "default" }}>Brush Tool</p>
                  <MdKeyboardArrowRight
                    size={30}
                    style={{ paddingBottom: "0.5rem" }}
                    onClick={(e) => handleToolbar(e, "RANDOMONE")}
                  />
                </div>
              </li>
              <div
                style={{
                  height: "1px",
                  backgroundColor: "#F3F3F3",
                  width: "100%",
                }}
              ></div>
              <li className="px-3 py-1" style={{ cursor: "default" }}>
                Brush Size
              </li>
              <li style={{ cursor: "default" }}>
                {" "}
                <input
                  type="number"
                  id="quantity"
                  name="quantity"
                  min="1"
                  max="100"
                  value={brush.width}
                  onChange={(e) => {
                    setBrush((state) => ({
                      ...state,
                      width: e.target.value,
                    }));
                    handleToolbar(e, "brush");
                  }}
                  style={{
                    width: "40px",
                    margin: "7px 15px",
                    textAlign: "center",
                  }}
                />
              </li>
              <li className="px-3 py-1" style={{ cursor: "default" }}>
                <div className="d-flex justify-content-between align-items-baseline ">
                  <div>
                    <input
                      type="color"
                      value={brush.color}
                      onChange={(e) => {
                        setBrush((state) => ({
                          ...state,
                          color: e.target.value,
                        }));
                        handleToolbar(e, "brush");
                      }}
                      style={{
                        width: "16px",
                        height: "16px",
                        margin: "4px",
                        cursor: "pointer",
                      }}
                    />
                    <label>{brush.color}</label>
                  </div>
                  <div>
                    <p className="persentage" style={{ cursor: "pointer" }}>
                      100%
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div
            className={
              "sideshow-menu " +
              (activeTool === "eraser" ? "active-tool" : "") +
              (canvas ? " customBtn" : " without-canvas")
            }
            style={{ position: "relative" }}
            onClick={(e) => handleToolbar(e, "eraser")}
          >
            <img src="/assets/images/toolbox/Eraser.svg" />
            <ul
              className={"dropdown-menu eraser " + showEraserSubMenu}
              aria-labelledby="dropdownMenuLink"
              style={{ position: "absolute", top: "0px", right: "100%" }}
            >
              <li className="px-3 py-1" style={{ cursor: "default" }}>
                <div
                  className="d-flex justify-content-between"
                  style={{ cursor: "default", height: "3vh" }}
                >
                  <p style={{ cursor: "default" }}> Eraser Tool</p>
                  <MdKeyboardArrowRight
                    size={30}
                    style={{ paddingBottom: "0.5rem" }}
                  />
                </div>
              </li>
              <div
                style={{
                  height: "1px",
                  backgroundColor: "#F3F3F3",
                  width: "100%",
                }}
              ></div>
              <li className="px-3 py-1" style={{ cursor: "default" }}>
                {" "}
                Brush Size
              </li>
              <li className="d-flex">
                {" "}
                <input
                  type="number"
                  id="quantity"
                  name="quantity"
                  min="1"
                  max="100"
                  style={{
                    width: "86%",
                    margin: "7px 15px",
                  }}
                  value={eraser.width}
                  onChange={(e) =>
                    setEraser((state) => ({
                      ...state,
                      width: parseInt(e.target.value),
                    }))
                  }
                />
              </li>
            </ul>
          </div>

          <div
            className={
              "sideshow-menu " +
              (activeTool === "text" ? "active-tool" : "") +
              (canvas ? " customBtn" : " without-canvas")
            }
            style={{ position: "relative" }}
          >
            <img
              src="/assets/images/toolbox/TextT.svg"
              onClick={(e) => {
                handleToolbar(e, "text");
              }}
            />
            <ul
              className={"dropdown-menu text-t  " + showTextSubMenu}
              aria-labelledby="dropdownMenuLink"
              style={{ position: "absolute", top: "-182px", right: "100%" }}
            >
              <li className="px-3 py-1" style={{ cursor: "pointer" }}>
                <div
                  className="d-flex justify-content-between"
                  style={{ height: "3vh" }}
                >
                  <p
                    style={{ cursor: "pointer" }}
                    onClick={(e) => addTextToCanvas(e, text)}
                  >
                    Text Tool (click to insert text)
                  </p>
                  <MdKeyboardArrowRight
                    size={30}
                    style={{ paddingBottom: "0.5rem" }}
                    onClick={(e) => handleToolbar(e, "RANDOMONE")}
                  />
                </div>
              </li>
              <div
                style={{
                  height: "1px",
                  backgroundColor: "#F3F3F3",
                  width: "100%",
                }}
              ></div>
              {/* <li className="px-3 py-1" style={{ cursor: "default" }}>
                Align
              </li>
              <li className="px-3 py-1">
                <div
                  className=" d-flex justify-content-between"
                  style={{ cursor: "default" }}
                >
                  <img
                    src={align4}
                    name="aligntop"
                    onClick={(e) => handleElementsAlign(e)}
                    style={{ cursor: "pointer" }}
                  />
                  <img
                    src={align3}
                    name="alignright"
                    onClick={(e) => handleElementsAlign(e)}
                    style={{ cursor: "pointer" }}
                  />
                  <img
                    src={align2}
                    name="alignleft"
                    onClick={(e) => handleElementsAlign(e)}
                    style={{ cursor: "pointer" }}
                  />
                  <img
                    src={align}
                    name="centervertically"
                    onClick={(e) => handleElementsAlign(e)}
                    style={{ cursor: "pointer" }}
                  />
                  <img
                    src={align1}
                    name="center"
                    onClick={(e) => handleElementsAlign(e)}
                    style={{ cursor: "pointer" }}
                  />
                  <img
                    src={align5}
                    name="bottom"
                    onClick={(e) => handleElementsAlign(e)}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </li> */}
              <li className="px-3 py-1" style={{ cursor: "default" }}>
                Font
              </li>
              <li className="px-3 py-1 d-flex justify-content-around align-items-center">
                <select
                  id="select "
                  name="fontFamily"
                  className="form-item__element form-item__element--select  "
                  style={{ width: "100%", margin: "0px", textAlign: "center" }}
                  onChange={(e) => handleTextOptions(e)}
                >
                  <option value="My Font" defaultValue>
                    My Font
                  </option>
                  <option value="Brittany Signature">Brittany Signature</option>
                  <option value="RockoFLF Regular">RockoFLF Regular</option>
                  <option value="RockoFLF Bold">RockoFLF Bold</option>
                </select>
              </li>

              <li className="px-3 py-1 d-flex  align-items-center">
                <div
                  className="d-flex justify-content-between  "
                  style={{ columnGap: "10px" }}
                >
                  <div>
                    <select
                      id="select "
                      className="form-item__element form-item__element--select  "
                      name="fontWeight"
                      style={{
                        width: "20vh",
                        margin: "0px",
                        textAlign: "center",
                      }}
                      onChange={(e) => handleTextOptions(e)}
                    >
                      <option defaultValue value="bold">
                        bold
                      </option>
                      <option value="normal">normal</option>
                    </select>
                  </div>

                  <div>
                    <select
                      id="select "
                      className="form-item__element form-item__element--select  "
                      style={{
                        width: "10vh",
                        margin: "0px",
                        textAlign: "center",
                      }}
                      name="fontSize"
                      onChange={(e) => handleTextOptions(e)}
                    >
                      <option defaultValue value="16">
                        16pt
                      </option>
                      <option value="18">18pt</option>
                      <option value="20">20pt</option>
                      <option value="22">22pt</option>
                      <option value="24">24pt</option>
                      <option value="26">26pt</option>
                    </select>
                  </div>
                </div>
              </li>

              {/* <li className="px-3 py-1" style={{ cursor: "default" }}>
                            Space
                        </li>
                        <li className="px-3 py-1 d-flex  align-items-center">
                            <div className="d-flex   " style={{ columnGap: "20px" }}>
                                <div className="d-flex align-items-center justify-content-end ">
                                    <CgArrowsVAlt size={20} />
                                    <select
                                        id="select "
                                        className="form-item__element form-item__element--select  "
                                        style={{ width: "90%", margin: "0px", textAlign: "center" }}
                                        onChange={(e) => setTitle(title => ({
                                            ...title,
                                            lineHeight: e.target.value
                                        }))}
                                    >
                                        <option defaultValue value="0.5">0.5</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                    </select>
                                </div>

                                <div
                                    className="d-flex align-items-center justify-content-end "
                                    style={{ columnGap: "4px" }}
                                >
                                    <CgArrowsHAlt size={20} />
                                    <select
                                        id="select "
                                        className="form-item__element form-item__element--select  "
                                        style={{ width: "90%", margin: "0px", textAlign: "center" }}
                                        required
                                    >
                                        <option disabled defaultValue value="">
                                            Pt
                                        </option>
                                        <option value="1">Value 1</option>
                                        <option value="2">Value 2</option>
                                    </select>
                                </div>
                            </div>
                        </li> */}

              <li className="px-3 py-1" style={{ cursor: "default" }}>
                Paragraph
              </li>
              <li className="px-3 py-1" style={{ cursor: "default" }}>
                <div className=" d-flex justify-content-between">
                  <img
                    src={para}
                    style={{
                      cursor: "pointer",
                      padding: "5px",
                      borderRadius: "5px",
                      backgroundColor:
                        text.textAlign == "left" ? "#e9e9e9" : "",
                    }}
                    name="left"
                    onClick={(e) => handleTextOptions(e)}
                  />
                  <img
                    src={para1}
                    style={{
                      cursor: "pointer",
                      padding: "5px",
                      borderRadius: "5px",
                      backgroundColor:
                        text.textAlign == "right" ? "#e9e9e9" : "",
                    }}
                    name="right"
                    onClick={(e) => handleTextOptions(e)}
                  />
                  <img
                    src={para2}
                    style={{
                      cursor: "pointer",
                      padding: "5px",
                      borderRadius: "5px",
                      backgroundColor:
                        text.textAlign == "justify" ? "#e9e9e9" : "",
                    }}
                    name="justify"
                    onClick={(e) => handleTextOptions(e)}
                  />
                  <img
                    src={para3}
                    style={{
                      cursor: "pointer",
                      padding: "5px",
                      borderRadius: "5px",
                      backgroundColor:
                        text.textAlign == "center" ? "#e9e9e9" : "",
                    }}
                    name="center"
                    onClick={(e) => handleTextOptions(e)}
                  />
                </div>
              </li>

              <li className="px-3 py-1" style={{ cursor: "default" }}>
                Decoration
              </li>
              <li className="px-3 py-1" style={{ cursor: "default" }}>
                <div className=" d-flex justify-content-between">
                  <img
                    src={decore}
                    style={{
                      cursor: "pointer",
                      padding: "5px",
                      borderRadius: "5px",
                      backgroundColor: text.underline ? "#e9e9e9" : "",
                    }}
                    name="underline"
                    onClick={(e) => handleTextOptions(e)}
                  />
                  <img
                    src={decore1}
                    style={{
                      cursor: "pointer",
                      padding: "5px",
                      borderRadius: "5px",
                      backgroundColor:
                        textFonstStyle.italic === true ? "#e9e9e9" : "",
                    }}
                    name="italic"
                    onClick={(e) => handleTextOptions(e)}
                  />
                  <img
                    src={decore2}
                    style={{
                      cursor: "pointer",
                      padding: "5px",
                      borderRadius: "5px",
                      backgroundColor:
                        textFonstStyle.bold === true ? "#e9e9e9" : "",
                    }}
                    name="bold"
                    onClick={(e) => handleTextOptions(e)}
                  />
                  <img
                    src={decore3}
                    style={{
                      cursor: "pointer",
                      padding: "5px",
                      borderRadius: "5px",
                      backgroundColor: text.linethrough ? "#e9e9e9" : "",
                    }}
                    name="linethrough"
                    onClick={(e) => handleTextOptions(e)}
                  />
                </div>
              </li>

              <li className="px-3 py-1 " style={{ cursor: "default" }}>
                Fill
                <div
                  className="d-flex justify-content-between align-items-baseline "
                  style={{ height: "4vh", cursor: "default" }}
                >
                  <div>
                    <input
                      type="color"
                      name="fill"
                      style={{
                        width: "16px",
                        height: "16px",
                        margin: "4px",
                        cursor: "pointer",
                      }}
                      onChange={(e) => {
                        handleTextOptions(e);
                        setTextColor(e.target.value);
                      }}
                    />
                    <label>{textColor}</label>
                  </div>
                  {/* <div>
                                    <p className="persentage" style={{cursor: "pointer"}}>
                                        100%
                                    </p>
                                </div> */}
                </div>
              </li>
              <li className="px-3 py-1 " style={{ cursor: "default" }}>
                Stroke
                <div
                  className="d-flex justify-content-between align-items-baseline"
                  style={{ height: "4vh", cursor: "default" }}
                >
                  <div>
                    <input
                      type="color"
                      style={{
                        width: "16px",
                        height: "16px",
                        margin: "4px",
                        cursor: "pointer",
                      }}
                      onChange={(e) => handleColorPallete(e)}
                    />
                    <label>{colorPallete}</label>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div
            className={
              "sideshow-menu " + (canvas ? " customBtn" : " without-canvas")
            }
            style={{ position: "relative" }}
          >
            <img src="/assets/images/toolbox/Stack.svg" />
            <ul
              className={"dropdown-menu stack"}
              aria-labelledby="dropdownMenuLink"
              style={{
                position: "absolute",
                top: "0px",
                right: "100%",
                overflow: "auto",
                height: "250px",
              }}
            >
              <li className="px-3 py-1" style={{ cursor: "default" }}>
                <div
                  className="d-flex justify-content-between"
                  style={{ height: "3vh" }}
                >
                  <p style={{ cursor: "default" }}>Layers</p>
                  <MdKeyboardArrowRight
                    size={30}
                    style={{ paddingBottom: "0.5rem" }}
                  />
                </div>
              </li>
              <div
                style={{
                  height: "1px",
                  backgroundColor: "#F3F3F3",
                  width: "100%",
                  marginBottom: "2%",
                }}
              ></div>

              <li>
                {/* <Accordion defaultActiveKey="0" className="customizer--accordion">
                <Accordion.Item eventKey="0">
                  <Accordion.Header >
                    <div className="d-flex " style={{ fontSize: "12px" }}>
                      Group 1
                    </div>
                    <div className="d-flex" style={{ columnGap: "6px" }}>
                      <img
                        src={lock}
                        style={{ width: "10px", height: "10px" }}
                      />
                      <img
                        src={eye}
                        style={{ height: "10px", width: "10px" }}
                      />
                    </div>
                  </Accordion.Header>

                  <Accordion.Body>
                    <>
                      <div className="py-1 d-flex justify-content-between align-items-center">
                        <div style={{ paddingLeft: "9%" }}>Layer 1</div>
                        <div className="d-flex" style={{ columnGap: "6px" }}>
                          <img
                            src={lock}
                            style={{ width: "10px", height: "10px" }}
                          />
                          <img
                            src={eye}
                            style={{ height: "10px", width: "10px" }}
                          />
                        </div>
                      </div>

                      <div className="py-1 d-flex justify-content-between align-items-center">
                        <div>
                          {" "}
                          <img src={text} style={{ paddingRight: "3px" }} />
                          Text Layer{" "}
                        </div>
                        <div className="d-flex" style={{ columnGap: "6px" }}>
                          <img
                            src={lock}
                            style={{ width: "10px", height: "10px" }}
                          />
                          <img
                            src={eye}
                            style={{ height: "10px", width: "10px" }}
                          />
                        </div>
                      </div>

                      <div className="py-1 d-flex justify-content-between align-items-center">
                        <div>
                          <img src={text1} style={{ paddingRight: "3px" }} />
                          Path Layer{" "}
                        </div>
                        <div className="d-flex" style={{ columnGap: "6px" }}>
                          <img
                            src={lock}
                            style={{ width: "10px", height: "10px" }}
                          />
                          <img
                            src={eye}
                            style={{ height: "10px", width: "10px" }}
                          />
                        </div>
                      </div>

                      <div className="py-1 d-flex justify-content-between align-items-center">
                        <div>
                          <img src={text2} style={{ paddingRight: "3px" }} />
                          Shape Layer{" "}
                        </div>
                        <div className="d-flex" style={{ columnGap: "6px" }}>
                          <img
                            src={lock}
                            style={{ width: "10px", height: "10px" }}
                          />
                          <img
                            src={eye}
                            style={{ height: "10px", width: "10px" }}
                          />
                        </div>
                      </div>
                    </>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion> */}

                {/* <div className=" px-3 py-1 d-flex justify-content-between align-items-center">
                <div style={{ fontSize: "10px", fontWeight: "600" }}>
                  {" "}
                  Group 2{" "}
                </div>
                <div className="d-flex" style={{ columnGap: "6px" }}>
                  <img src={lock} style={{ width: "10px", height: "10px" }} />
                  <img src={eye} style={{ height: "10px", width: "10px" }} />
                </div>
              </div> */}
                {canvas !== null &&
                  canvas.getObjects().length > 0 &&
                  canvas.getObjects().map((object, index) => {
                    const isEditing = editingLayerIndex === index;

                    return (
                      <div
                        className="px-3 py-1 d-flex justify-content-between align-items-center"
                        key={index}
                      >
                        {isEditing ? (
                          <input
                            type="text"
                            style={{
                              height: "22px",
                              wisth: "90%",
                              padding: "4px",
                              fontSize: "12px",
                            }}
                            value={layerNames[index]}
                            onChange={(e) =>
                              handleLayerNameChange(index, e.target.value)
                            }
                            onBlur={() => handleRenameLayers(index)}
                          />
                        ) : (
                          <div
                            style={{
                              fontSize: "10px",
                              fontWeight: "600",
                              textTransform: "capitalize",
                            }}
                            onClick={() => handleLayer(index)}
                          >
                            {object.newName || object.type} Layer
                          </div>
                        )}
                        <span onClick={() => toggleEditing(index)}>
                          {isEditing && (
                            <i class="fa fa-floppy-o" aria-hidden="true"></i>
                          )}
                        </span>
                        {!isEditing && (
                          <div
                            className="d-flex align-items-center"
                            style={{ columnGap: "6px" }}
                          >
                            <span
                              style={{ width: "10px", height: "15px" }}
                              onClick={() => toggleEditing(index)}
                            >
                              <i
                                class="fa fa-pencil-square-o"
                                aria-hidden="true"
                              ></i>
                            </span>
                            <img
                              src={lock}
                              style={{ width: "10px", height: "10px" }}
                              onClick={(e) => handleToggleLockLayers(index)}
                            />
                            <img
                              src={eye}
                              style={{ height: "10px", width: "10px" }}
                              onClick={(e) => handleToggleVisibility(index)}
                            />
                            <img
                              src="/assets/images/toolbox/upArrow.svg"
                              style={{
                                width: "10px",
                                height: "10px",
                                cursor: index === 0 ? "not-allowed" : "pointer",
                              }}
                              onClick={() => {
                                if (index !== 0) {
                                  moveLayerUp(index);
                                }
                              }}
                            />

                            <img
                              src="/assets/images/toolbox/downArrow.svg"
                              style={{
                                width: "10px",
                                height: "10px",
                                cursor:
                                  index === canvas.getObjects().length - 1
                                    ? "not-allowed"
                                    : "pointer",
                              }}
                              onClick={() => {
                                if (index !== canvas.getObjects().length - 1) {
                                  moveLayerDown(index);
                                }
                              }}
                            />
                          </div>
                        )}
                      </div>
                    );
                  })}
                {canvas === null && (
                  <div className="text-center">No Layer added yet</div>
                )}
              </li>
            </ul>
          </div>

          <div
            className={
              "sideshow-menu " + (canvas ? " customBtn" : " without-canvas")
            }
            style={{ position: "relative" }}
            onClick={(e) => handleToolbar(e, "colorpallete")}
          >
            <img src="/assets/images/toolbox/Palette.svg" />
            <ul
              className={
                "dropdown-menu pallete-menu " + showColorpalleteSubMenu
              }
              aria-labelledby="dropdownMenuLink"
              style={{ position: "absolute", top: "0px", right: "100%" }}
            >
              <li className="px-3 py-1" style={{ cursor: "default" }}>
                <div
                  className="d-flex justify-content-between"
                  style={{ height: "3vh" }}
                >
                  <p style={{ cursor: "default" }}>Color pallete</p>
                  <MdKeyboardArrowRight
                    size={30}
                    style={{ paddingBottom: "0.5rem" }}
                  />
                </div>
              </li>
              <div
                style={{
                  height: "1px",
                  backgroundColor: "#F3F3F3",
                  width: "100%",
                  marginBottom: "2%",
                }}
              ></div>
              <li>
                <div
                  className=" px-3 py-1 d-flex justify-content-between align-items-baseline "
                  style={{ cursor: "default", height: "5vh" }}
                >
                  <div>
                    <input
                      type="color"
                      name="fill"
                      style={{
                        width: "16px",
                        height: "16px",
                        margin: "4px",
                        cursor: "pointer",
                      }}
                      onChange={(e) => {
                        handleTextOptions(e);
                        setTextColor(e.target.value);
                      }}
                    />
                    <label>{textColor}</label>
                  </div>
                  <div>
                    <p className="persentage">Fill Color</p>
                  </div>
                </div>
              </li>
              <li>
                <div
                  className=" px-3 py-1 d-flex justify-content-between align-items-baseline "
                  style={{ cursor: "default", height: "5vh" }}
                >
                  <div>
                    {/* <img src={paint} style={{ cursor: "pointer" }} /> */}
                    <input
                      type="color"
                      style={{
                        width: "16px",
                        height: "16px",
                        margin: "4px",
                        cursor: "pointer",
                      }}
                      onChange={(e) => handleColorPallete(e)}
                    />
                    <label>{colorPallete}</label>
                  </div>
                  <div>
                    <p className="persentage">Stroke</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div
            className={
              "sideshow-menu " +
              (activeTool === "Bucket" ? "active-tool" : "") +
              (canvas ? " customBtn" : " without-canvas")
            }
            style={{ position: "relative" }}
          >
            <img
              src="/assets/images/toolbox/PaintBucket.svg"
              onClick={(e) => {
                handleToolbar(e, "Bucket");
              }}
            />
            <ul
              className={"dropdown-menu paint-backet " + showBucket}
              aria-labelledby="dropdownMenuLink"
              style={{ position: "absolute", top: "0px", right: "100%" }}
            >
              <li
                className="px-3 py-2"
                id="show-submenu"
                style={{ borderBottom: "1px solid #F3F3F3", cursor: "default" }}
              >
                <div
                  className="d-flex justify-content-between"
                  style={{ height: "3vh" }}
                >
                  <p style={{ cursor: "default" }}>Color Bucket</p>
                  <MdKeyboardArrowRight
                    size={30}
                    style={{ paddingBottom: "0.5rem" }}
                    onClick={(e) => {
                      handleToolbar(e,"RANDOMONE")
                     }}
                  />
                </div>
                <p
                  className="mb-0"
                  style={{ fontSize: "12px", cursor: "default" }}
                >
                  Choose the color of your background
                </p>
              </li>
              <div
                style={{
                  height: "1px",
                  backgroundColor: "#F3F3F3",
                  width: "100%",
                  marginBottom: "2%",
                }}
              ></div>
              <div className="row justify-content-center">
                <div className="col-md-12" style={{ cursor: "default" }}>
                  <div className="col-md-12 d-flex flex-wrap">
                    {colors.map((item, index) => {
                      return (
                        <input
                          type="color"
                          value={item}
                          key={index}
                          style={{
                            cursor: "pointer",
                            width: "26px",
                            height: "26px",
                            margin: "7px 9px",
                          }}
                          onChange={(e) => handleCanvasBgColor(e)}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </ul>
          </div>

          {/* Lock Layers */}

          <div className={"" + (canvas ? " customBtn" : " without-canvas")}>
            <img
              src={lockLayers}
              style={{ width: "18px", height: "25px", cursor: "pointer" }}
              className="sideshow-menu"
              title="Lock these"
              onClick={(e) => handleLockLayers(e)}
            />
          </div>

          {/* Reset Canvas */}
          <div className={"" + (canvas ? " customBtn" : " without-canvas")}>
            <img
              src="/assets/images/reset.svg"
              style={{ width: "18px", height: "25px", cursor: "pointer" }}
              className="sideshow-menu"
              title="reset changes"
              onClick={(e) => {
                if (!canvas) return;
                setShowResetModal(true);
              }}
            />
          </div>

          <div className={"" + (canvas ? " customBtn" : " without-canvas")}>
            <img
              src="/assets/images/set_to_top.svg"
              style={{ width: "18px", height: "25px", cursor: "pointer" }}
              className="sideshow-menu"
              alt="bring to front"
              title="bring to front"
              onClick={(e) => {
                if (!canvas) return;
                canvas.bringToFront(canvas.getActiveObject());
              }}
            />
          </div>

          <div className={"" + (canvas ? " customBtn" : " without-canvas")}>
            <img
              src="/assets/images/ungroup.svg"
              style={{ width: "18px", height: "25px", cursor: "pointer" }}
              className="sideshow-menu"
              alt="Un Group"
              title="Un Group"
              onClick={(e) => unGroupElement(e)}
            />
          </div>
          <div className={"" + (canvas ? " customBtn" : " without-canvas")}>
            <img
              src="/assets/images/group.svg"
              style={{ width: "18px", height: "25px", cursor: "pointer" }}
              className="sideshow-menu"
              alt="Group"
              title="Group"
              onClick={(e) => createGroupFromSelected()}
            />
          </div>
          <div
            // style={{
            //     position: "absolute", bottom: "180px", /* text-align: center; */
            //     left: "0px", right: "0px", cursor: "pointer",
            // }}
            className={"" + (canvas ? " customBtn" : " without-canvas")}
            onClick={() => setDownloadCanvasImg(makeCanvasImg + 1)}
          >
            <img src="/assets/images/toolbox/CloudArrowDown.svg" />
          </div>
        </div>
      </div>
      <ResetCanvas
        show={resetCanvasModal}
        onHide={(e) => setShowResetModal(false)}
        resetFabricCanvas={resetFabricCanvas}
        canvas={canvas}
      />
    </>
  );
};

export default Toolbar;
