
import api from "./api";

export const getPrintFulToken = async (code) => {
  try {
    const response = await api.post("/api/v1/printful/token", {
      code: code,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getPrintfulProducts = async () => {
  try {
    const response = await api.get("/api/v1/printful/products/printful");
    return response;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};
export const getPrintFulCategories = async () => {
  try {
    const response = await api.get("/api/v1/printful/categories");
    return response;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};

export const getPrintFulPrices = async (printfulId) => {
  try {
    const response = await api.get(`/api/v1/printful/get-variant/${printfulId}`);
    return response;
  } catch (error) {
    console.error(error);
    return error.response;
  }
}


export const getPrintFulProductImage = async (printfulId) => {
  try {
    const response = await api.get(`/api/v1/printful/getsinglevariant/${printfulId}`);
    return response;
  } catch (error) {
    console.error(error);
    return error.response;
  }
}

export const getSelectedVariantImage = async (payload) => {
  try {
    const response = await api.post(
      `/api/v1/printful/getvariantimage`, payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};