import { useCallback, useEffect, useRef, useState } from "react";
import { fabric } from "fabric";
import { useNavigate, useParams } from "react-router";
import { getPersistentData, persistData } from "../components/Constants/persistentHelper";
import SidbarAdminCustomizer from "../components/customizer/SidbarAdminCustomizer";
import Tool from "../components/customizer/Tool";
import UploadMediaAdminCustomizer from "../components/customizer/UplaodMediaAdminCustomizer";
import "../components/customizer/customizer.css";
import "../components/customizer/dropdown.css";
import CustomizerNav from "../components/layouts/CustomizerNav";
import { getPrintFulCategories, getPrintfulProducts } from "../services/PrintFulService";
import { getProductById } from "../services/customizerService";

import ResponsiveNavbar from "./AdminResponsiveNavbar";

const AdminCustomizer = () => {
    const [fileData, setFileData] = useState();
    const [canvasState, setCanvasState] = useState("");
    const [canvasClone, setCanvasClone] = useState(null);
    const [download, setDownload] = useState(false);
    const [undo, setUndo] = useState(0);
    const [redo, setRedo] = useState(0);
    const [layerIndex, handleLayer] = useState(null);
    const [canvascolor, setcanvascolor] = useState("white");
    const [timestamp, setTimeStamp] = useState(null);
    const [makeCanvasImg, setDownloadCanvasImg] = useState(0);
    const [history, setHistory] = useState([]);
    const [currentStep, setCurrentStep] = useState(-1);
    const [product, setEditCanvasState] = useState(null);
    const [canvas, initCanvas] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [idArray, setIdArray] = useState([19, 71, 146, 300, 581, 505]);
    const [mode, setMode] = useState(null)
    const canvasRef = useRef();
    const [deletedElementSrc, setDeletedElementSrc] = useState(null);
    const ignoreObjectAddedEvent = useRef(true);
    const [printfulPrice, setPrintfulPrice] = useState(null);
    const [canvasSize, setCanvasSize] = useState({
        width: 500, height: 500
    });

    const handleUndo = () => setUndo(undo + 1);
    const handleRedo = () => setRedo(redo + 1);

    const { id } = useParams();

    useEffect(() => {
        if (id) {
            console.log('id', id);
            getProducts();
        }
    }, []);

    const navigate = useNavigate();

    const customFun = useCallback((myCanvas, e) => {
        fabric.Object.prototype.transparentCorners = false;
        fabric.Object.prototype.cornerColor = 'black';
        fabric.Object.prototype.cornerStyle = 'circle';
        if (e.target.type === "path" && myCanvas.hasOwnProperty("eraser") && myCanvas.eraser) {
            if (myCanvas.getObjects().length > 0) {
                myCanvas.getObjects().forEach((obj, index) => {
                    if (e.target.intersectsWithObject(obj)) {
                        e.target.set({
                            evented: false, selectable: false, eraser: true
                        })
                        obj.set({
                            evented: false, selectable: false
                        })
                        myCanvas.renderAll();
                    }
                })
            }
        }
        fabric.Object.prototype.controls.deleteControl = new fabric.Control({
            x: 0.5,
            y: -0.5,
            offsetY: 5,
            cursorStyle: 'pointer',
            mouseUpHandler: deleteObject,
            render: renderIcon,
            cornerSize: 28
        });
        fabric.Object.prototype.controls.mtr = new fabric.Control({
            x: 0,
            y: -0.5,
            offsetY: -40,
            cursorStyle: 'grabbing',
            actionHandler: fabric.controlsUtils.rotationWithSnapping,
            actionName: 'rotate',
            mouseUpHandler: rotateObject, // Add this line to handle the rotate functionality
            render: renderiCon,
            cornerSize: 28,
            withConnection: true
        });
        updateTimeStamp(myCanvas);
        // console.log(ignoreObjectAddedEvent.current);
        if (ignoreObjectAddedEvent.current) {
            updateHistory(myCanvas);
        }
    }, []);



    function rotateObject(eventData, transform) {
        var target = transform.target;
        target.setCoords();
        target.canvas.renderAll();
        localStorage.setItem("CANVAS", JSON.stringify(target.canvas.toJSON()));
    }

    const updateHistory = (myCanvas) => {
        const canvasState = myCanvas.toJSON();
        const newHistory = history.slice(0, currentStep + 1);
        newHistory.push(canvasState);
        setHistory(state => ([...state, newHistory[0]]));
        setCurrentStep((step) => step + 1);
    }

    const updateTimeStamp = useCallback((myCanvas) => {
        // console.log("object modified");
        let date = new Date();
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let ampm = hours > 12 ? 'pm' : 'am';
        hours = hours > 12 ? hours - 12 : hours;
        hours = hours < 10 ? '0' + hours : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        let time = hours + ':' + minutes + ampm;
        let updatedCanvas = JSON.stringify(myCanvas.toJSON(['selectable', 'evented', 'eraser']));
        localStorage.setItem("CANVAS", updatedCanvas);
        localStorage.setItem("CANVAS_UPDATED_AT", time);
        setTimeStamp(time);
        setDownload((prevState) => !prevState);
    }, []);



    function deleteObject(eventData, transform) {
        setDeletedElementSrc(transform?.target?._element?.currentSrc);
        var target = transform.target;
        var canvas = target.canvas;
        canvas.remove(target);
        canvas.requestRenderAll();
        localStorage.setItem("CANVAS", JSON.stringify(canvas.toJSON()));
    }

    function renderIcon(ctx, left, top, styleOverride, fabricObject) {
        var size = this.cornerSize;
        ctx.save();
        ctx.translate(left, top);
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
        ctx.drawImage(document.getElementById("del-icon"), -size / 2, -size / 2, size, size);
        ctx.restore();
    }

    function renderiCon(ctx, left, top, styleOverride, fabricObject) {
        var size = this.cornerSize;
        ctx.save();
        ctx.translate(left, top);
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
        ctx.drawImage(document.getElementById("rotate-icon"), -size / 2, -size / 2, size, size); // Add this line to render the rotate icon
        ctx.restore();
    }

    // initialize fabric canvas
    const initializeCanvas = useCallback((image = null, canvas = null) => {
        if (canvasRef.current && typeof canvasRef.current?.dispose === "function") {
            canvasRef.current?.dispose();
        }

        // console.log("initialize canvas ", canvascolor);
        document.getElementById("canvas").classList.remove("d-none");
        var myCanvas = new fabric.Canvas("canvas", {
            height: canvasSize.height, width: canvasSize.width, backgroundColor: canvascolor || "white"
        });

        if (canvas !== null) {
            myCanvas.loadFromJSON(canvas);
        }

        if (image !== null) {
            fabric.Image.fromURL(image, (img) => {
                // img._element.crossOrigin = '*';
                myCanvas.setBackgroundImage(img, myCanvas.renderAll.bind(myCanvas), {
                    scaleX: myCanvas.width / img.width, scaleY: myCanvas.height / img.height
                });
            }, { crossOrigin: 'anonymous' });
        }

        myCanvas.on('object:added', (e) => customFun(myCanvas, e));
        myCanvas.on('object:modified', (e) => updateTimeStamp(myCanvas));
        myCanvas.on('path:created', function (e) {
            e.path.set();
            myCanvas.renderAll();
        });

        myCanvas.on('mouse:wheel', function (opt) {
            var delta = opt.e.deltaY;
            var zoom = myCanvas.getZoom();
            zoom *= 0.999 ** delta;
            if (zoom > 20) zoom = 20;
            if (zoom < 0.01) zoom = 0.01;
            // setZoom(zoom)
            // console.log("zoom should be updated to:",zoom)
            myCanvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
            opt.e.preventDefault();
            opt.e.stopPropagation();
        });

        myCanvas.renderAll();
        setCanvasClone(myCanvas)
        initCanvas(myCanvas)
        canvasRef.current = myCanvas;
        return myCanvas;
    }, []);


    const getProducts = async () => {
        // const response = await getProductById(id);
        // if (response.status === 401) {
        //     localStorage.removeItem("token");
        //     localStorage.removeItem("user");
        //     navigate("/login");
        //     return;
        // }
        // if (response.status === 200) {
        //     setEditCanvasState(response.data.data);
        // }
    };

    const [printFulProducts, setPrintFulProducts] = useState();
    const [printfulCategories, setPrintfulCategories] = useState();
    const [filterPrintfulProducts, setFilterPrintfulProducts] = useState();
    const [loader, setLoader] = useState(true);

   


    const [reinitCanvas, setReinitCanvas] = useState(0);
    useEffect(() => {
        if (reinitCanvas > 0) {
            console.log("setting canvas to null")
            initCanvas(null);
        }
    }, [reinitCanvas]);

    return (<>
        {/* <CustomizerNav
            fileData={fileData}
            canvasState={canvasState}
            product={product}
            canvas={canvasClone}
            timestamp={timestamp}
            setDownload={setDownload}
            history={history}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            handleUndo={handleUndo}
            handleRedo={handleRedo}
            selectedProduct={selectedProduct}
            mode={mode}
            setMode={setMode}
            initializeCanvas={initializeCanvas}
            printfulPrice={printfulPrice}
        /> */}
        <ResponsiveNavbar/>
        <div
            className="row customizer"
            style={{
                backgroundColor: "rgba(249, 249, 249, 1)", minHeight: "100vh", overflowY: "hidden",
            }}
        >
            <div className="col-md-12 d-flex justify-content-between">
                <SidbarAdminCustomizer
                    printfulCategories={printfulCategories}
                    printFulProducts={printFulProducts}
                    filterPrintfulProducts={filterPrintfulProducts}
                    setFilterPrintfulProducts={setFilterPrintfulProducts}
                    loader={loader}
                    canvas={canvasClone}
                    selectedProduct={selectedProduct}
                    setSelectedProduct={setSelectedProduct}
                    mode={mode}
                    initializeCanvas={initializeCanvas}
                    canvasRef={canvasRef}
                />
                <UploadMediaAdminCustomizer
                    download={download}
                    setDownload={setDownload}
                    setFileData={setFileData}
                    setCanvasState={setCanvasState}
                    oldCanvas={canvas}
                    product={product}
                    undo={undo}
                    redo={redo}
                    canvasss={canvasClone}
                    layerIndex={layerIndex}
                    canvascolor={canvascolor}
                    setTimeStamp={setTimeStamp}
                    makeCanvasImg={makeCanvasImg}
                    history={history}
                    setHistory={setHistory}
                    setCurrentStep={setCurrentStep}
                    currentStep={currentStep}
                    reinitCanvas={reinitCanvas}
                    selectedProduct={selectedProduct}
                    setSelectedProduct={setSelectedProduct}
                    mode={mode}
                    setMode={setMode}
                    printfulCategories={printfulCategories}
                    printFulProducts={printFulProducts}
                    filterPrintfulProducts={filterPrintfulProducts}
                    setFilterPrintfulProducts={setFilterPrintfulProducts}
                    loader={loader}
                    canvasRef={canvasRef}
                    deletedElementSrc={deletedElementSrc}
                    setDeletedElementSrc={setDeletedElementSrc}
                    ignoreObjectAddedEvent={ignoreObjectAddedEvent}
                    canvasSize={canvasSize}
                    setCanvasSize={setCanvasSize}
                    canvas={canvas}
                    initCanvas={initCanvas}
                    initializeCanvas={initializeCanvas}
                    setPrintfulPrice={setPrintfulPrice}
                />

                <Tool
                    canvas={canvasClone}
                    handleLayer={handleLayer}
                    setDownloadCanvasImg={setDownloadCanvasImg}
                    makeCanvasImg={makeCanvasImg}
                    reinitCanvas={reinitCanvas}
                    setReinitCanvas={setReinitCanvas}
                />
            </div>
        </div>
    </>);
}

export default AdminCustomizer