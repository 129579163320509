import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import ppro from "../assets/images/cup.png";
import AlsoLike from "../components/AlsoLike";
import { getProductsById } from "../services/ShopService";
import { toast } from "react-toastify";
import { AddCart } from "../services/CartService";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { login } from "../services/AuthService";
import MugCenter from "../assets/images/center.webp"

const ProductDetail = ({ setCartCount }) => {
  const navigate = useNavigate();
  const [payload, setPayLoad] = useState(null);
  const [products, setProducts] = useState([]);
  const [variants, setVariants] = useState([]);
  const [uniqueSizes, setuniqueSizes] = useState([]);
  const [uniqueColors, setuniqueColors] = useState([]);
  const [loader, setLoader] = useState();
  const [selectedColor, setSelectedColor] = useState();
  const [selectedSize, setSelectedSize] = useState();
  const [selectedVariantID,setSelectedVariantID] = useState(0);
  const [selectedImage,setSelectedImage] = useState(null);
  const [quantity,setQuantity] = useState(1);

  const handleQuantityChange = (e) => {

    const selectedValue = e.target.value;
    setQuantity(selectedValue);
  }

  const handleColorChange = (event) => {
    setSelectedColor(event.target.value);
    const filteredVariant = variants.find(
      (item) =>
        item.color_code === event.target.value && item.size === selectedSize
    );
    setSelectedVariantID(filteredVariant?.id);
    setSelectedImage(filteredVariant?.image);
  };
  const filterVariantsColors = (event) => {
    setSelectedSize(event.target.value);
    const filteredColors = variants.filter(
      (item) => item.size === event.target.value
    );
    const filteredNames = filteredColors.map((item) => item.color_code);
    setuniqueColors(filteredNames);
    const filteredVariant = variants.find(
      (item) =>
        item.size === event.target.value && item.color_code === selectedColor
    );
    setSelectedVariantID(filteredVariant?.id);
    setSelectedImage(filteredVariant?.image);
  };
  const renderTooltip = (text) => <Tooltip id="tooltip">{text}</Tooltip>;

  const { id } = useParams();
  const user = JSON.parse(localStorage.getItem("user"));
  useEffect(() => {
    getCartData();
  }, []);
  
  const quantities = [
    {
      quantity: 1,
      value: "one",
    },
    {
      quantity: 2,
      value: "two",
    },
    {
      quantity: 3,
      value: "three",
    },
    {
      quantity: 4,
      value: "four",
    },
    {
      quantity: 5,
      value: "five",
    },
  ];

  const getCartData = async () => {
    setLoader(true);
    const response = await getProductsById(id);
    if (response.status === 200) {
      setPayLoad(response.data.data);
      setProducts(response.data.products);
      // console.log('variants',response.data.variant);
      // return;
      setVariants(response.data.variant);
      let uniqueSizeses = [
        ...new Set(response.data.variant?.map((item) => item.size)),
      ];
      let uniqueColorses = [
        ...new Set(response.data.variant?.map((item) => item.color_code)),
      ];
      // setuniqueColors(uniqueColorses);
      setuniqueSizes(uniqueSizeses);
      // get variantID 
        setSelectedSize(uniqueSizeses[0]);
        setSelectedColor(uniqueColorses[0]);
        
        const filteredColors = response.data.variant?.filter(
          (item) => item.size === uniqueSizeses[0]
        );
        const filteredNames = filteredColors?.map((item) => item.color_code);
        setuniqueColors(filteredNames);

        // get VariantID
        const filteredVariant = response.data.variant?.find(
          (item) =>
            item.color_code === uniqueColorses[0] && item.size === uniqueSizeses[0]
        );
        setSelectedVariantID(filteredVariant?.id);
        setSelectedImage(filteredVariant?.image);

      // 
      setLoader(false);
    } else {
      toast.error(response.data.message);
      setLoader(false);
    }
  };

  const viewCart = () => {
    navigate("/carts");
  };

  const addToCart = async () => {
    setCartCount((prevCount) => {
      const newCount = parseInt(prevCount, 10) + 1;
      localStorage.setItem("cartCount", newCount);
      return newCount;
    });

    setPayLoad((prevPayload) => ({ ...prevPayload, in_cart: true }));

    const finalData = {
      product_id: id,
      variant_id: selectedVariantID ? selectedVariantID : 0,
      quantity: quantity
    };
    
    const response = await AddCart(finalData);
    if (response.status === 200) {
      toast.success("Product added");
    } else {
      toast.error(response.data.message);
    }
  };

  const buyNow = (itemID) => {
    
    const variantId = selectedVariantID;
    const url = `/checkout/${itemID}${variantId ? `?variantId=${variantId}` : ''}`;
    navigate(url);  
  };

  return (
    <>
      <div className="container product--detail ">
      {
        loader ? (
          
            <div className="d-flex justify-content-center pt-5 pb-5">
                <div className="spinner-grow" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        ) : (
          <div className="row d-flex p-4 pt-5 justify-content-center mb-5 " style={{ backgroundColor: "rgb(243, 243 ,243, 50%)" }}>
            
            <div className="col-lg-5 col-md-7 col-12 ">
            {payload?.type === 1 ? (  
            <div
                style={{
                  width: "100%",
                  height: "500px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  objectFit: "contain", // This is not needed for a background image
                  backgroundImage: (payload?.printful_product_id === 19 || payload?.printful_product_id === 300) && payload?.placement === "Center"
                    ? `url(${payload?.front_mockup})`
                    : (payload?.printful_product_id === 19 || payload?.printful_product_id === 300) && payload?.placement === "Right"
                    ? `url(${payload?.right_mockup})`
                    : (payload?.printful_product_id === 19 || payload?.printful_product_id === 300) && payload?.placement === "Left"
                    ? `url(${payload?.left_mockup})`
                    : `url(${selectedImage})`,
                  backgroundPosition: "center center",
                  backgroundSize: "100% 100%",
                  border:"0.5px solid rgba(36, 36, 36, 0.5)",
                  // Cover the entire div with the image (may crop)
                }}
            >
                {payload !== null && payload?.printful_product_id != 19 && payload?.left_mockup == null ? (
                  <img style={{ width: "150px",position:"relative",bottom:"25px"}}
                    src={
                      payload?.creation_image
                        ? process.env.REACT_APP_apiBaseURL + "/" + payload?.creation_image
                        : ppro
                      
                    }
                    className="produc--detail--img p-2"
                    alt=""
                  />
                ) : (
                  <div className="card placeholder-glow">
                    {/* <img src="..." className="card-img-top" alt="..." /> */}
                    <span className="placeholder col-6"></span>
                  </div>
                )}
              </div>
            ) : (
              <div
            >
                {payload !== null ? (
                  <img 
                  style={{
                    width: "100%",
                    // height: "500px",
                    objectFit: "contain",
                    border:"0.5px solid rgba(36, 36, 36, 0.5)",
                  }}
                    src={
                      payload?.image
                        ? process.env.REACT_APP_apiBaseURL + "/" + payload?.image
                        : ppro
                      
                    }
                    className="produc--detail--img p-2"
                    alt=""
                  />
                ) : (
                  <div className="card placeholder-glow">
                    {/* <img src="..." className="card-img-top" alt="..." /> */}
                    <span className="placeholder col-6"></span>
                  </div>
                )}
              </div>
            ) }  
            </div>

            <div className="col-lg-5 col-md-5 col-12">
              <div>
                <p
                  className="m-0"
                  style={{ color: " rgba(36, 36, 36, 0.5", fontSize: "14px" }}
                >
                  Created By:
                </p>
                <div className="d-flex" style={{ columnGap: "10px" }}>
                  <img
                    src={
                      payload?.user?.photo
                        ? payload?.user?.photo.includes("http")
                          ? payload?.user?.photo
                          : process.env.REACT_APP_apiBaseURL +
                            "/" +
                            payload?.user?.photo
                        : "/assets/images/favicon.jpg"
                    }
                    alt=""
                    className="d-flex justify-content-center align-items-center "
                    style={{
                      borderRadius: "50%",
                      width: "22px",
                      height: "22px",
                      border: "1px solid #242424",
                      objectFit: "cover",
                      cursor: "pointer",
                    }}
                    onClick={(e) =>
                      navigate("/profile/" + payload?.user?.user_name)
                    }
                  />
                  <p
                    onClick={(e) =>
                      navigate("/profile/" + payload?.user?.user_name)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    {payload?.type === 1 && payload.user.user_name === "admin"
                      ? "stickfigures"
                      : payload?.user?.name}
                  </p>
                </div>

                <h1 style={{ fontSize: "30px" }}>{payload?.title}</h1>
                <div className="price-details">
                  <p>
                    <span>Price : $</span>
                    {payload?.price}
                  </p>
                  {payload?.type === 1 && (
                    <p className="printfull-price">
                      <span>Printful Charges : $</span>
                      {payload?.printful_price}
                    </p>
                  )}
                </div>
                {/* Colors & Sizes Radio */}

                {payload?.type === 1 && variants !== null && (
                  <div className="variants-sizes">
                    <h5 className="variants-heading">Choose Size:</h5>
                    {uniqueSizes.map((size, index) => (
                      <label 
                        key={index}
                        className={`radio-button-label ${
                          selectedSize === size ? "selected" : ""
                        }`}
                      >
                        <input
                          type="radio"
                          name="options"
                          value={size}
                          checked={selectedSize === size}
                          onChange={filterVariantsColors}
                          className="size-radio"
                        />
                        {size}
                      </label>
                    ))}
                  </div>
                )}
                {payload?.type === 1 && variants !== null && (
                  <div className="variants-colors">
                    <h5 className="variants-heading">Choose Colors:</h5>
                    {uniqueColors.map((colorCode, index) => (
                      <div
                        key={index}
                        className={`custom-radio ${
                          selectedColor === colorCode ? "selected" : ""
                        }`}
                        style={{ background: colorCode }}
                        data-toggle="tooltip"
                        title={
                          variants.find((item) => item.color_code === colorCode)
                            .color
                        }
                      >
                        <input
                          type="radio"
                          id={`option${index}`}
                          name="radioGroup"
                          value={colorCode}
                          checked={selectedColor === colorCode}
                          onChange={handleColorChange}
                        />
                        <label htmlFor={`option${index}`}></label>
                      </div>
                    ))}
                  </div>
                )}
                {/*  */}
              </div>

              {payload?.type === 1 && (
                <form className="d-flex justify-content-between px-0 py-2 rounded">
                  <div>
                    <label
                      htmlFor="fruit-select"
                      className="products-quantity-heading"
                    >
                      Quantity:
                    </label>
                    <div>
                      <select
                        id="fruit-select"
                        name="fruit"
                        onChange={(e) => handleQuantityChange(e)}
                        style={{
                          width: "120px",
                          backgroundColor: "transparent",
                          borderRadius: "10px",
                          border: "1px solid #A3A3A3",
                          padding: "6px",
                        }}
                      >
                        {quantities.map((i,index) => {
                          return <option  value={i.quantity} key={index}>{i.quantity}</option>;
                        })}
                      </select>
                    </div>
                  </div>
                  {payload?.colors?.length > 0 && (
                    <div>
                      <label htmlFor="fruit-select" style={{ fontSize: "24px" }}>
                        Color:
                      </label>

                      <div className="d-flex gap-2">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault10"
                          />
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault20"
                          />
                        </div>

                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault30"
                          />
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault40"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </form>
              )}

              <div className="d-flex flex-column mt-4 gap-3">
                {!payload?.in_cart ? (
                  <button className="btn1--detail" onClick={() => addToCart()}>
                    Add to cart
                  </button>
                ) : (
                  <button className="btn1--detail" onClick={() => viewCart()}>
                    View Cart
                  </button>
                )}
                <button
                  className="btn2--detail"
                  onClick={() => buyNow(payload.id)}
                >
                  Buy now
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      {products.length > 0 && <AlsoLike products={products} user={user} />}
    </>
  );
};

export default ProductDetail;
