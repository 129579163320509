import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { updateUserCard } from "../../services/ProfileService";
import { toast } from "react-toastify";

const EditPaymentCard = (props) => {
    const [loading, setLoading] = useState(false);
    const [card, setCard] = useState({
        id: '',
        name: '',
        exp_month: '',
        exp_year: ''
    });
    useEffect(() => {
        setCard(preState => ({
            ...preState,
            ...props?.detail
        }))
    }, [props.detail])
    const updateCard = async () => {
        const id = toast.loading("Updating card info..");
        setLoading(true);
        setCard(preState => ({
            ...preState,
            exp_month: card.exp_month.length === 1 ? '0' + card.exp_month : card.exp_month,
            exp_year: card.exp_year?.length > 2 ? card.exp_year.slice(-2) : card.exp_year
        }))
        const method = card;
        method.exp_month = card.exp_month.length === 1 ? 0 + card.exp_month : card.exp_month;
        method.exp_year = card.exp_year?.length > 2 ? card.exp_year.slice(-2) : card.exp_year;
        const response = await updateUserCard(card?.id, method);
        if (response.status === 200) {
            toast.update(id, { render: response.data.message, type: "success", isLoading: false });
            if (props.cards.length > 0) {
                const updatedCards = props.cards.map((obj, index) => {
                    return obj.id === card.id ? {
                        ...obj,
                        ...card
                    } : obj;
                })
                props.setCards(updatedCards);
            }
        } else {
            toast.update(id, { render: response.data.message, type: "warn", isLoading: false });
        }
        props.onHide();
        setLoading(false);
        setTimeout(() => {
            toast.dismiss(id);
        }, 4000);
    }

    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header className="justify-content-center">
                <div className="wrapper w-100 d-flex justify-content-between align-items-center">
                    <h2
                        className="modal-title mt-0"
                        id="exampleModalLabel"
                        style={{ fontFamily: "RockoFLF Bold", fontSize: "16px" }}
                    >
                        {" "}
                        Card Detail
                    </h2>
                </div>
            </Modal.Header>
            <Modal.Body style={{ background: "#FFFFFF", borderRadius: "3px" }}>
                <div
                    className="d-flex justify-content-between align-items-baseline "
                    style={{ width: "100%", padding: "0px 20px", marginTop: "10px" }}
                >
                    <div>
                        <h5
                            className="my-0"
                            style={{ fontSize: "14px", fontWeight: "500" }}
                        >
                            Name{" "}
                        </h5>
                    </div>
                    <div
                        className="d-flex justify-content-between align-items-center   "
                        style={{
                            border: "1px solid #242424",
                            borderRadius: "50px",
                            fontSize: "14px",
                            width: "140px",
                            height: "30px",
                        }}
                    >
                        <input
                            className="px-2 py-1"
                            style={{
                                backgroundColor: "white ",
                                width: "120px",
                                height: "20px",
                                marginLeft: "10px",
                                border: "none",
                                fontSize: '12px'
                            }}
                            type="text"
                            onChange={(e) => setCard(preState => ({
                                ...preState,
                                name: e.target.value
                            }))}
                            value={card?.name}
                        />
                    </div>
                </div>

                <div
                    className="d-flex justify-content-between align-items-baseline "
                    style={{ width: "100%", padding: "0px 20px", marginTop: "10px" }}
                >
                    <div>
                        <h5
                            className="my-0"
                            style={{ fontSize: "14px", fontWeight: "500" }}
                        >
                            Expiry Month{" "}
                        </h5>
                    </div>
                    <div
                        className="d-flex justify-content-between align-items-center   "
                        style={{
                            border: "1px solid #242424",
                            borderRadius: "50px",
                            fontSize: "14px",
                            width: "140px",
                            height: "30px",
                        }}

                    >
                        <input
                            className="px-2 py-1"
                            style={{
                                backgroundColor: "white",
                                width: "120px",
                                height: "20px",
                                marginLeft: "10px",
                                border: "none",
                                fontSize: '12px'
                            }}
                            placeholder="MM"
                            type="text"
                            value={card?.exp_month}
                            onChange={(e) => setCard(preState => ({
                                ...preState,
                                exp_month: e.target.value
                            }))}
                        />
                    </div>
                </div>
                <div
                    className="d-flex justify-content-between align-items-baseline "
                    style={{ width: "100%", padding: "0px 20px", marginTop: "10px" }}
                >
                    <div>
                        <h5
                            className="my-0"
                            style={{ fontSize: "14px", fontWeight: "500" }}
                        >
                            Expiry Year{" "}
                        </h5>
                    </div>
                    <div
                        className="d-flex justify-content-between align-items-center   "
                        style={{
                            border: "1px solid #242424",
                            borderRadius: "50px",
                            fontSize: "14px",
                            width: "140px",
                            height: "30px",
                        }}

                    >
                        <input
                            className="px-2 py-1"
                            style={{
                                backgroundColor: "white",
                                width: "120px",
                                height: "20px",
                                marginLeft: "10px",
                                border: "none",
                                fontSize: '12px'
                            }}
                            placeholder="YY"
                            type="text"
                            value={card?.exp_year?.length > 2 ? card.exp_year.slice(-2) : card?.exp_year}
                            onChange={(e) => setCard(preState => ({
                                ...preState,
                                exp_year: e.target.value
                            }))}
                        />
                    </div>
                </div>

                <div
                    className=" d-flex justify-content-center my-3"
                    style={{ columnGap: "10px" }}
                >
                    <button
                        className="px-4 py-1 d-flex justify-content-center align-items-center button-modal"
                        style={{
                            background: "#242424",
                            color: "#ffff",
                            fontSize: "14px",
                            border: "1px solid #242424",
                            borderRadius: "50px",
                            fontWeight: "500",
                            width: "38%",
                            opacity: loading || Object.values(card).every(value => value === '') ? '0.5' : '1'
                        }}
                        disabled={loading || Object.values(card).every(value => value === '')}
                        onClick={updateCard}
                    >
                        {"Update"}
                    </button>
                    <button
                        className="px-4 py-1 d-flex justify-content-center align-items-center  button-modal"
                        style={{
                            fontSize: "14px",
                            border: "1px solid #242424",
                            borderRadius: "50px",
                            backgroundColor: "white",
                            fontWeight: "500",
                            width: "38%",
                        }}
                        data-dismiss="modal"
                        onClick={props.onHide}
                    >
                        Cancel
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default EditPaymentCard