import api from "./api";


export const getProducts = async (pageIndex) => {
  const query = new URLSearchParams(window.location.search);
  try {
    const response = await api.get(
      `/api/v1/products?page=${pageIndex}&type=${query.get('type')}`
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getProductsByUserId = async (id, pageIndex) => {
  const query = new URLSearchParams(window.location.search);
  try {
    const response = await api.get(
      `/api/v1/${id}/products?page=${pageIndex}&type=${query.get('type')}`
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const DeleteProduct = async (id) => {
  try {
    const response = await api.delete(
      "/api/v1/products/" + id
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getProduct = async (productID) => {
  try {
    const response = await api.get(
      `/api/v1/products/${productID}`
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const updateStoreName = async (payload) => {
  try {
    const response = await api.post(
      `/api/v1/user/store`, payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
};