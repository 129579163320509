import React from "react";
import wall from "../assets/images/icons/image 35.png";
// import vid from "../assets/images/icons/vid.mp4";
import icon from "../assets/images/icons/ArrowCircleLeft.svg";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <div>
      <div className="container">

      <div style={{display:'flex', alignItems:'center'}}>
      <Link to='/' ><img src={icon} className="Arrow-icon" alt="Arrow" /> </Link>
        <h1
          className="py-4 about-strickfigure"
          style={{marginRight:'auto', marginLeft: 'auto', fontSize:'20' }} >
          ABOUT STICKFIGURES
        </h1>
        </div>
        <div className="container" style={{ backgroundColor: "white" }}>
          <img
            style={{
              display: "flex",
              marginLeft: "auto",
              marginRight: "auto",
              paddingTop: "2%",
              paddingBottom: "2%",
            }}
            src={wall}
            alt=""
          />
        </div>
        <p
          style={{
            textAlign: "center",
            marginLeft: "5%",
            marginRight: "5%",
            paddingTop: "2%",
          }}
        >
          It is a long established fact that a reader will be distracted by the
          readable content of a page when looking at its layout. The point of
          using Lorem Ipsum is that it has a more-or-less normal distribution of
          letters, as opposed to using 'Content here, content here', making it
          look like readable English. Many desktop publishing packages and web
          page editors now use Lorem Ipsum as their default model text, and a
          search for 'lorem ipsum' will uncover many web sites still in their
          infancy. Various versions have evolved over the years, sometimes by
          accident, sometimes on purpose (injected humour and the like).
        </p>
        <p
          style={{
            textAlign: "center",
            marginLeft: "5%",
            marginRight: "5%",
          }}
        >
          It is a long established fact that a reader will be distracted by the
          readable content of a page when looking at its layout. The point of
          using Lorem Ipsum is that it has a more-or-less normal distribution of
          letters, as opposed to using 'Content here, content here', making it
          look like readable English. Many desktop publishing packages and web
          page editors now use Lorem Ipsum as their default model text, and a
          search for 'lorem ipsum' will uncover many web sites still in their
          infancy. Various versions have evolved over the years, sometimes by
          accident, sometimes on purpose (injected humour and the like).
        </p>
        <p
          style={{
            textAlign: "center",
            marginLeft: "5%",
            marginRight: "5%",
            paddingBottom: "2%",
          }}
        >
          It is a long established fact that a reader will be distracted by the
          readable content of a page when looking at its layout. The point of
          using Lorem Ipsum is that it has a more-or-less normal distribution of
          letters, as opposed to using 'Content here, content here', making it
          look like readable English. Many desktop publishing packages and web
          page editors now use Lorem Ipsum as their default model text, and a
          search for 'lorem ipsum' will uncover many web sites still in their
          infancy. Various versions have evolved over the years, sometimes by
          accident, sometimes on purpose (injected humour and the like).
        </p>
      </div>
      <div style={{ backgroundColor: "white" }}>
        <div className="container d-flex justify-content-center">
        
          <div className=" row col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-5" > 
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 ">
          

              {/* <video width="100%" height="330"  
              
              src={vid}
             autoPlay 
             muted
             loop
            /> */}

                                            
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 ">
              <h1 className="pt-4">Dummy Heading</h1>
              <p
                style={{
                  paddingTop: "2%",
                }}
              >
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that it has a more-or-less
                normal distribution of letters more-or-less normal .
              </p>
              <p>
                eadable content of a page when looking at its layout. The point
                of using Lorem Ipsum is that it has a more-or-less normal
                distribution of
              </p>
              <button className="py-2 px-4" style={{borderRadius:'40px', border:'1px solid #242424B ' , backgroundColor:'white'}}>Get Started</button>
            </div>
            </div>
        
        </div>
      </div>
    </div>
  );
};

export default About;
