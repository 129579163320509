import api from "./api";

export const getCategories = async () => {
  try {
    const response = await api.get("/api/v1/categories");
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getUsersProducts = async (
  pageIndex,
  type,
  category,
  price,
  search
) => {
  try {
    const response = await api.get(
      `/api/v1/user/products/${type}?page=${pageIndex}&category=${category}&price=${price}&q=${search}`
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getProductsById = async (id) => {
  try {
    const response = await api.get("/api/v1/products/" + id);
    return response;
  } catch (error) {
    return error.response;
  }
};
