import Modal from "react-bootstrap/Modal";

const ResetCanvas = (props) => {
    const domProps = Object.assign({}, props);
    delete domProps.resetFabricCanvas;
  return (
    <>
      <Modal
        {...domProps}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="delelte--modal"
      >
        <div className="container">
          <Modal.Header className="justify-content-center border-0 pb-0 mt-3">
            <Modal.Title id="contained-modal-title-vcenter">
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            className="text-center"
            style={{ background: "#FFFFFF", borderRadius: "3px" }}
          >
            <div className="row justify-content-center">
              <div className="col-md-9">
                <h1 style={{ fontFamily: "My Font" }}>Are you sure</h1>

                <h4>{"This will reset all changes"} </h4>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center border-0 mb-3 pt-0">
          <button
              className="get-started"
              onClick={props.onHide}
              style={{ width: "115px", height: "43px" }}
            >
              No
            </button>

            <button
              className="get-started"
              onClick={(e) => {
                props.canvas.remove(...props.canvas.getObjects());
                props.onHide()
              }}
              style={{ width: "115px", height: "43px" }}
            >
              {"Yes"}
            </button>

          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};

export default ResetCanvas;
