import { getReceipt } from "../../services/OrderService";



const Invoice = ({ invoice, index }) => {

    const downloadReceipt = async (id) => {
        try {
            const response = await getReceipt(id);
            // Create a temporary URL object to generate a download link
            const url = window.URL.createObjectURL(new Blob([response.data]));

            // Create an anchor element to trigger the download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'file.pdf'); // Set the desired file name

            // Append the link to the body and click it to trigger the download
            document.body.appendChild(link);
            link.click();

            // Clean up the temporary URL object
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading receipt:', error);
        }
    };


    return (
        <>
            <tr>
                <td>{index + 1}</td>
                <td>Inovice ID: {invoice.id} <br></br> {invoice.user.name} <br></br> Sent oct 29,2022</td>
                <td>Amount: ${invoice.price}</td>
                <td>{invoice.status}</td>
                <td><a href={invoice.receipt_url}><img src="/assets/images/eye_icon_shaded.svg" /></a> | <a href={invoice.receipt_url}><img src="/assets/images/toolbox/CloudArrowDown.svg" /></a></td>
            </tr>
        </>
    );
}

export default Invoice