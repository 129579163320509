import { useNavigate } from "react-router";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getBlogPosts } from "../../services/BlogPostService";

const OurBlog = () => {

    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [postsData, setPostsData] = useState();
    const [pageIndex, setPageIndex] = useState(1);

    const getPosts = async (pageIndex) => {
        setLoader(true);
        const response = await getBlogPosts(pageIndex);
        if (response.status === 200) {
            setPostsData(response.data.data.slice(0, 3));
            setPageIndex(response.data.meta.current_page);
            setLoader(false);
        } else {
            console.log(response.data.message);
        }
    };

    const truncateContent = (content, maxLength) => {
        if (content.length > maxLength) {
          return content.substring(0, maxLength) + '...'; // Truncate and add an ellipsis
        }
        return content;
    }

    useEffect(() => {
        getPosts(pageIndex)
    }, []);
    return (
        <section className="what-we-do-section-section my-4" style={{background: "rgba(243, 243, 243, 1)"}}>
            <div className="container">
                <div className="row g-0 ">
                    <div className="pb-5 col-lg-12 col-md-12 text-center">
                        <h2>OUR BLOGS</h2>
                        <small>
                            Enjoy some creative writing pieces written by the Stick Figures team. Happy Reading!
                        </small>
                    </div>
                </div>
                <div className="row gy-4 ">
                    
                {loader ? (
                    <div className="d-flex justify-content-center">
                        <div className="spinner-grow" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                ) : (
                    
                        postsData?.length > 0 ? (
                            postsData?.map((item, key) => (
                                key === 0 ? (
                                    <div className="col-sm-12 col-md-6 col-lg-4" style={{borderRadius: "10px"}} key={key}>
                                        <div className="card h-100">
                                            <div className="card-img-holder">
                                                <img src={item.blog_image} style={{ width:"415px",height:"245px" }} className="card-img-top img-fluid"
                                                    alt="card" />
                                            </div>

                                            <div className="card-body text-white block-border" style={{backgroundColor: "black"}}>
                                                <div className="container my-3">
                                                    <h4 className="card-title" style={{color: "rgba(255, 255, 255, 1)"}}>{item.title}</h4>
                                                    <p className="card-text" dangerouslySetInnerHTML={{ __html: truncateContent(item.content, 170) }}></p>
                                                    <Link className="btn btn-danger blog-readmore-home" to={`/blog-detail/${item.slug}`}>Read more</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : null
                                ))
                            ) : "No Post Available"
                        )}    

                </div>

                <div className="row justify-content-center my-5">
                    <button className="get-started" onClick={() => navigate('/blogs')}>View all</button>
                </div>
            </div>
        </section>
    );
}

export default OurBlog