import PaymentCard from "../components/payment/PaymentCard";
import { useEffect, useState } from "react";
import { getUserCards, AttachPaymentMethod } from "../services/ProfileService";
import {
    useStripe,
    useElements,
    Elements,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { toast } from "react-toastify";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

const StripeElements = ({ getUserPaymentCard, setAttachNew }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [name, setName] = useState("");
    const [loader, setLoader] = useState(false);
    const [validationStart, setValidationStart] = useState(false);
    const [isCardNumberValid, setIsCardNumberValid] = useState(false);
    const [isExpiryDateValid, setIsExpiryDateValid] = useState(false);
    const [isCvcValid, setIsCvcValid] = useState(false);
    const [fieldErrors, setFieldErrors] = useState({
        cardNumber: "",
        cardExpiry: "",
        cardCvc: "",
        name: "",
    });

    const handleNameChange = (e) => {
        setName(e.target.value)
        validateCardDetails();
    }

    const validateCardDetails = () => {
        let isValid = true;
        const errors = {
            ...fieldErrors,
            name: "",
        };

        if (!name) {
            errors.name = "Name is required";
        }

        if (!isCardNumberValid) {
            errors.cardNumber = "Card Number is required";
            isValid = false;
        }

        if (!isExpiryDateValid) {
            errors.cardExpiry = "Card Expiry is required";
            isValid = false;
        }

        if (!isCvcValid) {
            errors.cardCvc = "Card CVC is required";
            isValid = false;
        }
        setFieldErrors(errors);
        return isValid;
    }

    const handleChange = (event) => {
        const errors = {
            ...fieldErrors
        }

        if (event.elementType === "cardNumber") {
            if (event.empty || event.error || !event.complete) {
                errors.cardNumber = "Card Number is required";
                setIsCardNumberValid(false);
            } else {
                setIsCardNumberValid(true);
            }
        }

        if (event.elementType === "cardExpiry") {
            if (event.empty || event.error || !event.complete) {
                errors.cardExpiry = "Card Expiry is required";
                setIsExpiryDateValid(false);
            } else {
                setIsExpiryDateValid(true);
            }
        }

        if (event.elementType === "cardCvc") {
            if (event.empty || event.error || !event.complete) {
                errors.cardCvc = "Card CVC is required";
                setIsCvcValid(false);
            } else {
                setIsCvcValid(true);
            }
        }
        setFieldErrors(errors);
    }

    const handleAttach = async () => {
        setValidationStart(true);
        if (!validateCardDetails()) {
            return;
        }
        setLoader(true);
        const id = toast.loading("Attaching payment method..");
        const user = JSON.parse(localStorage.getItem("user"));
        const result = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardNumberElement),
            billing_details: {
                name: name,
                email: user.email,
            },
            metadata: {
                payer_id: user.id
            }
        })

        if (result.error) {
            toast.update(id, { render: result.error.message, type: "error", isLoading: false });
        } else {
            const response = await AttachPaymentMethod({ pm_id: result.paymentMethod.id, name: user.name });
            if (response.status === 201) {
                setAttachNew(false);
                setLoader(false);
                toast.update(id, { render: response.data.message, type: "success", isLoading: false });
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            } else {
                toast.update(id, { render: response.data.message, type: "error", isLoading: false });
            }
        }
    }

    return (
        <div className="container mt-3">
            <div>
                <h1 className="font--stripe" style={{ marginBottom: '16px' }}>Card Number</h1>
                <CardNumberElement options={{ showIcon: true }} onChange={handleChange} />
                {
                    validationStart && !isCardNumberValid && (
                        <div className="text-danger">{fieldErrors.cardNumber}</div>
                    )
                }
            </div>
            <div style={{ marginTop: '32px' }}>
                <div className="d-flex flex-column">
                    <label className="font--stripe" style={{ marginBottom: '16px' }}>Card Holder Name</label>

                    <input type="text" name="name" className="px-2" placeholder="Card Holder Name" value={name}
                        onChange={handleNameChange} />
                    {
                        validationStart && fieldErrors.name && (
                            <div className="text-danger">{fieldErrors.name}</div>
                        )
                    }
                </div>
            </div>

            <div className="row" style={{ marginTop: '32px' }}>
                <div className="col-md-8">
                    <h1 className="font--stripe" style={{ marginBottom: '16px' }}>Expiration Date</h1>
                    <CardExpiryElement onChange={handleChange} />
                    {
                        validationStart && !isExpiryDateValid && (
                            <div className="text-danger">{fieldErrors.cardExpiry}</div>
                        )
                    }
                </div>
                <div className="col-md-4">
                    <h1 className="font--stripe" style={{ marginBottom: '16px' }}>CVC</h1>
                    <CardCvcElement onChange={handleChange} />
                    {
                        validationStart && !isCvcValid && (
                            <div className="text-danger">{fieldErrors.cardCvc}</div>
                        )
                    }
                </div>
            </div>
            <div className="row justify-content-center mt-4">
                <button
                    className="get-started"
                    style={{
                        width: '115px',
                        opacity: loader ? '0.5' : '1'
                    }}
                    disabled={loader}
                    onClick={handleAttach}
                >
                    Attach
                </button>
            </div>
        </div>
    );
}

const Payment = () => {
    const [cards, setCards] = useState([]);
    const [isAttachNew, setAttachNew] = useState(false);

    const getUserPaymentCard = async () => {
        const response = await getUserCards();
        if (response.status === 200) {
            setAttachNew(response.data.data?.length === 0);
            setCards(response.data.data);
        }
    }

    useEffect(() => {
        getUserPaymentCard();
    }, []);

    return (
        <>
            <div className="d-flex justify-content-center my-3 flex-wrap">
                {
                    cards.length > 0 && !isAttachNew ?
                        cards.map((account, index) => {
                            return <PaymentCard account={account} key={index} setCards={setCards} cards={cards} />
                        })
                        : <></>
                }
                {
                    cards.length > 0 && !isAttachNew ?
                        <div className="d-flex flex-column justify-content-center align-items-center m-2"
                            style={{ width: '336px', height: '192px', border: '2px solid #242424', borderRadius: '12px', cursor: 'pointer' }}
                            onClick={(e) => setAttachNew(true)}>
                            <img src="/assets/images/add_method.svg" alt="payment method" style={{ width: '48px', height: '48px' }} />
                            <p style={{ marginBottom: '0px' }}>Payment Method</p>
                            <p style={{ marginBottom: '0px' }}>Click + to create new one</p>
                        </div>
                        :
                        <Elements stripe={stripePromise}>
                            <StripeElements getUserPaymentCard={getUserPaymentCard} setAttachNew={setAttachNew} />
                        </Elements>
                }
            </div>
        </>
    );
}

export default Payment