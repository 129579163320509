import React, { useEffect, useState } from 'react'
import WithdrawCash from '../modal/WithdrawCash';

const CashAccount = ({ authProfile, bankAccounts }) => {
  const [modalShow, setModalShow] = useState(false);
  const [bankAccount, setBankAccounts] = useState([]);

  useEffect(() => {
    setBankAccounts(bankAccounts)
  }, [bankAccounts])
  return (
    <>
      <div className="row justify-content-center">
        <div
          className="col-md-9 d-flex justify-content-between py-3 px-3"
          style={{ border: "1px solid #A3A3A3" }}
        >
          <div className="col-md-6 d-flex align-items-center ">
            <h3 className="cash-carry mb-0">Cash Account</h3>
          </div>
          <div className="col-md-5 d-flex justify-content-around align-items-center">
            <h3 className="mb-0 cash-carry">
              <b>Balance:</b> $ {authProfile?.available_amount} USD
            </h3>
            <button
              className="get-started"
              style={{ width: "110px" }}
              onClick={() => setModalShow(true)}
            >
              Withdraw
            </button>
          </div>
        </div>
      </div>
      <WithdrawCash show={modalShow} onHide={() => setModalShow(false)} bankAccounts={bankAccount} balance={authProfile?.available_amount} setModalShow={setModalShow} />
    </>
  )
}

export default CashAccount