export const convertTime = (value) => {
  const datetime = value;
  const now = new Date();
  const differenceInMilliseconds = now - new Date(datetime);
  const differenceInMinutes = Math.floor(
    differenceInMilliseconds / (1000 * 60)
  );
  const differenceInHours = Math.floor(
    differenceInMilliseconds / (1000 * 60 * 60)
  );
  const differenceInDays = Math.floor(
    differenceInMilliseconds / (1000 * 60 * 60 * 24)
  );

  if (differenceInMinutes < 60) {
    return `${differenceInMinutes} min${
      differenceInMinutes !== 1 ? "" : ""
    } ago`;
  } else if (differenceInHours < 24) {
    return `${differenceInHours} hour${differenceInHours !== 1 ? "s" : ""} ago`;
  } else {
    return `${differenceInDays} day${differenceInDays !== 1 ? "s" : ""} ago`;
  }
};

export const LikesToK = (num) => {
  if (num >= 1000) {
    return (num / 1000).toFixed(1) + "K";
  } else {
    return num.toString();
  }
};

export function timeToSeconds() {
  var now = new Date(); // Create a new Date object representing the current time
  var secondsSinceEpoch = Math.floor(now.getTime() / 1000); // Convert to seconds since epoch and round down
   return secondsSinceEpoch
}
