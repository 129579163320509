import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
    useStripe,
    useElements,
    Elements,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement,
} from "@stripe/react-stripe-js";
import "../components/checkout/style.css";
import { toast } from "react-toastify";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

const CheckoutForm = ({ clientSecret, chargePayment, setLoader, setOrder, setPaymentMethod, setToasterId }) => {
    const [mounted, setMounted] = useState(false);
    const stripe = useStripe();
    const elements = useElements();
    const [name, setName] = useState("");
    const [validationStart, setValidationStart] = useState(false);
    const [isCardNumberValid, setIsCardNumberValid] = useState(false);
    const [isExpiryDateValid, setIsExpiryDateValid] = useState(false);
    const [isCvcValid, setIsCvcValid] = useState(false);

    const [fieldErrors, setFieldErrors] = useState({
        cardNumber: "",
        cardExpiry: "",
        cardCvc: "",
        name: "",
    });

    const handleChange = (event) => {
        const errors = {
            ...fieldErrors
        }

        if (event.elementType === "cardNumber") {
            if (event.empty || event.error || !event.complete) {
                errors.cardNumber = "Card Number is required";
                setIsCardNumberValid(false);
            } else {
                setIsCardNumberValid(true);
            }
        }

        if (event.elementType === "cardExpiry") {
            if (event.empty || event.error || !event.complete) {
                errors.cardExpiry = "Card Expiry is required";
                setIsExpiryDateValid(false);
            } else {
                setIsExpiryDateValid(true);
            }
        }

        if (event.elementType === "cardCvc") {
            if (event.empty || event.error || !event.complete) {
                errors.cardCvc = "Card CVC is required";
                setIsCvcValid(false);
            } else {
                setIsCvcValid(true);
            }
        }

        setFieldErrors(errors);
    }

    const handleNameChange = (e) => {
        setName(e.target.value)
        validateCardDetails();
    }

    const validateCardDetails = () => {
        let isValid = true;
        const errors = {
            ...fieldErrors,
            name: "",
        };

        if (!name) {
            errors.name = "Name is required";
        }

        if (!isCardNumberValid) {
            errors.cardNumber = "Card Number is required";
            isValid = false;
        }

        if (!isExpiryDateValid) {
            errors.cardExpiry = "Card Expiry is required";
            isValid = false;
        }

        if (!isCvcValid) {
            errors.cardCvc = "Card CVC is required";
            isValid = false;
        }

        setFieldErrors(errors);

        return isValid;
    }

    const handleSubmit = async () => {
        setValidationStart(true);
        if (!validateCardDetails()) {
            return;
        }
        const id = toast.loading("Verifying payment method...");
        setToasterId(id);
        setLoader(true);

        let user = JSON.parse(localStorage.getItem("user"));

        const result = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardNumberElement),
            billing_details: {
                name,
                email: user.email,
            }
        })

        if (result.error) {
            console.log("Error", result.error.message);
            toast.update(id, { render: result.error.message, type: "error", isLoading: false });
        } else {
            toast.update(id, { render: "Placing order..." });
            setPaymentMethod(result.paymentMethod.id);
            setOrder(result.paymentMethod.id, id);
            // setLoader(false);
        }
    };

    useEffect(() => {
        if (!mounted) {
            setMounted(true);
        } else {
            handleSubmit();
        }
    }, [chargePayment]);

    return (
        <div className="mt-3">
            <form className="">
                <div style={{ marginTop: '42px' }}>
                    <h1 className="font--stripe" style={{ marginBottom: '16px' }}>Card Number</h1>
                    <CardNumberElement options={{ showIcon: true }} onChange={handleChange} />
                    {
                        validationStart && !isCardNumberValid && (
                            <div className="text-danger">{fieldErrors.cardNumber}</div>
                        )
                    }
                </div>
                <div style={{ marginTop: '32px' }}>
                    <div className="d-flex flex-column">
                        <label className="font--stripe" style={{ marginBottom: '16px' }}>Card Holder Name</label>

                        <input type="text" name="name" className="px-2" placeholder="Card Holder Name" value={name}
                            onChange={handleNameChange} />
                        {
                            validationStart && fieldErrors.name && (
                                <div className="text-danger">{fieldErrors.name}</div>
                            )
                        }
                    </div>
                </div>

                <div className="row" style={{ marginTop: '32px' }}>
                    <div className="col-md-8">
                        <h1 className="font--stripe" style={{ marginBottom: '16px' }}>Expiration Date</h1>
                        <CardExpiryElement onChange={handleChange} />
                        {
                            validationStart && !isExpiryDateValid && (
                                <div className="text-danger">{fieldErrors.cardExpiry}</div>
                            )
                        }
                    </div>
                    <div className="col-md-4">
                        <h1 className="font--stripe" style={{ marginBottom: '16px' }}>CVC</h1>
                        <CardCvcElement onChange={handleChange} />
                        {
                            validationStart && !isCvcValid && (
                                <div className="text-danger">{fieldErrors.cardCvc}</div>
                            )
                        }
                    </div>
                </div>
            </form>
        </div>
    );
};

const StripeCheckout = ({
    paymentIntent,
    clientSecret,
    setLoader,
    setOrder,
    setPaymentMethod,
    setToasterId
}) => {
    const [chargePayment, setChargePayment] = useState(false);
    const options = {
        clientSecret: clientSecret?.client_secret,
    };

    useEffect(() => {
        if(paymentIntent) {
            setChargePayment(!chargePayment);
        }
    }, [paymentIntent]);

    return (
        <div style={{ padding: "5px" }}>
            <h1 style={{ fontSize: '24px' }}>Payment</h1>
            {(clientSecret !== null && options.clientSecret) && (
                <Elements stripe={stripePromise} options={options}>
                    <CheckoutForm clientSecret={clientSecret?.client_secret} chargePayment={chargePayment}
                        setLoader={setLoader} setOrder={setOrder} setPaymentMethod={setPaymentMethod} setToasterId={setToasterId}/>
                </Elements>
            )}
        </div>
    );
};

export default StripeCheckout;
