import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { RxCross2 } from "react-icons/rx";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { getCart } from "../services/CartService";
import { AddCart, getSimilarProducts } from "../services/CartService";
import DeleteItem from "../components/modal/Deleteitem";

const Cart = (props) => {

    const [productsYouMayLike, setProductYouMayLike] = useState([]);

    const settings = {
        arrow: true,
        infinite: productsYouMayLike.length > 1, // Infinite only if there's more than one item
        speed: 500,
        slidesToShow: Math.max(1, Math.min(4, productsYouMayLike.length)), // At least 1, at most 4
        slidesToScroll: Math.min(4, productsYouMayLike.length), // Never more than the number of products
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: Math.min(4, productsYouMayLike.length), // Up to 4 slides or the number of products
              slidesToScroll: Math.min(4, productsYouMayLike.length),
              infinite: productsYouMayLike.length > 3,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: Math.min(2, productsYouMayLike.length), // Up to 2 slides or the number of products
              slidesToScroll: Math.min(2, productsYouMayLike.length),
              initialSlide: Math.min(2, productsYouMayLike.length) - 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
    };
      

    const [payload, setPayload] = useState();
    const [loader, setLoader] = useState(false);
    const [deleteBtnLock, setBtnLock] = useState();
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState();
    const [deleteVariantId, setDeleteVaraintId] = useState();
    const [acceptedCards, setAcceptedCards] = useState(['visa','mastercard','amex','discover','jcb']);

    useEffect(() => {
        getCartData();
        similarProducts();
    }, []);

    const navigate = useNavigate();

    const getCartData = async () => {
        const response = await getCart();
        if (response.status === 200) {
            setPayload(response.data.data);
            setLoader(false);
        } else {
            toast.error(response.data.message);
            setLoader(false);
        }
    };

    const similarProducts = async () => {
        const response = await getSimilarProducts();
        if (response.status === 200) {
            setProductYouMayLike(response.data.data);
        }
    }

    const deleteItem = async () => {
        setBtnLock(true);
        setPayload((prev) => prev.filter((i) => i.id !== deleteId));
        setDeleteModal(false);
        props.setCartCount((prevCount) => {
            const newCount = parseInt(prevCount, 10) - 1;
            localStorage.setItem("cartCount", newCount);
            return newCount;
        });

        setProductYouMayLike((prev) => prev.map((product) => {
            if (product.id === deleteId) {
                product.in_cart = false;
            }
            return product;
        }));

        const finalData = {
            product_id: deleteId,
        };
        const response = await AddCart(finalData);
        if (response.status === 200) {
            toast.success("Product removed");
            setBtnLock(false);
        } else {
            toast.error(response.data.message);
            setDeleteModal(false);
        }
    };

    const addToCart = async (itemId) => {
        const isItemExistInCart = productsYouMayLike?.find((product) => product.id === itemId).in_cart;

        setProductYouMayLike((prev) => prev.map((product) => {
            if (product.id === itemId) {
                product.in_cart = !isItemExistInCart;
            }
            return product;
        }));

        const finalData = {
            product_id: itemId,
        };
        const response = await AddCart(finalData);
        if (response.status === 200) {
            if (isItemExistInCart) {
                props.setCartCount((prevCount) => {
                    const newCount = parseInt(prevCount, 10) - 1;
                    localStorage.setItem("cartCount", newCount);
                    return newCount;
                });
                setPayload((prev) => prev.filter((i) => i.id !== itemId));
                toast.success("Product removed");
            } else {
                props.setCartCount((prevCount) => {
                    const newCount = parseInt(prevCount, 10) + 1;
                    localStorage.setItem("cartCount", newCount);
                    return newCount;
                });
                setPayload((prev) => [...prev, response.data.data]);
                toast.success("Product added");
            }
        } else {
            toast.error(response.data.message);
        }
    }

    const total = payload?.reduce((sum, product) => sum + (product.price + product.printful_price)*product.quantity, 0);
    const roundedTotalPrice = total?.toFixed(2);

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row justify-content-start my-4 gap-4">
                            {/* Left Side */}
                            <div className=" col-xl-8 col-lg-8  col-md-8 col-12 ">
                                <div
                                    className="modal-body mt-3 p-3"
                                    style={{
                                        background: "white",
                                        borderRadius: "10px",
                                        position: "relative",
                                    }}
                                >
                                    <div className="wrapper w-100 d-flex justify-content-between align-items-center">
                                        <h4
                                            className="modal-title mt-0 user-carts"
                                            id="exampleModalLabel"
                                        >
                                            Buy Products
                                        </h4>
                                        {/* <h6 className="my-0">1 items</h6> */}
                                    </div>
                                    {payload?.length === 0 ? (
                                        <Link
                                            to="/shop/user"
                                            className="col-md-12 d-flex justify-content-center pt-4"
                                        >
                                            <button className="get-started">Shop Now</button>
                                        </Link>
                                    ) : (
                                        payload?.map((i,key) => {
                                            return (
                                                    <div
                                                        className="card my-3 bg-transparent"
                                                        style={{
                                                            border: "none",
                                                            position: "relative",
                                                        }}
                                                        key={key}
                                                    >
                                                        <div
                                                            style={{
                                                                position: "absolute",
                                                                right: "0",
                                                                top: "0",
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={() => {
                                                                setDeleteModal(true);
                                                                setDeleteId(i.id);
                                                            }}
                                                        >
                                                            <RxCross2 size={30} />
                                                        </div>
                                                        <div className="row no-gutters">
                                                            <div className="col-md-3 d-flex align-items-center">
                                                                <img
                                                                    src={
                                                                        i.printful_product_id === 19 || i.printful_product_id === 300
                                                                            ? (
                                                                                i.placement === 'Right'
                                                                                    ? (i.right_mockup ? i.right_mockup : (process.env.REACT_APP_apiBaseURL + '/' + i.image))
                                                                                    : (i.placement === 'Left' ? (i.left_mockup ? i.left_mockup : (process.env.REACT_APP_apiBaseURL + '/' + i.image)) : (i.front_mockup ? i.front_mockup : (process.env.REACT_APP_apiBaseURL + '/' + i.image)))
                                                                            )
                                                                            : (process.env.REACT_APP_apiBaseURL + '/' + i.image)
                                                                    }
                                                                    
                                                                    alt="..."
                                                                    style={{
                                                                        maxWidth: "154px",
                                                                        maxHeight: '174px',
                                                                        objectFit: 'cover'
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="col-md-9">
                                                                <div className="card-body">
                                                                { i.type === 1 ? (
                                                                    <h5 className="card-title user-carts">
                                                                        Price: ${(i.price + i.printful_price) * i.quantity} <span className="badge rounded-pill bg-info text-dark"  style={{ fontSize: "10px" }}>Including Printful Charges</span>
                                                                    </h5>
                                                                    ) : (
                                                                        <h5 className="card-title user-carts">
                                                                            Price: ${i.price * i.quantity}
                                                                        </h5>
                                                                )}
                                                                    

                                                                    <h6 className="card-text">
                                                                        {i.title}
                                                                    </h6>
                                                                    {
                                                                        i.colors?.length > 0 && (
                                                                            <>
                                                                                <h5 className="card-text user-carts">
                                                                                    Colors
                                                                                </h5>

                                                                                <div className="d-flex gap-2">
                                                                                    <div className="form-check">
                                                                                        <input
                                                                                            className="form-check-input"
                                                                                            type="radio"
                                                                                            name="flexRadioDefault"
                                                                                            id="flexRadioDefault10"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="form-check">
                                                                                        <input
                                                                                            className="form-check-input"
                                                                                            type="radio"
                                                                                            name="flexRadioDefault"
                                                                                            id="flexRadioDefault20"
                                                                                        />
                                                                                    </div>

                                                                                    <div className="form-check">
                                                                                        <input
                                                                                            className="form-check-input"
                                                                                            type="radio"
                                                                                            name="flexRadioDefault"
                                                                                            id="flexRadioDefault30"
                                                                                        />
                                                                                    </div>
                                                                                    <div className="form-check">
                                                                                        <input
                                                                                            className="form-check-input"
                                                                                            type="radio"
                                                                                            name="flexRadioDefault"
                                                                                            id="flexRadioDefault40"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            );
                                        })
                                    )}
                                </div>

                                <div
                                    className="modal-body mt-3 p-3"
                                    style={{ background: "white", borderRadius: "10px" }}
                                >
                                    <div className="wrapper  w-100 d-flex justify-content-between align-items-center">
                                        <div>
                                            <h4 className="modal-title mt-0 " id="exampleModalLabel">
                                                Sub-Total
                                            </h4>
                                            <p className=" mt-0 " id="">
                                                {payload?.length} item
                                                {payload?.length > 1 ? "s" : ""}
                                            </p>
                                        </div>

                                        <h6 className="my-0">{roundedTotalPrice}$</h6>
                                    </div>
                                </div>
                            </div>

                            {/* Right Side */}
                            <div
                                className="col-xl-3 col-lg-3  col-md-3 col-12  p-3"
                                style={{
                                    background: "white",
                                    borderRadius: "10px",
                                    height: "350px",
                                }}
                            >
                                <div className="modal-header cart--header flex-column">
                                    <div className="wrapper w-100 d-flex justify-content-between align-items-center">
                                        <h2
                                            className="modal-title mt-0 user-carts"
                                            id="exampleModalLabel"
                                        >
                                            Total
                                        </h2>
                                        <h6 className="my-0">
                                            {payload?.length} item{payload?.length > 1 ? "s" : ""}
                                        </h6>
                                    </div>
                                </div>
                                <div className="modal-body">
                                    <div
                                        className="wrapper w-100 mt-3 d-flex justify-content-between align-items-center">
                                        <h4
                                            className="modal-title mt-0 user-carts"
                                            id="exampleModalLabel"
                                        >
                                            Sub-Total:
                                        </h4>
                                        <h6 className="my-0 user-carts">${roundedTotalPrice}</h6>
                                    </div>
                                    <button className="get-started checkout my-4" onClick={() => navigate("/checkout")} disabled={roundedTotalPrice <= 0}>
                                        Checkout
                                    </button>
                                    <h5 className="user-carts">We Accept</h5>
                                    <div className="d-flex">
                                        {
                                            acceptedCards.map((card,key)=> {
                                                return <img className="me-2" src={"/assets/images/"+card+".svg"} alt={card} key={key} style={{width: '30px'}}/>
                                            })
                                        }
                                    </div>
                                </div>
                                <button className="get-started checkout my-2">
                                    <Link
                                        to="/store"
                                        style={{ textDecoration: "none", fontSize: "14px" }}
                                    >
                                        Go To Your Store
                                    </Link>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* reuse this component from top products */}
                <section className="py-5">
                    <div className="container d-flex flex-column justify-content-center">
                        <div className="row d-flex justify-content-center">
                            <div className="pb-5 col-md-12 d-flex flex-column justify-content-center text-center">
                                <h2 className="heading">You may also like</h2>
                            </div>
                            <div className="row justify-content-center">
                                <Slider {...settings}>
                                    {
                                        productsYouMayLike.map((item, index) => {
                                            return (
                                                <Link to={`/product-detail/${item.id}`} key={index}>
                                                    <div className="slide-wrap">
                                                        <div
                                                            className="product-card"
                                                            style={{ position: "relative" }}
                                                        >
                                                            <div
                                                                className="col-md-12 d-flex justify-content-between align-items-center p-3">
                                                                <h4>{item.title}</h4>
                                                                <p>${item.price+item.printful_price}</p>
                                                            </div>
                                                                <div className="col-md-12 d-flex justify-content-center">
                                                                    <img
                                                                        src={
                                                                            item.printful_product_id === 19 || item.printful_product_id === 300
                                                                                ? (
                                                                                    item.placement === 'right'
                                                                                        ? (item.right_mockup ? item.right_mockup : (process.env.REACT_APP_apiBaseURL + '/' + item.image))
                                                                                        : (item.placement === 'left' ? (item.left_mockup ? item.left_mockup : (process.env.REACT_APP_apiBaseURL + '/' + item.image)) : (item.front_mockup ? item.front_mockup : (process.env.REACT_APP_apiBaseURL + '/' + item.image)))
                                                                                )
                                                                                : (process.env.REACT_APP_apiBaseURL + '/' + item.image)
                                                                        }
                                                                        style={{
                                                                            width: "190px",
                                                                            height: "190px",
                                                                            objectFit: "contain",
                                                                        }}
                                                                    />    
                                                                </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            )
                                        })
                                    }

                                </Slider>
                            </div>
                        </div>
                        <div className="row justify-content-center my-4">
                            <button className="get-started">
                                <Link to="/shop/user">View all</Link>
                            </button>
                        </div>
                    </div>
                </section>
            </div>

            <DeleteItem
                show={deleteModal}
                onHide={() => setDeleteModal(false)}
                deleteText={`You want to remove product from cart`}
                deleteItem={deleteItem}
                deleteBtnLock={deleteBtnLock}
            />
        </>
    );
};

export default Cart;
