import { useEffect, useState } from "react";

import Modal from "react-bootstrap/Modal";
import { connectStripeAccount } from "../../services/customizerService";

const ConnectionModal = (props) => {

    const [btnText, setBtnText] = useState('Connect');
    const [loader, setLoader] = useState(false);

    const createStripeConnectLink = async () => {
        setBtnText('Connecting...');
        setLoader(true);
        const response = await connectStripeAccount();
        if (response.status === 200) {
            setBtnText('Connect');
            setLoader(false);
            window.location = response.data.data.url;
        }
    }
    return (
        <>
            <Modal
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                static
            >
                <Modal.Header className="justify-content-center" closeButton>
                    <div className="wrapper w-100 d-flex justify-content-between align-items-center">
                        <h2
                            className="modal-title mt-0"
                            id="exampleModalLabel"
                            style={{ fontFamily: "RockoFLF Bold", fontSize: "16px" }}
                        >
                            {" "}
                            Connect to Stripe
                        </h2>
                    </div>
                </Modal.Header>

                <Modal.Body style={{ background: "#FFFFFF", borderRadius: "3px", padding: '20px' }}>


                    <div className='d-flex justify-content-between'>
                        <img src='/assets/images/Stripe/Group%20965.svg'
                            style={{ width: '45px', height: '40px', objectFit: 'contain' }} />
                        <img src='/assets/images/Stripe/Layer_x0020_1.svg'
                            style={{ width: '45px', height: '40px', objectFit: 'contain' }} />
                        <img src='/assets/images/Stripe/Vector%20(1).svg'
                            style={{ width: '45px', height: '40px', objectFit: 'contain' }} />
                    </div>



                    <div>
                        <p className='mt-3 text-center' style={{fontSize:'13px', fontWeight: '600'}}>Proceed to link your profile with stripe?</p>
                    </div>


                    <div className='mt-3 d-flex justify-content-center'>
                        <button style={{
                            fontSize: "16px", width: '160px',
                            backgroundColor: '#242424',
                            color: 'white',
                            borderRadius: '30px',
                            opacity: loader ? '0.7' : ''
                        }}
                            className='button-modal d-flex justify-content-center align-items-center'
                            onClick={createStripeConnectLink}
                            disabled={loader}
                        >
                            {
                                loader && (
                                    <div className="spinner-border text-light me-2" role="status" style={{width: '1rem', height: '1rem'}}>
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                )
                            }
                            {btnText}
                        </button>
                    </div>

                </Modal.Body>
            </Modal>
        </>
    );
};

export default ConnectionModal;