import {Link} from "react-router-dom";
import {Dropdown} from "react-bootstrap";
import {RxDotsVertical} from "react-icons/rx";
import ReportModal from "./modal/ReportModal";
import React, {useState} from "react";

const CreationCard = ({item}) => {
    const [reportShow, setReportShow] = useState(false);
    const [reportCreationId, setReportCreationId] = useState();

    const reportCreation = (creationId) => {
        setReportCreationId(creationId);
        setReportShow(true);
    }

    return (
        <>
            <div
                className="card media-container"
                style={{maxWidth: "200px", width: "195px", maxHeight: '250px', height: '240px'}}
            >
                <div className="card-body p-0" style={{position: "relative"}}>
                    <img
                        src={process.env.REACT_APP_apiBaseURL + "/" + item.attachments[0].path}
                        alt="Card image cap"
                        style={{width: "100%", height: '240px', objectFit: 'cover'}}
                    />


                    <Dropdown
                        className="position-absolute"
                        style={{right: "5px", top: "5px"}}
                    >
                        <Dropdown.Toggle
                            className="btn btn-secondary three--dots"
                            id="dropdown-basic"
                        >
                            <RxDotsVertical
                                size={25}
                                style={{

                                    borderRadius: "4px",
                                    padding: "4px",
                                }}
                            />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => reportCreation(item.id)}>Report</Dropdown.Item>

                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div
                    className="card-footer post-footer py-2"
                    style={{border: "none", background: "#242424", opacity: "0.5"}}
                >
                    <div className="row meme-footer justify-content-end">
                        <div className="col-md-7 d-flex justify-content-between">
                            <div className="d-flex justify-content-center align-item-center flex-column">
                                <img
                                    src="/assets/images/profile/Vector.svg"
                                    alt=""
                                    style={{alignSelf: "center"}}
                                />
                                <p className="mb-0 text-white text-center">{item.total_reactions}</p>
                            </div>
                            <div className="d-flex justify-content-center align-item-center flex-column">
                                <img
                                    src="/assets/images/profile/Group 914.svg"
                                    alt=""
                                    style={{alignSelf: "center"}}
                                />
                                <p className="mb-0 text-white text-center">{item.total_comments}</p>
                            </div>
                            <div className="d-flex justify-content-center align-item-center flex-column">
                                <img
                                    src="/assets/images/profile/Group 916.svg"
                                    alt=""
                                    style={{alignSelf: "center"}}
                                />
                                <p className="mb-0 text-white text-center">{item.total_shares}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ReportModal
                productId={reportCreationId}
                show={reportShow}
                onHide={() => setReportShow(false)}
            />
        </>
    );
};

export default CreationCard;
