import { fabric } from "fabric";
import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import { AiOutlineDown } from "react-icons/ai";
import Slider from "react-slick";
import { getPrintFulPrices } from "../../services/PrintFulService";

const CanvasModalAdminCustomizer = (props) => {
    const [product, setProduct] = useState(null);

    const addImageToCanvas = (item) => {
        if (!props.canvas) return;
        // This is the code by old deveolper
        new fabric.Image.fromURL(item, function (image) {
            let scaleX = 500 / image.width;
            let scaleY = 500 / image.height;
            var img = image.set({
                left: 0, top: 0, scaleX: scaleX, scaleY: scaleY, padding: 0,
            });


            props.canvas.setBackgroundImage(img);

            const CENTER_WIDTH = 300;
            const CENTER_HEIGHT = 300;

            // Calculate boundaries
            const minLeft = (props.canvas.width - CENTER_WIDTH) / 2;
            const maxLeft = minLeft + CENTER_WIDTH;
            const minTop = (props.canvas.height - CENTER_HEIGHT) / 2;
            const maxTop = minTop + CENTER_HEIGHT;

            props.canvas.on('object:moving', function(e) {
                const object = e.target;

                // Check and adjust position
                if (object.left < minLeft) {
                    object.left = minLeft;
                    // alert("You can't move outside the center range!");
                }
                if (object.left > maxLeft - object.width * object.scaleX) {
                    object.left = maxLeft - object.width * object.scaleX;
                    // alert("You can't move outside the center range!");
                }
                if (object.top < minTop) {
                    object.top = minTop;
                    // alert("You can't move outside the center range!");
                }
                if (object.top > maxTop - object.height * object.scaleY) {
                    object.top = maxTop - object.height * object.scaleY;
                    // alert("You can't move outside the center range!");
                }
            });

            props.canvas.on('object:scaling', function(e) {
                const object = e.target;
            
                // Check and adjust size
                if (object.width * object.scaleX > CENTER_WIDTH) {
                    object.scaleX = CENTER_WIDTH / object.width;
                }
                if (object.height * object.scaleY > CENTER_HEIGHT) {
                    object.scaleY = CENTER_HEIGHT / object.height;
                }
            });

            props.canvas.renderAll();
        }, { crossOrigin: "Anonymous" });
    };

    useEffect(() => {
        setTimeout(() => {
            if (props.mode === "product") {
                addImageToCanvas(product);
            }
        }, 500);
    }, [props.canvas]);



    const handleCanvas = async (e) => {
        try {
            props.initializecanvas();
            props.set_canvas_modal(false);
        } catch (err) {
            console.log(err);
        }
    };


    return (<>

        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="canvas--section--modal"
        >
            <Modal.Header closeButton>
                <Modal.Title
                    id="contained-modal-title-vcenter"
                    style={{ fontSize: "14px", fontWeight: "500" }}
                >
                    Size
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex flex-column  justify-content-between px-3">
               {
                props.setmode("creation")
               }

                    <>
                    <div>
                        <p
                            className="m-0 pt-2 d-flex"
                            style={{ color: "#242424", fontSize: "14px" }}
                        >
                            {/*Choose a Stickfigure to open*/}
                            <span
                                style={{
                                    marginLeft: "auto", cursor: "pointer", textDecoration: "underline",
                                }}
                                onClick={() => props.setmode(null)}
                            >
                                Back
                            </span>
                        </p>
                    </div>
                    <div
                        className="w-100 d-flex align-items-center justify-content-between  py-2"
                        style={{ position: "relative" }}
                    >
                        <div className="d-flex flex-column">
                            <label style={{ fontSize: "14px", fontWeight: "500" }}>
                                Width
                            </label>
                            <input
                                className="input--canva px-2"
                                type="text"
                                value={props.canvassize.width}
                                onChange={(e) => props.setcanvassize((state) => ({
                                    ...state, width: e.target.value,
                                }))}
                            />
                        </div>

                        <div className="d-flex flex-column">
                            <label style={{ fontSize: "14px", fontWeight: "500" }}>
                                Height
                            </label>
                            <input
                                className="input--canva px-2"
                                type="text"
                                value={props.canvassize.height}
                                onChange={(e) => props.setcanvassize((state) => ({
                                    ...state, height: e.target.value,
                                }))}
                            />
                        </div>

                        <Dropdown
                            className="aboslute--class"
                            style={{ paddingTop: "18px" }}
                        >
                            <Dropdown.Toggle id="dropdown-basic">
                                <div
                                    className="d-flex justify-content-around align-items-center"
                                    style={{ fontSize: "10px" }}
                                >
                                    px <AiOutlineDown size={10} />
                                </div>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">px</Dropdown.Item>
                                {/* <Dropdown.Item href="#/action-2">40px</Dropdown.Item>
                        <Dropdown.Item href="#/action-3">60px</Dropdown.Item> */}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </>

                {props.mode !== null && (<div className="w-100 pt-3 pb-4">
                    <button
                        className="w-100 modal--canvas--btn"
                        onClick={handleCanvas}
                        disabled={props.mode === "product" && product === null}
                    >
                        {" "}
                        Create
                    </button>
                </div>)}
            </Modal.Body>
        </Modal>
    </>);
};

export default CanvasModalAdminCustomizer;
