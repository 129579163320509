import { fabric } from "fabric";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { saveDownload } from "../../services/customizerService";
import { getPublicCreations } from "../../services/customizerService";
import CanvasModalAdminCustomizer from "../modal/CanvasModalAdminCustomizer";
import { state } from "../../valtio";

const UploadMediaAdminCustomizer = ({
                         oldCanvas,
                         download,
                         setDownload,
                         setFileData,
                         setCanvasState,
                         product,
                         canvasss,
                         layerIndex,
                         canvascolor,
                         setTimeStamp,
                         makeCanvasImg,
                         undo,
                         redo,
                         history,
                         setHistory,
                         setCurrentStep,
                         currentStep,
                         reinitCanvas,
                         selectedProduct,
                         setSelectedProduct,
                         mode,
                         setMode,
                         printfulCategories,
                         printFulProducts,
                         setFilterPrintfulProducts,
                         filterPrintfulProducts,
                         canvasRef,
                         deletedElementSrc,
                         ignoreObjectAddedEvent,
                         canvasSize,
                         setCanvasSize,
                         canvas,
                         initCanvas,
                         initializeCanvas,
                         setPrintfulPrice
                     }) => {
    const [modalShow, setModalShow] = useState(false);
    const [deleteIcon, setDeleteIcon] = useState("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='595.275px' height='595.275px' viewBox='200 215 230 470' xml:space='preserve'%3E%3Ccircle style='fill:%23F44336;' cx='299.76' cy='439.067' r='218.516'/%3E%3Cg%3E%3Crect x='267.162' y='307.978' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)' style='fill:white;' width='65.545' height='262.18'/%3E%3Crect x='266.988' y='308.153' transform='matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)' style='fill:white;' width='65.544' height='262.179'/%3E%3C/g%3E%3C/svg%3E");
    const [rotateIcon, setRotateIcon] = useState("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0Ij48cGF0aCBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIi8+PHBhdGggZD0iTTEyIDZ2M2w0LTQtNC00djNjLTQuNDIgMC04IDMuNTgtOCA4IDAgMS41Ny40NiAzLjAzIDEuMjQgNC4yNkw2LjcgMTQuOGMtLjQ1LS44My0uNy0xLjc5LS43LTIuOCAwLTMuMzEgMi42OS02IDYtNnptNi43NiAxLjc0TDE3LjMgOS4yYy40NC44NC43IDEuNzkuNyAyLjggMCAzLjMxLTIuNjkgNi02IDZ2LTNsLTQgNCA0IDR2LTNjNC40MiAwIDgtMy41OCA4LTggMC0xLjU3LS40Ni0zLjAzLTEuMjQtNC4yNnoiLz48L3N2Zz4=")
    const [zoomLevel, setZoomLevel] = useState(1);
    const inputRef = useRef();
    const[stickfigures, setStickfigures] = useState([])
    const navigate = useNavigate();


    const setZoom = (e) => {
        if (!canvas) return;
        let newZoomLevel = e.target.value;
        setZoomLevel(newZoomLevel);
        canvas.zoomToPoint({x: 129, y: 129}, newZoomLevel);
        e.preventDefault();
        e.stopPropagation();
    }


    const zoomInOut = (e, zoom) => {
        if (!canvas) return;
        setZoomLevel((state) => parseFloat(state)+parseFloat(zoom));
        canvas.zoomToPoint({x: 129, y: 129}, parseFloat(zoomLevel)+parseFloat(zoom));
    }

    useEffect(() => {console.log(zoomLevel)},[zoomLevel])


    const getCreationList = async () => {
        const response = await getPublicCreations();
        console.log(response);
         setStickfigures(response?.data?.data?.stickfigures);
    };

    useEffect(() => {
          getCreationList()
    }, [])


    useEffect(() => {
        if (reinitCanvas > 0) {
            initializeCanvas();
        }
    }, [reinitCanvas])


    const handleKeyDown = (event) => {
        if (event.code === 'Delete' && canvasRef.current) {
            const activeObject = canvasRef.current.getActiveObject();
            if (activeObject) {
                canvasRef.current.remove(activeObject);
                canvasRef.current.renderAll();
            }
        }
    };


    useEffect(() => {
        if (product !== null && canvas === null) {
            initializeCanvas(null, product.canvas);
        } else if (oldCanvas !== null) {
            initializeCanvas(null, oldCanvas);
        }
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [product]);


    useEffect(() => {
        if (currentStep > 0) {
            const canvasState = history[currentStep - 1];
            setCurrentStep((step) => step - 1);
            ignoreObjectAddedEvent.current = false;
            canvas.loadFromJSON(canvasState, () => {
                canvas.renderAll();
                ignoreObjectAddedEvent.current = true;
            });
        }
    }, [undo])


    useEffect(() => {
        if (currentStep < history.length - 1) {
            setCurrentStep((step) => step + 1);
            const canvasState = history[currentStep + 1];
            ignoreObjectAddedEvent.current = false;
            canvas.loadFromJSON(canvasState, () => {
                canvas.renderAll();
                ignoreObjectAddedEvent.current = true;
            });
        }
    }, [redo])


    useEffect(() => {
        if (canvas) {
            let output = canvas.toDataURL('image/png', 1.0);
            const trimmedString = output.replace('data:image/png;base64,', '');
            const imageContent = atob(trimmedString);
            const buffer = new ArrayBuffer(imageContent.length);
            const view = new Uint8Array(buffer);
            for (let n = 0; n < imageContent.length; n++) {
                view[n] = imageContent.charCodeAt(n);
            }
            const type = 'image/jpeg';
            const blob = new Blob([buffer], {type});
            let file = new File([blob], "waheed", {lastModified: new Date().getTime(), type});

            // 1. Serialize the original canvas
            const canvasData = canvas.toJSON();

            // 2. Create a new canvas instance
            const clonedCanvas = new fabric.Canvas();
            clonedCanvas.setWidth(canvas.width);
            clonedCanvas.setHeight(canvas.height);

            // 3. Load the serialized data into the new canvas
            clonedCanvas.loadFromJSON(canvasData, function() {
                clonedCanvas.setBackgroundImage(null);
                clonedCanvas.setBackgroundImage('transparent');
                clonedCanvas.setBackgroundColor('transparent');
                clonedCanvas.renderAll();
                let output_1 = clonedCanvas.toDataURL();
                const trimmedString = output_1.replace('data:image/png;base64,', '');
                const imageContent = atob(trimmedString);
                const buffer = new ArrayBuffer(imageContent.length);
                const view = new Uint8Array(buffer);
                for (let n = 0; n < imageContent.length; n++) {
                    view[n] = imageContent.charCodeAt(n);
                }
                if (file) {
                    state.mockBuffer = buffer;
                    setFileData(file)
                    setCanvasState(JSON.stringify(canvas.toJSON(['selectable', 'evented', 'eraser'])))
                }
            });
        }
    }, [download])



    useEffect(() => {
        if (canvas) {
            const id = toast.loading("Dowloading");
            const fileName = 'Untitled ' + new Date().getTime() + '.png';
            saveDownload({name: fileName}).then(res => {
                if (res.status === 201) {
                    let a = document.getElementById("down-img");
                    a.href = canvas.toDataURL({
                        format: 'png', quality: 0.8
                    })
                    a.download = fileName;
                    a.click();
                    toast.update(id, {render: "Downloaded", type: "success", isLoading: false});
                } else {
                    toast.update(id, {render: res.data.message, type: "error", isLoading: false});
                }
                setTimeout(() => {
                    toast.dismiss(id);
                }, 2000);
            })
        } else if (makeCanvasImg) {
            toast.warn("Please initialize canvas first");
        }
    }, [makeCanvasImg]) 



    /* View in fullscreen */
    function openFullscreen() {
        /* Get the documentElement (<html>) to display the page in fullscreen */
        var elem = document.documentElement;
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) { /* Safari */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE11 */
            elem.msRequestFullscreen();
        }
    }


    /* Close fullscreen */
    function closeFullscreen() {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { /* Safari */
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE11 */
            document.msExitFullscreen();
        }
    }


    useEffect(() => {
        if (canvas) {
            if (canvas.getObjects().length > 0) {
                canvas.getObjects().forEach((obj, index) => {
                    if (obj.type === 'group') {
                        for (let i = 0; i < obj._objects.length; i++) {
                            if (obj._objects[i].type === "path" && obj._objects[i].hasOwnProperty('eraser')) {
                                obj.set({
                                    stroke: canvascolor
                                })
                            }
                        }
                    } else if (obj.type === "path" && obj.hasOwnProperty('eraser') && obj.eraser) {
                        obj.set({
                            stroke: canvascolor
                        })
                    }
                })
            }
            canvas.setBackgroundColor(canvascolor);
            canvas.renderAll();
        }
    }, [canvascolor]);


    useEffect(() => {
        if (canvas && layerIndex !== null) {
            canvas.setActiveObject(canvas.item(layerIndex));
            canvas.renderAll()
        }
    }, [layerIndex]);

    const [canvasModal, setCanvasModal] = useState(false)


    const handleDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.getData('URL');
        // Get the dropped element
        const droppedElement = event.dataTransfer.getData('text/html');

        // Create a temporary element to extract the attributes
        const tempElement = document.createElement('div');
        tempElement.innerHTML = droppedElement;
        const droppedObject = tempElement.firstChild;

        // Retrieve the attributes of the dropped element
        const attributes = droppedObject.attributes;
        const dataCustomAttribute = attributes.getNamedItem('data-custom-attribute')?.value;
        if (file && typeof dataCustomAttribute === "undefined") {
            new fabric.Image.fromURL(file, function (image) {
                let scale = 300 / image.width;
                var img = image.set({left: 0, top: 0, scaleX: scale, scaleY: scale, padding: 0});
                canvas.add(img);
            }, {crossOrigin: 'Anonymous'})
        } else {
            const objects = canvas.getObjects();
            canvas.loadFromJSON(dataCustomAttribute, function () {
                if (objects.length > 0) {
                    objects.forEach((obj, key) => {
                        canvas.add(obj);
                    })
                }
                canvas.renderAll();
            });
        }
        if (canvas && canvas.isDrawingMode) {
            canvas.isDrawingMode = false;
        }
    }



    return (<>
        <a href="" className="d-none" id="down-img"></a>
        <img src={deleteIcon} className="d-none" id="del-icon"/>
        <img src={rotateIcon} className="d-none" id="rotate-icon"/>

        <div className="col-md-8 pr-0">
            <div className="row my-3 zoom-In" style={{position: "absolute", cursor: 'pointer'}}
                 onClick={openFullscreen}>
                <img src="/assets/images/maxScreen.png"/>
            </div>
            {/* <input type="file" className="d-none" ref={inputRef} onChange={onSelectFile} /> */}
            <div className="wrapper w-100 h-75 d-flex justify-content-center align-items-center">
                <div
                    className="file-wrapper d-flex flex-column align-items-center justify-content-center upload-Media"
                    style={{cursor: 'pointer'}} onDrop={handleDrop}>
                    {(canvas === null && product === null) && (<>
                        <img src="/assets/images/PlusCircle.svg" onClick={() => {
                            setCanvasModal(true);
                        }}/>
                        <h2 style={{fontFamily: "RockoFLF Regular"}} onClick={() => {
                            setCanvasModal(true);
                        }}>Create</h2>
                    </>)}
                    <canvas className="sample-canvas d-none" id="canvas" ref={canvasRef}/>
                </div>
            </div>
            {/* <div className="footer" style={{position: "absolute", bottom: "52px", right: "73px"}}>
                {/* <!-- Small modal --> */}
                {/* <button type="button" className="btn btn-primary"
                        style={{
                            background: "#E9E9E9",
                            borderRadius: "10px 10px 0px 0px",
                            border: "none",
                            color: "black",
                            textTransform: "capitalize",
                            fontFamily: "RockoFLF Regular"
                        }} onClick={() => setModalShow(true)}>
                    <img src="/assets/images/LightbulbFilament.png"/>
                    Your Suggestions
                </button>
            </div>  */}
            <div className="footer-min">
                <div className="footer d-flex justify-content-between align-items-center w-100"
                     style={{
                         zIndex: '1',
                         backgroundColor: "#F9F9F9",
                         position: "absolute",
                         bottom: "0px",
                         height: "52px",
                         minWidth: "75%",
                         maxWidth: "75%",
                         right : "50px"
                     }}>
                    <div className="footer-wrapper d-flex justify-content-between align-items-center w-100 mr-3">
                        <div className="row w-100">
                            <div className="col-md-12 d-flex">
                                <div className="col-md-4" onClick={(e) => setCanvasModal(true)}
                                     style={{cursor: 'pointer'}}>
                                    <p>Resize:{canvasSize.width} x {canvasSize.height} px</p>
                                </div>
                                <div className="col-md-4">
                                    <p>Click Here For Zoom</p>
                                </div>
                                <div className="col-md-4 d-flex justify-content-around align-items-center pr-0">
                                    <img src="/assets/images/Zoomout.svg" style={{cursor:"pointer"}} onClick={(e) => zoomInOut(e,-0.15)}/>
                                    <input className="customizer--input" type="range" step="0.25" min="0.25" max="4"
                                           value={zoomLevel} onChange={setZoom}
                                           id="myRange" style={{height: '1px'}}/>
                                    <img src="/assets/images/Zoomin.svg"  style={{cursor:"pointer"}} onClick={(e) => zoomInOut(e,0.15)}/>
                                    <img src="/assets/images/Question.png"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <CanvasModalAdminCustomizer
            show={canvasModal}
            onHide={() => setCanvasModal(false)}
            set_canvas_modal={setCanvasModal}
            canvassize={canvasSize}
            setcanvassize={setCanvasSize}
            initializecanvas={initializeCanvas}
            initcanvas={initCanvas}
            mode={mode}
            setmode={setMode}
            printfulCategories={printfulCategories}
            printFulProducts={printFulProducts}
            filterPrintfulProducts={filterPrintfulProducts}
            setFilterPrintfulProducts={setFilterPrintfulProducts}
            canvas={canvasss}
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
            stickfigures={stickfigures}
            setStickfigures={setStickfigures}
            setPrintfulPrice={setPrintfulPrice}
        />
    </>);
}

export default UploadMediaAdminCustomizer