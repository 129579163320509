import { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import KycSuccess from '../components/modal/KycSuccess';
import { check_verification_status, upload_document } from "../services/KycService";
import VerificationPending from "./VerificationPending";
import { MAX_FILE_SIZE } from '../components/Constants/constants';

const VerifyDocs = () => {
    const navigate = useNavigate();
    const [modalShow, setModalShow] = useState(false);
    const [files, setFiles] = useState({
        photo: '', govt_id: '', electricity_bill: ''
    });
    const [loader, setLoader] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const photoPicker = useRef(null);
    const govtIdPicker = useRef(null);
    const electricityBillPicker = useRef(null);
    const [statusLoading, setStatusLoading] = useState(true);
    const [verificationPending, setVerificationPending] = useState(false);


    const handleFileInputChange = ({event, key}) => {
        const selectedFile = event.target.files[0];
    
        if (!selectedFile) {
            toast.warn("Please select a file");
            return;
        }
    
        if (!validateFile(selectedFile)) {
            return;
        }
    
        setFiles({ ...files, [key]: selectedFile });
    };


    const validateFile =(file) => {
        try {
            if (!file) throw new Error("File not provided");
    
            const allowedExtensions = ["jpg", "jpeg", "png", "pdf"];
            const fileExtension = file.name.split(".").pop().toLowerCase();
            
            if (file.size > MAX_FILE_SIZE) {
                throw new Error("File size should be less than 5MB");
            }
    
            if (!allowedExtensions.includes(fileExtension)) {
                throw new Error("Invalid file extension");
            }
    
            return true; 
        } catch (error) {
            
            console.error("File validation error:", error.message);
            toast.error(error.message); 
            return false;
        }
    }

    useEffect(() => {
        (async () => {
            const response = await check_verification_status();
            if (response?.status === 200) {
                if (response.data.verified) {
                    navigate("/wallet");
                } else if (response.data.status === "pending") {
                    setVerificationPending(true);
                }
            }
            setStatusLoading(false);
        })()
    }, []);

    const continueClicked = async () => {
        if (!files.photo || !files.govt_id || !files.electricity_bill) {
            setIsValid(false)
            return;
        } else {
            setIsValid(true)
        }

        setLoader(true)
        const data = new FormData();
        data.append('photo', files.photo);
        data.append('govt_id', files.govt_id);
        data.append('electricity_bill', files.electricity_bill);
        const response = await upload_document(data);
        setLoader(false)
        if (response?.status === 200) {
            setModalShow(true)
        } else if (response?.status === 403) {
            toast.warn(response.data.message)
        } else {
            toast.error(response.data.message)
        }
    }

    return (statusLoading ? <div style={{ minHeight: '100vh' }}></div> : verificationPending ? <VerificationPending /> : <>
        {/* STEPPER 1 */}
        <section className="collection-section py-5" style={{ backgroundColor: '#F9F9F9' }}>
            <div className="container d-flex flex-column justify-content-center">
                <div className="row d-flex justify-content-center">
                    <div className="pb-5 col-md-12 d-flex flex-column justify-content-center text-center">
                        <h2 className="heading" style={{ fontFamily: 'RockoFLF Regular' }}>Verify Your Identity</h2>
                    </div>
                </div>
                <div className="row d-flex justify-content-center">
                    <div className="col-md-12">
                        <h2 className="heading"
                            style={{ fontFamily: 'RockoFLF Regular', textTransform: 'unset' }}>Upload Documents</h2>
                        <p style={{ marginBottom: "0px !important" }}>
                            to verify your identity, Please upload digital photos of the following documents &#40; less than 5 MB &#41;
                        </p>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-md-6 d-flex justify-content-between flex-wrap upload-media-divs"
                        style={{ zIndex: '1' }}>
                        <div className={'flex flex-column'}>
                            <div className="text-danger" style={{ height: "24px" }}>
                                {!isValid && !files.photo && "Please upload the Photo"}
                            </div>
                            <div className='upload-media photos-steper'>
                                <div className='row justify-content-end'>
                                    <div className='col-md-5 d-flex p-3 justify-content-end'>
                                        {!files.photo ? <>
                                            <img src="/assets/images/status.svg" />
                                            <p className='mx-1'
                                                style={{
                                                    color: '#FFCA3F', fontSize: '12px', marginBottom: '0px'
                                                }}>Pending</p>
                                        </> : <>
                                            <img src="/assets/images/status_success.svg" />
                                            <p className='mx-1'
                                                style={{
                                                    color: '#5FD800', fontSize: '12px', marginBottom: '0px'
                                                }}>Success</p>
                                        </>}
                                    </div>
                                </div>
                                <div className='row flex-column justify-content-center align-items-center text-center'>
                                    {!files.photo ?
                                        <img src="/assets/images/uploadImage.svg" style={{ maxWidth: '50%' }} /> :
                                        <img src="/assets/images/uploadedImage.svg" style={{ maxWidth: '50%' }} />}
                                    <h6 className='mt-2'>Photo</h6>
                                </div>
                                <div className='row justify-content-center mt-2'>
                                    <button className='upload-btn' onClick={() => photoPicker.current.click()}>
                                        <img src='/assets/images/uploadBtnIcon.svg' alt={'upload_file'} />
                                        <span className='mx-1 text-white'>Upload</span>
                                    </button>
                                    <input type="file" hidden ref={photoPicker}
                                        onChange={(event) =>handleFileInputChange({event, key:"photo"})}
                                        accept=".jpeg, .jpg, .png, .pdf"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={'flex flex-column'}>
                            <div className="text-danger" style={{ height: "24px" }}>
                                {!isValid && !files.govt_id && "Please upload the document"}
                            </div>
                            <div className='upload-media'>
                                <div className='row justify-content-end'>
                                    <div className='col-md-5 d-flex p-3 justify-content-end'>
                                        {!files.govt_id ? <>
                                            <img src="/assets/images/status.svg" alt={'upload_file'} />
                                            <p className='mx-1'
                                                style={{
                                                    color: '#FFCA3F', fontSize: '12px', marginBottom: '0px'
                                                }}>Pending</p>
                                        </> : <>
                                            <img src="/assets/images/status_success.svg" />
                                            <p className='mx-1'
                                                style={{
                                                    color: '#5FD800', fontSize: '12px', marginBottom: '0px'
                                                }}>Success</p>
                                        </>}
                                    </div>
                                </div>
                                <div className='row flex-column justify-content-center align-items-center text-center'>
                                    {!files.govt_id ?
                                        <img src="/assets/images/uploadDoc.svg" style={{ maxWidth: '50%' }} /> :
                                        <img src="/assets/images/uploadedDoc.svg" style={{ maxWidth: '50%' }} />}
                                    <h6 className='mt-2'>Government Issued ID</h6>
                                </div>
                                <div className='row justify-content-center mt-2'>
                                    <button className='upload-btn' onClick={() => govtIdPicker.current.click()}>
                                        <img src='/assets/images/uploadBtnIcon.svg' alt={'upload_file'} />
                                        <span className='mx-1 text-white'>Upload</span>
                                    </button>
                                    <input type="file" hidden ref={govtIdPicker}
                                         onChange={(event) =>handleFileInputChange({event, key:"govt_id"})}
                                        accept=".jpeg, .jpg, .png, .pdf"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={'flex flex-column my-4'}>
                            <div className="text-danger" style={{ height: "24px" }}>
                                {!isValid && !files.electricity_bill && "Please upload the document"}
                            </div>
                            <div className='upload-media'>
                                <div className='row justify-content-end'>
                                    <div className='col-md-5 d-flex p-3 justify-content-end'>
                                        {!files.electricity_bill ? <>
                                            <img src="/assets/images/status.svg" alt={'upload_file'} />
                                            <p className='mx-1'
                                                style={{
                                                    color: '#FFCA3F', fontSize: '12px', marginBottom: '0px'
                                                }}>Pending</p>
                                        </> : <>
                                            <img src="/assets/images/status_success.svg" />
                                            <p className='mx-1'
                                                style={{
                                                    color: '#5FD800', fontSize: '12px', marginBottom: '0px'
                                                }}>Success</p>
                                        </>}
                                    </div>
                                </div>
                                <div className='row flex-column justify-content-center align-items-center text-center'>
                                    {!files.electricity_bill ?
                                        <img src="/assets/images/uploadDoc.svg" style={{ maxWidth: '50%' }} /> :
                                        <img src="/assets/images/uploadedDoc.svg" style={{ maxWidth: '50%' }} />}
                                    <h6 className='mt-2'>Electricity Bill</h6>
                                </div>
                                <div className='row justify-content-center mt-2'>
                                    <button className='upload-btn'
                                        onClick={() => electricityBillPicker.current.click()}>
                                        <img src='/assets/images/uploadBtnIcon.svg' alt={'upload_file'} />
                                        <span className='mx-1 text-white'>Upload</span>
                                    </button>
                                    <input type="file" hidden ref={electricityBillPicker}
                                         onChange={(event) =>handleFileInputChange({event, key:"electricity_bill"})}
                                        accept=".jpeg, .jpg, .png, .pdf"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className='wallet-banner'
                            style={{ backgroundImage: "url('/assets/images/wallet_banner2.png')" }}></div>
                    </div>
                </div>
                <div className='row'>
                    <div className="py-2 d-flex justify-content-center">
                        <button type="button"
                            className="get-started mt-2 d-flex justify-content-center align-items-center"
                            disabled={loader}
                            onClick={continueClicked}>
                            {loader && (<div className="spinner-border spinner-border-sm me-2" style={{ borderWidth: "0.2rem", width: '1.4rem', height: '1.4rem' }}>
                                <span className="visually-hidden">Loading...</span>
                            </div>)}Continue
                        </button>
                    </div>
                </div>
            </div>
        </section>
        <KycSuccess
            show={modalShow}
            onHide={() => setModalShow(false)}
            backdrop="static"
            keyboard={false}
        />
    </>);
}

export default VerifyDocs