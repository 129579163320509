import { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { WithdrawRequest } from '../../services/WithdrawalService';
import WithdrawalSuccess from './WithdrawalSuccess';


function WithdrawCash(props) {

    const [bankAccount, setBankAccounts] = useState([]);
    const [selectedAcount, setSelectedAccount] = useState(null);
    const [amount, setAmount] = useState(0);
    const [withdrawSuccess, setWithdrawSuccess] = useState(false);
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        setBankAccounts(props.bankAccounts)
    }, [props.bankAccounts])

    const submitWithdraw = async () => {
        if (amount < 0 || amount > props.balance || selectedAcount === null) {
            return;
        }
        setLoader(true);

        const payload = {
            account_id: selectedAcount,
            amount: amount
        }

        const response = await WithdrawRequest(payload);
        if (response.status === 200) {
            props.setModalShow(false);
            setWithdrawSuccess(true);
        } else if(response.status === 422) {
            setError(response.data.message);
        }
        setLoader(false);
    }
    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                className='withDrawl--cash px-2 d-flex justify-content-center align-items-center'
            >
                <Modal.Header className='justify-content-center' style={{ margin: '16px' }} closeButton>
                    <Modal.Title id="contained-modal-title-vcenter py-3">
                        <h2 style={{ fontSize: '32px' }}>Withdraw Funds - ${props.balance} USD available</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ background: '#FFFFFF', borderRadius: '3px', margin: '16px' }}>
                    <Form className='px-3'>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            {/* <Form.Label>Amount</Form.Label> */}
                            <div className='row'>
                                <div className='col-md-6'>
                                    <Form.Label>Amount</Form.Label>
                                    <Form.Control type="text" placeholder='$ Amount' style={{ height: '51px' }} onChange={(e) => setAmount(e.target.value)} />
                                    <small className='text-danger'>{error}</small>
                                </div>
                                <div className='col-md-6'>
                                    <Form.Label>Send To</Form.Label>
                                    {
                                        bankAccount.length > 0 && (
                                            <Form.Select onChange={(e) => setSelectedAccount(e.target.value)}>
                                                <option disabled selected>select bank account</option>
                                                {
                                                    bankAccount.map((account, index) => {
                                                        return <option value={account.id} key={index} >Bank - {account?.bank_name} </option>
                                                    })
                                                }
                                            </Form.Select>
                                        )
                                    }
                                </div>
                            </div>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-start border-0 mb-3' style={{ margin: '16px' }}>
                    <button className="get-started d-flex justify-content-center align-items-center"
                        style={{ width: '194px ', height: "51px", color: amount > props.balance ? 'red' : 'black' }}
                        disabled={amount === 0 || amount > props.balance}
                        onClick={submitWithdraw}>
                        {
                            loader && (
                                <div className="spinner-border me-2" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            )
                        }
                        withdraw ${amount}</button>
                    <button className="get-started" onClick={props.onHide} style={{ width: '194px ', height: "51px" }} >Cancel</button>
                </Modal.Footer>
            </Modal >
            <WithdrawalSuccess show={withdrawSuccess} onHide={() => setWithdrawSuccess(false)} />
        </>
    );
}

export default WithdrawCash;