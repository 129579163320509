
import { Link } from 'react-router-dom'
import foto from '../../assets/images/Group 925.svg'
const YouDesignWeDeliver = ({ data }) => {
    return (

        <>


            {
                data?.data?.map((i) => {
                    if (i.type === 'desire') {
                        return (
                            <>
                                <div className='we--deliver--container'>
                                    <div className='container py-5  '>
                                        <div className='row'>
                                            <div className='col-lg-6 col-md-12 col-12 mb-5' >
                                                <img src={foto} style={{ width: '450px', objectFit: 'contain', }} />
                                            </div>
                                            <div className='col-lg-6 col-md-12 col-12 ' style={{ position: 'relative', }}>
                                                <div className=' bg--color d-flex flex-column' >
                                                    <h2 className="mb-4" style={{ fontFamily: "Brittany Signature", fontWeight: '400', fontSize: '28px' }}>{i.pre_heading}</h2>
                                                    <h1 style={{ fontSize: '28px', marginBottom: '1.2rem' }}>{i.heading}</h1>
                                                    <p>{i.description}</p>
                                                    <Link className='btn px-3 mt-3'> Customizer Now</Link>
                                                </div>
                                                {/* <div className='bg--dice' style={{ position: 'absolute', top: '-9%', left: '-5%' }}>
            </div> */}
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </>
                        )
                    }
                })
            }



        </>
    )
}

export default YouDesignWeDeliver