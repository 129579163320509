import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

////News feed place holder
export function PlaceHolder() {
  const feedSkeleton = [
    {
      skeleton: 1,
    },
    {
      skeleton: 2,
    },
    {
      skeleton: 3,
    },
    {
      skeleton: 4,
    },
    {
      skeleton: 5,
    },
    {
      skeleton: 6,
    },
  ];
  return (
    <>
      {feedSkeleton?.map((i,index) => {
        return (
          <div
            className="card mt-2"
            style={{ backgroundColor: "#E9E9E9 !important" }}
            key={index}
          >
            <div className="card-body">
              <div className="container-fluid">
                <div className="row pt-3" style={{ position: "relative" }}>
                  <div className="col-md-1 d-flex align-items-center">
                    <Skeleton
                      circle
                      height="40px"
                      width="40px"
                      containerClassName="avatar-skeleton"
                    />
                    <div className="col-md-4 d-flex align-items-center p-0">
                      <h5
                        className="card-title me-2 mb-0"
                        style={{ paddingLeft: "1rem" }}
                      >
                        <Skeleton width={70} />
                      </h5>
                      <p className="card-text">
                        <Skeleton width={50} />
                      </p>
                    </div>
                    <div className="btn-group btn--group--three ">
                      <Skeleton width={40} height={30} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-11 p-0">
                      <p className="card-text">
                        {" "}
                        <Skeleton width={"100%"} />
                        <Skeleton width={"100%"} />
                      </p>
                    </div>
                  </div>
                  <div className="container my-3 p-0">
                    <div>
                      <div className="image__div">
                        <Skeleton width={250} height={150} />
                        <Skeleton width={250} height={150} />
                        <Skeleton width={250} height={150} />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-5 d-flex justify-content-between my-2">
                        <div
                          className="like--folow"
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            gap: "15px",
                            alignItems: "center",
                          }}
                        >
                          <Skeleton width={40} height={30} />
                          <Skeleton width={40} height={30} />
                          <Skeleton width={40} height={30} />
                          <Skeleton width={40} height={30} />
                        </div>
                      </div>

                      <Skeleton width={120} height={20} />
                    </div>
                    <div className="row mt-1 p-2">
                      <div
                        className="col-md-12 pl-0"
                        style={{
                          position: "relative",
                          backgroundColor: "#F6F6F6",
                          borderRadius: "10px",
                        }}
                      >
                        <div
                          className="d-flex  pt-1 gap-3"
                          style={{ height: "36px" }}
                        >
                          <Skeleton
                            circle
                            height="30px"
                            width="30px"
                            containerClassName="avatar-skeleton"
                          />
                          <div className="col-md-12 d-flex align-items-baseline p-0 gap-3">
                            <h5
                              className=" me-2 mb-0 "
                              style={{
                                fontSize: "16px",
                                fontWeight: "700",
                              }}
                            >
                              <Skeleton width={70} />
                            </h5>
                            <p className="card-text">
                              <small className="text-muted">
                                {" "}
                                <Skeleton width={50} />
                              </small>
                            </p>
                            <Skeleton width={40} height={20} />
                            <Skeleton width={40} height={20} />
                          </div>
                        </div>

                        <div className="btn-group btn--group--three1 ">
                          <Skeleton width={40} height={30} />
                        </div>

                        <div>
                          <p className="m-0">
                            {" "}
                            <Skeleton width={"100%"} />
                          </p>
                          <p className="m-0">
                            {" "}
                            <Skeleton width={"100%"} />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row ">
                      <div
                        className="col-md-12 pl-0"
                        style={{ position: "relative" }}
                        id="commentInput_data"
                      >
                        <Skeleton width={"100%"} height={30} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}

////Follower Place holder
export function PlaceHolderFollower() {
  const followerSkeleton = [
    {
      follower: 1,
    },
    {
      follower: 2,
    },
    {
      follower: 3,
    },
    {
      follower: 4,
    },
    {
      follower: 5,
    },
  ];
  return (
    <>
      {followerSkeleton.map((item,index) => {
        return (
          <li
            key={index}
            className="list-group-item d-flex justify-content-between align-items-center"
          >
            <div
              className="user-container d-flex align-items-center gap-3"
              style={{ fontWeight: "500" }}
            >
              <Skeleton
                circle
                height="30px"
                width="30px"
                containerClassName="avatar-skeleton"
              />
              <Skeleton width={70} />
            </div>
            <Skeleton
              circle
              height="10px"
              width="10px"
              containerClassName="avatar-skeleton"
            />
          </li>
        );
      })}
    </>
  );
}

/////Products place holder
export function PlaceHolderProducts() {
  const product_place = [
    {
      product: 1,
    },
    {
      product: 2,
    },
    {
      product: 3,
    },
    {
      product: 4,
    },
    {
      product: 5,
    },
    {
      product: 6,
    },
  ];
  return (
    <>
      {product_place.map((product, i) => {
        return (
          <div key={i}
            className="card m-2 col-md-4 col-sm-12 col-12"
            style={{ width: "260px", backgroundColor: "#F3F3F3 !important" }}
          >
            <div className="col-md-12 d-flex justify-content-between align-items-center p-3">
              <h4 className="shop--heading">
                {" "}
                <Skeleton width={70} />
              </h4>
              <p>
                {" "}
                <Skeleton width={50} />
              </p>
            </div>
            <div className="col-md-12 d-flex justify-content-center">
              <Skeleton width={190} height={190} />
            </div>
            <div className="col-md-12 d-flex justify-content-between align-items-center p-3">
              <div
                className="custom-anchor d-flex align-items-center"
                style={{ fontSize: "14px ", columnGap: "5px" }}
              >
                {" "}
                <Skeleton
                  circle
                  height="20px"
                  width="20px"
                  containerClassName="avatar-skeleton"
                />
                <Skeleton width={70} />
              </div>
              <div
                to=""
                className="custom-anchor d-flex align-items-center"
                style={{ fontSize: "14px ", columnGap: "5px" }}
              >
                <Skeleton width={20} height={20} />
                <Skeleton width={70} />
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}

//// category Place holder
export function PlaceHolderCategory() {
  const CategoryPlaceholder = [
    {
      category: 1,
    },
    {
      category: 2,
    },
    {
      category: 3,
    },
    {
      category: 4,
    },
    {
      category: 5,
    },
    {
      category: 6,
    },
    {
      category: 7,
    },
    {
      category: 8,
    },
    {
      category: 9,
    },
  ];
  return (
    <>
      {CategoryPlaceholder.map((i) => {
        return (
          <div className="d-flex gap-1 " key={i.category}>
            <Skeleton
              circle
              height="15px"
              width="15px"
              containerClassName="avatar-skeleton"
            />
            <label htmlFor="cup">
              {" "}
              <Skeleton width={50} />
            </label>
          </div>
        );
      })}
    </>
  );
}

///Notification Place holder
export function PlaceHolderNotification() {
  const notification = [
    {
      notify: 1,
    },
    {
      notify: 2,
    },
    {
      notify: 3,
    },
    {
      notify: 4,
    },
  ];
  return (
    <>
      {notification.map((i,index) => {
        return (
          <li
            className="list-group-item d-flex"
            key={index}
            style={{ gap: "18px", width: "100%" }}
          >
            <Skeleton width={100} height={100} />
            <div
              className=" d-flex pb-2 w-100 "
              style={{ justifyContent: "space-between" }}
            >
              <div className="gap-3 align-items-center">
                <Skeleton width={70} />
                <Skeleton width={140} />
                <Skeleton width={210} />
                <Skeleton width={280} />
              </div>
              <div className="col-md-4 d-flex justify-content-end align-items-center">
                <div className="d-flex flex-column align-items-center">
                  <small>
                    {" "}
                    <Skeleton width={70} />
                  </small>
                  <>
                    <div className="btn-group ">
                      <Skeleton width={70} height={30} />
                    </div>
                  </>
                </div>
              </div>
            </div>
          </li>
        );
      })}
    </>
  );
}

/// Followers Place holder
export function PlaceHolderFollowers() {
  const FollowersData = [
    {
      follower: 1,
    },
    {
      follower: 2,
    },
    {
      follower: 3,
    },
    {
      follower: 4,
    },
  ];
  return (
    <>
      {FollowersData?.map((i,index) => {
        return (
          <li
            className="list-group-item d-flex "
            key={index}
            style={{ gap: "18px", width: "100%" }}
          >
            <Skeleton width={100} height={100} />
            <div
              className=" d-flex pb-2 w-100 "
              style={{ justifyContent: "space-between" }}
            >
              <div className="gap-3 align-items-center">
                <Skeleton width={70} />
                <Skeleton width={140} />
                <Skeleton width={210} />
                <Skeleton width={280} />
              </div>
              <div className=" d-flex align-items-center justify-content-center gap-3">
                <Skeleton width={60} height={60} />
                <Skeleton width={30} height={60} />
              </div>
            </div>
          </li>
        );
      })}
    </>
  );
}

///Followers Request PlaceHolder
export function PlaceHolderRequests() {
  return (
    <li className="list-group-item d-flex justify-content-between align-items-center mb-2">
      <div className="row w-100 d-flex ">
        <div className="col-md-12 d-flex justify-content-between ">
          <div
            className="d-flex "
            style={{ alignItems: "baseline", gap: "10px" }}
          >
            <Skeleton height="40px" width="40px" />
            <div>
              <Skeleton width={50} height={10} />
              <Skeleton width={80} height={10} />
            </div>
          </div>
          <div className=" d-flex justify-content-end">
            <>
              <div
                className="rounded-circle p me-2"
                style={{ cursor: "pointer" }}
              >
                <Skeleton
                  circle
                  height="40px"
                  width="40px"
                  containerClassName="avatar-skeleton"
                />
              </div>
              <div className="rounded-circle " style={{ cursor: "pointer" }}>
                <Skeleton
                  circle
                  height="40px"
                  width="40px"
                  containerClassName="avatar-skeleton"
                />
              </div>
            </>
          </div>
        </div>
      </div>
    </li>
  );
}

///CSS component on loader true & false
export function CSSFunction({ loader, reqLoader }) {
  useEffect(() => {
    if (loader || reqLoader) {
      const elements = document.querySelectorAll(".card");
      elements.forEach((element) => {
        element.style.backgroundColor = "white";
      });
      const elements1 = document.querySelectorAll(".card-header");
      elements1.forEach((element) => {
        element.style.backgroundColor = "white";
      });
    } else {
      const elements = document.querySelectorAll(".card");
      elements.forEach((element) => {
        element.style.backgroundColor = "";
      });
      const elements1 = document.querySelectorAll(".card-header");
      elements1.forEach((element) => {
        element.style.backgroundColor = "";
      });
    }
  }, [loader, reqLoader]);
}

///Profile PlaceHolder
export function ProfilePlaceHolder() {
  const product_place = [
    {
      product: 1,
    },
    {
      product: 2,
    },
    {
      product: 3,
    },
  ];
  return (
    <>
      {product_place.map((i,index) => {
        return (
          <div
            key={index}
            className="card m-2 col-md-4 col-sm-12 col-12"
            style={{ width: "260px", backgroundColor: "#F3F3F3 !important" }}
          >
            <div className="col-md-12 d-flex justify-content-between align-items-center p-3">
              <h4 className="shop--heading">
                {" "}
                <Skeleton width={70} />
              </h4>
              <p>
                {" "}
                <Skeleton width={50} />
              </p>
            </div>
            <div className="col-md-12 d-flex justify-content-center">
              <Skeleton width={190} height={190} />
            </div>
            <div className="col-md-12 d-flex justify-content-between align-items-center p-3">
              <div
                className="custom-anchor d-flex align-items-center"
                style={{ fontSize: "14px ", columnGap: "5px" }}
              >
                {" "}
                <Skeleton
                  circle
                  height="20px"
                  width="20px"
                  containerClassName="avatar-skeleton"
                />
                <Skeleton width={70} />
              </div>
              <div
                to=""
                className="custom-anchor d-flex align-items-center"
                style={{ fontSize: "14px ", columnGap: "5px" }}
              >
                <Skeleton width={20} height={20} />
                <Skeleton width={70} />
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}

export function ChatRooms() {
  const chatRooms = [
    {
      follower: 1,
    },
    {
      follower: 2,
    },
    {
      follower: 3,
    },
    {
      follower: 4,
    },
  ];
  return (
    <>
      {chatRooms?.map((i,index) => {
        return (
          <li
            className="list-group-item d-flex "
            key={index}
            style={{ gap: "18px", width: "100%" }}
          >
            <Skeleton width={60} height={60} />
            <div
              className=" d-flex pb-2 w-100 "
              style={{ justifyContent: "space-between" }}
            >
              <div className="gap-3 align-items-center">
                <Skeleton width={120} />
                <Skeleton width={185} />
                <Skeleton width={250} />
              </div>
              <div className=" d-flex align-items-center justify-content-center gap-3"></div>
            </div>
          </li>
        );
      })}
    </>
  );
}
export function InboxLoader() {
  const messages = [
    {
      type: 1,
    },
    {
      type: 2,
    },
    {
      type: 1,
    },
    {
      type: 2,
    },
    {
      type: 1,
    },
    {
      type: 2,
    },
    {
      type: 1,
    },
    {
      type: 2,
    },
    {
      type: 1,
    },
    {
      type: 2,
    },
    {
      type: 1,
    },
    {
      type: 2,
    },
    {
      type: 1,
    },
    {
      type: 2,
    },
  ];
  return (
    <>
      <div className="col-md-9 px-0 mobile-inbox ">
        <div
          className="card py-3"
          style={{
            border: "none",
            borderRadius: "0px",
            backgroundColor: "rgba(243, 243, 243, 1)",
            marginLeft: "5px",
          }}
        >
          <div className="container">
            <div className="row no-gutters">
              <div className="col-md-4 d-flex align-items-center">
                <Skeleton width={55} height={55} />
                <div className="card-body mx-3">
                  <h5
                    className="card-title mb-1 mt-0"
                    style={{ fontFamily: "RockoFLF Bold" }}
                  >
                    <Skeleton width={80} />
                  </h5>
                  <h6
                    className="card-text my-0"
                    style={{
                      fontFamily: "RockoFLF Regular",
                      fontSize: "14px",
                    }}
                  >
                    <Skeleton width={80} />
                  </h6>
                </div>
              </div>
              <div className="col-md-8 second--div">
                <button style={{ border: "none" }}>
                  <Skeleton width={50} height={30} />
                </button>
                {/* <TbDotsVertical size={25} /> */}
              </div>
            </div>
          </div>
        </div>
        <div className="mesgs">
          <div className="msg_history pr-4">
            <div className="incoming_msg my-1">
              <div className="incoming_msg_img"></div>
              <div>
                {messages?.map((i,index) => {
                  return (
                    <div key={index}>
                      {i.type === 1 ? (
                        <div>
                          <div className="col-md-12 d-flex ">
                            <div className="col-md-6 "> </div>
                            <div className="col-md-6 ">
                              <div className="received_msg">
                                <div className="received_withd_msg">
                                  <Skeleton width={550} height={20} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="col-md-12 d-flex ">
                            <div className="col-md-6 ">
                              <div className="received_msg">
                                <div className="other_user_msg">
                                  <Skeleton width={550} height={20} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="type_msg">
                  <Skeleton width={1090} height={40} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export function PrinfulCategoryPlace() {
  const product = [
    {
      product: 1,
    },
    {
      product: 1,
    },
    {
      product: 1,
    },
    {
      product: 1,
    },
    {
      product: 1,
    },
    {
      product: 1,
    },
  ];
  return (
    <>
      <div className={"d-flex justify-content-center align-items-center mb-3"}>
        <Skeleton width={240} height={35} />
      </div>
      <div className="col-md-12 d-flex justify-content-center flex-wrap mb-2 gap-3">
        {product.map((product, index) => {
          return <Skeleton width={80} height={80} key={index} />;
        })}
      </div>
    </>
  );
}
