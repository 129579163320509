import Modal from 'react-bootstrap/Modal';


function WithdrawalSuccess(props) {
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header className='justify-content-center border-0 pb-0 mt-3'>
                <Modal.Title id="contained-modal-title-vcenter">
                    <img src="/assets/images/successIcon.svg" style={{ maxWidth: '100px', maxHeight: '100px' }} />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center" style={{ background: '#FFFFFF', borderRadius: '3px' }}>
                <div className="row justify-content-center">
                    <div className='col-md-8'>
                        <h2>We’re on it!</h2>
                        <h4>
                            Please check your email for
                            updates on your withdrawal request status.
                            This can take upto 48hrs.
                        </h4>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-center border-0 mb-3'>
                <button className="get-started" onClick={props.onHide} style={{ width: '122px', height: '50px' }}>Done</button>
            </Modal.Footer>
        </Modal>
    );
}

export default WithdrawalSuccess