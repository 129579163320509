import { Link } from "react-router-dom";
import Slider from "react-slick";

const TopProduct = ({ data }) => {
  const getSlidesToShow = (minSlides, maxSlides) => {
    if (!data || !data.top_products) return minSlides;
    const length = data.top_products.length;
    return length >= maxSlides ? maxSlides : (length === 0 ? minSlides : length);
  };
  
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: getSlidesToShow(1, 3),
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: getSlidesToShow(1, 4),
          slidesToScroll: 4,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: getSlidesToShow(1, 2),
          slidesToScroll: 2,
          initialSlide: 2,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: getSlidesToShow(1, 1),
          slidesToScroll: 1,
          initialSlide: 1
        },
      },
    ],
  };
  
  
  return (
    <section className="collection-section top--product--of--day py-5">
      <div className="container d-flex flex-column justify-content-center">
        <div className="row d-flex justify-content-center">
          <div className="pb-5 col-md-12 d-flex flex-column justify-content-center text-center">
            <h2 className="heading">TOP PRODUCTS</h2>
            <p style={{fontSize: '24px'}}> Awesome products made by creators like you!</p>
            {/* <p style={{ marginBottom: "0px !important" }}>
              Let’s applaud our Top Users of the Day for their creativity!
            </p> */}
          </div>
          <div className="row justify-content-center">
            <Slider {...settings} className="top--product">
              {data?.top_products?.map((product, key) => {
                return (
                  <div className="slide-wrap" key={key}>
                    <div
                      className="product-card"
                      style={{ position: "relative" }}
                    >
                      <div className="col-md-12 d-flex justify-content-between align-items-center p-3">
                        <h4
                          style={{
                            width: "150px",
                            display: "inline-block",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            textTransform: "capitalize"
                          }}
                        >
                          {product.title.split(0, 15)}
                        </h4>
                        <p className="m-0">${product.price + product.printful_price}</p>
                      </div>
                      <div className="col-md-12 d-flex justify-content-center">
                      <Link to={'/product-detail/'+product.id}>
                        <img
                          src={
                                (product.printful_product_id === 19 || product.printful_product_id === 300)
                                    ? (product.placement === "Center" && product.front_mockup !== null
                                        ? product.front_mockup
                                        : (product.placement === "Right" && product.right_mockup !== null
                                            ? product.right_mockup
                                            : (product.placement === "Left" && product.left_mockup !== null
                                                ? product.left_mockup
                                                : (product.image
                                                    ? process.env.REACT_APP_apiBaseURL + "/" + product.image
                                                    : "")
                                            )
                                        )
                                    )
                                    : (product.image
                                        ? process.env.REACT_APP_apiBaseURL +"/" + product.image
                                          : "")
                                }
                          style={{
                            width: "100%",
                            height: "300px",
                            objectFit: "contain",
                          }}
                        />
                        </Link>
                      </div>
                      <div
                        className="col-md-12 d-flex justify-content-between align-items-center p-3"
                        style={{
                          position: "absolute",
                          bottom: "0px",
                          width: "100% ",
                        }}
                      >
                        {/* <Link
                          className="custom-anchor"
                          to={"/store/" + product.id + "/customize"}
                        >
                          <i className="fa fa-pencil" aria-hidden="true"></i>
                          Customize
                        </Link> */}
                        {/* <a href="#" className="custom-anchor1">
                          <i className="fa fa-cart-plus" aria-hidden="true"></i>
                          Add to cart
                        </a> */}
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
        <div className="row justify-content-center my-4">
          <button className="get-started">
            <Link to="/shop/user" style={{ textDecoration: "none" }}>
              {" "}
              Shop Now
            </Link>
          </button>
        </div>
      </div>
    </section>
  );
};

export default TopProduct;
