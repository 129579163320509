import React from 'react'
import icon from "../assets/images/icons/ArrowCircleLeft.svg";
import { Link } from "react-router-dom";
import { RxDotsVertical } from 'react-icons/rx'
import { RxCross2 } from 'react-icons/rx'

const Download = () => {
    return (
        <>
            <div className='container my-5 download--section'>
                <div className="row d-flex justify-content-center">
                    <div className="col-md-8 px-0">


                        <div className='d-flex justify-content-between align-items-center'>
                            <div className='d-flex align-items-center ' style={{columnGap: '10px'}}>
                                <Link to="/">
                                    <img className="Arrow-icon" src={icon} alt="Arrow" />{" "}
                                </Link>
                                <h1 className='m-0' style={{fontSize: '34px'}}>YOUR DOWNLOADS</h1>

                            </div>

                            {/* Search Section */}

                            <div style={{ position: "relative" }}>
                                <input
                                    type="text"
                                    className="px-4"
                                    placeholder="Search Downloads"
                                    style={{
                                        backgroundColor: "#F3F3F3",
                                        border: "1px solid #F3F3F3",
                                        height: "50px",

                                        width: '303px',
                                        borderRadius: '150px',
                                    }} />
                                <img src="/assets/images/search.png"
                                    style={{
                                        position: "absolute",
                                        bottom: "15px",
                                        right: "20px",

                                    }}
                                    alt="" />
                            </div>
                            {/* Dot Section */}
                            <div className="btn-group three--dots--download ">
                                <button
                                    className="btn btn-secondary three--dots dropdown-toggle"
                                    type="button"
                                    id="dropdownMenuClickableOutside"
                                    data-bs-toggle="dropdown"
                                    data-bs-auto-close="inside"
                                    aria-expanded="false"
                                >
                                    <RxDotsVertical size={23} />
                                </button>
                                <ul
                                    className="dropdown-menu"
                                    aria-labelledby="dropdownMenuClickableOutside"
                                >
                                    <li>
                                        <a className="dropdown-item" style={{ cursor: "pointer" }}>
                                            Clean All
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" style={{ cursor: "pointer" }}>
                                            Go to Customizer
                                        </a>
                                    </li>

                                </ul>
                            </div>
                        </div>




                        {/* MAin Body */}
                    </div>
                </div>

                <div className="row d-flex justify-content-center">
                {/* First */}
                    <div className="col-md-8 ">
                        <div>
                            <p className='mb-1 mt-4' style={{fontSize: '18px'}}>
                                Recently Downloaded
                            </p>
                        </div>
                        <div className="card-body p-0">
                            <div className="container px-0">
                                <div className="row">
                                    <div className="col-md-12 px-0">
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item ">
                                                <a href="#">
                                                    <div className="row d-flex pb-2">
                                                        <div className="col-md-10 d-flex align-items-center">
                                                            <img className="mr-3"
                                                                src="/assets/images/notification4.png" />
                                                              <div>
                                                                <p style={{ marginBottom: "0px" }}>Martin Cooper Likes A Meme You shared</p>
                                                                <a href='#' style={{fontSize: '14px'}}>Show in profile</a>
                                                                </div>
                                                        </div>
                                                        <div className="col-md-2 d-flex justify-content-end ">
                                                            <div className="d-flex flex-column pt-3"
                                                               >
                                                                <RxCross2 size={20}/>
                                                               
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    {/* Second */}
                    <div className="col-md-8 ">
                        <div>
                            <p className='mb-1 mt-3' style={{fontSize: '18px'}}>
                            Yesterday
                            </p>
                        </div>
                        <div className="card-body p-0">
                            <div className="container px-0">
                                <div className="row">
                                    <div className="col-md-12 px-0">
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item ">
                                                <a href="#">
                                                    <div className="row d-flex pb-2">
                                                        <div className="col-md-10 d-flex align-items-center">
                                                            <img className="mr-3"
                                                                src="/assets/images/notification4.png" />
                                                              <div>
                                                                <p style={{ marginBottom: "0px" }}>Martin Cooper Likes A Meme You shared</p>
                                                                <a href='#' style={{fontSize: '14px'}}>Show in profile</a>
                                                                </div>
                                                        </div>
                                                        <div className="col-md-2 d-flex justify-content-end ">
                                                            <div className="d-flex flex-column pt-3"
                                                               >
                                                                <RxCross2 size={20}/>
                                                               
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                    {/* Third */}

                    <div className="col-md-8 ">
                        <div>
                            <p className='mb-1 mt-3' style={{fontSize: '18px'}}>
                            13/01/2022
                            </p>
                        </div>
                        <div className="card-body p-0">
                            <div className="container px-0">
                                <div className="row">
                                    <div className="col-md-12 px-0">
                                        <ul className="list-group list-group-flush " style={{rowGap: '10px'}}>
                                            <li className="list-group-item ">
                                                <a href="#">
                                                    <div className="row d-flex pb-2">
                                                        <div className="col-md-10 d-flex align-items-center">
                                                            <img className="mr-3"
                                                                src="/assets/images/notification4.png" />
                                                                <div>
                                                                <p style={{ marginBottom: "0px" }}>Martin Cooper Likes A Meme You shared</p>
                                                                <a href='#' style={{fontSize: '14px'}}>Show in profile</a>
                                                                </div>
                                                            
                                                        </div>
                                                        <div className="col-md-2 d-flex justify-content-end ">
                                                            <div className="d-flex flex-column pt-3"
                                                               >
                                                                <RxCross2 size={20}/>
                                                               
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li className="list-group-item ">
                                                <a href="#">
                                                    <div className="row d-flex pb-2">
                                                        <div className="col-md-10 d-flex align-items-center">
                                                            <img className="mr-3"
                                                                src="/assets/images/notification4.png" />
                                                                <div>
                                                                <p style={{ marginBottom: "0px" }}>Martin Cooper Likes A Meme You shared</p>
                                                                <a href='#' style={{fontSize: '14px'}}>Show in profile</a>
                                                                </div>
                                                            
                                                        </div>
                                                        <div className="col-md-2 d-flex justify-content-end ">
                                                            <div className="d-flex flex-column pt-3"
                                                               >
                                                                <RxCross2 size={20}/>
                                                               
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                      

                    </div>
                </div>
            </div>
        </>
    )
}

export default Download