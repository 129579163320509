import EmojiPicker from "emoji-picker-react";

const EmojiModal = (props) => {
  const handleEmojiClick = (event, emojiObject) => {
 
    
    props.setText(props.text + event.emoji);
  };

  return (
    <>
      <div className="emojii--modal">
        <EmojiPicker lazyLoadEmojis={true} onEmojiClick={handleEmojiClick} />
      </div>
    </>
  );
};

export default EmojiModal;
