import { useEffect, useState } from "react";
import Invoice from "../components/wallet/Invoice";
import { getInvoices } from "../services/KycService";
import { Tab, Table, Tabs } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router";
import Payment from "./Payment";


const Invoices = () => {

    const [invoices, setInvoices] = useState([]);
    const [activeTab, setActiveTab] = useState("invoice");

    const location = useLocation();
    const navigate = useNavigate();

    const getOrders = async () => {
        const response = await getInvoices();
        if (response.status === 200) {
            setInvoices(response.data.data.data);
        }
    }

    useEffect(() => {
        getOrders();
    }, []);

    useEffect(() => {
        if (location.hash) {
            const tabs = ["invoice", "method"];
            if (tabs.includes(location.hash.replace("#", ""))) {
                setActiveTab(location.hash.replace("#", ""));
            }
        }
    }, [location.hash]);

    const addHashToRoute = (type) => {
        setActiveTab(type);
        navigate(`/payments#${type}`)
    }
    return (
        <section
            className="collection-section py-5"
            style={{ backgroundColor: "#F9F9F9" }}
        >
            <div className="container d-flex flex-column justify-content-center">
                <div className="row d-flex justify-content-center">
                    <div className="col-md-12 d-flex flex-column justify-content-center">
                        <h1
                            className="heading"
                            style={{ fontFamily: "RockoFLF Regular", fontSize: "28px" }}
                        >
                            Payments
                        </h1>
                    </div>
                </div>
                <Tabs
                    defaultActiveKey="invoice"
                    transition={true}
                    id="noanim-tab-example"
                    className="mb-3 wallet-tabs"
                    activeKey={activeTab}
                    onSelect={(k) => addHashToRoute(k)}
                >
                    <Tab eventKey="invoice" title="Invoices">
                        {
                            invoices.length > 0 ?
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Invoice</th>
                                            <th>Amount Transfer</th>
                                            <th>Payment Status</th>
                                            <th>Receipt</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            invoices.map((invoice, key) => {
                                                return <Invoice invoice={invoice} key={key} index={key} />
                                            })
                                        }
                                    </tbody>
                                </Table>
                                :
                                <div className='d-flex justify-content-center'>
                                    No meme purchased yet
                                </div>
                        }
                    </Tab>
                    <Tab eventKey="payment-method" title="Payment Method">
                        <Payment />
                    </Tab>
                </Tabs>
            </div>
        </section>
    );
}

export default Invoices