import React, { useEffect, useState } from "react";
import Spinner from "react-bootstrap/esm/Spinner";
import { toast } from "react-toastify";
import "../components/notification/notification.css";
import {deleteNotification, getNotifications, updateNotification} from "../services/NotificationService";
import { convertTime } from "../components/TimeFormat";
import { RxDotsHorizontal } from "react-icons/rx";
import { Link } from "react-router-dom";
import {
  CSSFunction,
  PlaceHolderNotification,
} from "../components/PlaceHolder";
import { Dropdown } from "react-bootstrap";
import {useNavigate} from "react-router";
import ReportModal from "../components/modal/ReportModal";
import {constants} from "../components/Constants/constants";
import {acceptRequest} from "../services/FollowersService";

const Notification = ({setAlertsCount}) => {
    const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [paginateLinks, setPaginateLinks] = useState();
  const [pageIndex, setPageIndex] = useState(1)
  const [reportShow, setReportShow] = useState(false);
  const [notificationId, setNotificationId] = useState(null);

  useEffect(() => {
    getPushNotifications(1);
  }, []);



  const getPushNotifications = async (pageIndex, paginate) => {
    setLoader(true);
    const response = await getNotifications(pageIndex);
    if (response.status === 200) {
     
      if (paginate === "paginate") {
        setData([...data, ...response?.data?.data]);
      } else {
        setData(response.data.data);
      }
      setPaginateLinks(response.data.links.next);
      if (response.data.links?.next) {
        setPageIndex(pageIndex + 1);
      }
      setLoader(false);
    } else {
      toast.error(response.data.message);
      setLoader(false);
    }
  };
    const notificationOpened = (notification) => {
        if (notification.read === 0) {
            updateNotification(notification.id, { read: 1});
            setAlertsCount((prev) => prev - 1 <= 0 ? 0 : prev -1);
        }
        switch (notification.type) {
            case 'user':
                navigate(`/profile/${notification.sender.id}`);
                break;
            case 'tag':
                navigate(`/posts/${notification.child_id}`);
                break;
            // case 'invite':
            //     navigate(`/invite/${notification.child_id}`);
            //     break;
            case 'post':
                navigate(`/posts/${notification.child_id}`);
                break;
            case 'product':
                navigate(`/product-detail/${notification.child_id}`);
                break;
            case 'reaction':
                navigate(`/posts/${notification.child_id}`)
                break;
            case 'follower':
                navigate(`/followers`);
                break;
            case 'payment':
                navigate(`/payment/${notification.child_id}`);
                break;
            case 'comment':
                navigate(`/posts/${notification.child_id}`);
                break;
            case 'reply':
                navigate(`/posts/${notification.child_id}`);
                break;
            default:
                break;
        }
    }

    const removeNotification = async (notificationId) => {
        const response = await deleteNotification(notificationId);
        if (response.status === 200) {
            toast.success(response.data.message);
            setData(data.filter((notification) => notification.id !== notificationId));
        }
    }

    const reportNotification = (notificationId) => {
        setNotificationId(notificationId);
        setReportShow(true);
    }

    const acceptRequestApi = async (id, type, selectedNotificationId) => {
        const response = await acceptRequest(id, type);
        if (response.status === 200) {
            toast.success(response.data.message);

            let status = type === 'accept' ? 'accepted' : 'rejected';

            let notification = data.find((notification) => notification.id === selectedNotificationId);

            if (notification.read === 0) {
                setAlertsCount((prev) => prev - 1);
            }

            setData(data.map((notification) => {
                if (notification.id === selectedNotificationId) {
                    notification.read = 1;
                    notification.status = status;
                }
                return notification;
            }));

            await updateNotification(selectedNotificationId, { read: 1, status});
        } else {
            toast.error(response.data.message);
        }
    };

  return (
    <>
      <CSSFunction loader={loader} />
      <main className={'py-4'} style={{ backgroundColor: "rgba(249, 249, 249, 1)" }}>
        <div className="container notification-container">
          <div className="row d-flex justify-content-center">
            <div className="col-md-8 ">
              <div className="card">
                <div className="container p-0">
                  <div className="card-header d-flex justify-content-between align-items-center pt-4">
                    <h2>Notifications</h2>
                    <Link to="/setting">
                      <img src="/assets/images/setting.png" />
                    </Link>
                  </div>
                </div>
                <div className="card-body p-0">
                  <div className="container p-2">
                    <div className="row ">
                      <div className="col-md-12">
                        <ul className="list-group list-group-flush">
                          {loader ? (
                            <PlaceHolderNotification />
                          ) : data?.length === 0 ? (
                            <div className="text-center mt-4">
                              <h4
                                style={{ fontWeight: "700", fontSize: "12px" }}
                              >
                                No Notification found
                              </h4>
                            </div>
                          ) : (
                            data?.map((notification,key) => {
                              const dateFormat = convertTime(notification.sent_at);
                              return (
                                <li key={key} onClick={() => {!(notification?.body.includes('Reported Content') || notification?.body.includes('we have removed it.') || notification?.body.includes('Terms of Usage')) && notificationOpened(notification)}}
                                    className={`list-group-item notify ${notification.read === 0 ? 'active': ''}`}>
                                    <div className="row">
                                      <div className="col-md-10 col-10 p-0 d-flex align-items-center">
                                        <img
                                          className="mr-3"
                                          src={
                                            notification.sender?.photo
                                              ? notification.sender?.photo.includes("http") ? notification.sender?.photo : process.env
                                                .REACT_APP_apiBaseURL +
                                              "/" +
                                              notification.sender?.photo
                                              : "/assets/images/notification4.png"
                                          }
                                          style={{
                                            objectFit: "cover",
                                            width: "100px",
                                            height: "100px",
                                            borderRadius: "15px",
                                          }}
                                          alt=""
                                        />
                                          <div>
                                                <p style={{ marginBottom: "0px" }}>
                                                  {/* {notification.sender?.user_name + " " + notification?.body} */}
                                                  {!(notification?.body.includes('Reported Content') || notification?.body.includes('we have removed it.') || notification?.body.includes('Terms of Usage')) ? (notification.sender?.user_name + " " + notification?.body) : (notification?.body)}
                                                </p>
                                              {notification.type === 'follower' && notification.child_id && notification.status === "pending" &&
                                                  <div className="d-flex mt-2" onClick={(e)=> e.stopPropagation()}>
                                                      <button 
                                                          className="notifying-btns px-2"
                                                          style={{
                                                              backgroundColor:
                                                                  "rgba(36, 36, 36, 1)",
                                                              color: "white",
                                                              borderRadius: "50px",
                                                              marginRight: "5%",
                                                              height: "42px",
                                                              width: "120px",
                                                          }}
                                                          onClick={() => {
                                                              acceptRequestApi(
                                                                  notification.sender.id,
                                                                  constants.accept,
                                                                  notification.id
                                                              )
                                                          }}
                                                      >
                                                          Confirm
                                                      </button>
                                                      <button
                                                          className=" notifying-btns px-2"
                                                          style={{
                                                              borderRadius: "50px",
                                                              marginRight: "5%",
                                                              height: "42px",
                                                              width: "115px",
                                                          }}
                                                          onClick={() => {
                                                              acceptRequestApi(
                                                                  notification.sender.id,
                                                                  constants.decline,
                                                                  notification.id
                                                              )
                                                          }}
                                                      >
                                                          Delete
                                                      </button>
                                                  </div>
                                              }
                                          </div>
                                      </div>
                                      <div className="col-md-2 col-2 d-flex justify-content-end align-items-center">
                                        <div className="d-flex flex-column align-items-center">
                                          <small>{dateFormat}</small>
                                            <Dropdown onClick={(e) => {
                                                e.stopPropagation();
                                            }}>
                                              <Dropdown.Toggle
                                                className="btn btn-secondary three--dots"
                                                id="dropdown-basic"
                                              >
                                                <RxDotsHorizontal size={20} />
                                              </Dropdown.Toggle>
                                              <Dropdown.Menu>
                                                <Dropdown.Item className='mb-1'>
                                                  Turn Off Notification from this status
                                                </Dropdown.Item>
                                                <Dropdown.Item className='mb-1' onClick={()=> removeNotification(notification.id)}>
                                                  Remove this Notification
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={() => reportNotification(notification.notifiable_id)}>
                                                  Report An Issue
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                      </div>
                                    </div>
                                </li>
                              );
                            })
                          )}

                          {paginateLinks !== null ? (
                            <div className="col-md-12 d-flex justify-content-center pt-4">
                              <button
                                className="get-started"
                                onClick={() =>
                                  getPushNotifications(
                                    pageIndex,
                                    "paginate"
                                  )
                                }
                              >
                                View more
                              </button>
                            </div>
                          ) : (
                            ""
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
        <ReportModal
            notificationId={notificationId}
            notificationType={data?.find((notification) => notification.notifiable_id === notificationId)?.type}
            show={reportShow}
            onHide={() => setReportShow(false)}
        />
    </>
  );
};

export default Notification;
