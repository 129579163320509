import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { constants } from "../Constants/constants";
import React, { useEffect, useState } from "react";
import { postReact } from "../../services/NewsFeedsService";
import { toast } from "react-toastify";
import { AiFillDislike, AiOutlineDislike } from "react-icons/ai";
import ShareModal from "../modal/ShareModal";
import { increment } from "firebase/firestore";

const TopCreation = ({ data }) => {
    const navigate = useNavigate();

    const [response, setResponse] = useState(data);
    const [share, setShare] = useState(false);
    const [selectedCreationId, setSelectedCreationId] = useState(null);

    useEffect(() => {
        setResponse(data);
    }, [data]);


    const goToProfile = (userId) => {
        navigate(`/profile/${userId}`)
    }

    const react = async (id, type) => {
        setResponse((prevState) => {
            return {
                ...prevState,
                top_creations: prevState.top_creations.map((creation) => {
                    if (creation.id === id) {
                        if (type === constants.love) {
                            if (creation.reaction === 0) {
                                return {
                                    ...creation,
                                    have_i_reacted: 1,
                                    total_reactions: creation.total_reactions + 1,
                                    reaction: constants.love,
                                };
                            } else {
                                if (creation.reaction === 2) {
                                    return {
                                        ...creation,
                                        have_i_reacted: 1,
                                        total_reactions: creation.total_reactions + 1,
                                        total_dislikes: creation.total_dislikes - 1,
                                        reaction: constants.love,
                                    };
                                }
                                return {
                                    ...creation,
                                    have_i_reacted: 0,
                                    total_reactions: creation.total_reactions - 1,
                                    reaction: 0,
                                };
                            }
                        } else {
                            if (creation.reaction === 0 || creation.reaction === 1) {
                                if (creation.reaction === 1) {
                                    return {
                                        ...creation,
                                        have_i_reacted: 1,
                                        total_dislikes: creation.total_dislikes + 1,
                                        total_reactions: creation.total_reactions - 1,
                                        reaction: constants.dislike,
                                    };
                                }
                                return {
                                    ...creation,
                                    have_i_reacted: 1,
                                    total_dislikes: creation.total_dislikes + 1,
                                    reaction: constants.dislike,
                                };
                            } else {
                                return {
                                    ...creation,
                                    have_i_reacted: 0,
                                    total_dislikes: creation.total_dislikes - 1,
                                    reaction: 0,
                                };
                            }
                        }
                    } else {
                        return creation;
                    }
                })
            }
        });
        let fianlData = {
            reaction: type, id: id, type: constants.post,
        };

        const audio = new Audio("/assets/sounds/facebook_likes.mp3");
        audio.play();
        const response = await postReact(fianlData);

        if (response.status === 200) {
        } else {
            toast.error(response.data.message);
        }
    };
    return (
        <>
            <div>
                <section className="shop-section bg-light" id="shop">
                    <div className="container d-flex flex-column justify-content-center">
                        <div className="row d-flex justify-content-center">
                            <div className="p-5 col-md-8 d-flex flex-column justify-content-center text-center">
                                <h2 className="heading" style={{ fontSize: "34px" }}>
                                    TOP CREATIONS
                                </h2>
                                <p style={{ fontSize: "24px" }}>Popular creations designed by Stickfigures fans!</p>
                                {/* <p style={{ fontSize: "16px", marginBottom: "0px" }}>
                                    Enjoy the top memes of the day designed by the Stick Figures
                                    fans!
                                </p> */}
                            </div>
                        </div>

                        <div className="row gy-4 justify-content-center">
                            {response?.top_creations?.length === 0 ? (
                                <>
                                    <div className="d-flex justify-content-center">
                                        <div className="d-flex justify-content-center align-items-center flex-column ">
                                            <p>No Data Found</p>
                                            <Link
                                                to="/top-products"
                                                className="get-started text-decoration-none d-flex justify-content-center align-items-center"
                                            >
                                                Create New
                                            </Link>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                response?.top_creations?.map((i,index) => {
                                    return (
                                        <div className="col-sm-12 col-md-5 col-lg-4 d-flex justify-content-center" key={index}>
                                            <div
                                                className="card  h-100"
                                                style={{
                                                    backgroundColor: `#E9E9E9`,
                                                    width: "90%",
                                                }}
                                            >
                                                <div className="container-fluid  ">
                                                    <div className="row">
                                                        <div
                                                            className="col-md-5 col-5 d-flex justify-content-around gap-2 my-3">
                                                            <img
                                                                src={
                                                                    i.user.photo
                                                                        ? i.user.photo.includes("http") ? i.user.photo :
                                                                            process.env.REACT_APP_apiBaseURL +
                                                                            "/" +
                                                                            i.user.photo
                                                                        : "/assets/images/Vector.png"
                                                                }
                                                                alt=""
                                                                style={{
                                                                    width: "41px",
                                                                    height: "41px",
                                                                    borderRadius: "50%",
                                                                    objectFit: "cover",
                                                                    cursor: 'pointer'
                                                                }}
                                                                onClick={() => goToProfile(i.user.user_name)}
                                                            />
                                                            <div
                                                                className="d-flex justify-content-center align-items-center p-0">
                                                                <h6
                                                                    className="my-0"
                                                                    style={{
                                                                        fontWeight: "700",
                                                                        width: "80px",
                                                                        display: "inline-block",
                                                                        overflow: "hidden",
                                                                        textOverflow: "ellipsis",
                                                                        whiteSpace: " nowrap",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onClick={() => goToProfile(i.user.user_name)}
                                                                >
                                                                    {i.user.name}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="container-fluid p-0">
                                                    <div className="row">
                                                        <div className="col-md-12" style={{padding:"0"}}>
                                                            <Link to={'/store/' + i?.product_id + '/customize'}>
                                                                <img
                                                                    style={{
                                                                        width: " 100%",
                                                                        height: "300px",
                                                                        objectFit: "cover",
                                                                    }}
                                                                    src={
                                                                        i?.attachments[0]?.path
                                                                            ? process.env.REACT_APP_apiBaseURL +
                                                                            "/" +
                                                                            i.attachments[0].path
                                                                            : "/assets/images/Mask group.png"
                                                                    }
                                                                    alt="card"
                                                                />
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="card-footer">
                                                    <div
                                                        className="d-flex justify-content-start"
                                                        style={{ marginTop: "10px", columnGap: "30px" }}
                                                    >
                                                        <div className="wrapper text-center align-self-center">
                                                            {i?.have_i_reacted === 1 && constants.love === i.reaction ? (
                                                                <i
                                                                    className="fa fa-heart heart-icon animated"
                                                                    aria-hidden="true"
                                                                    style={{ color: "red", cursor: "pointer" }}
                                                                    onClick={() => react(i?.id, constants.love)}
                                                                ></i>) : (<i
                                                                    className="fa fa-heart-o heart-icon"
                                                                    aria-hidden="true"
                                                                    style={{ color: "red", cursor: "pointer" }}
                                                                    onClick={() => react(i?.id, constants.love)}
                                                                ></i>)}
                                                            <p style={{ fontSize: "14px" }}>
                                                                {i.total_reactions}
                                                            </p>
                                                        </div>
                                                        <div className="wrapper text-center align-self-center">
                                                            {i?.have_i_reacted === 1 && constants.dislike === i.reaction ? (
                                                                <AiFillDislike
                                                                    style={{
                                                                        color: "black",
                                                                        width: "26px",
                                                                        height: "26px",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onClick={() => react(i?.id, constants.dislike)}
                                                                />) : (<AiOutlineDislike
                                                                    style={{
                                                                        color: "black",
                                                                        width: "26px",
                                                                        height: "26px",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onClick={() => react(i?.id, constants.dislike)}
                                                                />)}
                                                            <p style={{ fontSize: "14px" }}>
                                                                {i.total_dislikes}
                                                            </p>
                                                        </div>
                                                        <div className="wrapper text-center align-self-center">
                                                            <img
                                                                src="/assets/images/icons/share.svg"
                                                                alt=""
                                                                style={{
                                                                    height: "20px",
                                                                }}
                                                                onClick={() => {
                                                                    setSelectedCreationId(i?.id);
                                                                    setShare(true);
                                                                }}
                                                            />
                                                            <p style={{ fontSize: "14px" }}>
                                                                {i.total_shares}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            )}
                        </div>
                        {response?.top_creations?.length === 0 ? (
                            ""
                        ) : (
                            <div className="row justify-content-center  mt-4">
                                <Link
                                    to="/store"
                                    className="get-started text-decoration-none d-flex justify-content-center align-items-center"
                                >
                                    View All
                                </Link>
                            </div>
                        )}
                    </div>
                </section>
            </div>

            <ShareModal
                show={share}
                item={response?.top_creations?.find((i) => i.id === selectedCreationId)}
                onHide={() => setShare(false)}
                setShare={setShare}
                setPostsData={() => {
                    setResponse((prevState) => {
                        return {
                            ...prevState,
                            top_creations: prevState.top_creations.map((i) => {
                                if (i.id === selectedCreationId) {
                                    i.total_shares = i.total_shares + 1;
                                }
                                return i;
                            }),
                        };
                    })
                }}
            />
        </>
    );
};

export default TopCreation;
