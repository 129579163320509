import "../components/wallet/invoice.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import PaymentMethod from "../components/wallet/PaymentMethod";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { check_verification_status } from "../services/KycService";
import CashAccount from "../components/wallet/CashAccount";
import VerificationPending from "./VerificationPending";
import { GetBankAccounts, GetWithdrawals } from "../services/AccountService";
import { toast } from "react-toastify";
import Withdrawal from "../components/wallet/Withdrawal";
import { Table } from "react-bootstrap";

const WalletListing = (props) => {
    const location = useLocation();
    const navigate = useNavigate();

    const [activeTab, setActiveTab] = useState("transfer");
    const [statusLoading, setStatusLoading] = useState(true);
    const [verificationPending, setVerificationPending] = useState(false);
    const [bankAccounts, setBankAccounts] = useState([]);
    const [withdrawals, setWithdrawals] = useState([]);
    const [isSeller, setIsSeller] = useState(true);

    useEffect(() => {
        if (location.hash) {
            const tabs = ["transfer", "cash", "withdrawal"];
            if (tabs.includes(location.hash.replace("#", ""))) {
                setActiveTab(location.hash.replace("#", ""));
            }
        }
    }, [location.hash]);


    useEffect(() => {
        (async () => {
            var user = JSON.parse(localStorage.getItem("user"));
            if (!user.verified) {
                const response = await check_verification_status();
                if (response?.status === 200) {
                    if (!response.data.verified) {
                        if (response.data.status === "pending") {
                            setVerificationPending(true);
                        } else {
                            navigate("/verify");
                        }
                    }
                    if (response.data.hasStripe && response.data.verified) {
                        user.verified = response.data.verified;
                        setIsSeller(true);
                        localStorage.setItem("user", JSON.stringify(user));
                    }
                }
            }
            setStatusLoading(false);
        })()
    }, []);

    useEffect(() => {
        (async () => {
            const response = await GetBankAccounts();
            if (response?.status === 200) {
                setBankAccounts(response.data.data)
            } else {
                toast.error(response.data.message)
            }
        })()
    }, []);

    useEffect(() => {
        if (isSeller) {
            (async () => {
                const response = await GetWithdrawals();
                if (response?.status === 200) {
                    setWithdrawals(response.data.data)
                } else {
                    toast.error(response.data.message)
                }
            })()
        }
    }, [isSeller]);

    const addHashToRoute = (type) => {
        setActiveTab(type);
        navigate(`/wallet#${type}`)
    }

    return (statusLoading ? <div style={{ minHeight: '100vh' }}></div> : verificationPending ? <VerificationPending /> : <section
        className="collection-section invoice py-5"
        style={{ backgroundColor: "#F9F9F9" }}
    >
        <div className="container d-flex flex-column justify-content-center">
            <div className="row d-flex justify-content-center">
                <div className="col-md-12 d-flex flex-column justify-content-center">
                    <h1
                        className="heading"
                        style={{ fontFamily: "RockoFLF Regular", fontSize: "28px" }}
                    >
                        Wallet
                    </h1>
                </div>
            </div>
            <Tabs
                defaultActiveKey="invoice"
                transition={true}
                id="noanim-tab-example"
                className="mb-3 wallet-tabs"
                activeKey={activeTab}
                onSelect={(k) => addHashToRoute(k)}
            >
                <Tab eventKey="transfer" title="Transfer Method">
                    <PaymentMethod bankAccounts={bankAccounts} />
                </Tab>
                <Tab eventKey="withdrawal" title="Withdrawals">
                    <Withdrawal withdrawals={withdrawals} />
                </Tab>
                <Tab eventKey="cash" title="Cash Account">
                    <CashAccount authProfile={props.authProfile} bankAccounts={bankAccounts} />
                </Tab>
            </Tabs>
        </div>
    </section>);

};

export default WalletListing;
