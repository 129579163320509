import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import React, { useEffect, useRef, useState } from "react";
import { getCountries } from "../../services/AuthService";
import { AddBankAccount, EditBankAccount } from "../../services/AccountService";
import { toast } from "react-toastify";
import SuccessMsg from './SuccessMsg';
import ErrorMsg from './ErrorMsg';

function BankModal({ isEdit, bankAccount, insertBankAccount, ...props }) {
    const [accountInfo, setAccountInfo] = useState({
        account_type: '',
        account_name: '',
        routing_no: '',
        account_no: '',
        confirm_account_no: '',
        phone_no: '',
        country_code: ''
    });

    const [successMsg, setSuccessMsg] = useState('');
    const [error, setError] = useState('');
    const [countries, setCountries] = useState([]);
    const [isValid, setIsValid] = useState(true);
    const [success, setSuccess] = useState(false);
    const [errorresp, setRespError] = useState(false);

    useEffect(() => {
        if (isEdit) {
            const accountDetails = {
                account_type: bankAccount.account_type,
                account_name: bankAccount.account_name,
                routing_no: bankAccount.routing_no,
                account_no: bankAccount.account_no,
                confirm_account_no: bankAccount.account_no,
                phone_no: bankAccount.phone_no,
                country_code: bankAccount.country_code
            }
            setAccountInfo(accountDetails);
        }
    }, [isEdit]);


    // load countries list
    useEffect(() => {
        const loadCountryList = async () => {
            await getCountries().then((response) => {
                setCountries(response.data.data);
            });
        };
        loadCountryList();
    }, []);

    useEffect(() => {
        if (props.show && !isEdit) {
            setAccountInfo({
                account_type: '',
                account_name: '',
                routing_no: '',
                account_no: '',
                confirm_account_no: '',
                phone_no: '',
                country_code: ''
            })
            setIsValid(true)
        }
    }, [props.show]);

    const validate = () => {
        if (!accountInfo.account_type || !accountInfo.account_name || !accountInfo.routing_no || !accountInfo.account_no || !accountInfo.confirm_account_no || accountInfo.account_no != accountInfo.confirm_account_no || !accountInfo.phone_no || !accountInfo.country_code) {
            return false;
        }
        return true;
    }

    const createAccount = async () => {
        if (validate()) {
            setIsValid(true)
            const response = await AddBankAccount({
                account_type: accountInfo.account_type,
                account_name: accountInfo.account_name,
                routing_no: accountInfo.routing_no,
                account_no: accountInfo.account_no,
                account_confirm: accountInfo.account_no,
                phone_no: accountInfo.phone_no,
                country_code: accountInfo.country_code
            })
            if (response.status === 201) {
                insertBankAccount(response.data.data);
                props.onHide();
                setSuccessMsg("Bank Account Associated");
                // var errorModal = document.getElementById('exampleModal');
                // var modal = new bootstrap.Modal(errorModal);
                setSuccess(true);
            } else if (response.status === 500) {
                setError(response.data.message);
                // var errorModal = document.getElementById('errorModal');
                // errorModal.show();
                // var modal = new bootstrap.Modal(errorModal);
                setRespError(true)
            } else {
                toast.error(response.data.message)
            }
        } else {
            setIsValid(false);
        }
    }

    const editAccount = async () => {
        if (validate()) {
            setIsValid(true)
            const response = await EditBankAccount({
                account_type: accountInfo.account_type,
                account_name: accountInfo.account_name,
                routing_no: accountInfo.routing_no,
                account_no: accountInfo.account_no,
                account_confirm: accountInfo.account_no,
                phone_no: accountInfo.phone_no,
                country_code: accountInfo.country_code
            }, bankAccount.id)
            if (response.status === 200) {
                toast.success('Bank Account Updated')
                insertBankAccount(response.data.data, bankAccount.id);
                props.onHide();
            } else if (response.status === 500) {
                setError(response.data.message);
                setRespError(true)
            } else {
                toast.info(response.data.message)
            }
        } else {
            setIsValid(false);
        }
    }

    return (<>
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            className='bank--modal'
        >
            <Modal.Header className='justify-content-center pb-0 mt-3' closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h2 style={{ fontSize: '32px' }}>{isEdit ? 'Bank - ' + accountInfo.account_no : 'Add Bank Account'}</h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ background: '#FFFFFF', borderRadius: '3px', margin: '6px 16px' }}>
                <h1 className='fs-4'>Bank Account Info </h1>
                <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Account Type</Form.Label>
                        <Form.Select value={accountInfo.account_type}
                            onChange={(e) => setAccountInfo({ ...accountInfo, account_type: e.target.value })}>
                            <option defaultValue disabled value={''}>Select Account Type</option>
                            <option value={'Individual'}>Individual</option>
                            <option value={'Company'}>Company</option>
                        </Form.Select>
                        <Form.Text className="text-danger" style={{ fontSize: '11px' }}>
                            {!isValid && !accountInfo.account_type && 'Account Type is required'}
                        </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Account Holder Name</Form.Label>
                        <Form.Control type="text" value={accountInfo.account_name} onChange={(e) => setAccountInfo({
                            ...accountInfo, account_name: e.target.value
                        })} />
                        <Form.Text className="text-danger" style={{ fontSize: '11px' }}>
                            {!isValid && !accountInfo.account_name && 'Account Holder Name is required'}
                        </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Routing Number</Form.Label>
                        <Form.Control type="text" value={accountInfo.routing_no} onChange={(e) => setAccountInfo({
                            ...accountInfo, routing_no: e.target.value
                        })} placeholder='9 digit #, starting with 0, 1, 2, 3' />
                        <Form.Text className="text-danger" style={{ fontSize: '11px' }}>
                            {!isValid && !accountInfo.routing_no ? 'Routing Number is required' : ''}
                        </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Account Number</Form.Label>
                        <Form.Control type="text" value={accountInfo.account_no} onChange={(e) => setAccountInfo({
                            ...accountInfo, account_no: e.target.value
                        })} />
                        <Form.Text className="text-danger" style={{ fontSize: '11px' }}>
                            {!isValid && !accountInfo.account_no ? 'Account Number is required' : ''}
                        </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Confirm Account Number</Form.Label>
                        <Form.Control type="text" value={accountInfo.confirm_account_no}
                            onChange={(e) => setAccountInfo({
                                ...accountInfo, confirm_account_no: e.target.value
                            })} />
                        <Form.Text className="text-danger" style={{ fontSize: '11px' }}>
                            {!isValid && accountInfo.account_no !== accountInfo.confirm_account_no ? 'Account number does not match' : ''}
                        </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Phone Number</Form.Label>
                        <div className='row'>
                            <div className='col-md-4'>
                                <Form.Select value={accountInfo.country_code} onChange={(e) => setAccountInfo({
                                    ...accountInfo, country_code: e.target.value
                                })} style={{ fontSize: '14px' }}>
                                    <option defaultValue disabled value="">
                                        Country Code
                                    </option>
                                    {countries.map((country, index) => {
                                        return (<option key={index} value={country.phonecode}>
                                            {country.phonecode}
                                        </option>);
                                    })}
                                </Form.Select>
                            </div>
                            <div className='col-md-6'>
                                <Form.Control type="text" value={accountInfo.phone_no} onChange={(e) => setAccountInfo({
                                    ...accountInfo, phone_no: e.target.value
                                })} />
                            </div>
                            <Form.Text className="text-danger" style={{ fontSize: '11px' }}>
                                {!isValid && (!accountInfo.phone_no || !accountInfo.country_code) && 'Phone Number & Country code is required'}
                            </Form.Text>
                        </div>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-start border-0 mb-3'>
                <button className="get-started" type={'button'}
                    onClick={isEdit ? editAccount : createAccount}>{isEdit ? "Update" : "Add Bank Account"}</button>
                <button className="get-started" onClick={props.onHide}>Cancel</button>
            </Modal.Footer>
        </Modal>        

        <SuccessMsg
        show={success} onHide={() => setSuccess(false)}
            message={successMsg}
        />
        <ErrorMsg
        show={errorresp} onHide={() => setRespError(false)}
            message={error}
        />
    </>);
}

export default BankModal;