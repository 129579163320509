import api from "./api";

export const getBlogPosts = async (pageIndex) => {
  pageIndex
    try {
      const response = await api.get(`/api/v1/blogposts?page=${pageIndex}`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  export const getBlogPostDetail = async (slug) => {
    slug
    try {
      const response = await api.get(`/api/v1/blogposts/${slug}`);
      return response;
    } catch (error) {
      return error.response;
    }
  } 