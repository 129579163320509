import { Link, useLocation, useNavigate, NavLink } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { getMessaging, onMessage } from "firebase/messaging";

import {
    AiOutlineHome,
    AiOutlineShop,
    AiOutlinePlusCircle,
    AiFillPlusCircle,
    AiFillHome,
    AiFillShop,
} from "react-icons/ai";
import { BsChevronDown } from "react-icons/bs";
import { GiBlackBook, GiWhiteBook } from "react-icons/gi";
import { RiUserLine, RiUserFill } from "react-icons/ri";
import { logout } from "../../services/AuthService";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { db } from "../../components/Constants/Firebase";
import { getNewToken, onMessageListener } from "../Constants/Firebase";
import { NotificationsToast } from "../Toast";
import { Toast } from "bootstrap";
import { getCart } from "../../services/CartService";
import { getHomeData } from "../../services/HomeService";


const Navbar = (props) => {

    /// states management
    const [authUser, setAuthUser] = useState(false);
    const [authProfile, setAuthProfile] = useState();
    const [messageCount, setMessageCount] = useState(
        localStorage.getItem("notificationCount") || 0
    );
    const [followerCount, setFollowerCount] = useState(
        localStorage.getItem("followerCount") || 0
    );
    const [notificationStatus, setNotificationStatus] = useState(false);
    
    /// path of url
    const { pathname, key } = useLocation();

    const [isOpen, setIsOpen] = useState(false);
    const [carts, setModalCarts] = useState([]);

    const handleToggle = (isOpen) => {
        setIsOpen(isOpen);
    };

    const handleViewMoreClick = () => {
        // Handle "View More" button click logic here
        setIsOpen(false); // Close the dropdown
        navigate("/carts"); // Navigate to carts page
    };

    const navigate = useNavigate();

    // custom event handling and creted in login page
    window.addEventListener("storage", () => {
        if (JSON.parse(localStorage.getItem("user"))) {
            setAuthUser(true);
        } else {
            setAuthUser(false);
        }
    });

    /// login check of localstorage
    useEffect(() => {
        if (JSON.parse(localStorage.getItem("user"))) {
            setAuthUser(true);
        } else {
            setAuthUser(false);
        }

        requestPermission();
    }, []);

    useEffect(() => {
        setAuthProfile(props.authProfile);
    }, [props.authProfile]);

    /// logout handler`
    const handleLogout = async () => {
        const response = await logout();
        if (response.status === 200) {
            toast.success(response.data.message);
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            localStorage.removeItem("FCM_TOKEN");
            localStorage.removeItem("cartCount");
            localStorage.removeItem("productsData");
            localStorage.removeItem("categoryData");
            localStorage.removeItem("printfulAuthorized");
            localStorage.removeItem("printfulToken");
            localStorage.removeItem("productsData_loaded_time");
            localStorage.removeItem("categoryData_loaded_time");
            localStorage.removeItem("printfulAuthorized");
            localStorage.removeItem("CANVAS");
            localStorage.removeItem("epr_suggested");
            localStorage.removeItem("followerCount");
            localStorage.removeItem("CANVAS_UPDATED_AT");
            localStorage.removeItem("deviceId");
            window.location.href = "/login";
        } else {
            // console.log(response.data.message);
        }
    };

    function requestPermission() {


        if ('Notification' in window) {
            Notification.requestPermission().then(function(permission) {
              if (permission === 'granted') {
                // Firebase messaging setup here
                getNewToken();
              }
            });
          }
        // console.log("Requesting permission...");
        // !(/iPhone|iPod/.test(navigator.userAgent)) && !window.MSStream  &&  Notification.requestPermission().then((permission) => {
        //     if (permission === "granted") {
        //         // console.log("Notification permission granted.");
                
        //     }
        // });
    }

    const [notification, setNotification] = useState();
    let messaging =null;
    if ('serviceWorker' in navigator && 'PushManager' in window) {
        navigator.serviceWorker.register('/firebase-messaging-sw.js')
            .then((registration) => {
                // Use the registration object with Firebase messaging
                messaging = getMessaging();
                onMessage(messaging, (payload) => {
                    // Process the received message
                    setNotification({
                        ...payload.data,
                        title: payload.data?.type === "message"
                            ? payload.data.sender_name
                            : payload.data.subject,
                        body: payload.data?.type === "message"
                            ? payload.data?.message
                            : payload.data?.body,
                        icon: "/assets/images/logo.svg",
                    });
                
                    if (payload.data.body === "Has sent you request!") {
                        setFollowerCount((prevCount) => {
                            const newCount = parseInt(prevCount, 10) + 1;
                            localStorage.setItem("followerCount", newCount);
                            return newCount;
                        });
                    }
                    props.setAlertsCount((prevCount) => prevCount + 1);
                    setNotificationStatus(true);
                });
            })
            .catch(err => console.error("Service Worker registration failed: ", err));
    } else {
        console.log("Push notifications are not supported in this browser.");
    }
    // onMessageListener().then((payload) => {
    //     setNotification({
    //         ...payload.data,
    //         title: payload.data?.type === "message"
    //             ? payload.data.sender_name
    //             : payload.data.subject,
    //         body: payload.data?.type === "message"
    //             ? payload.data?.message
    //             : payload.data?.body,
    //         icon: "/assets/images/logo.svg",
    //     });

    //     if (payload.data.body === "Has sent you request!") {
    //         setFollowerCount((prevCount) => {
    //             const newCount = parseInt(prevCount, 10) + 1;
    //             localStorage.setItem("followerCount", newCount);
    //             return newCount;
    //         });
    //     }
    //     props.setAlertsCount((prevCount) => prevCount + 1);
    //     setNotificationStatus(true);
    // }).catch((err) => console.log("failed: ", err));

    useEffect(() => {
        if (authUser) {
            getdata();
        }
    }, [authUser]);

    const getdata = async () => {
        ///Followers API
        const response = await getHomeData();
        if (response.status === 200) {
            props.setAlertsCount(response.data?.unreadNotificatoins);
        }
    };

    const toastRef = useRef(null);

    useEffect(() => {
        const toastTrigger = toastRef.current;
        if (notificationStatus) {
            if (toastTrigger) {
                const audio = new Audio("/assets/sounds/notification.mp3");
                const toast = new Toast(toastTrigger);
                toast.show();
                // audio.play();
                setNotificationStatus(false);
            }
        }
    }, [notification]);

    /// pipeline of Rooms with count
    useEffect(() => {
        if (JSON.parse(localStorage.getItem("user"))?.id) {
            const roomsCol = query(
                collection(db, "ChatRooms"),
                where("UserIds", "array-contains-any", [
                    JSON.parse(localStorage.getItem("user"))?.id,
                ])
            );
            const roomsSnapshot = onSnapshot(roomsCol, (querySnapshotRooms) => {
                const rooms = [];
                querySnapshotRooms.forEach((doc) => {
                    rooms.push({
                        id: doc.data()?.id,
                        lastmessage: doc.data()?.lastmessage,
                        timeStampOfLastMessage: doc.data()?.timeStampOfLastMessage,
                        UserIds: doc.data.UserIds,
                        totalUnreadMessages: doc.data()?.totalUnreadMessages,
                        recieveUser: doc.data()?.recieveUser,
                        senderUser: doc.data()?.senderUser,
                    });
                });
                let totalCount = 0;
                rooms.forEach((room) => {
                    const { senderUser, recieveUser } = room;
                    if (JSON.parse(localStorage.getItem("user")).id !== senderUser.id) {
                        totalCount += senderUser.totalUnreadMessages;
                    } else {
                        totalCount += recieveUser.totalUnreadMessages;
                    }
                });
                setMessageCount(totalCount);
            });
        }
    }, [db]);

    const FollowerReset = () => {
        setFollowerCount(0);
        localStorage.setItem("followerCount", 0);
        navigate("/followers");
    };

    const goToNotifications = () => {
        navigate("/notification");
    };

    useEffect(() => {
        if (JSON.parse(localStorage.getItem("user"))) {
            getCartData();
        }
    }, [props.cartCount]);

    const getCartData = async () => {
        const responseCart = await getCart();
        if (responseCart.status === 200) {
            // setPayload(response.data.data);
            setModalCarts(responseCart.data.data);
            props.setCartCount((prevCount) => {
                const newCount = parseInt(responseCart?.data?.data?.length, 10);
                localStorage.setItem("cartCount", newCount);
                return newCount;
            });
            // console.log(responseCart);
        } else {
            toast.error(responseCart.data.message);
        }
    };

    const handleCarts = (itemID) => {
        navigate("/product-detail/" + itemID);
    };

    return (
        <>
            {pathname === "/customizer" ||
                /\/store\/.*\/customize/g.test(pathname) || pathname==="/mugcustomizer" ? (
                <></>
            ) : (
                <nav className="navbar navbar-expand-lg navbar-light bg-white sticky-top py-4 navbarr--badge">
                    <NotificationsToast toastRef={toastRef} notification={notification} />
                    <div className="container ">
                        <div className="row w-100">
                            <div
                                className="col-lg-12 d-flex justify-content-between "
                                style={{ columnGap: "10px" }}
                            >
                                <div className="d-flex justify-content-between col-lg-2 col-md-3 col-sm-5 col-5">
                                    <Link className="navbar-brand" to="/">
                                        <img
                                            src="/assets/images/SF Logo.png"
                                            style={{
                                                width: "160px",
                                                height: "25px",
                                                objectFit: "contain",
                                            }}
                                            alt=""
                                        />
                                    </Link>
                                </div>

                                {authUser && JSON.parse(localStorage.getItem("user")) && (
                                    <div
                                        className="col-lg-2 col-md-2 col-sm-5 col-5 notification-wrapper d-flex justify-content-between align-items-center"
                                        style={{ width: "100px",gap:"5px" }}
                                    >
                                        <div
                                            onClick={() => FollowerReset()}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div
                                                className="cart-wrapper"
                                                style={{ position: "relative" }}
                                            >
                                                {pathname === "/followers" ? (
                                                    <svg
                                                        width="27"
                                                        height="27"
                                                        viewBox="0 0 27 27"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        style={{ fill: "#242424", stroke: "#F3F3F3" }}
                                                    >
                                                        <circle
                                                            cx="13.5"
                                                            cy="13.5"
                                                            r="12.75"
                                                            strokeWidth="1.5"
                                                            stroke="#242424"
                                                        />
                                                        <path
                                                            d="M10.2188 16.4919C12.5746 16.4919 14.4844 14.5821 14.4844 12.2262C14.4844 9.87039 12.5746 7.9606 10.2188 7.9606C7.86291 7.9606 5.95312 9.87039 5.95312 12.2262C5.95312 14.5821 7.86291 16.4919 10.2188 16.4919Z"
                                                            strokeWidth="1.5"
                                                            strokeMiterlimit="10"
                                                        />
                                                        <path
                                                            d="M15.7476 8.11646C16.1246 8.01419 16.5135 7.96179 16.9042 7.9606C18.0355 7.9606 19.1205 8.41001 19.9205 9.20997C20.7204 10.0099 21.1698 11.0949 21.1698 12.2262C21.1698 13.3575 20.7204 14.4425 19.9205 15.2425C19.1205 16.0424 18.0355 16.4919 16.9042 16.4919"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M4.3125 19.5597C4.97855 18.6119 5.86292 17.8383 6.89091 17.3043C7.9189 16.7703 9.06032 16.4915 10.2188 16.4915C11.3772 16.4915 12.5186 16.7703 13.5466 17.3043C14.5746 17.8383 15.4589 18.6119 16.125 19.5597"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M16.9043 16.4915C18.0628 16.4908 19.2045 16.7692 20.2326 17.3033C21.2607 17.8373 22.145 18.6112 22.8105 19.5595"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                ) : (
                                                    <svg
                                                        width="27"
                                                        height="27"
                                                        viewBox="0 0 27 27"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <circle
                                                            cx="13.5"
                                                            cy="13.5"
                                                            r="12.75"
                                                            stroke="#242424"
                                                            strokeWidth="1.5"
                                                        />
                                                        <path
                                                            d="M10.2188 16.4919C12.5746 16.4919 14.4844 14.5821 14.4844 12.2262C14.4844 9.87039 12.5746 7.9606 10.2188 7.9606C7.86291 7.9606 5.95312 9.87039 5.95312 12.2262C5.95312 14.5821 7.86291 16.4919 10.2188 16.4919Z"
                                                            stroke="#242424"
                                                            strokeWidth="1.5"
                                                            strokeMiterlimit="10"
                                                        />
                                                        <path
                                                            d="M15.7476 8.11646C16.1246 8.01419 16.5135 7.96179 16.9042 7.9606C18.0355 7.9606 19.1205 8.41001 19.9205 9.20997C20.7204 10.0099 21.1698 11.0949 21.1698 12.2262C21.1698 13.3575 20.7204 14.4425 19.9205 15.2425C19.1205 16.0424 18.0355 16.4919 16.9042 16.4919"
                                                            stroke="#242424"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M4.3125 19.5597C4.97855 18.6119 5.86292 17.8383 6.89091 17.3043C7.9189 16.7703 9.06032 16.4915 10.2188 16.4915C11.3772 16.4915 12.5186 16.7703 13.5466 17.3043C14.5746 17.8383 15.4589 18.6119 16.125 19.5597"
                                                            stroke="#242424"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M16.9043 16.4915C18.0628 16.4908 19.2045 16.7692 20.2326 17.3033C21.2607 17.8373 22.145 18.6112 22.8105 19.5595"
                                                            stroke="#242424"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                )}
                                                {Number(followerCount) !== 0 && (
                                                    <span
                                                        className="badge badge-dark rounded-circle"
                                                        style={{ position: "absolute", left: "14px" }}
                                                    >
                                                        {followerCount}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <Link to="/chat">
                                            <div
                                                className="cart-wrapper"
                                                style={{ position: "relative" }}
                                            >
                                                {pathname === "/chat" ? (
                                                    <svg
                                                        width="27"
                                                        height="26"
                                                        viewBox="0 0 27 26"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        style={{ fill: "#242424", stroke: "#F3F3F3" }}
                                                    >
                                                        <path
                                                            d="M3.27495 18.9918C1.78606 16.4798 1.26526 13.5107 1.81033 10.642C2.3554 7.77322 3.92885 5.20207 6.2353 3.4112C8.54174 1.62034 11.4226 0.732932 14.3369 0.915575C17.2513 1.09822 19.9988 2.33835 22.0636 4.40316C24.1284 6.46797 25.3685 9.21546 25.5512 12.1298C25.7338 15.0442 24.8464 17.925 23.0555 20.2314C21.2647 22.5379 18.6935 24.1113 15.8248 24.6564C12.956 25.2015 9.98693 24.6807 7.47495 23.1918V23.1918L3.32495 24.3668C3.15492 24.4165 2.97464 24.4196 2.80302 24.3757C2.63139 24.3318 2.47474 24.2425 2.34947 24.1173C2.22421 23.992 2.13495 23.8353 2.09105 23.6637C2.04714 23.4921 2.05022 23.3118 2.09995 23.1418L3.27495 18.9918Z"
                                                            stroke="#242424"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M9.59961 10.8667H17.5996"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M9.59961 14.8667H17.5996"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                ) : (
                                                    <svg
                                                        width="27"
                                                        height="26"
                                                        viewBox="0 0 27 26"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M3.27495 18.9918C1.78606 16.4798 1.26526 13.5107 1.81033 10.642C2.3554 7.77322 3.92885 5.20207 6.2353 3.4112C8.54174 1.62034 11.4226 0.732932 14.3369 0.915575C17.2513 1.09822 19.9988 2.33835 22.0636 4.40316C24.1284 6.46797 25.3685 9.21546 25.5512 12.1298C25.7338 15.0442 24.8464 17.925 23.0555 20.2314C21.2647 22.5379 18.6935 24.1113 15.8248 24.6564C12.956 25.2015 9.98693 24.6807 7.47495 23.1918V23.1918L3.32495 24.3668C3.15492 24.4165 2.97464 24.4196 2.80302 24.3757C2.63139 24.3318 2.47474 24.2425 2.34947 24.1173C2.22421 23.992 2.13495 23.8353 2.09105 23.6637C2.04714 23.4921 2.05022 23.3118 2.09995 23.1418L3.27495 18.9918Z"
                                                            stroke="#242424"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M9.59961 10.8667H17.5996"
                                                            stroke="#242424"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M9.59961 14.8667H17.5996"
                                                            stroke="#242424"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                )}

                                                {messageCount !== 0 && (
                                                    <span
                                                        className="badge badge-dark rounded-circle"
                                                        style={{ position: "absolute", left: "15px" }}
                                                    >
                                                        {messageCount > 0 ? messageCount : 0 }
                                                    </span>
                                                )}
                                            </div>
                                        </Link>
                                       <div
                                            onClick={() => goToNotifications()}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <div
                                                className="cart-wrapper"
                                                style={{ position: "relative" }}
                                            >
                                                {pathname === "/notification" ? (
                                                    <svg
                                                        width="25"
                                                        height="27"
                                                        viewBox="0 0 25 27"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        style={{ fill: "#242424", stroke: "#F3F3F3" }}
                                                    >
                                                        <path
                                                            d="M19.75 8.66666C19.75 6.74384 18.9862 4.89977 17.6265 3.54013C16.2669 2.18049 14.4228 1.41666 12.5 1.41666C10.5772 1.41666 8.73312 2.18049 7.37348 3.54013C6.01384 4.89977 5.25 6.74384 5.25 8.66666C5.25 17.125 1.625 19.5417 1.625 19.5417H23.375C23.375 19.5417 19.75 17.125 19.75 8.66666Z"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            stroke="#242424"
                                                        />
                                                        <path
                                                            d="M14.591 24.375C14.3786 24.7412 14.0736 25.0452 13.7068 25.2565C13.3399 25.4678 12.9239 25.5791 12.5006 25.5791C12.0772 25.5791 11.6613 25.4678 11.2944 25.2565C10.9275 25.0452 10.6226 24.7412 10.4102 24.375"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                ) : (
                                                    <svg
                                                        width="25"
                                                        height="27"
                                                        viewBox="0 0 25 27"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M19.75 8.66666C19.75 6.74384 18.9862 4.89977 17.6265 3.54013C16.2669 2.18049 14.4228 1.41666 12.5 1.41666C10.5772 1.41666 8.73312 2.18049 7.37348 3.54013C6.01384 4.89977 5.25 6.74384 5.25 8.66666C5.25 17.125 1.625 19.5417 1.625 19.5417H23.375C23.375 19.5417 19.75 17.125 19.75 8.66666Z"
                                                            stroke="#242424"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                        <path
                                                            d="M14.591 24.375C14.3786 24.7412 14.0736 25.0452 13.7068 25.2565C13.3399 25.4678 12.9239 25.5791 12.5006 25.5791C12.0772 25.5791 11.6613 25.4678 11.2944 25.2565C10.9275 25.0452 10.6226 24.7412 10.4102 24.375"
                                                            stroke="#242424"
                                                            strokeWidth="1.5"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                )}

                                                {Number(props.alertsCount) !== 0 && (
                                                    <span
                                                        className="badge badge-dark rounded-circle"
                                                        style={{ position: "absolute", left: "10px" }}
                                                    >
                                                        {props.alertsCount}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="cart--div" id="cart-on-mobile">
                                                <Dropdown show={isOpen} onToggle={handleToggle}>
                                                    <Dropdown.Toggle
                                                        variant="light"
                                                        id="dropdown-basic"
                                                        className="wrapper d-flex justify-content-around align-items-center p-0"
                                                    >
                                                        <div
                                                            className="cart-wrapper"
                                                            style={{ position: "relative" }}
                                                        >
                                                            <svg width="25" height="27" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" transform="rotate(0)matrix(-1, 0, 0, 1, 0, 0)"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" stroke="#CCCCCC" strokeWidth="0.048"></g><g id="SVGRepo_iconCarrier"> <g clipPath="url(#clip0_15_35)"> <rect width="24" height="24" fill="white"></rect> <path d="M5.33331 6H19.8672C20.4687 6 20.9341 6.52718 20.8595 7.12403L20.1095 13.124C20.0469 13.6245 19.6215 14 19.1172 14H16.5555H9.44442H7.99998" stroke="#000000" strokeLinejoin="round"></path> <path d="M2 4H4.23362C4.68578 4 5.08169 4.30341 5.19924 4.74003L8.30076 16.26C8.41831 16.6966 8.81422 17 9.26638 17H19" stroke="#000000" strokeLinecap="round" strokeLinejoin="round"></path> <circle cx="10" cy="20" r="1" stroke="#000000" strokeLinejoin="round"></circle> <circle cx="17.5" cy="20" r="1" stroke="#000000" strokeLinejoin="round"></circle> </g> <defs> <clipPath id="clip0_15_35"> <rect width="24" height="24" fill="white"></rect> </clipPath> </defs> </g></svg>
                                                            {Number(props.cartCount) !== 0 && (
                                                                <span
                                                                    className="badge badge-dark rounded-circle"
                                                                    style={{
                                                                        position: "absolute",
                                                                        left: "18px",
                                                                    }}
                                                                >
                                                                    {props.cartCount}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <h1 className="fs-6 px-3 d-flex align-items-center">
                                                            Item Added To Your Cart
                                                        </h1>
                                                        <div
                                                            style={{
                                                                maxHeight: "225px",
                                                                overflowY: "auto",
                                                            }}
                                                        >
                                                            {carts.map((cart, index) => {
                                                                if (index === 0 || index === 1) {
                                                                    return (
                                                                        <Dropdown.Item
                                                                            key={index}
                                                                            onClick={(e) => handleCarts(cart.id)}
                                                                        >
                                                                            <div
                                                                                className="img--div d-flex justify-content-center align-items-center">
                                                                                <img
                                                                                    src={
                                                                                        cart.printful_product_id === 19 || cart.printful_product_id === 300
                                                                                            ? (
                                                                                                cart.placement === 'right'
                                                                                                    ? (cart.right_mockup ? cart.right_mockup : (process.env.REACT_APP_apiBaseURL + '/' + cart.image))
                                                                                                    : (cart.placement === 'left' ? (cart.left_mockup ? cart.left_mockup : (process.env.REACT_APP_apiBaseURL + '/' + cart.image)) : (cart.front_mockup ? cart.front_mockup : (process.env.REACT_APP_apiBaseURL + '/' + cart.image)))
                                                                                            )
                                                                                            : (process.env.REACT_APP_apiBaseURL + '/' + cart.image)
                                                                                    }
                                                                                    style={{
                                                                                        width: "33px",
                                                                                        height: "41px",
                                                                                        objectFit: "contain",
                                                                                    }}
                                                                                    alt={cart.title}
                                                                                />
                                                                            </div>

                                                                            <div className="d-flex flex-column">
                                                                                <p
                                                                                    style={{
                                                                                        overflow: "hidden",
                                                                                        textOverflow: "ellipsis",
                                                                                        whiteSpace: "nowrap",
                                                                                        maxWidth: "170px",
                                                                                    }}
                                                                                >
                                                                                    {cart.title}
                                                                                </p>
                                                                                    {cart.type ==1 ? (<p className="mb-1 lable--font">Price : ${cart.price*cart.quantity  } <span className="printfull-prices"> & Printful Charge : ${ cart.printful_price*cart.quantity}</span></p>) : (<p className="mb-1 lable--font">Price : ${cart.price}</p>) }
                                                                            </div>
                                                                        </Dropdown.Item>
                                                                    );
                                                                }
                                                            })}
                                                        </div>
                                                        <div className="d-flex justify-content-center my-2">
                                                            <button
                                                                onClick={handleViewMoreClick}
                                                                className="link--btn"
                                                            >
                                                                View Cart
                                                            </button>
                                                        </div>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                    </div>
                                )}

                                <div className="col-lg-6 d-flex hidden--class app-navigation">
                                    <div
                                        className="collapse navbar-collapse justify-content-center"
                                        id="navbarSupportedContent"
                                    >
                                        <ul className="navbar-nav d-flex justify-content-between">
                                            <li className="nav-item px-1">
                                                <NavLink className="nav-link nav-text" to="/" end>
                                                    Homepage
                                                </NavLink>
                                            </li>
                                            <li className="nav-item px-1">
                                                <NavLink
                                                    className="nav-link nav-text"
                                                    // activeClassName="active"
                                                    to="/newsfeed"
                                                >
                                                    Newsfeed
                                                </NavLink>
                                            </li>
                                            <li className="nav-item px-1">
                                                <NavLink className="nav-link nav-text" to="/customizer">
                                                    Creator
                                                </NavLink>
                                            </li>
                                            <li className="nav-item dropdown px-1 hover--class--navbar">
                                                {/* <a className="nav-link dropdown-toggle nav-text px-3" href="#" id="navbarDropdown"
                                            role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={() => {setShowDropDown(true)}}>
                                            Shop
                                        </a>
                                        <div className="dropdown-menu" aria-labelledby="navbarDropdown"
                                            style={{ backgroundColor: "rgba(233, 233, 233, 1)", display: showShop ? 'block' : 'none' }}>
                                            <Link className="dropdown-item" to="/shop">Official Products</Link>
                                            <Link className="dropdown-item" to="/shop">User-Made Products</Link>
                                        </div> */}
                                                <Dropdown>
                                                    <Dropdown.Toggle
                                                        variant="light"
                                                        id="dropdown-basic"
                                                        className="wrapper d-flex justify-content-around align-items-center p-0"
                                                        style={{
                                                            width: "100%",
                                                            backgroundColor: "transparent",
                                                            borderRadius: "5px",
                                                            heihgt: "auto",
                                                        }}
                                                    >
                                                        <div
                                                            className={
                                                                "align-self-center dropdown--navbar " +
                                                                (pathname === "/shop/official" ||
                                                                    pathname === "/shop/user"
                                                                    ? "active"
                                                                    : "")
                                                            }
                                                        >
                                                            <h6
                                                                className="my-0"
                                                                style={{
                                                                    marginBottom: "0px",
                                                                    padding: "0.6rem",
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                Shop <BsChevronDown size={12} />
                                                            </h6>
                                                            {/* <p className="text-start" style={{ fontSize: '12px', marginBottom: '0px' }}>$55 USD</p> */}
                                                        </div>
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <Dropdown.Item as={Link} to="/shop/official">
                                                            <p
                                                                style={{
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                Official products
                                                            </p>
                                                        </Dropdown.Item>
                                                        <Dropdown.Item as={Link} to="/shop/user">
                                                            <p
                                                                style={{
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                User-Made products
                                                            </p>
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </li>
                                            <li className="nav-item px-1">
                                                <NavLink
                                                    className="nav-link nav-text"
                                                    // activeClassName="active"
                                                    to="/blogs"
                                                >
                                                    Blogs
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="col-lg-3 text-center align-self-center hidden--class">
                                    {authUser && JSON.parse(localStorage.getItem("user")) ? (
                                        <div
                                            className="d-flex  align-items-center navigation-Mobile"
                                            style={{ columnGap: "10px" }}
                                        >
                                            <Link to="/search">
                                                <img src="/assets/images/search.png" alt="" />
                                            </Link>
                                            {/* <Link className="custom-anchor" to="/profile"> */}
                                            <Dropdown>
                                                <Dropdown.Toggle
                                                    variant="light"
                                                    id="dropdown-basic"
                                                    className="wrapper d-flex justify-content-around align-items-center p-0"
                                                    style={{
                                                        backgroundColor: "#E3E3E3",
                                                        borderRadius: "5px",
                                                        outline: "none",
                                                    }}
                                                >
                                                    <img
                                                        className="nav-profile-wrapper"
                                                        src={
                                                            JSON.parse(localStorage.getItem("user"))?.photo
                                                                ? JSON.parse(localStorage.getItem("user"))?.photo.includes("http") ? JSON.parse(localStorage.getItem("user")).photo : process.env.REACT_APP_apiBaseURL +
                                                                    "/" +
                                                                    JSON.parse(localStorage.getItem("user"))
                                                                        ?.photo
                                                                : "/assets/images/Rectangle 41.png"
                                                        }
                                                        alt=""
                                                        style={{
                                                            width: "60px",
                                                            height: "60px",
                                                            objectFit: "cover",
                                                        }}
                                                    />
                                                    <div
                                                        className="align-self-center d-flex flex-column"
                                                        style={{ paddingLeft: "8px" }}
                                                    >
                                                        <h6
                                                            className="my-0 customizer--username"
                                                            style={{ cursor: "pointer", fontWeight: "600" }}
                                                        >
                                                            {JSON.parse(localStorage.getItem("user")).name
                                                                ? JSON.parse(localStorage.getItem("user")).name
                                                                : "User"}
                                                        </h6>
                                                        <p
                                                            style={{
                                                                margin: "0px",
                                                                textAlign: "left",
                                                                fontSize: "14px",
                                                                fontWeight: "600",
                                                                cursor: "pointer",
                                                            }}
                                                        >
                                                            $
                                                            {authProfile?.available_amount
                                                                ? authProfile?.available_amount
                                                                : 0}
                                                        </p>
                                                        {/* <p className="text-start" style={{ fontSize: '12px', marginBottom: '0px' }}>$55 USD</p> */}
                                                    </div>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item as={Link} to={`/profile/` + authProfile?.user_name}>
                                                        <img
                                                            src="/assets/images/icons/profile.svg"
                                                            alt=""
                                                        />
                                                        <p style={{ cursor: "pointer" }}>View Profile</p>
                                                    </Dropdown.Item>
                                                    <Dropdown.Item as={Link} to="/store">
                                                        <img src="/assets/images/icons/store.svg" alt="" />
                                                        <p style={{ cursor: "pointer" }}>My Store</p>
                                                    </Dropdown.Item>
                                                    <Dropdown.Item as={Link} to="/wallet">
                                                        <img src="/assets/images/icons/Wallet.svg" alt="" />
                                                        <p style={{ cursor: "pointer" }}>Wallet</p>
                                                    </Dropdown.Item>
                                                    <Dropdown.Item as={Link} to="/payments">
                                                        <img src="/assets/images/Payment.svg" alt="" style={{width: '17px'}}/>
                                                        <p style={{ cursor: "pointer" }}>Payment</p>
                                                    </Dropdown.Item>
                                                    <Dropdown.Item as={Link} to="/setting">
                                                        <img
                                                            src="/assets/images/icons/Settings.svg"
                                                            alt=""
                                                        />
                                                        <p style={{ cursor: "pointer" }}>Settings</p>
                                                    </Dropdown.Item>
                                                    <Dropdown.Item onClick={handleLogout}>
                                                        <img
                                                            style={{width: '17px'}}
                                                            src="/assets/images/icons/logout.png"
                                                            alt=""
                                                        />
                                                        <p style={{ cursor: "pointer" }}>Log Out</p>
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>

                                            {/* </Link> */}

                                            <div className="cart--div">
                                                <Dropdown show={isOpen} onToggle={handleToggle}>
                                                    <Dropdown.Toggle
                                                        variant="light"
                                                        id="dropdown-basic"
                                                        className="wrapper d-flex justify-content-around align-items-center p-0"
                                                    >
                                                        <div
                                                            className="cart-wrapper"
                                                            style={{ position: "relative" }}
                                                        >
                                                            <img src="/assets/images/cart.svg" alt="" />
                                                            {Number(props.cartCount) !== 0 && (
                                                                <span
                                                                    className="badge badge-dark rounded-circle"
                                                                    style={{
                                                                        position: "absolute",
                                                                        left: "18px",
                                                                    }}
                                                                >
                                                                    {props.cartCount}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <h1 className="fs-6 px-3 d-flex align-items-center">
                                                            Item Added To Your Cart
                                                        </h1>
                                                        <div
                                                            style={{
                                                                maxHeight: "225px",
                                                                overflowY: "auto",
                                                            }}
                                                        >
                                                            {carts.map((cart, index) => {
                                                                if (index === 0 || index === 1) {
                                                                    return (
                                                                        <Dropdown.Item
                                                                            key={index}
                                                                            onClick={(e) => handleCarts(cart.id)}
                                                                        >
                                                                            <div
                                                                                className="img--div d-flex justify-content-center align-items-center">
                                                                                <img
                                                                                    src={
                                                                                        cart.printful_product_id === 19 || cart.printful_product_id === 300
                                                                                            ? (
                                                                                                cart.placement === 'right'
                                                                                                    ? (cart.right_mockup ? cart.right_mockup : (process.env.REACT_APP_apiBaseURL + '/' + cart.image))
                                                                                                    : (cart.placement === 'left' ? (cart.left_mockup ? cart.left_mockup : (process.env.REACT_APP_apiBaseURL + '/' + cart.image)) : (cart.front_mockup ? cart.front_mockup : (process.env.REACT_APP_apiBaseURL + '/' + cart.image)))
                                                                                            )
                                                                                            : (process.env.REACT_APP_apiBaseURL + '/' + cart.image)
                                                                                    }
                                                                                    
                                                                                    style={{
                                                                                        width: "33px",
                                                                                        height: "41px",
                                                                                        objectFit: "contain",
                                                                                    }}
                                                                                    alt={cart.title}
                                                                                />
                                                                            </div>

                                                                            <div className="d-flex flex-column">
                                                                                <p
                                                                                    style={{
                                                                                        overflow: "hidden",
                                                                                        textOverflow: "ellipsis",
                                                                                        whiteSpace: "nowrap",
                                                                                        maxWidth: "170px",
                                                                                    }}
                                                                                >
                                                                                    {cart.title}
                                                                                </p>
                                                                                    {cart.type ==1 ? (<p className="mb-1 lable--font">Price : ${cart.price * cart.quantity} <span className="printfull-prices"> & Printful Charge : ${ cart.printful_price*cart.quantity}</span></p>) : (<p className="mb-1 lable--font">Price : ${cart.price}</p>) }
                                                                            </div>
                                                                        </Dropdown.Item>
                                                                    );
                                                                }
                                                            })}
                                                        </div>
                                                        <div className="d-flex justify-content-center my-2">
                                                            <button
                                                                onClick={handleViewMoreClick}
                                                                className="link--btn"
                                                            >
                                                                View Cart
                                                            </button>
                                                        </div>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="d-flex justify-content-end align-items-center nav-Login ">
                                            <Link className="nav-link nav-text" to="/login">
                                                Login
                                            </Link>
                                            |
                                            <Link className="nav-link nav-text" to="/signup">
                                                Register
                                            </Link>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            )}
        {authUser && JSON.parse(localStorage.getItem("user")) ? (
            <div className="navbar-Mobile">
                <NavLink className="nave" to="/">
                    {pathname === "/" ? (
                        <AiFillHome size={30} />
                    ) : (
                        <AiOutlineHome size={30} />
                    )}
                    Home
                </NavLink>
                <NavLink className="nave" to="/newsfeed">
                    {pathname === "/newsfeed" ? (
                        <GiWhiteBook size={30} />
                    ) : (
                        <GiBlackBook size={30} />
                    )}
                    Feed
                </NavLink>
                <NavLink className="nave1" to="/customizer">
                    {pathname === "/customizer" ? (
                        <AiFillPlusCircle size={50} />
                    ) : (
                        <AiOutlinePlusCircle size={50} />
                    )}
                </NavLink>
                <NavLink className="nave" to="/shop/user">
                    {pathname === "/shop/user" ? (
                        <AiFillShop size={30} />
                    ) : (
                        <AiOutlineShop size={30} />
                    )}
                    Shop
                </NavLink>
                <NavLink className="nave" to="/profile">
                    {pathname === "/profile" ? (
                        <RiUserFill size={30} />
                    ) : (
                        <RiUserLine size={30} />
                    )}
                    Profile
                </NavLink>
            </div>
        ) : '' }
        </>
    );
};

export default Navbar;
