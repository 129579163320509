import { Link } from "react-router-dom";

function AccountSuspended() {

    return (
        <div className="container d-flex flex-column align-items-center my-3">
            <h1 style={{ textTransform: 'capitalize' }}>Account Suspended</h1>
            <h5 style={{ textTransform: 'capitalize' }} className="pb-4">This account has been suspended</h5>
            <img src="/assets/images/account_suspended.svg" alt={"account_suspended"} style={{width: '477px', height: '426px'}}/>
            <h5 style={{ textTransform: 'capitalize' }} className="pt-4 pb-2">For more information please</h5>
            <Link to={"/contact"} className="delivry--btn d-flex justify-content-center align-items-center" style={{textDecoration: 'none'}}>Contact With Us</Link>
        </div>
    );
}

export default AccountSuspended