import { RxDotsHorizontal } from "react-icons/rx";
import chara from "../assets/images/Vector.png";

import trash from "../assets/images/Trash (1).svg";
import edit from "../assets/images/Group 872.svg";
import { convertTime, LikesToK } from "./TimeFormat";
import { useEffect, useState } from "react";
import {
  deleteComment,
  submitCommentData,
  updateComment,
} from "../services/CommentsService";
import { toast } from "react-toastify";
import { convertToFormData } from "../services/customizerService";
import arrow from "../assets/images/newsfeed/Group 931.svg";
import { constants } from "./Constants/constants";
import { postReact } from "../services/NewsFeedsService";
import { AiFillDislike, AiOutlineDislike } from "react-icons/ai";
import { Link } from "react-router-dom";
import Spinner from "react-bootstrap/esm/Spinner";
import { getPostComments } from "../services/CommentsService";
import { Dropdown } from "react-bootstrap";
const Comment = ({
  item,
  setPostsData,
  newsFeedCommentStatus,
  setNewsFeedCommentStatus,
}) => {
  ///States
  const [commentInput, setCommentInput] = useState({
    comment: "",
  });
  const [editCommentInput, setEditCommentInput] = useState([]);
  const [commentdisplay, setCommentDisplay] = useState({});
  const [commentsLoader, setCommentsLoader] = useState(false);
  const [commentsState, setCommentsState] = useState([]);
  const [paginateLinks, setPaginateLinks] = useState(null);
  const [seeMore, setSeeMore] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);

  useEffect(() => {
    if (newsFeedCommentStatus) {
      getComments();
    }
  }, []);
  const getComments = async (page, paginate) => {
    setCommentsLoader(true);
    ///setting the comment data to the state
    const response = await getPostComments(item.id, page);
    if (paginate === "paginate") {
      setPostsData((prevState) => {
        return prevState.map((post) => {
          if (post.id === item.id) {
            const newComments = response.data.data;
            return {
              ...post,
              comments: [...(post.comments || []), ...newComments],
            };
          } else {
            return post;
          }
        });
      });
    } else {
      setPostsData((prevState) => {
        return prevState.map((post) => {
          const updatedComments = response?.data?.data
            ?.filter((comment) => {
              return comment.post_id === post.id;
            })
            .filter((comment) => {
              return !post.comments?.some((c) => c.id === comment.id);
            });

          return {
            ...post,
            comments: [...(post.comments || []), ...updatedComments],
          };
        });
      });
    }
    setCommentsLoader(false);
    setPaginateLinks(response.data.links?.next);

    if (response.data.links?.next) {
      setPageIndex(pageIndex + 1);
    }
  };

  //// Funtcions
  const submitComment = async (id, e) => {
    e.preventDefault();
    if (commentInput.comment === "") {
      return;
    }
    const finalObject = {
      comment: commentInput.comment,
      post_id: id,
    };
    const formData = convertToFormData(finalObject);
    const response = await submitCommentData(formData);
    if (response.status === 201) {
      setPostsData((prevState) => {
        return prevState.map((post) => {
          if (post.id === id) {
            const updatedComments = [
              ...(post.comments || []),
              response.data.data,
            ];
            return {
              ...post,
              comments: updatedComments,
              total_comments: post.total_comments + 1,
            };
          } else {
            return post;
          }
        });
      });

      commentInput.comment = "";
    } else {
      toast.error(response.data.message);
    }
  };

  const deleteComentData = async (commentId, postId) => {
    setPostsData((prevState) => {
      return prevState.map((post) => {
        if (post.id === postId) {
          const updatedComments = post.comments.filter(
            (comment) => comment.id !== commentId
          );
          return {
            ...post,
            total_comments: post.total_comments - 1,
            comments: updatedComments,
          };
        } else {
          return post;
        }
      });
    });

    const response = await deleteComment(commentId);
    if (response.status === 200) {
    } else {
      toast.error(response.data.message);
    }
  };

  const editCommentData = async (e, commentId, postId) => {
    e.preventDefault();
    setPostsData((prev) => {
      const updatedPosts = prev.map((post) => {
        if (post.id === postId) {
          const updatedComments = post.comments.map((comment) => {
            if (comment.id === commentId) {
              return {
                ...comment,
                comment: editCommentInput[commentId].editComment,
              };
            } else {
              return comment;
            }
          });
          return {
            ...post,
            comments: updatedComments,
          };
        } else {
          return post;
        }
      });
      return updatedPosts;
    });
    const commentData = {
      comment: editCommentInput[commentId].editComment,
    };
    const response = await updateComment(commentId, commentData);
    if (response.status === 200) {
    } else {
      toast.error(response.data.message);
    }
    setCommentDisplay({ ...commentdisplay, [commentId]: false });
  };

  const react = async (id, type, postId) => {
    setPostsData((prevState) => {
      return prevState?.map((i) => {
        if (i.id === postId) {
          const updatedComments = i.comments.map((comment) => {
            if (comment.id === id) {
              if (type === constants.love) {
                if (comment.reaction === 0) {
                  return {
                    ...comment,
                    have_i_reacted: 1,
                    total_reactions: comment.total_reactions + 1,
                    reaction: constants.love,
                  };
                } else {
                  if (comment.reaction === 2) {
                    return {
                      ...comment,
                      have_i_reacted: 1,
                      total_reactions: comment.total_reactions + 1,
                      total_dislikes: comment.total_dislikes - 1,
                      reaction: constants.love,
                    };
                  }
                  return {
                    ...comment,
                    have_i_reacted: 0,
                    total_reactions: comment.total_reactions - 1,
                    reaction: 0,
                  };
                }
              } else {
                if (comment.reaction === 0 || comment.reaction === 1) {
                  if (comment.reaction === 1) {
                    return {
                      ...comment,
                      have_i_reacted: 1,
                      total_dislikes: comment.total_dislikes + 1,
                      total_reactions: comment.total_reactions - 1,
                      reaction: constants.dislike,
                    };
                  }
                  return {
                    ...comment,
                    have_i_reacted: 1,
                    total_dislikes: comment.total_dislikes + 1,
                    reaction: constants.dislike,
                  };
                } else {
                  return {
                    ...comment,
                    have_i_reacted: 0,
                    total_dislikes: comment.total_dislikes - 1,
                    reaction: 0,
                  };
                }
              }
            } else {
              return comment;
            }
          });
          return {
            ...i,
            comments: updatedComments,
          };
        } else {
          return i;
        }
      });
    });
    const audio = new Audio("/assets/sounds/facebook_likes.mp3");
    audio.play();
    let fianlData = {
      reaction: type,
      id: id,
      type: constants.comment,
    };

    const response = await postReact(fianlData);
    if (response.status === 200) {
    } else {
      toast.error(response.data.message);
    }
  };

  return (
    <>
      {
        <>
          {" "}
          {item?.comments?.map((i, key) => {
            const converttime = convertTime(i.created_at);
            const likesFormat = LikesToK(i.total_reactions);
            const dislikeFormat = LikesToK(i.total_dislikes);

            return (
              <div className="user-post" key={i.id}>
                <div className="user-header">
                  <div className="user-icon">
                    <Link to={`/profile/${item?.user?.id}`}>
                        <img
                        src={
                          i.user.photo
                            ? i.user.photo.includes("http") ? i.user.photo : process.env.REACT_APP_apiBaseURL +
                              "/" +
                              i.user.photo
                            : chara
                        }
                        alt=""
                        style={{
                          objectFit: "cover",
                          borderRadius: "50%",
                          cursor: "pointer",
                        }}
                      />
                    </Link>
                  </div>
                  <div className="user-details">
                    <span className="user-name">{i?.user?.name}</span>
                    <span className="timestamp">{converttime}</span>
                  </div>
                  <div className="user-actions">
                    {i.have_i_reacted === 1 &&
                            constants.love === i.reaction ? (
                            <i className="fa fa-heart heart-icon" aria-hidden="true" onClick={() =>
                              react(i?.id, constants.love, i.post_id)
                            }></i>
                            ) : (
                              <i
                                className="fa fa-heart-o heart-icon"
                                aria-hidden="true"
                                onClick={() =>
                                  react(i?.id, constants.love, i.post_id)
                                }
                              ></i>
                            )
                    }        
                    <span className="likes-count">
                      {" "}
                      {likesFormat}
                    </span>
                    {i.have_i_reacted === 1 &&
                      constants.dislike === i.reaction ? (
                        <AiOutlineDislike className="dislike-icon"  onClick={() =>
                          react(i?.id, constants.dislike, i?.post_id)
                        } />
                      ):(
                        <AiOutlineDislike className="dislike-icon"
                          onClick={() =>
                            react(i?.id, constants.dislike, i?.post_id)
                          }
                        />
                      )
                    }  
                    <span className="dislikes-count">{dislikeFormat}</span>
                    {i?.user?.id ===
                      JSON.parse(localStorage.getItem("user")).id ? (
                        <>
                          <img src={edit} alt="Edit" className="action-icon" 
                            onClick={() =>{
                              setCommentDisplay({
                                ...commentdisplay,
                                [i.id]: true,
                              });
                              setEditCommentInput({
                                ...editCommentInput,
                                [i.id]: { editComment: i.comment }
                              });
                            }}
                          />
                          <img src={trash} alt="Delete" className="action-icon" 
                            onClick={() => deleteComentData(i?.id, i?.post_id)}
                          />
                        </>
                      ) : (
                        <Dropdown>
                          <Dropdown.Toggle
                            className="btn btn-secondary three--dots report--btn"
                            id="dropdown-basic"
                          >
                            <RxDotsHorizontal size={20} />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item>Report</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                  </div>
                </div>
                {commentdisplay[i.id] && (
                  <form
                    className="col-md-12 pl-0"
                    style={{ position: "relative" }}
                    onSubmit={(e) => editCommentData(e, i.id, i.post_id)}
                    id="commentInput_data"
                  >
                    <input
                      type="text"
                      placeholder="Add your comment"
                      className="px-3 edit__comment"
                      style={{
                        width: "100%",
                        backgroundColor: "#F6F6F6",
                        borderRadius: "10px",
                      }}
                      value={
                        editCommentInput[i.id]
                          ? editCommentInput[i.id].editComment
                          : i.comment
                      }
                      onChange={(e) => {
                        const updatedValue = e.target.value;
                        setEditCommentInput(prevInput => ({
                          ...prevInput,
                          [i.id]: { ...prevInput[i.id], editComment: updatedValue }
                        }));
                      }}                      
                    />
                    <button type="submit" style={{ border: "none" }}>
                      <img
                        src={arrow}
                        style={{
                          width: "21px",
                          height: "21px",
                          cursor: "pointer",
                          position: "absolute",
                          top: "25%",
                          right: "3%",
                        }}
                        alt=""
                      />
                    </button>
                  </form>
                )}
                {!commentdisplay[i.id] && (
                  <div className="text-message">
                    {!seeMore ? i?.comment.slice(0, 40 * 3) : i?.comment}{" "}
                        {i?.comment.length > 40 * 3 ? (
                          <a
                            onClick={(e) => setSeeMore(!seeMore)}
                            style={{ fontWeight: "bold", cursor: "pointer" }}
                          >
                            {" "}
                            {seeMore ? "... see less" : "... see more"}
                          </a>
                        ) : (
                          ""
                    )}
                  </div>
                )}  
              </div>
              // <div className="row mt-1 p-2" key={i.id}>
              //   <div
              //     className="col-md-12 pl-0"
              //     style={{
              //       position: "relative",
              //       backgroundColor: "#F6F6F6",
              //       borderRadius: "10px",
              //     }}
              //   >
              //     <div
              //       className="d-flex  pt-1 gap-3"
              //       style={{ height: "36px" }}
              //     >
              //       <div className="col-md-12 d-flex align-items-center p-0 gap-2">
              //         <Link to={`/profile/${item?.user?.id}`}>
              //           <img
              //             src={
              //               i.user.photo
              //                 ? i.user.photo.includes("http") ? i.user.photo : process.env.REACT_APP_apiBaseURL +
              //                   "/" +
              //                   i.user.photo
              //                 : chara
              //             }
              //             alt=""
              //             style={{
              //               width: "30px",
              //               height: "30px",
              //               objectFit: "cover",
              //               borderRadius: "50%",
              //               cursor: "pointer",
              //             }}
              //           />
              //         </Link>
              //         <h5
              //           className="  mb-0 "
              //           style={{
              //             fontSize: "16px",
              //             fontWeight: "700",
              //           }}
              //         >
              //           {i?.user?.name}
              //         </h5>
              //         <p className="card-text m-0">
              //           <small className="text-muted">{converttime}</small>
              //         </p>
              //         <div className="like--folow like___follow">
              //           {i.have_i_reacted === 1 &&
              //           constants.love === i.reaction ? (
              //             <i
              //               className="fa fa-heart heart-icon animated"
              //               aria-hidden="true"
              //               style={{
              //                 color: "red",
              //                 cursor: "pointer",
              //                 fontSize: "21px",
              //               }}
              //               onClick={() =>
              //                 react(i?.id, constants.love, i.post_id)
              //               }
              //             ></i>
              //           ) : (
              //             <i
              //               className="fa fa-heart-o heart-icon"
              //               aria-hidden="true"
              //               style={{
              //                 color: "red",
              //                 cursor: "pointer",
              //                 fontSize: "21px",
              //               }}
              //               onClick={() =>
              //                 react(i?.id, constants.love, i.post_id)
              //               }
              //             ></i>
              //           )}
              //           <h5 className="m-0" style={{ fontSize: "15px" }}>
              //             {" "}
              //             {likesFormat}
              //           </h5>
              //         </div>
              //         <div className="like--folow like___follow">
              //           {i.have_i_reacted === 1 &&
              //           constants.dislike === i.reaction ? (
              //             <AiFillDislike
              //               style={{
              //                 color: "black",
              //                 width: "21px",
              //                 height: "21px",
              //                 cursor: "pointer",
              //               }}
              //               onClick={() =>
              //                 react(i?.id, constants.dislike, i?.post_id)
              //               }
              //             />
              //           ) : (
              //             <AiOutlineDislike
              //               style={{
              //                 color: "black",
              //                 width: "21px",
              //                 height: "21px",
              //                 cursor: "pointer",
              //               }}
              //               onClick={() =>
              //                 react(i?.id, constants.dislike, i?.post_id)
              //               }
              //             />
              //           )}
              //           <h5 className="m-0" style={{ fontSize: "15px" }}>
              //             {dislikeFormat}
              //           </h5>
              //         </div>
              //       </div>
              //     </div>

              //     {i?.user?.id ===
              //     JSON.parse(localStorage.getItem("user")).id ? (
              //       <div className=" d-flex gap-3 justify-content-end edit--and--trash ">
              //         <img
              //           src={edit}
              //           alt=""
              //           style={{
              //             cursor: "pointer",
              //             width: "19px",
              //             height: "19px",
              //           }}
              //           onClick={() =>
              //             setCommentDisplay({
              //               ...commentdisplay,
              //               [i.id]: true,
              //             })
              //           }
              //         />
              //         <img
              //           src={trash}
              //           alt=""
              //           style={{
              //             cursor: "pointer",
              //             width: "22px",
              //             height: "22px",
              //           }}
              //           onClick={() => deleteComentData(i?.id, i?.post_id)}
              //         />
              //       </div>
              //     ) : (
              //       <Dropdown>
              //         <Dropdown.Toggle
              //           className="btn btn-secondary three--dots report--btn"
              //           id="dropdown-basic"
              //         >
              //           <RxDotsHorizontal size={20} />
              //         </Dropdown.Toggle>

              //         <Dropdown.Menu>
              //           <Dropdown.Item>Report</Dropdown.Item>
              //         </Dropdown.Menu>
              //       </Dropdown>
              //     )}

              //     <div>
              //       {commentdisplay[i.id] && (
              //         <form
              //           className="col-md-12 pl-0"
              //           style={{ position: "relative" }}
              //           onSubmit={(e) => editCommentData(e, i.id, i.post_id)}
              //           id="commentInput_data"
              //         >
              //           <input
              //             type="text"
              //             placeholder="Add your comment"
              //             className="px-3 edit__comment"
              //             style={{
              //               width: "100%",
              //               backgroundColor: "#F6F6F6",
              //               borderRadius: "10px",
              //             }}
              //             value={
              //               editCommentInput[i.id]
              //                 ? editCommentInput[i.id].editComment
              //                 : i.comment
              //             }
              //             onChange={(e) => {
              //               let c = [...editCommentInput];
              //               c[i.id] = { editComment: e.target.value };
              //               setEditCommentInput(c);
              //             }}
              //           />
              //           <button type="submit" style={{ border: "none" }}>
              //             <img
              //               src={arrow}
              //               style={{
              //                 width: "21px",
              //                 height: "21px",
              //                 cursor: "pointer",
              //                 position: "absolute",
              //                 top: "25%",
              //                 right: "3%",
              //               }}
              //               alt=""
              //             />
              //           </button>
              //         </form>
              //       )}

              //       {!commentdisplay[i.id] && (
              //         <p className="m-0" style={{ padding: "0px 38px" }}>
              //           {!seeMore ? i?.comment.slice(0, 40 * 3) : i?.comment}{" "}
              //           {i?.comment.length > 40 * 3 ? (
              //             <a
              //               onClick={(e) => setSeeMore(!seeMore)}
              //               style={{ fontWeight: "bold", cursor: "pointer" }}
              //             >
              //               {" "}
              //               {seeMore ? "... see less" : "... see more"}
              //             </a>
              //           ) : (
              //             ""
              //           )}
              //         </p>
              //       )}
              //     </div>
              //   </div>
              // </div>
            );
          })}
        </>
      }

      {paginateLinks !== null && (
        <div className="col-md-12 d-flex justify-content-center ">
          {commentsLoader ? (
            <Spinner />
          ) : (
            <button
              className="comments_view_more get-started mt-2"
              onClick={() => getComments(pageIndex, "paginate")}
            >
              View more
            </button>
          )}
        </div>
      )}
      <div className="row">
        <form
          className="col-md-12 pt-2 px-2"
          style={{ position: "relative" }}
          onSubmit={(e) => submitComment(item.id, e)}
          id="commentInput_data"
        >
          <input
            type="text"
            placeholder="Add your comment"
            className="px-3"
            style={{
              width: "100%",
              backgroundColor: "#F6F6F6",
              borderRadius: "10px",
              color: "black",
            }}
            value={commentInput.comment}
            onChange={(e) => {
              const c = { ...commentInput };
              c.comment = e.target.value;
              setCommentInput(c);
            }}
          />
          <button type="submit" style={{ border: "none" }}>
            <img
              src={arrow}
              style={{
                width: "21px",
                height: "21px",
                cursor: "pointer",
                position: "absolute",
                top: "33%",
                right: "3%",
              }}
              alt=""
            />
          </button>
        </form>
      </div>
    </>
  );
};
export default Comment;
