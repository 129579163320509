import api from "./api";

export const searchUnique = async (id, type, search) => {
    const query = new URLSearchParams(window.location.search);
    let queryParam = query.get('type');
    try {
        const response = await api.get(`/api/v1/${id ? `${id}/` : ""}${type}?q=${search}&type=${queryParam}`);
        return response;
    } catch (error) {
        return error.response;
    }
};