import React, { useEffect, useState } from "react";
import icon from "../assets/images/icons/ArrowCircleLeft.svg";
import { Link, useNavigate } from "react-router-dom";
import loco from "../assets/images/icons/loco.svg";
import fone from "../assets/images/icons/fone.svg";
import msg from "../assets/images/icons/msg.svg";
import insta from "../assets/images/icons/insta.svg";
import fb from "../assets/images/icons/fb.svg";
import twit from "../assets/images/icons/twit.svg";
import { contactUs } from "../services/AuthService";
import Spinner from "react-bootstrap/esm/Spinner";
import {toast} from "react-toastify";
import { socialInformation }  from "../services/SettingService"

const Contact = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [checkValidator, setValidator] = useState(true);
  const [contact, setContact] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [error, setError] = useState({
    name: '',
    email: '',
    message: ''
  });
  
  const [socialData, setSocialData] = useState([])

  const validateInput = () => {
    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (contact.name === '') {
      setError({
        error,
        name: 'Full name is required'
      });
      return false;
    } else {
      setError({
        error,
        name: ''
      });
    }
    if (contact.email === '') {
      setError({
        error,
        email: 'Email is required'
      });
      return false;
    } else if (contact.email && !emailRegex.test(contact.email)) {
      setError({
        error,
        email: 'Invalid email address'
      });
      return false;
    } else {
      setError({
        error,
        email: ''
      });
    }
    if (contact.message === '') {
      setError({
        error,
        message: 'Message is required'
      });
      return false;
    } else if (contact.message?.length < 20) {
      setError({
        error,
        message: 'Message atleast 20 characters long'
      });
      return false;
    } else {
      setError({
        error,
        message: ''
      });
    }
    return true;
  }

  const getSocialdata = async () => {
    ///Followers API
    const response = await socialInformation();
    if (response?.status === 200) {
        setSocialData(response?.data);
    }
  };

  useEffect(() => {
    getSocialdata();
    if (!checkValidator) {
      validateInput();
    }
    setValidator(false);
  }, [contact]);

  const handleContactUsForm = async () => {
    if (!validateInput()) {
      return;
    }
    setLoader(true);
    const response = await contactUs(contact);
    if (response.status === 200) {
      
      toast.success("Thank you for contacting with us.We'll shortly come back to you.");
      setLoader(false);
    }
  }
  return (
    <div>
      <div className="container">
        <div style={{ display: "flex", alignItems: "baseline" }}>
            <img src={icon} style={{cursor: 'pointer'}} className="Arrow-icon" alt="Arrow" onClick={(e) => navigate(-1)}/>

          <div
            className="my-5 px-4"
            style={{
              backgroundColor: "white",
              marginRight: "auto",
              marginLeft: "auto",
              borderRadius: '10px'
            }}
          >
            <h1 className="pt-4 " style={{ width: "100%" }}>
              Contact Us
            </h1>
            <p className="pb-3 " style={{ color: "#A3A3A3" }}>
              We are here for you. Let Us know How we can help you!
            </p>

            <form className="example">
              <small className="text-danger">{error.name}</small>
              <input
                className="mb-3 input-feild-contact"
                style={{ width: "100%", paddingLeft: "5%", color: "#A3A3A3" }}
                type="text"
                placeholder="Enter your fullname"
                name="search"
                onChange={(e) => setContact({
                  ...contact,
                  name: e.target.value
                })}
                onBlur={validateInput}
              />
              <br />
              <small className="text-danger">{error.email}</small>
              <input
                className="mb-3"
                style={{
                  width: "100%",
                  paddingLeft: "5%",
                  color: "#A3A3A3",
                }}
                type="email"
                placeholder="Enter Email"
                name="search"
                onChange={(e) => setContact({
                  ...contact,
                  email: e.target.value
                })}
                onBlur={validateInput}
              />
              <br />
              <small className="text-danger">{error.message}</small>
              <textarea
                className="mb-3"
                style={{
                  resize: "none",
                  width: "100%",
                  color: "#A3A3A3",
                }}
                rows="4"
                cols="50"
                placeholder="Write Something"
                onChange={(e) => setContact({
                  ...contact,
                  message: e.target.value
                })}
                onBlur={validateInput}
              ></textarea>
              <br />
              <button
                className="py-2 d-flex justify-content-center align-items-center"
                style={{
                  color: "black",
                  width: "100%",
                  border: " 1px solid #A3A3A3",
                  borderRadius: "40px",
                  backgroundColor: "white",
                }}
                type="button"
                disabled={loader}
                onClick={handleContactUsForm}
              >
                {loader && <Spinner animation="border me-2" />}Submit</button>
            </form>

            <p className="mt-4 mb-3">Or, You can reach out to us here!</p>
            <div
              className=" mb-4"
              style={{
                width: "100%",
                backgroundColor: "#F5F5F5",
                borderRadius: "5px",
              }}
            >
              <div className="d-flex align-content-center px-3 py-3">
                <img src={loco} style={{ marginRight: '2%' }} alt="" />
                <p className="ending-para ">
                  {socialData.address}
                </p>
              </div>
              <div className="d-flex align-content-center px-3 py-3">

                <img src={fone} style={{ marginRight: '2%' }} alt="" />
                <p className="ending-para">{socialData.phone_number}</p>
              </div>
              <div className="d-flex align-content-center px-3 py-3">

                <img src={msg} style={{ marginRight: '2%' }} alt="" />
                <p className="ending-para">{socialData.email}</p>
              </div>
              <div
                className="mx-3 my-3"
                style={{
                  height: "1px",
                  backgroundColor: "black",
                  marginTop: "1%",
                  marginBottom: "1%",
                }}></div>

              <p className="mx-3 paragraph-bottom">Follow Us On</p>
              <a href={socialData.instagram} target="_blank" rel="noopener noreferrer"><img className="mx-3 mb-3" src={insta} alt="" /></a>
              <a href={socialData.facebook} target="_blank" rel="noopener noreferrer"><img className="mx-3 mb-3" src={fb} alt="" /></a>
              <a href={socialData.twitter} target="_blank" rel="noopener noreferrer"><img className="mx-3 mb-3" src={twit} alt="" /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
