import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
// import valid from "../assets/images/Group 918.svg";

function CardDetail({ account, setCheckoutUser, setPaymentSelected }) {

    const [selectCard, setSelectCard] = useState(false);
    const handleSelectedCard = () => {
        setCheckoutUser(state => ({
            ...state,
            payment_method_id: !selectCard ? account.id : null
        }));
        setPaymentSelected(!selectCard ? "pre-card" : "");
        setSelectCard(!selectCard);
    }

    return (
        <>
            {
                account !== null ?
                    <Card className='justify-content-center px-3' style={{ width: '336px', height: '192px', border: "2px solid " + (selectCard ? "#0d6efd" : "#242424"), borderRadius: '12px', backgroundColor: 'white', cursor: 'pointer' }}
                        onClick={handleSelectedCard}>
                        <div className="d-flex ">
                            <input
                                type="text"
                                placeholder="1234 5678 9101 1213"
                                className="px-2 debit--card"
                                value={"**** **** **** " + account.last4}
                                style={{
                                    height: "30px",
                                    width: "100%",
                                    border: "1px solid #ACACAC",
                                    letterSpacing: "7px",
                                    color: 'black'
                                }}
                                readOnly
                            />
                        </div>
                        <div className="d-flex justify-content-between align-items-center mt-3 ">
                            <input
                                type="text"
                                placeholder="Name"
                                className="px-2 debit--card"
                                value={account.name}
                                style={{
                                    height: "30px",
                                    width: "150px",
                                    border: "1px solid #ACACAC",
                                    color: 'black',
                                    textTransform: 'capitalize'
                                }}
                                readOnly
                            />
                            <div className="gap-2">
                                {/* <img src={valid} style={{ color: "white" }} /> */}
                                <input
                                    type="text"
                                    placeholder="25/28"
                                    className="px-2 debit--card"
                                    value={(account.exp_month <= 10 ? "0" + account.exp_month : account.exp_month) + "/" + (account.exp_year.length === 4 ? account.exp_year.slice(-2) : account.exp_year)}
                                    style={{
                                        height: "30px",
                                        width: "60px",
                                        border: "1px solid #ACACAC",
                                        color: 'black'
                                    }}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="mt-3 d-flex justify-content-end">
                            <img src={"/assets/images/" + account.brand.toLowerCase() + ".svg"} style={{ width: '30px' }} />
                        </div>
                    </Card>
                    : <></>
            }
        </>
    );
}

export default CardDetail