import axios from "axios";
import { fabric } from "fabric";
import { useCallback, useEffect, useMemo, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useNavigate } from "react-router";
import Slider from "react-slick";
import { toast } from "react-toastify";
import { getCreations, getPublicCreations, getPurchaseCreation } from '../../services/customizerService';
import { PrinfulCategoryPlace } from "../PlaceHolder";
import media from "./media.json";

const Sidebar = ({
    printfulCategories,
    printFulProducts,
    setFilterPrintfulProducts,
    filterPrintfulProducts,
    loader,
    canvas,
    selectedProduct,
    setSelectedProduct,
    mode,
    initializeCanvas,
    canvasRef
}) => {
    const settings = {
        dots: false, infinite: true, speed: 300, slidesToShow: 3, slidesToScroll: 3,
    };

    const settings1 = {
        dots: false, infinite: true, speed: 300, slidesToShow: 2, slidesToScroll: 2,
    };

    const payload = JSON.parse(JSON.stringify(media)) || [];

    const getStickers = (sticker = "stickfigure") => {
        const productBanners = payload.filter((item) => item.sticker === sticker);
        return productBanners;
    };

    const [stickfigureCategories, setStickfigureCategories] = useState(getStickers("stickfigure"));
    const [products, setProducts] = useState(getStickers("products"));
    const [scribles, setScribles] = useState(getStickers("elements"));
    const [creations, setCreation] = useState(null);
    const [stickfigures, setStickfigures] = useState(null);
    const [elements, setElements] = useState(null);
    const [purchasedCreations, setPurchasedCreation] = useState([]);
    const [searchText, setSearchText] = useState('');

    const [selectedStickfigureAction, setSelectedStickfigureAction] = useState(stickfigures?.title);
    const [selectedElementAction, setSelectedElementAction] = useState(elements?.title);
    const [selectedProductAction, setSelectedProductAction] = useState(products[0]?.type);
    const [selectedScribleAction, setSelectedScribleAction] = useState(scribles[0]?.type);

    const [loadingCreations, setLoadingCreations] = useState(false);

    const filteredStickfigures = useMemo(() => {
        return stickfigures?.filter((category) => {
            return category.creation.some((creation) => {
                return creation.title.toLowerCase().includes(searchText.toLowerCase());
            });
        })
    }, [stickfigures, searchText]);

    const filteredElements = useMemo(() => {
        return elements?.filter((category) => {
            return category.creation.some((creation) => {
                return creation.title.toLowerCase().includes(searchText.toLowerCase());
            });
        })
    }, [elements, searchText]);

    // Load First Category by Default
    useEffect(() => {
        if (stickfigures) {
            setSelectedStickfigureAction(stickfigures[0]?.title);
        }
    }, [stickfigures]);

    // Load First Category by Default
    useEffect(() => {
        if (elements) {
            setSelectedElementAction(elements[0]?.title);
        }
    }, [elements]);

    useEffect(() => {
        if (filteredStickfigures?.length > 0) {
            setSelectedStickfigureAction(filteredStickfigures[0]?.title);
        }
    }, [filteredStickfigures]);


    useEffect(() => {
        if (filteredElements?.length > 0) {
            setSelectedElementAction(filteredElements[0]?.title);
        }
    }, [filteredElements]);

    const handleTabAction = (sticker, type = "heads") => {
        if (sticker === "stickfigure") {
            setSelectedStickfigureAction(type);
        } else if (sticker === "products") {
            setSelectedProductAction(type);
        } else if (sticker === "elements") {
            setSelectedElementAction(type);
        }
    };




    // const navigate = useNavigate();

    const getCreationList = async () => {
        setLoadingCreations(true);
        const response = await getPublicCreations(searchText);
        setStickfigures(response.data?.data?.stickfigures);
        setCreation(response.data?.data?.creations);
        setElements(response.data?.data?.elements);
        setLoadingCreations(false);
    };

   

    useEffect(() => {
        getCreationList();
    }, []);

    const handleStickers = useCallback(async (bannerImage, banner) => {
        if (!canvas) return;
        if (banner?.lego) {
            let res = (await axios.get(`${process.env.REACT_APP_apiBaseURL + '/' + banner.lego}`)).data;

            let currentCanvas = canvasRef.current?.toJSON();
            currentCanvas.objects = currentCanvas.objects.concat(res.objects);

            canvasRef.current?.loadFromJSON(currentCanvas)
        } else {
           
            if (bannerImage.endsWith('.svg')) {

                fabric.loadSVGFromURL(bannerImage, function (objects, options) {
                    let targetWidth = 300;  // Target width for the SVG
                    let targetHeight = 300; // Target height for the SVG
               
                    let scaleX = targetWidth / options.width;
                    let scaleY = targetHeight / options.height;
               
                    let offsetX = (canvas.width - targetWidth) / 2;
                    let offsetY = (canvas.height - targetHeight) / 2;
               
                    objects.forEach(function(obj) {
                        obj.set({
                            left: obj.left * scaleX + offsetX,
                            top: obj.top * scaleY + offsetY,
                            scaleX: obj.scaleX * scaleX,
                            scaleY: obj.scaleY * scaleY
                        });
                        canvas.add(obj);
                    });
               
                    canvas.renderAll();
                });    
            }
            else{

                new fabric.Image.fromURL(bannerImage, function (image) {
                    let scale = mode === "creation" ? 100 / image.width : 200 / image.width;
                    var img = image.set({ left: 100, top: 100 , scaleX: scale, scaleY: scale, padding: 0 });
                    canvas.add(img);
    
                    
                }, { crossOrigin: 'Anonymous' })
            }
        }
    }, [canvas]);

    const loadPurchasedCreation = (meme) => {
        const objects = canvas.getObjects();
        canvas.loadFromJSON(meme.canvas, function () {
            if (objects.length > 0) {
                objects.forEach((obj, key) => {
                    canvas.add(obj);
                })
            }
            canvas.renderAll();
        });
    }

    const selectProduct = (item) => {
        if (!selectedProduct) {
            setSelectedProduct(item);
            handleStickers(item.image);
        } else {
            toast.warn("You can only select one product at a time");
        }
    }

    function removeFirstAndLastChar(text, char) {
        if (text.startsWith(char) && text.endsWith(char)) {
            return text.slice(1, -1);
        } else if (text.startsWith(char)) {
            return text.slice(1);
        } else if (text.endsWith(char)) {
            return text.slice(0, -1);
        }
        return text;
    }

    return (<div
        className="col-md-3 py-4 slider-Web slider--slide"
        style={{
            backgroundColor: "#F3F3F3",
            maxWidth: "353px",
            paddingRight: "0px",
            paddingLeft: "0px",
            maxHeight: "100vh",
            minHeight: "666px",
            overflowY: "auto",
            overflowX: "hidden",
        }}
    >
        <div className="container mb-3">
            {/* <h1>Explore</h1> */}
            <div
                className="wrapper d-flex align-items-center my-3"
                style={{ position: "relative" }}
            >
                <input
                    type="text"
                    id="search"
                    placeholder="Search here"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    style={{ width: "100%", borderRadius: "30px", height: "49px" }}
                />
                <img
                    src="/assets/images/search.png"
                    alt=""
                    style={{ position: "absolute", right: "20px" }}
                />
            </div>
        </div>
        <div
            className="tab-content product-li-slick"
            id="myTabContent"
            style={{ overflowY: "auto", backgroundColor: "#D9D9D9" }}
        >
            <Tabs
                defaultActiveKey="home"
                id="fill-tab-example"
                className="mb-3"
                fill
            >
                <Tab eventKey="home" title="Stickfigures">
                    {loadingCreations ? (<div style={{ width: "100%" }}>
                        <PrinfulCategoryPlace />
                    </div>) : stickfigures !== null ? <>
                        <div className="container product-li-slick">
                            <Slider {...settings}
                                slidesToScroll={filteredStickfigures.length >= 3 ? 3 : filteredStickfigures.length}
                                slidesToShow={filteredStickfigures.length >= 3 ? 3 : filteredStickfigures.length}
                            >
                                {filteredStickfigures.map((item, index) => {
                                    return (<div
                                        className={"d-flex justify-content-center align-items-center product-wrapper " + (selectedStickfigureAction === item.title ? "bg-dark" : " ")}
                                        key={index}
                                        onClick={() => {
                                            handleTabAction("stickfigure", item.title);
                                        }}
                                    >
                                        <div
                                            className={"text-black " + (selectedStickfigureAction === item.title ? "text-white" : "")}
                                        >
                                            {item.title}
                                        </div>
                                    </div>);
                                })}{" "}
                            </Slider>
                        </div>
                        <div className="col-md-12 d-flex justify-content-center flex-wrap">
                            {stickfigures?.filter((item) => item.title === selectedStickfigureAction)[0]
                                ?.creation
                                .filter((item) => item.title.toLowerCase().includes(searchText.toLowerCase()))
                                .map((banner, i) => {
                                    return (<div
                                        className="img-container m-1 d-flex justify-content-center align-items-center"
                                        style={{
                                            width: "96px",
                                            height: "96px",
                                            maxWidth: "96px",
                                            minWidth: "96px",
                                            maxHeight: "96px",
                                            minHeight: "96px",
                                            border: "1px solid gray",
                                            cursor: "pointer",
                                        }}
                                        onClick={(e) => handleStickers(banner.attachment.includes('http') || banner.attachment.includes('https') ? banner.attachment : process.env.REACT_APP_apiBaseURL + '/' + banner.attachment, banner)}
                                        key={i}
                                    >
                                        <img
                                            className="m-1 cursor"
                                            src={banner.attachment.includes('http') || banner.attachment.includes('https') ? banner.attachment : process.env.REACT_APP_apiBaseURL + '/' + banner.attachment}
                                            style={{ width: "100%", height: "100%", objectFit: "contain" , backgroundColor:'#fff' }}
                                        />
                                    </div>);
                                })}
                        </div>
                    </> : (<></>)}
                </Tab>
                <Tab eventKey="profile" title="Creations">
                    <div className="col-md-12 d-flex justify-content-center flex-wrap my-4">
                        {loadingCreations ? (<div style={{ width: "100%" }}>
                            <PrinfulCategoryPlace />
                        </div>) : creations
                            ?.filter((item) => item.title.toLowerCase().includes(searchText.toLowerCase())).map((creation, index) => {
                                return (<img
                                    className="m-1 cursor"
                                    src={process.env.REACT_APP_apiBaseURL + '/' + creation.attachment}
                                    key={index}
                                    style={{
                                        width: "96px",
                                        height: "96px",
                                        maxWidth: "96px",
                                        minWidth: "96px",
                                        maxHeight: "96px",
                                        minHeight: "96px",
                                        border: "1px solid gray",
                                        cursor: "pointer",
                                        objectFit: "contain",
                                    }}
                                    onClick={(e) => handleStickers(creation.attachment.includes('http') || creation.attachment.includes('https') ? creation.attachment : process.env.REACT_APP_apiBaseURL + '/' + creation.attachment)}
                                />);
                            })}
                    </div>
                </Tab>
            </Tabs>
        </div>
    </div>);
};

export default Sidebar;
