import valid from "../assets/images/Group 918.svg";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import { getUserPrimaryAccount } from "../services/ProfileService";

const Card = () => {
  const [account, setPrimaryAccount] = useState(null);
  const location = useLocation();
  const { id } = useParams();

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user.user_name === id || user.id === id) {
      getPrimaryAccount();
    }
  }, [location]);

  const getPrimaryAccount = async () => {
    const response = await getUserPrimaryAccount();
    if (response.status === 200) {
      setPrimaryAccount(response.data.data);
      console.log(response.data.data)
    }
  }
  return (
    <>
      <div
        className="py-3"
        style={{ backgroundColor: "#F3F3F3 !important", borderRadius: "10px" }}
      >
        <div className="row">
          <div className="col-md-12 px-0">
            <div className="card p-2" style={{ border: "none !important" }}>
              <div className="card-title p-3 d-flex justify-content-between" >
                <h4 style={{ fontWeight: "700", marginBottom: "0px" }}>
                  Payment Method
                </h4>
                {/* <a className="under--line" style={{ TextDecoder: 'underline' }}> Edit</a> */}
              </div>
              {
                account !== null && (
                  <div className="card-body pt-0">
                    <div
                      className="bg-dark px-4 py-4 d-flex flex-column justify-content-center"
                      style={{ borderRadius: "10px", height: '192px' }}
                    >
                      <div className="d-flex ">
                        <input
                          type="text"
                          placeholder="1234 5678 9101 1213"
                          className="px-2 debit--card"
                          value={"**** **** **** " + account.last4}
                          style={{
                            height: "30px",
                            width: "100%",
                            border: "1px solid #ACACAC",
                            letterSpacing: "7px",
                            color: 'white'
                          }}
                          readOnly
                        />
                      </div>
                      <div className="d-flex justify-content-between align-items-center mt-3 ">
                        <input
                          type="text"
                          placeholder="Name"
                          className="px-2 debit--card"
                          value={account.name}
                          style={{
                            height: "30px",
                            width: "150px",
                            border: "1px solid #ACACAC",
                            color: 'white',
                            textTransform: 'capitalize'
                          }}
                          readOnly
                        />
                        <div className="gap-2">
                          <img src={valid} style={{ color: "white" }} />
                          <input
                            type="text"
                            placeholder="25/28"
                            className="px-2 debit--card"
                            value={(account.exp_month <= 10 ? "0" + account.exp_month : account.exp_month) + "/" + (account.exp_year.length === 4 ? account.exp_year.slice(-2) : account.exp_year)}
                            style={{
                              height: "30px",
                              width: "60px",
                              border: "1px solid #ACACAC",
                              color: 'white'
                            }}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="mt-3 d-flex justify-content-end">
                        <img src={"/assets/images/" + account.brand.toLowerCase() + ".svg"} style={{ width: '30px' }} />
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
