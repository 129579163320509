import axios from "axios";
import { clearSession, redirectToSuspend } from "./AuthService";
// import { useNavigate } from "react-router";

// const navigate = useNavigate();
const generateDeviceId = (len, an) => {
  an = an && an.toLowerCase();
  var str = "",
    i = 0,
    min = an == "a" ? 10 : 0,
    max = an == "n" ? 10 : 62;
  for (; i++ < len;) {
    var r = (Math.random() * (max - min) + min) << 0;
    str += String.fromCharCode((r += r > 9 ? (r < 36 ? 55 : 61) : 48));
  }

  localStorage.setItem("deviceId", str);
  return str;
};

const token = localStorage.getItem("token");
const deviceId = localStorage.getItem("deviceId") || generateDeviceId(12);

const api = axios.create({
  baseURL:
    process.env.REACT_APP_apiBaseURL ,
  headers: {
    Authorization: `Bearer ${token ? token : null}`,
    "Device-id": deviceId ? deviceId : null,
  },
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
            window.location.href = "/"
      // if (!error.request.responseURL.includes("fcm_token")) {v
      //   clearSession();
      // }

    }
    if (error.response?.status === 403 && error.response?.data.code === 205) {
      redirectToSuspend(error);
    }
    return Promise.reject(error);
  }
);

export const setAuthorizationHeader = (token) => {
  if (token) {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete api.defaults.headers.common.Authorization;
  }
};

export default api;
