import React from 'react';

function ResponsiveNavbar() {
  const navbarStyle = {
    backgroundColor: 'white',
    padding: '1rem',
    display: 'flex',
    justifyContent: 'right',
  };

  const buttonStyle = {
    backgroundColor: 'white',
    color: 'black',
    padding: '0.5rem 1rem',
    borderRadius: '999px',
    cursor: 'pointer',
  };


  const handleDownloadClick = () => {
    // Get the JSON object from localStorage
    const obj = JSON.parse(localStorage.getItem('CANVAS'));

    // Create a blob with the JSON data
    const jsonBlob = new Blob([JSON.stringify(obj)], { type: 'application/json' });

    // Create a URL for the blob
    const jsonBlobUrl = URL.createObjectURL(jsonBlob);

    // Create a download link
    const a = document.createElement('a');
    a.href = jsonBlobUrl;
    a.download = 'lego.json';

    // Trigger a click event on the link to start the download
    a.click();

    // Clean up by revoking the blob URL
    URL.revokeObjectURL(jsonBlobUrl);
  };

  return (
    <nav style={navbarStyle}>
      {/* Button on the right */}
      <button style={buttonStyle} onClick={handleDownloadClick}>Download JSON</button>
    </nav>
  );
}

export default ResponsiveNavbar;
