import { useEffect, useRef, useState } from "react";
import Spinner from "react-bootstrap/esm/Spinner";
import { Link, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { constants } from "../components/Constants/constants";
import DeleteAccount from "../components/modal/DeleteAccount";

import {
  DeleteProduct,
  getProducts,
  getProductsByUserId,
} from "../services/storeService";
import { HiOutlinePencil } from "react-icons/hi";
import { AiOutlinePlus } from "react-icons/ai";
import EditModal from "../components/modal/EditModal";
import { searchUsersProducts, updateStoreName } from "../services/storeService";
import { searchUnique } from "../services/SearchUnique";
import { Overlay, OverlayTrigger, Popover } from "react-bootstrap";
import { MdDone } from "react-icons/md";

const Store = () => {
  ///state management
  const [modalShow, setModalShow] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [payload, setPayload] = useState();
  const [deleteId, setDeleteId] = useState("");
  const [loader, setLoader] = useState(false);
  const [customizerId, setCutumizerId] = useState();
  const [paginateLinks, setPaginateLinks] = useState(null);
  const [editModalData, setEditModalData] = useState();
  const [pageIndex, setPageIndex] = useState(1);

  const [pageCount, setPageCount] = useState();
  const { id } = useParams();

  const location = useLocation();
  /// function called on page load
  useEffect(() => {
    getData(pageIndex);
  }, [location]);

  /// geting data from an API
  const getData = async (pageIndex, paginate) => {
    setLoader(true);
    if (id) {
      const response = await getProductsByUserId(id, pageIndex);
      if (response?.status === 200) {
        setPaginateLinks(response.data.links?.next);
        if (response.data.links?.next) {
          setPageIndex(pageIndex + 1);
        }
        setPageCount(response.data.meta);
        if (paginate === "paginate") {
          setPayload([...payload, ...response?.data?.data]);
        } else {
          setPayload(response.data.data);
        }
        setLoader(false);
      } else {
        toast.error(response.data.message);
        setLoader(false);
      }
    } else {
      const response = await getProducts(pageIndex);
      if (response?.status === 200) {
        setPaginateLinks(response.data.links?.next);
        if (response.data.links?.next) {
          setPageIndex(pageIndex + 1);
        }
        if (paginate === "paginate") {
          setPayload([...payload, ...response?.data?.data]);
        } else {
          setPayload(response.data.data);
        }
        setPageCount(response.data.meta);
        setLoader(false);
      } else {
        toast.error(response.data.message);
        setLoader(false);
      }
    }
  };

  /// calling delete API
  const deleteItem = async () => {
    const response = await DeleteProduct(deleteId);
    if (response?.status === 200) {
      toast.success("Product deleted");
      setModalShow(false);
      getData();
    } else {
      toast.error(response.data.message);
    }
  };

  /// search filter API
  const searchFilter = async (e) => {
    setLoader(true);
    setTimeout(async () => {
      const response = await searchUnique(
        id ? id : "",
        constants.products,
        e.target.value
      );
      if (response?.status === 200) {
        setPayload(response.data?.data);
        setPageCount(response.data.meta);
        setLoader(false);
      }
    }, 500);
  };

  const storeRef = useRef();
  const [storeLoader, setStoreLoader] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [storeTarget, setStoreTarget] = useState(null);
  const [storeName, setStoreName] = useState(JSON.parse(localStorage.getItem("user"))?.store_name || "");
  const handleStoreHide = (event) => {
    setShowOverlay(!showOverlay);
    setStoreTarget(event.target);
  }

  const handleStoreNameOnBlur = () => {
    setShowOverlay(!showOverlay);
    if (!storeLoader) {
      setStoreName(JSON.parse(localStorage.getItem("user"))?.store_name || "")
    }
  }

  const updateStore = async () => {
    setStoreLoader(true);
    const response = await updateStoreName({ name: storeName });
    if (response?.status === 200) {
      setShowOverlay(!showOverlay);
      toast.success("Store name updated!");
      let user = JSON.parse(localStorage.getItem("user"));
      user.store_name = storeName;
      localStorage.setItem("user", JSON.stringify(user));
    }
    setStoreLoader(false);
  }

  return (
    <>
      <div className="container mb-4 py-4 store">
        <div className="row my-4">
          <div className="col-md-12 d-flex justify-content-center">
            <h1
              className="d-flex g-2"
              style={{ textTransform: "uppercase", fontWeight: "700" }}
            >
              {storeName || "Store"}
              <HiOutlinePencil size={20} onClick={handleStoreHide} style={{ cursor: 'pointer' }} />
              <Overlay
                show={showOverlay}
                target={storeTarget}
                placement="right"
                container={storeRef.current}
                containerPadding={20}
                onHide={handleStoreNameOnBlur}
                rootClose={true}>
                <Popover id="popover-basic">
                  <Popover.Header as="h3">Store Name</Popover.Header>
                  <Popover.Body>
                    <div className="d-flex gap-2 align-items-center socialmedia--handler">
                      <input
                        type="text"
                        className="px-2"
                        placeholder="e.g martin's store"
                        value={storeName}
                        onChange={(e) => setStoreName(e.target.value)}
                        onKeyPress={(e) => {
                          if(e.key === 'Enter') updateStore();
                        }}
                        // onBlur={handleStoreNameOnBlur}
                      />

                      <button
                        className="save--btn"
                        onClick={updateStore}
                        disabled={storeLoader}
                        style={{opacity: storeLoader ? '0.5' : '1'}}
                      >
                        <MdDone style={{ color: "white" }} />
                      </button>
                    </div>
                  </Popover.Body>
                </Popover>
              </Overlay>
            </h1>
          </div>
        </div>
        <div className="row justify-content-center">
          <div
            className="col-md-4 d-flex justify-content-center align-items-center"
            style={{ position: "relative" }}
          >
            <input
              type="text"
              className="px-0"
              placeholder="Search your meme"
              style={{
                borderRadius: "0px",
                border: "none",
                borderBottom: "1px solid #242424",
                width: "100%",
              }}
              onChange={(e) => searchFilter(e)}
            />
            <img
              src="/assets/images/search.png"
              style={{
                position: "absolute",
                right: "20px",
                width: "26px",
                height: "26px",
              }}
              alt=""
            />
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <div className="col-md-12">
            <div className="d-flex justify-content-between align-items-center mt-5">
              {/* <p className="text text-danger">
                  
              </p> */}
              <p className="m-0" style={{ fontSize: "14px" }}>
              <p className="text text-danger">*Check latest preview(s) in few moments (~5 mints time) or open product page once.</p>
                Showing results from {pageCount?.to}-{pageCount?.per_page} of{" "}
                {pageCount?.total}
              </p>
              <Link
                to="/customizer"
                className="text-decoration-none px-3 py-2 d-flex justify-content-center align-items-center"
                style={{
                  border: "1px solid #242424",
                  borderRadius: "50px",
                  width: "258px",
                  height: "57px",
                  columnGap: "4px",
                }}
              >
                <AiOutlinePlus size={30} /> Create a new creation
              </Link>
            </div>
          </div>
        </div>

        <div className="row pt-2 pb-5 mb-3 justify-content-center ">
          <div className="col-md-12  px-0">
            <div className="row" style={{margin: "0"}}>
            {loader ? (
              <Spinner animation="border me-2" />
            ) : payload?.length === 0 ? (
              <h4 style={{ fontWeight: "700" }}>No data found</h4>
            ) : (
              payload?.map((item, index) => {
                return (
                  <div className="col-lg-3 col-sm-1 col-md-2 col-xs-1" style={{display :"flex",justifyContent:"center"}} key={index}>
                    <div className="card mt-4">
                      <div className="card-header d-flex justify-content-between align-items-center bg-transparent" style={{ padding:"1rem 1rem 0rem 1rem" }}>
                        <img
                          src="/assets/images/store/Group 908.svg"
                          style={{ width: "20px", height: "16px" }}
                          alt=""
                        />
                        {!id && (
                          <div>
                            <img
                              src="/assets/images/store/Group 910.svg"
                              className="me-2"
                              style={{
                                width: "20px",
                                height: "16px",
                                cursor: "pointer",
                              }}
                              alt=""
                              onClick={() => {
                                setCutumizerId(item.id);
                                setEditModal(true);
                                setEditModalData(item);
                              }}
                            />

                            <img
                              src="/assets/images/store/Group 522.svg"
                              style={{
                                width: "20px",
                                height: "16px",
                                cursor: "pointer",
                              }}
                              alt=""
                              onClick={() => {
                                setModalShow(true);
                                setDeleteId(item.id);
                              }}
                            />
                          </div>
                        )}
                      </div>
                      <Link to={`/product-detail/${item.id}`}>
                        <div className="card-body d-flex justify-content-center align-items-center p-0">
                          <img
                            src={
                                  (item.printful_product_id === 19 || item.printful_product_id === 300)
                                      ? (item.placement === "Center" && item.front_mockup !== null
                                          ? item.front_mockup
                                          : (item.placement === "Right" && item.right_mockup !== null
                                              ? item.right_mockup
                                              : (item.placement === "Left" && item.left_mockup !== null
                                                  ? item.left_mockup
                                                  : (item.image
                                                      ? process.env.REACT_APP_apiBaseURL + "/" + item.image
                                                      : "")
                                              )
                                          )
                                      )
                                      : (item.image
                                          ? process.env.REACT_APP_apiBaseURL + "/" + item.image
                                          : "")
                                }
                            alt=""
                          />
                        </div>
                      </Link>
                      <div className="card-footer mb-3 mx-0 px-0">
                        <div className="col-md-12 d-flex justify-content-between align-items-start">
                          <Link to={`/product-detail/${item.id}`} style={{ paddingLeft:"10px" }}>
                            <p
                              className="mb-0"
                              style={{
                                fontFamily: "RockoFLF Bold",
                                display: " inline-block",
                                width: "auto",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {item?.title}
                            </p>
                          </Link>
                          <p className="mb-0" style={{ paddingRight:"10px" }}>
                            <small>$</small> {item?.price+item?.printful_price}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>  
                );
              })
            )}
          </div>
        </div>
          {payload?.length === 0 ? (
            <Link
              to="/customizer"
              className="col-md-12 d-flex justify-content-center pt-4"
            >
              <button className="get-started">Customize</button>
            </Link>
          ) : paginateLinks !== null ? (
            <div className="col-md-12 d-flex justify-content-center pt-4">
              <button
                className="get-started"
                onClick={() => getData(pageIndex, "paginate")}
              >
                View more
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <DeleteAccount
        show={modalShow}
        onHide={() => setModalShow(false)}
        message="You want to delete this Item!"
        deleteId={deleteId}
        deletePost={deleteItem}
      />
      <EditModal
        show={editModal}
        editModalData={editModalData}
        onHide={() => setEditModal(false)}
        customizerId={customizerId}
        setEditModalData={setEditModalData}
        setPayload={setPayload}
      />
    </>
  );
};

export default Store;
