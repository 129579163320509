import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/esm/Spinner";
import { BiDollar } from "react-icons/bi";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import {
    addProduct,
    convertToFormData,
    updateProduct,
} from "../../services/customizerService";

import { useSnapshot } from 'valtio'
import { state } from "../../valtio";


const CartModal = (props) => {

    const domProps = Object.assign({}, props);
    delete domProps.setMode;
    delete domProps.fileData;
    delete domProps.canvasState;
    delete domProps.selectedProduct;
    delete domProps.printfulPrice;
    const snap = useSnapshot(state);

    const [data, setData] = useState({
        type: props.mode === "creation" ? 0 : 1, product: "", title: "", price: 0,
    });
    const [validation, setValidation] = useState({
        radioBtn: "", price: "",
    });
    const [showLoading, setShowLoading] = useState(false);

    const navigate = useNavigate();

   
    const submitData = async () => {
        if (data.title === "") {
            setValidation((validation) => ({
                ...validation, title: "title is required",
            }));
            return;
        }
        if (data.type === "") {
            setValidation((validation) => ({
                ...validation, radioBtn: "meme/product is required",
            }));
            return;
        }
        if (data.price === "") {
            setValidation((validation) => ({
                ...validation, price: "price is required",
            }));
            return;
        }

        setShowLoading(true);
        let localCanvas = localStorage.getItem("CANVAS");
            const type = 'image/png';
            const blob = new Blob([snap.mockBuffer], {type});
            let pngImageFile = new File([blob], "waheed", {lastModified: new Date().getTime(), type});
            
        let finalData = {
            type: parseInt(data.type),
            price: parseFloat(data.price),
            image: props?.fileData,
            creation_image: pngImageFile,
            title: data.title,
            canvas: localCanvas,
            printful_product_id: props?.printfulPrice?.product_id || 0 ,
            printful_price: props?.printfulPrice?.price || 0
        };
        if (props.product !== null) {
            finalData._method = "PUT";
        }

        let formData = convertToFormData(finalData);
        
        if (props.product === null) {
            const response = await addProduct(formData);
            if (response.status === 201) {
                toast.success((data.type === 0 ? "Meme" : "Product") + " published !");
                props.onHide();
                localStorage.removeItem("CANVAS");
                localStorage.removeItem("CANVAS_UPDATED_AT");
                navigate("/store");
                setShowLoading(false);
            } else {
                toast.error(response.data.message);
                setShowLoading(false);
            }
        } else {
            const response = await updateProduct(props.product.id, formData);
            if (response.status === 200) {
                toast.success((data.type === 0 ? "Meme" : "Product") + " updated !");
                props.onHide();
                localStorage.removeItem("CANVAS");
                localStorage.removeItem("CANVAS_UPDATED_AT");
                navigate("/store");
                setShowLoading(false);
            } else {
                toast.error(response.data.message);
                setShowLoading(false);
            }
        }
    };

    useEffect(() => {
        if (props.product !== null) {
            setData((state) => ({
                ...state, type: props.product.type, title: props.product.title, price: props.product.price,
            }));
        }
    }, [props.product]);
    return (<>
        <Modal
            {...domProps}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header className="justify-content-center">
                <div className="wrapper w-100 d-flex justify-content-between align-items-center">
                    <h2
                        className="modal-title mt-0"
                        id="exampleModalLabel"
                        style={{ fontFamily: "RockoFLF Bold", fontSize: "16px" }}
                    >
                        {" "}
                        Save to Store
                    </h2>
                </div>
            </Modal.Header>
            <div
                className="d-flex justify-content-between align-items-baseline "
                style={{ width: "100%", padding: "0px 20px", marginTop: "10px" }}
            >
                <div>
                    <h5
                        className="my-0"
                        style={{ fontSize: "14px", fontWeight: "500" }}
                    >
                        Title{" "}
                    </h5>
                </div>

                <div
                    className="d-flex justify-content-between align-items-center   "
                    style={{
                        border: "1px solid #242424",
                        borderRadius: "50px",
                        fontSize: "14px",
                        width: "140px",
                        height: "30px",
                    }}

                >
                    <input
                        className="px-2 py-1 "
                        id="modal"
                        style={{
                            backgroundColor: "white ",
                            width: "120px",
                            height: "20px",
                            marginLeft: "10px",
                            border: "none",
                        }}
                        type="text"
                        value={data.title}
                        onChange={(e) => {
                            const c = { ...data };
                            c.title = e.target.value;
                            setData(c);
                        }}
                    />
                </div>
            </div>
            <div className="text-center">
                {validation.title !== "" && (<span className="text-danger">{validation.title}</span>)}
            </div>
            <div
                className="d-flex justify-content-between align-items-center"
                style={{ width: "100%", padding: "10px 20px" }}
            >
                <div>
                    <h5
                        className="my-0"
                        style={{ fontSize: "14px", fontWeight: "500" }}
                    >
                        Save As
                    </h5>
                </div>
                <div className="d-flex" style={{ columnGap: "10px" }}>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                            value="0"
                            checked={data.type.toString() === "0" ? true : false}
                            disabled
                        // onChange={(e) => {
                        //     const c = {...data};
                        //     c.type = e.target.value;
                        //     setData(c);
                        // }}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault1"
                            style={{ fontSize: "14px", fontWeight: "500" }}
                        >
                            Creation
                        </label>
                    </div>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault2"
                            value="1"
                            checked={data.type.toString() === "1" ? true : false}
                            disabled
                        // onChange={(e) => {
                        //     const c = {...data};
                        //     c.type = e.target.value;
                        //     setData(c);
                        // }}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault2"
                            style={{ fontSize: "14px", fontWeight: "500" }}
                        >
                            Product
                        </label>
                    </div>
                </div>
            </div>
            <div className="text-center">
                {validation.radioBtn !== "" && (<span className="text-danger">{validation.radioBtn}</span>)}
            </div>

            <Modal.Body style={{ background: "#FFFFFF", borderRadius: "3px" }}>
                <div
                    className="d-flex justify-content-between align-items-baseline "
                    style={{ width: "100%", padding: "0px 20px" }}
                >
                    <div>
                        <h5
                            className="my-0"
                            style={{ fontSize: "14px", fontWeight: "500" }}
                        >
                            Price{" "}
                        </h5>
                    </div>

                    <div
                        className="d-flex justify-content-between align-items-center   "
                        style={{
                            border: "1px solid #242424",
                            borderRadius: "50px",
                            fontSize: "14px",
                            width: "140px",
                            height: "30px",
                        }}
                    >
                        <BiDollar size={15} />
                        <input
                            className="px-2 py-1 input__number "
                            id="modal"
                            style={{
                                backgroundColor: "white ",
                                width: "120px",
                                height: "20px ",
                                border: "none",
                                outline: "none",
                                marginRight: "1px",
                            }}
                            type="number"
                            value={data.price}
                            onChange={(e) => {
                                const c = { ...data };
                                c.price = e.target.value;
                                setData(c);
                            }}
                        />
                    </div>
                </div>
                <div className="text-center">
                    {validation.price !== "" && (<span className="text-danger">{validation.price}</span>)}
                </div>


                {data.type.toString() === "1" && (<div
                    className="d-flex justify-content-between align-items-baseline "
                    style={{ width: "100%", padding: "0px 20px", marginTop: "10px" }}
                >
                    <div>
                        <h5
                            className="my-0"
                            style={{ fontSize: "14px", fontWeight: "500" }}
                        >
                            Printful Price{" "}
                        </h5>
                    </div>

                    <div
                        className="d-flex justify-content-between align-items-center   "
                        style={{
                            border: "1px solid #242424",
                            borderRadius: "50px",
                            fontSize: "14px",
                            width: "140px",
                            height: "30px",
                        }}
                    >
                        <BiDollar size={15} />
                        <input
                            className="px-2 py-1 input__number "
                            id="modal"
                            style={{
                                backgroundColor: "white ",
                                width: "120px",
                                height: "20px ",
                                border: "none",
                                outline: "none",
                                marginRight: "1px",
                                cursor: "default",
                            }}
                            type="number"
                            value={props.printfulPrice?.price}
                            disabled

                        />
                    </div>
                </div>)}

                <div
                    className=" d-flex justify-content-center my-3"
                    style={{ columnGap: "10px" }}
                >
                    <button
                        className="px-4 py-1 d-flex justify-content-center align-items-center button-modal"
                        style={{
                            background: "#242424",
                            color: "#ffff",
                            fontSize: "14px",
                            border: "1px solid #242424",
                            borderRadius: "50px",
                            fontWeight: "500",
                            width: "38%",
                        }}
                        disabled={showLoading}
                        onClick={submitData}
                    >
                        {showLoading && (<Spinner
                            animation="border me-2"
                            style={{ width: "15px", height: "15px" }}
                        />)}
                        {props.product === null ? "Save" : "Update"}
                    </button>
                    <button
                        className="px-4 py-1 d-flex justify-content-center align-items-center button-modal"
                        style={{
                            fontSize: "14px",
                            border: "1px solid #242424",
                            borderRadius: "50px",
                            backgroundColor: "white",
                            fontWeight: "500",
                            width: "38%",
                        }}
                        data-dismiss="modal"
                        onClick={props.onHide}
                    >
                        Cancel
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    </>);
};

export default CartModal;
