import api from "./api";
import axios from "axios";

export const login = async (body) => {
  try {
    const response = await api.post("/api/v1/login", body);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const loginWithGoogle = async (body) => {
  try {
    const response = await api.post("/api/v1/login_with_google", body);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const loginWithFacebook = async (body) => {
  try {
    const response = await api.post("/api/v1/login_with_facebook", body);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const signup = async (body) => {
  try {
    const response = await api.post("api/v1/register", body);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getCountries = async () => {
  try {
    const response = await api.get("api/v1/countries");
    return response;
  } catch (error) {
    return error.response;
  }
};
export const logout = async () => {
  try {
    const response = await api.get("/api/v1/logout");
    return response;
  } catch (error) {
    return error.response;
  }
};

export const forgotPasswordApi = async (body) => {
  try {
    const response = await api.post(`/api/v1/forgot_password`, body);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const ResetPasswordApi = async (body) => {
  const headers = {
    Accept: "application/json",
    "Content-type": "application/json",
  };

  let baseURL =
    process.env.REACT_APP_apiBaseURL || "https://api.stickfigures.com";

  try {
    const response = await axios.post(
      `${baseURL}/api/v1/reset_password`,
      body,
      {
        headers: headers,
      }
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const contactUs = async (body) => {
  try {
    const response = await api.post(`/api/v1/contact_us`, body);
    return response;
  } catch (error) {
    return error.response;
  }
};

// export const clearSession = async () => {
//   if (localStorage.getItem("token")) {
//     localStorage.removeItem("token");
//     localStorage.removeItem("user");
//     localStorage.removeItem("FCM_TOKEN");
//     localStorage.removeItem("cartCount");
//     localStorage.removeItem("productsData");
//     localStorage.removeItem("categoryData");
//     localStorage.removeItem("printfulAuthorized");
//     localStorage.removeItem("printfulToken");
//     localStorage.removeItem("productsData_loaded_time");
//     localStorage.removeItem("categoryData_loaded_time");
//     window.location.href = "/login";
//     throw new Error("Redirecting to a different page...");
//   }
// };

export const redirectToSuspend = () => {
  var currentRoute = window.location.pathname;
  if (currentRoute !== '/account_suspended') {
    window.location.href = '/account_suspended';
    throw new Error("Redirecting to a different page...");
  }
};