import {useEffect, useState} from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Slider from 'react-slick';
import media from "./media.json";

const Sidebar = () => {
    const settings = {
        dots: false, infinite: true, speed: 300, slidesToShow: 3, slidesToScroll: 3
    };

    const settings1 = {
        dots: false, infinite: true, speed: 300, slidesToShow: 2, slidesToScroll: 1
    };

    const payload = JSON.parse(JSON.stringify(media)) || [];

    const getStickers = (sticker = "stickfigure") => {
        const productBanners = payload.filter(item => item.sticker === sticker);
        return productBanners;
    }

    const [stickfigureCategories, setStickfigureCategories] = useState(getStickers("stickfigure"));
    const [products, setProducts] = useState(getStickers("products"));
    const [scribles, setScribles] = useState(getStickers("elements"));

    const [selectedStickfigureAction, setSelectedStickfigureAction] = useState(stickfigureCategories[0]?.type);
    const [selectedProductAction, setSelectedProductAction] = useState(products[0]?.type);
    const [selectedScribleAction, setSelectedScribleAction] = useState(scribles[0]?.type);

    const handleTabAction = (sticker, type = "heads") => {

        if (sticker === "stickfigure") {
            setSelectedStickfigureAction(type);
        } else if (sticker === "products") {
            setSelectedProductAction(type);
        } else if (sticker === "elements") {
            setSelectedScribleAction(type);
        }
    }

    return (
        <div className="col-md-3 py-4 "
             style={{
                 backgroundColor: '#F3F3F3',
                 maxWidth: '353px',
                 paddingRight: "0px",
                 paddingLeft: "0px",
                 maxHeight: "666px",
                 minHeight: "666px",
                 overflowY: "auto",
                 overflowX: "hidden"
             }}>

            <div className="container mb-3">
                {/* <h1>Explore</h1> */}
                <div className="wrapper d-flex align-items-center my-3" style={{position: 'relative'}}>
                    <input type="text" id="search" placeholder="Search here"
                           style={{width: '100%', borderRadius: '30px', height: '49px'}}/>
                    <img src="/assets/images/search.png" alt="" style={{position: "absolute", right: "20px"}}/>
                </div>
            </div>
            <div className="tab-content product-li-slick" id="myTabContent"
                 style={{overflowY: "auto", backgroundColor: "#D9D9D9"}}>
                <Tabs
                    defaultActiveKey="home"
                    id="fill-tab-example"
                    className="mb-3"
                    fill
                >
                    <Tab eventKey="home" title="Stickfigures">
                        <div className="container product-li-slick">
                            {stickfigureCategories.length > 0 ? <Slider {...settings}>
                                {stickfigureCategories.map((item, i) => {
                                    return <div
                                        className={"d-flex justify-content-center align-items-center product-wrapper " + (selectedStickfigureAction === item.type ? 'bg-dark' : ' ')}
                                        key={i}>
                                        <a href="#"
                                           className={'text-black ' + (selectedStickfigureAction === item.type ? 'text-white' : '')}
                                           onClick={() => {
                                               handleTabAction(item.sticker, item.type)
                                           }}>{item.type}</a>
                                    </div>
                                })} </Slider> : <></>}
                        </div>
                        <div className="col-md-12 d-flex justify-content-center flex-wrap">
                            {stickfigureCategories.filter(item => item.type === selectedStickfigureAction)[0]?.banners.map((banner, i) => {
                                return (
                                    <div className='img-container m-1 d-flex justify-content-center align-items-center'
                                         style={{
                                             width: '96px',
                                             height: '96px',
                                             maxWidth: '96px',
                                             minWidth: '96px',
                                             maxHeight: '96px',
                                             minHeight: '96px',
                                             border: '1px solid gray'
                                         }}>
                                        <img className="m-1 cursor" src={banner}
                                             style={{width: '100%', height: '100%', backgroundColor:'#fff'}}/>
                                    </div>);
                            })}
                        </div>
                    </Tab>
                    <Tab eventKey="profile" title="Creations">
                        <div className="col-md-12 d-flex justify-content-center flex-wrap my-4">
                            <img className="m-1 cursor" src="/assets/images/Group 897.png"/>
                            <img className="m-1 cursor" src="/assets/images/Group 897.png"/>
                            <img className="m-1 cursor" src="/assets/images/Group 897.png"/>
                            <img className="m-1 cursor" src="/assets/images/Group 897.png"/>
                            <img className="m-1 cursor" src="/assets/images/Group 897.png"/>
                            <img className="m-1 cursor" src="/assets/images/Group 897.png"/>
                            <img className="m-1 cursor" src="/assets/images/Group 897.png"/>
                            <img className="m-1 cursor" src="/assets/images/Group 897.png"/>
                            <img className="m-1 cursor" src="/assets/images/Group 897.png"/>
                            <img className="m-1 cursor" src="/assets/images/Group 897.png"/>
                            <img className="m-1 cursor" src="/assets/images/Group 897.png"/>
                            <img className="m-1 cursor" src="/assets/images/Group 897.png"/>
                        </div>
                    </Tab>
                </Tabs>
            </div>
            <h1 className="products p-4 mb-0">Products</h1>
            <div className="container product-li-slick">
                {products.length > 0 ? <Slider {...settings}>
                    {products.map((item, i) => {
                        return (<div
                            className={"d-flex justify-content-center align-items-center product-wrapper " + (selectedProductAction === item.type ? 'bg-dark' : ' ')}
                            key={i}>
                            <a href="#"
                               className={'text-black ' + (selectedProductAction === item.type ? 'text-white' : '')}
                               onClick={() => {
                                   handleTabAction(item.sticker, item.type)
                               }}>{item.type}</a>
                        </div>)
                    })} </Slider> : <></>}
            </div>
            <div className="col-md-12 d-flex justify-content-center flex-wrap mb-2">
                {products.filter(item => item.type === selectedProductAction)[0]?.banners.map((banner, i) => {
                    return (<div className='img-container m-1 d-flex justify-content-center align-items-center' style={{
                        width: '96px',
                        height: '96px',
                        maxWidth: '96px',
                        minWidth: '96px',
                        maxHeight: '96px',
                        minHeight: '96px',
                        border: '1px solid gray'
                    }}>
                        <img className="m-1 cursor" src={banner} style={{width: '100%', height: '100%' , backgroundColor:'#fff'}}/>
                    </div>);
                })}
            </div>
            <h1 className="products p-4 mb-0">Purchased Creations</h1>
            <div className="col-md-12 d-flex justify-content-center flex-wrap mb-2">
                <img className="m-1 cursor" style={{width: '96px'}} src="/assets/images/Group 897.png"/>
                <img className="m-1 cursor" style={{width: '96px'}} src="/assets/images/Group 897.png"/>
                <img className="m-1 cursor" style={{width: '96px'}} src="/assets/images/Group 897.png"/>
                <img className="m-1 cursor" style={{width: '96px'}} src="/assets/images/Group 897.png"/>
                <img className="m-1 cursor" style={{width: '96px'}} src="/assets/images/Group 898.png"/>
                <img className="m-1 cursor" style={{width: '96px'}} src="/assets/images/Group 897.png"/>
                <img className="m-1 cursor" style={{width: '96px'}} src="/assets/images/Group 898.png"/>
                <img className="m-1 cursor" style={{width: '96px'}} src="/assets/images/Group 897.png"/>
                <img className="m-1 cursor" style={{width: '96px'}} src="/assets/images/Group 898.png"/>
                <img className="m-1 cursor" style={{width: '96px'}} src="/assets/images/Group 897.png"/>
                <img className="m-1 cursor" style={{width: '96px'}} src="/assets/images/Group 897.png"/>
                <img className="m-1 cursor" style={{width: '96px'}} src="/assets/images/Group 897.png"/>
            </div>
            <h1 className="products p-4 mb-0">Elements</h1>
            <div className="container product-li-slick">
                {scribles.length > 0 ? <Slider {...settings1}>
                    {scribles.map((item, i) => {
                        return (<div
                            className={"d-flex justify-content-center align-items-center product-wrapper " + (selectedScribleAction === item.type ? 'bg-dark' : ' ')}
                            key={i}>
                            <a href="#"
                               className={'text-black ' + (selectedScribleAction === item.type ? 'text-white' : '')}
                               onClick={() => {
                                   handleTabAction(item.sticker, item.type)
                               }}>{item.type}</a>
                        </div>)
                    })} </Slider> : <></>}
            </div>
            <div className="col-md-12 d-flex justify-content-center flex-wrap mb-2 pb-4">
                {scribles.filter(item => item.type === selectedScribleAction)[0]?.banners.map((banner, i) => {
                    return (<div className='img-container m-1 d-flex justify-content-center align-items-center' style={{
                        width: '96px',
                        height: '96px',
                        maxWidth: '96px',
                        minWidth: '96px',
                        maxHeight: '96px',
                        minHeight: '96px',
                        border: '1px solid gray'
                    }}>
                        <img className="m-1 cursor" src={banner} style={{width: '100%', height: '100%' , backgroundColor:'#fff'}}/>
                    </div>);
                })}
            </div>
        </div>);
}

export default Sidebar