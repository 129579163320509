import React from 'react'

const NewSocialMedia = ({ data }) => {
    return (
  <>
  {
    data?.data?.map((i,index) => {
        if(i.type === "socialMedia"){
            return(
                <div className='main--container' key={index}>
            <div className='container d-flex justify-content-center'>
                <div className='my-5 w-75'>
                    <h1 className='text-center'>{i.heading}</h1>
                    <p className='text-center m-0 '>{i.description}</p>
                </div>

            </div>
        </div>
            )
        }
    })
  }


        
        </>
    )
}

export default NewSocialMedia