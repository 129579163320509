import Card from "../Card";
import SocialMediaHandler from "../SocialMediaHandler";
import DeliveryOption from "../DeliveryOption";
import UserInfo from "./UserInfo";
import { useEffect, useRef, useState } from "react";
import { HiOutlinePencil } from "react-icons/hi";
import { MdDownloadDone } from "react-icons/md";
import { RxDotsVertical } from "react-icons/rx";
import { toast } from "react-toastify";
import ImageUploadModal from "../modal/ImageUploadModal";
import { Dropdown } from "react-bootstrap";
import { DeleteAddress, updateProfile } from "../../services/ProfileService";
import { getCountries } from "../../services/ProfileService";
import { getAddress } from "../../services/ProfileService";
import { addNewAddress } from "../../services/ProfileService";
import { UpdateAddress } from "../../services/ProfileService";
import { logout } from "../../services/AuthService";
import { useNavigate } from "react-router";
import { State, City } from "country-state-city";
import { Link } from "react-router-dom";

const Sidebar = ({ profileData, id, setPostsData, account }) => {
    const navigate = useNavigate();
    const [modalShow, setModalShow] = useState(false);
    const [imageData, setImagData] = useState();
    const [countriesData, setCountriesData] = useState([]);
    const [address, setAddress] = useState([]);
    const [editId, setEditId] = useState();
    const [authUser, setAuthUser] = useState(JSON.parse(localStorage.getItem("user")));
    const [updateButton, setUpdateButton] = useState(false);
    const [states, setStates] = useState(State.getStatesOfCountry("US"));
    const [cities, setCities] = useState([]);

    const formRef = useRef(null);

    const [validation, setValidation] = useState({
        address: "",
        country_id: "",
        state: "",
        state_code: "",
        pincode: "",
        city: "",
    });

    const [newAddress, setNewAddress] = useState({
        address: "",
        country_id: "NTcrZjRCd2hCajgvRFpxVXpzd2N5QT09", // United States
        state: "",
        state_code: "default",
        pincode: "",
        city: "",
    });


    useEffect(() => {
        setData(profileData);
        getCountryData();
    }, [profileData]);

    useEffect(() => {
        getCountryData();
        getAddresses();
    }, []);

    useEffect(() => {
        if (newAddress.state_code) {
            loadUsCities(newAddress.state_code);
        }
    }, [newAddress.state_code]);

    const loadUsCities = (state) => {
        setCities(City.getCitiesOfState("US", state));
    }

    const getAddresses = async () => {
        const response = await getAddress();

        if (response.status === 200) {
            setAddress(response.data.data);
        }
    };

    const [data, setData] = useState();

    const [profileStatus, setProfileStatus] = useState({
        bioStatus: false,
        DateOfBirth: false,
        Hobbies: false,
        LivesIn: false,
        Language: false,
    });

    const bioSubmit = async () => {
        let finalData = {
            bio: data.bio,
        };
        setProfileStatus((data) => ({
            ...data,
            bioStatus: false,
        }));
        const response = await updateProfile(finalData);

        if (response.status === 200) {
            localStorage.setItem('user', JSON.stringify(response.data.data));
        }
    };

    const editProfileStatus = () => {
        setProfileStatus((data) => ({
            ...data,
            Hobbies: true,
            LivesIn: true,
            Language: true,
            DateOfBirth: true,
        }));
    };

    const getCountryData = async () => {
        const response = await getCountries();

        if (response.status === 200) {
            setCountriesData(response.data.data);
        }
    };

    const otherDataSubmit = async () => {
        let finalData = {
            dob: data.dob,
            hobbies: data.hobbies,
            country_id: data.country.id,
        };
        const response = await updateProfile(finalData);

        if (response.status === 200) {
            localStorage.setItem('user', JSON.stringify(response.data.data));
        }
        setProfileStatus((data) => ({
            ...data,
            Hobbies: false,
            LivesIn: false,
            Language: false,
            DateOfBirth: false,
        }));
    };

    const submitAddress = async (e, i) => {
        e.preventDefault();

        if (newAddress.country_id === "") {
            validation.country_id = "";
            setValidation((validation) => ({
                ...validation,
                country_id: "Country is required",
            }));
            return;
        } else {
            setValidation((validation) => ({
                ...validation,
                country_id: "",
            }));
        }

        if (newAddress.state === "") {
            setValidation((validation) => ({
                ...validation,
                state: "State is required",
            }));
            return;
        } else {
            setValidation((validation) => ({
                ...validation,
                state: "",
            }));
        }

        if (newAddress.state.length < 5) {
            setValidation((validation) => ({
                ...validation,
                state: "State must be at least 5 letters",
            }));
            return;
        }

        if (newAddress.city === "") {
            setValidation((validation) => ({
                ...validation,
                city: "city is required",
            }));
            return;
        } else {
            setValidation((validation) => ({
                ...validation,
                city: "",
            }));
        }
        if (newAddress.city.length < 5) {
            setValidation((validation) => ({
                ...validation,
                city: "City must be at least 5 letters",
            }));
            return;
        }

        if (newAddress.pincode === "") {
            setValidation((validation) => ({
                ...validation,
                pincode: "Pin-code is required",
            }));
            return;
        } else {
            setValidation((validation) => ({
                ...validation,
                pincode: "",
            }));
        }
        if (newAddress.pincode.length < 5) {
            setValidation((validation) => ({
                ...validation,
                pincode: "City must be at least 5 number",
            }));
            return;
        }

        if (newAddress.address === "") {
            setValidation((validation) => ({
                ...validation,
                address: "Address is required",
            }));
            return;
        } else {
            setValidation((validation) => ({
                ...validation,
                address: "",
            }));
        }

        if (newAddress.address.length < 20) {
            setValidation((validation) => ({
                ...validation,
                address: "Address must be at least 20 letters",
            }));
            return;
        }

        if (i === "updateAddress") {
            const response = await UpdateAddress(newAddress, editId);
            if (response.status === 200) {
                setNewAddress({
                    address: "",
                    country_id: "",
                    state: "",
                    pincode: "",
                    city: "",
                });
                getAddresses();
                formRef.current.querySelectorAll("input, textarea").forEach((input) => {
                    input.value = "";
                });
            } else {
                toast.error(response.data.message);
            }
        } else {
            const response = await addNewAddress(newAddress);
            if (response.status === 201) {
                setNewAddress({
                    address: "",
                    country_id: "",
                    state: "",
                    pincode: "",
                    city: "",
                });
                formRef.current.querySelectorAll("input, textarea").forEach((input) => {
                    input.value = "";
                });
                getAddresses();
            } else {
                toast.error(response.data.message);
            }
        }
    };

    const setTheValue = (i) => {
        setEditId(i.id);
        setNewAddress((prev) => ({
            ...prev,
            address: i.address,
            country_id: i.country.id,
            state: i.state,
            state_code: i.state_code,
            pincode: i.pincode,
            city: i.city
        }));
        setUpdateButton(true);
    };

    const removeAddress = async (e) => {
        e.preventDefault();

        const response = await DeleteAddress(editId);

        if (response.status === 200) {
            setAddress(address.filter((i) => i.id !== editId));

            setNewAddress({
                address: "",
                country_id: "",
                state: "",
                state_code: "",
                pincode: "",
                city: "",
            });

            formRef.current.querySelectorAll("input, textarea").forEach((input) => {
                input.value = "";
            });

            setUpdateButton(false);
            toast.success(response.data.message);
        } else {
            toast.error(response.data.message);
        }
    };

    /// logout handler`
    const handleLogout = async () => {
        const response = await logout();
        if (response.status === 200) {
            toast.success(response.data.message);
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            localStorage.removeItem("FCM_TOKEN");
            localStorage.removeItem("cartCount");
            localStorage.removeItem("productsData");
            localStorage.removeItem("categoryData");
            localStorage.removeItem("printfulAuthorized");
            localStorage.removeItem("printfulToken");
            localStorage.removeItem("productsData_loaded_time");
            localStorage.removeItem("categoryData_loaded_time");
            localStorage.removeItem("printfulAuthorized");
            localStorage.removeItem("CANVAS");
            localStorage.removeItem("epr_suggested");
            localStorage.removeItem("followerCount");
            localStorage.removeItem("CANVAS_UPDATED_AT");
            localStorage.removeItem("deviceId");
            window.location.href = "/login";
        } else {
            // console.log(response.data.message);
        }
    };


    return (
        <>
            <div
                className="col-md-4 sidebar--address"
                style={{ position: "relative", top: "-125px" }}
            >
                <div
                    className="col-md-12"
                    style={{
                        backgroundColor: "#F3F3F3 !important",
                        borderRadius: "10px",
                    }}
                >
                    <div className="row">
                        <div
                            className="slider--body card w-100"
                            style={{ border: "none", position: "relative" }}
                        >
                            {(authUser.user_name === id || authUser.id === id) && (
                                <Dropdown
                                    className="position-absolute "
                                    style={{ right: "10px", top: "10px" }}
                                >
                                    <Dropdown.Toggle
                                        className="btn btn-secondary three--dots"
                                        id="dropdown-basic"
                                    >
                                        <RxDotsVertical size={20} />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => editProfileStatus()}>
                                            Edit Profile
                                        </Dropdown.Item>
                                        <Dropdown.Item>Go to Customizer</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            )}

                            <div className="card-body text-center">
                                <label
                                    htmlFor="uploadImage"
                                    style={{ position: "relative" }}
                                    className="banner-container d-flex justify-content-center"
                                >
                                    {(authUser.user_name === id || authUser.id === id) ? (
                                        <img
                                            className="d-flex justify-content-center"
                                            src="/assets/images/choose_image.png"
                                            style={{
                                                position: "absolute",
                                                top: "70%",
                                                right: "23%",
                                                cursor: "pointer",
                                            }}
                                            alt=""
                                            title="Go to Creator"
                                            onClick={() => navigate("/customizer")}
                                        />
                                    ) : (
                                        ""
                                    )}

                                    {imageData ? (
                                        <img
                                            className="d-flex justify-content-center mt-4"
                                            src={imageData}
                                            style={{
                                                width: "195px",
                                                height: "195px",
                                                borderRadius: "50%",
                                                objectFit: "cover",
                                            }}
                                            alt=""
                                        />
                                    ) : profileData?.photo ? (
                                        <img
                                            className="d-flex justify-content-center mt-4"
                                            src={
                                                profileData?.photo.includes("http") ? profileData?.photo : process.env.REACT_APP_apiBaseURL +
                                                    "/" +
                                                    profileData.photo
                                            }
                                            style={{
                                                width: "200px",
                                                height: "200px",
                                                borderRadius: "50%",
                                                objectFit: "cover",
                                            }}
                                            alt=""
                                        />
                                    ) : (
                                        <div className="spinner-border m-5 p-5" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    )}
                                </label>

                                <h1
                                    style={{
                                        fontFamily: "My Font",
                                        fontSize: "20px",
                                        fontWeight: "700",
                                    }}
                                >
                                    {profileData?.name ? profileData?.name : authUser?.name}
                                </h1>
                                <p>
                                    {profileData?.user_name ? "@" + profileData?.user_name : ""}
                                </p>
                                {(authUser.user_name === id || authUser.id === id) && (
                                    <div className="d-flex justify-content-center mb-2 ">
                                        <div className="edit--icons d-flex justify-content-center align-items-center">
                                            <>
                                                {profileStatus.bioStatus ? (
                                                    <MdDownloadDone
                                                        onClick={() => bioSubmit()}
                                                        style={{ cursor: "pointer" }}
                                                    />
                                                ) : (
                                                    <HiOutlinePencil
                                                        onClick={() => {
                                                            setProfileStatus((data) => ({
                                                                ...data,
                                                                bioStatus: true,
                                                            }));
                                                        }}
                                                        style={{ cursor: "pointer" }}
                                                    />
                                                )}
                                            </>
                                        </div>
                                    </div>
                                )}

                                {profileStatus.bioStatus ? (
                                    <h6 style={{ fontSize: "16px" }}>
                                        <input
                                            className="w-100 px-3"
                                            type="text"
                                            value={data?.bio}
                                            onChange={(e) => {
                                                let c = { ...data };
                                                c.bio = e.target.value;
                                                setData(c);
                                            }}
                                        />
                                    </h6>
                                ) : (
                                    <h6 style={{ fontSize: "16px" }}> {data?.bio} </h6>
                                )}
                            </div>
                            {/* Mobile profile Nav */}
                            <ul className="list-group list-group-flush info-list" id="mobile-profile-nav">
                                <li className="list-group-item">
                                    <Link to="/store" className="mobile-profile-links">
                                        <img style={{ cursor: "pointer" }}
                                            src="/assets/images/icons/store.svg"
                                            alt=""
                                        />
                                       Store
                                    </Link>
                                </li>
                                <li className="list-group-item">
                                    <Link to="/wallet" className="mobile-profile-links">
                                        <img style={{ cursor: "pointer" }}
                                            src="/assets/images/icons/Wallet.svg"
                                            alt=""
                                        />
                                       Wallet
                                    </Link>
                                </li>
                                <li className="list-group-item">
                                    <Link to="/payments" className="mobile-profile-links">
                                        <img style={{ cursor: "pointer",width:"17px" }}
                                            src="/assets/images/Payment.svg"
                                            alt=""
                                        />
                                       Payment
                                    </Link>
                                </li>
                                <li className="list-group-item">
                                    <Link to="/setting" className="mobile-profile-links">
                                        <img style={{ cursor: "pointer" }}
                                            src="/assets/images/icons/Settings.svg"
                                            alt=""
                                        />
                                       Settings
                                    </Link>
                                </li>
                                <li className="list-group-item">
                                    <Link  onClick={handleLogout}  className="mobile-profile-links">
                                        <img style={{ cursor: "pointer",width:"17px" }}
                                            src="/assets/images/icons/logout.png"
                                            alt=""
                                        />
                                       Logout
                                    </Link>
                                </li>
                            </ul>
                            {/* <UserInfo /> */}
                            <>
                                <ul className="list-group list-group-flush info-list">
                                    {profileStatus.DateOfBirth ? (
                                        <div className="w-100 d-flex justify-content-center my-2  ">
                                            <MdDownloadDone
                                                onClick={() => otherDataSubmit()}
                                                style={{ cursor: "pointer" }}
                                            />
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    {
                                        authUser?.user_name === id || authUser?.id === id ?
                                            <li className="list-group-item d-flex align-items-center  justify-content-between">
                                                <div className="user-container">
                                                    <h5> Date of Birth:</h5>
                                                </div>
                                                <div className="rounded-circle d-flex align-items-center">
                                                    {profileStatus.DateOfBirth ? (
                                                        <input
                                                            type="date"
                                                            className="form-control"
                                                            value={data?.dob}
                                                            onChange={(e) => {
                                                                let c = { ...data };
                                                                c.dob = e.target.value;
                                                                setData(c);
                                                            }}
                                                        />
                                                    ) : (
                                                        <h5>{data?.dob}</h5>
                                                    )}
                                                </div>
                                            </li>
                                            : profileData?.dob_visibility === 0 ? <></>
                                                : profileData?.dob_visibility === 2 || profileData?.am_i_being_followed
                                                    ?
                                                    <li className="list-group-item d-flex align-items-center  justify-content-between">
                                                        <div className="user-container">
                                                            <h5> Date of Birth:</h5>
                                                        </div>
                                                        <div className="rounded-circle d-flex align-items-center">
                                                            <h5>{profileData?.dob}</h5>
                                                        </div>
                                                    </li>
                                                    : <></>
                                    }
                                    <li className="list-group-item align-items-center  d-flex justify-content-between">
                                        <div className="user-container">
                                            <h5>Likes:</h5>
                                        </div>
                                        <div className="rounded-circle d-flex align-items-center">
                                            <h5>{data?.total_likes}</h5>
                                        </div>
                                    </li>
                                    <li className="list-group-item align-items-center  d-flex justify-content-between">
                                        <div className="user-container">
                                            <h5>Shares:</h5>
                                        </div>
                                        <div className="rounded-circle d-flex align-items-center">
                                            <h5>{data?.total_shares}</h5>
                                        </div>
                                    </li>
                                    <li className="list-group-item d-flex align-items-center  justify-content-between">
                                        <div className="user-container">
                                            <h5>Hobbies:</h5>
                                        </div>
                                        <div className="rounded-circle d-flex align-items-center text-right">
                                            {profileStatus.Hobbies ? (
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={data?.hobbies}
                                                    onChange={(e) => {
                                                        let c = { ...data };
                                                        c.hobbies = e.target.value;
                                                        setData(c);
                                                    }}
                                                />
                                            ) : (
                                                <h5>{data?.hobbies}</h5>
                                            )}
                                        </div>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        <div className="user-container">
                                            <h5>Lives In:</h5>
                                        </div>
                                        <div className="rounded-circle d-flex align-items-center text-right">
                                            {profileStatus.LivesIn ? (
                                                <select
                                                    onChange={(e) => {
                                                        let c = { ...data };
                                                        c.country.name =
                                                            e.target.options[e.target.selectedIndex].text;
                                                        c.country.id = e.target.value;
                                                        setData(c);
                                                    }}
                                                    className="px-2 "
                                                >
                                                    <option value="" selected>
                                                        --Select an country--
                                                    </option>
                                                    {countriesData?.map((i,index) => {
                                                        return <option value={i.id} key={index}>{i.name}</option>;
                                                    })}
                                                </select>
                                            ) : (
                                                <h5>{data?.country?.name}</h5>
                                            )}
                                        </div>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between">
                                        <div className="user-container">
                                            <h5>Language</h5>
                                        </div>
                                        <div className="rounded-circle d-flex align-items-center">
                                            <h5>English</h5>
                                        </div>
                                    </li>
                                </ul>
                            </>
                            <hr></hr>
                            <SocialMediaHandler data={data} setData={setData} id={id} authUser={authUser} />
                        </div>
                    </div>
                </div>
                {(authUser.user_name === id || authUser.id === id) ? (
                    <>
                        {" "}
                        <Card />
                        {/* <DeliveryOption />{" "} */}
                        <div
                            className="py-3"
                            style={{
                                backgroundColor: "#F3F3F3 !important",
                                borderRadius: "10px",
                            }}
                        >
                            <div className="row">
                                <div className="col-md-12 px-0">
                                    <div
                                        className="card p-2"
                                        style={{ border: "none !important" }}
                                    >
                                        <div className="card-title p-3 d-flex justify-content-between">
                                            <h4 style={{ fontWeight: "700", marginBottom: "0px" }}>
                                                Delivery Option
                                            </h4>
                                        </div>
                                        <div className="card-body d-flex justify-content-center">
                                            <ul className="list-group d-flex gap-3 list-group-flush delivery-option">
                                                {address?.map((i, index) => {
                                                    return (
                                                        <li
                                                            key={index}
                                                            className="list-group-item position-relative d-flex justify-content-between align-items-center"
                                                            style={{
                                                                border: "1px solid #A3A3A3 !important",
                                                                borderRadius: "10px",
                                                            }}
                                                        >
                                                            <div className="form-check">
                                                                <label
                                                                    className="form-check-label "
                                                                    htmlFor={index}
                                                                    style={{ width: "250px" }}
                                                                >
                                                                    {i.address.slice(0, 40) +
                                                                        (i.address.length > 40 ? "... " : "")}
                                                                </label>
                                                                <input
                                                                    className="form-check-input position-absolute radio-btn"
                                                                    style={{ right: "4px" }}
                                                                    type="radio"
                                                                    name="flexRadioDefault"
                                                                    id={index}
                                                                    onChange={() => setTheValue(i)}
                                                                />
                                                            </div>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="card p-2 bg--dark mt-4 ">
                                        <form
                                            className="delivry--card"
                                            style={{ padding: "1rem" }}
                                            ref={formRef}
                                        >
                                            <div>
                                                <h4
                                                    className="mb-3"
                                                    style={{ fontWeight: "700", marginBottom: "0px" }}
                                                >
                                                    Add Your Address
                                                </h4>
                                            </div>
                                            <select
                                                onChange={(e) => {
                                                    let c = { ...newAddress };
                                                    c.state_code = e.target.value;
                                                    c.state = states.find(
                                                        (state) => state.isoCode === e.target.value
                                                    ).name;
                                                    setNewAddress(c);
                                                }}
                                                className=" mt-2 w-100 px-3 form-control"
                                                style={{ fontSize: "15px" }}
                                                value={newAddress.state_code}
                                            >
                                                <option
                                                    value="default"
                                                    disabled
                                                    className="address--type"
                                                    style={{ color: "black" }}
                                                >
                                                    ---- Select State ----
                                                </option>
                                                {states?.map((state,index) => {
                                                    return (
                                                        <option
                                                            key={index}
                                                            value={state.isoCode}
                                                            selected={state.isoCode === newAddress.state_code}
                                                        >
                                                            {state.name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                            <span className="text-danger">{validation.state}</span>
                                            <input
                                                className="w-100 mt-2 px-3 address--type"
                                                type="text"
                                                placeholder="City"
                                                id="city"
                                                name="city"
                                                style={{ color: "black" }}
                                                value={newAddress.city}
                                                onChange={(e) => {
                                                    let c = { ...newAddress };
                                                    c.city = e.target.value;
                                                    setNewAddress(c);
                                                }}
                                            />

                                            <span className="text-danger">{validation.city}</span>
                                            <input
                                                className="w-100 mt-2 px-3 address--type"
                                                value={newAddress.pincode}
                                                type="number"
                                                placeholder="Pincode"
                                                id="pincod"
                                                name="pincod"
                                                style={{ color: "black" }}
                                                onChange={(e) => {
                                                    let c = { ...newAddress };
                                                    c.pincode = e.target.value;
                                                    setNewAddress(c);
                                                }}
                                            />
                                            <span className="text-danger">{validation.pincode}</span>
                                            <textarea
                                                className="mt-2 address--type"
                                                value={newAddress.address}
                                                style={{ color: "black" }}
                                                onChange={(e) => {
                                                    let c = { ...newAddress };
                                                    c.address = e.target.value;
                                                    setNewAddress(c);
                                                }}
                                                placeholder="Address Line"
                                            >
                                                {" "}
                                            </textarea>
                                            <span className="text-danger">{validation.address}</span>
                                            <div className="d-flex justify-content-center gap-3 my-3">
                                                {updateButton ? (
                                                    <button
                                                        className="delivry--btn"
                                                        onClick={(e) => submitAddress(e, "updateAddress")}
                                                    >
                                                        Update Address{" "}
                                                    </button>
                                                ) : (
                                                    <button
                                                        className="delivry--btn"
                                                        onClick={(e) => submitAddress(e)}
                                                    >
                                                        Save Address{" "}
                                                    </button>
                                                )}

                                                <button
                                                    className="delivry--btn1"
                                                    onClick={(e) => removeAddress(e)}
                                                >
                                                    Remove
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    ""
                )}
            </div>
            <ImageUploadModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                setImagData={setImagData}
                setPostsData={setPostsData}
            />
        </>
    );
};

export default Sidebar;
