import api from "./api";

export const getFollowers = async (pageIndex) => {
  try {
    const response = await api.get(
      `/api/v1/followers?page=${pageIndex}`
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getFollowersRequest = async (pageIndex) => {
  try {
    const response = await api.get(
      `/api/v1/my/requests?page=${pageIndex}`
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const acceptRequest = async (id, type) => {
  try {
    const response = await api.get(
      `/api/v1/follow/${id}/request?type=${type}`
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const cancelRequest = async (id) => {
  try {
    const response = await api.get(
      `/api/v1/cancel/${id}/request`
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getFollowersById = async (id, pageIndex) => {
  try {
    const response = await api.get(
      `/api/v1/${id}/followers?page=${pageIndex}`
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getFollowingUsers = async (id, pageIndex) => {
  try {
    const response = await api.get(
      `/api/v1/${id ? `${id}/` : ""}following?page=${pageIndex}`
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const blockProfile = async (data) => {
  try {
    const response = await api.post(`/api/v1/blocks`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};