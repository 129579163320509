import { useEffect,useState } from "react";
import { Link } from "react-router-dom";
import Comment from "../components/Comment";
import { getBlogPostDetail } from "../services/BlogPostService";
import arrow from "../assets/images/newsfeed/Group 931.svg";
import { toast } from "react-toastify";
import { useParams } from "react-router";

const BlogDetail = () => {

    const [postDetail, setPostDetail] = useState([]);
    const [postComments, setPostComments] = useState([]);
    const [loader, setLoader]        = useState();

    const { slug } = useParams();
    
    const getPostDetail = async () => {
        setLoader(true);
        const response = await getBlogPostDetail(slug);
        if (response.status === 200) {
            setPostDetail(response.data.data);
            setPostComments(response.data.data.comments)
            setLoader(false);
        }else{

            toast.error(response.data.message);
            setLoader(false);
        }

    }
    useEffect(() => {
        getPostDetail();
    }, []);
    return (
        <>
            <main style={{backgroundColor: "#F9F9F9 !important"}}>
                <div className="container px-0" style={{backgroundColor: "#F3F3F3 !important"}}>
                    <div className="container">

                        <div className="row">
                            <div className="col-md-6 d-flex justify-content-between align-items-center">
                                <Link to="/blogs">
                                    <img src="/assets/images/ArrowCircleLeft.png" />
                                </Link>    
                                <h1>Blogs</h1>
                            </div>
                        </div>

                        <div className="row">
                        {loader ? (
                            <div className="mt-5 d-flex justify-content-center">
                                <div className="spinner-grow" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        ) : (
                                
                                    
                                        <div className="col-md-12 d-flex flex-column justify-content-center">
                                            <img src={postDetail.blog_image} /><br></br>
                                            <h6 className="mb-0">{postDetail.date}</h6>
                                            <h3>{postDetail.title}</h3><br></br>
                                            <p dangerouslySetInnerHTML={{ __html: postDetail.content }}></p>
                                            {/* <hr /> */}
                                                {/* <div className="row">
                                                    <div className="col-md-12 d-flex">
                                                        <div className="col-md-6 pl-0">
                                                            <div className="col-md-8 d-flex justify-content-between pl-0">
                                                                <a href="#">
                                                                    <img src="/assets/images/heart.png" /> {postDetail.total_likes}
                                                                </a>
                                                                <a href="#">
                                                                    <img src="/assets/images/dislike.png" /> {postDetail.total_dislikes}
                                                                </a>
                                                                <a href="#">
                                                                    <img src="/assets/images/comment.png" /> {postDetail.total_comments}
                                                                </a>
                                                                <a href="#">
                                                                    <img src="/assets/images/share.png" /> {postDetail.total_shares}
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 d-flex justify-content-end pr-0">
                                                            <div className="col-md-4 d-flex justify-content-around pr-0">
                                                                <a href="">
                                                                    <div className="rounded-circle d-flex justify-content-center align-items-center"
                                                                        style={{width: "41px", height: "41px", border: "1px solid black"}}>
                                                                        <img style={{width: "70%"}} src="/assets/images/blog_fb.png" />
                                                                    </div>
                                                                </a>

                                                                <a href="">
                                                                    <div className="rounded-circle d-flex justify-content-center align-items-center"
                                                                        style={{width: "41px", height: "41px", border: "1px solid black"}}>
                                                                        <img style={{width: "70%"}} src="/assets/images/blog_insta.png" />
                                                                    </div>
                                                                </a>
                                                                <a href="">
                                                                    <div className="rounded-circle d-flex justify-content-center align-items-center"
                                                                        style={{width: "41px", height: "41px", border: "1px solid black"}}>
                                                                        <img style={{width: "70%"}}
                                                                            src="/assets/images/blog_twitter.png" />
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <h6 className="mb-0">Comments ({postDetail.total_comments})</h6>
                                                    </div>
                                                </div> */}
                                                {/* <div className="row">
                                                    <form
                                                    className="col-md-12 pt-2 px-2"
                                                    style={{ position: "relative" }}
                                                    onSubmit={(e) => submitComment(item.id, e)}
                                                    id="commentInput_data"
                                                    >
                                                    <input
                                                        type="text"
                                                        placeholder="Add your comment"
                                                        className="px-3"
                                                        style={{
                                                            width: "100%",
                                                            backgroundColor: "#F6F6F6",
                                                            borderRadius: "10px",
                                                            color: "black",
                                                            border: "1px solid #ebebeb"
                                                        }}
                                                        // value={commentInput.comment}
                                                        // onChange={(e) => {
                                                        //   const c = { ...commentInput };
                                                        //   c.comment = e.target.value;
                                                        //   setCommentInput(c);
                                                        // }}
                                                    />
                                                    <button type="submit" style={{ border: "none" }}>
                                                        <img
                                                        src={arrow}
                                                        style={{
                                                            width: "21px",
                                                            height: "21px",
                                                            cursor: "pointer",
                                                            position: "absolute",
                                                            top: "33%",
                                                            right: "3%",
                                                        }}
                                                        alt=""
                                                        />
                                                    </button>
                                                    </form>
                                                </div> */}
                                                {/* {{-- comment start --}} */}
                                                {/* <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-12 pl-0">
                                                            <ul className="list-group list-group-flush mb-3">
                                                                <li className="list-group-item my-3"
                                                                    style={{backgroundColor: "#F9F9F9 !important", borderRadius: "10px"}}>
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <div
                                                                                className="col-md-7 d-flex justify-content-between align-items-center pl-0">
                                                                                <div className="d-flex align-items-center">
                                                                                    <img src="/assets/images/comment_avatar.png"/>
                                                                                    <h4 className="mx-2"
                                                                                        style={{fontSize: "18px", fontWeight: "500"}}>
                                                                                        Nadeem</h4>
                                                                                    <span style={{color: "rgba(36, 36, 36, 0.5)"}}>5h
                                                                                        ago</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6 d-flex justify-content-end align-items-center">
                                                                            <img src="/assets/images/DotsThree.png" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <p style={{fontSize: "16px", color: "black"}}>
                                                                                In publishing and graphic design, Lorem ipsum is
                                                                                a placeholder text commonly used to demonstrate
                                                                                the visual form a typeface without relying on
                                                                                meaningful content. Lorem ipsum may </p>
                                                                        </div>
                                                                    </div>
                                                                </li>

                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div> */}
                                        </div>
                                    
                                 
                            )
                        }
                        </div>

                    </div>
                </div>

                {/* <div className="row my-4">
                    <div className="col-md-12 d-flex justify-content-center">
                        <h1>Related Blogs</h1>
                    </div>
                </div>

                <div className="container">
                    <div className="row my-4">
                        <div className="col-md-12 d-flex justify-content-between flex-wrap">
                            <div className="card my-2" style={{width: "22rem"}}>
                                <img src="/assets/images/blog_banner.png" className="card-img-top" alt="..." />
                                    <div className="">
                                        <div className="card-body">
                                            <div className="row my-2">
                                                <div className="col-md-12 d-flex justify-content-between">
                                                    <div className="col-md-6 pl-0" style={{paddingLeft: "10px"}}>
                                                        <h6 className="mb-0">27/07/2022</h6>
                                                    </div>
                                                    <div className="col-md-6 d-flex justify-content-end align-items-center pr-0">
                                                        <div className="col-md-12 d-flex justify-content-end pr-0">
                                                            <div className="col-md-8 d-flex justify-content-between px-0">
                                                                <a href="">
                                                                    <img src="/assets/images/blog_fb.png" />
                                                                </a>

                                                                <a href="">
                                                                    <img src="/assets/images/blog_insta.png" />
                                                                </a>
                                                                <a href="">
                                                                    <img src="/assets/images/blog_twitter.png" />
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h4 className="card-title">Why is he doing Yoga with wearing
                                                        Headphone at his head?</h4>
                                                </div>
                                                <div className="col-md-12">
                                                    <p className="card-text">
                                                        Lorem
                                                        Ipsum
                                                        is
                                                        simply dummy text of the printing and typesetting
                                                        industry.
                                                        Lorem
                                                        Ipsum has been the industry's standard dummy text ever since the 1500s, when
                                                        an
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            <div className="card my-2" style={{width: "22rem"}}>
                                <img src="/assets/images/blog_banner2.png" className="card-img-top" alt="..." />
                                    <div className="">
                                        <div className="card-body">
                                            <div className="row my-2">
                                                <div className="col-md-12 d-flex justify-content-between">
                                                    <div className="col-md-6 pl-0" style={{paddingLeft: "10px"}}>
                                                        <h6 className="mb-0">27/07/2022</h6>
                                                    </div>
                                                    <div className="col-md-6 d-flex justify-content-end align-items-center pr-0">
                                                        <div className="col-md-12 d-flex justify-content-end pr-0">
                                                            <div className="col-md-8 d-flex justify-content-between px-0">
                                                                <a href="">
                                                                    <img src="/assets/images/blog_fb.png" />
                                                                </a>

                                                                <a href="">
                                                                    <img src="/assets/images/blog_insta.png" />
                                                                </a>
                                                                <a href="">
                                                                    <img src="/assets/images/blog_twitter.png" />
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h4 className="card-title">Why is he doing Yoga with wearing
                                                        Headphone at his head?</h4>
                                                </div>
                                                <div className="col-md-12">
                                                    <p className="card-text">
                                                        Lorem
                                                        Ipsum
                                                        is
                                                        simply dummy text of the printing and typesetting
                                                        industry.
                                                        Lorem
                                                        Ipsum has been the industry's standard dummy text ever since the 1500s, when
                                                        an
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            <div className="card my-2" style={{width: "22rem"}}>
                                <img src="/assets/images/blog_banner4.png" className="card-img-top" alt="..." />
                                    <div className="">
                                        <div className="card-body">
                                            <div className="row my-2">
                                                <div className="col-md-12 d-flex justify-content-between">
                                                    <div className="col-md-6 pl-0" style={{paddingLeft: "10px"}}>
                                                        <h6 className="mb-0">27/07/2022</h6>
                                                    </div>
                                                    <div className="col-md-6 d-flex justify-content-end align-items-center pr-0">
                                                        <div className="col-md-12 d-flex justify-content-end pr-0">
                                                            <div className="col-md-8 d-flex justify-content-between px-0">
                                                                <a href="">
                                                                    <img src="/assets/images/blog_fb.png" />
                                                                </a>

                                                                <a href="">
                                                                    <img src="/assets/images/blog_insta.png" />
                                                                </a>
                                                                <a href="">
                                                                    <img src="/assets/images/blog_twitter.png" />
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h4 className="card-title">Why is he doing Yoga with wearing
                                                        Headphone at his head?</h4>
                                                </div>
                                                <div className="col-md-12">
                                                    <p className="card-text">
                                                        Lorem
                                                        Ipsum
                                                        is
                                                        simply dummy text of the printing and typesetting
                                                        industry.
                                                        Lorem
                                                        Ipsum has been the industry's standard dummy text ever since the 1500s, when
                                                        an
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row pb-4">
                    <div className="col-md-12 d-flex justify-content-center">
                        <button className="get-started">View all</button>
                    </div>
                </div> */}
            </main>
        </>
    );
}

export default BlogDetail