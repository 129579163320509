import { useState } from "react";
import { forgotPasswordApi } from "../../services/AuthService";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/esm/Spinner";

const ForgotPassword = () => {

    const [data, setData] = useState("")
    const [valid, setValid] = useState("")
    const [loader, setLoader] = useState(false)



    const onSubmit = async () => {
        const emailCond =
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/;

        if (data === "") {
            setValid("email is required")
            return
        } else {
            setValid("")

        }

        if (!emailCond.test(data)) {
            setValid("Please enter a valid email address")
            return;
        } else {
            setValid("")
        }

        let body = {
            email: data
        }

        setLoader(true)
        try {
            const response = await forgotPasswordApi(body)
            if (response.status === 200) {
                setLoader(false)
                toast.success(response.data.message);
            } else {
                setLoader(false)
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.warn("email not found !");
            setLoader(false)
        }

    }

    return (
        <>
            <div className="container">
                <div className="row m-5 no-gutters">
                    <div className="col-md-6 d-md-block banner-container"
                        style={{ backgroundImage: "url('/assets/images/Images/stick images-06.png')" }}>
                    </div>
                    <div className="col-md-6 d-flex flex-column justify-content-center
                     align-items-center bg-white p-3">
                        <h3 className="signin font-sizing-login-heading1"
                            style={{ marginBottom: "10px" }}>forgot password</h3>
                        <p
                            style={{ color: '#A3A3A3' }}>You will  get an verification code at
                            your email address</p>
                        <div className="form-style" style={{ minWidth: '100%' }}>

                            <div className="form-group">
                                <span className="text-danger">{valid}</span>
                                <input type="email"
                                    placeholder="Email" className="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp" onChange={(e) => setData(e.target.value)} />
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-md-7">
                                    {loader ? (
                                        <div className="d-flex justify-content-center align-items-center" style={{ marginTop: "10px" }}>
                                            <Spinner animation="border me-2" />
                                        </div>
                                    ) : (<button type="submit"
                                        className="get-started w-100 mt-2"
                                        disabled={loader}
                                        onClick={() => onSubmit()}>Submit</button>)}

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ForgotPassword