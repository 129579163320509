import api from "./api";

export const upload_document = async (body) => {
    try {
        const response = await api.post(`/api/v1/identities`, body);
        return response;
    } catch (error) {
        return error.response;
    }
}

export const check_verification_status = async () => {
    try {
        const response = await api.get(`/api/v1/kyc/verified`);
        return response;
    } catch (error) {
        return error.response;
    }
}

export const getInvoices = async () => {
    try {
        const response = await api.get(`/api/v1/orders`);
        return response;
    } catch (error) {
        return error.response;
    }
}