import Modal from "react-bootstrap/Modal";

const DeleteAccount = (props) => {
  return (
    <>
      <Modal
        show={props.show}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="container">
          <Modal.Header className="justify-content-center border-0 pb-0 mt-3">
            <Modal.Title id="contained-modal-title-vcenter">
              {/* <img src="/assets/images/successIcon.svg" style={{ maxWidth: '100px', maxHeight: '100px' }} /> */}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            className="text-center"
            style={{ background: "#FFFFFF", borderRadius: "3px" }}
          >
            <div className="row justify-content-center">
              <div className="col-md-9">
                <h1 style={{ fontFamily: "My Font" }}>Are you sure</h1>

                <h4>You want to Delete your {props.name}</h4>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center border-0 mb-3 pt-0">
            <button
              className="get-started"
              onClick={props.deletePost}
              style={{ width: "122px", height: "50px" }}
              disabled={props.deleteBtnLock}
            >
              Delete
            </button>
            <button
              className="get-started"
              onClick={props.onHide}
              style={{ width: "122px", height: "50px" }}
            >
              Cancel
            </button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};

export default DeleteAccount;
