import Table from 'react-bootstrap/Table';


const Withdrawal = ({ withdrawals }) => {

    const convertUtcToLocal = (utcTimeString) => {
        // Create a Date object with the UTC time string
        const utcDate = new Date(utcTimeString);

        // Convert UTC time to local time
        const localDate = new Date(utcDate.getTime() + (utcDate.getTimezoneOffset() * 60000));

        // Get the local date and time components
        const year = localDate.getFullYear();
        const month = localDate.getMonth() + 1; // Months are zero-based
        const day = localDate.getDate();
        let hours = localDate.getHours();
        const minutes = localDate.getMinutes();
        const seconds = localDate.getSeconds();
        let ampm = '';

        // Determine AM or PM
        if (hours >= 12) {
            ampm = 'PM';
            hours %= 12;
        } else {
            ampm = 'AM';
        }

        // Format the local date and time as a string
        const localTimeString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${ampm}`;
        return localTimeString;
    }

    return (
        <>
            <div className='row'>
                <div className="col-md-9 invoice">
                    {
                        withdrawals.length > 0 ?
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Amount</th>
                                        <th>Funds Status</th>
                                        <th>Applied</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        withdrawals.map((withdrawal, key) => {
                                            return <tr key={key}>
                                                <td>{key + 1}</td>
                                                <td>${withdrawal.amount}</td>
                                                <td>{withdrawal.status}</td>
                                                <td>{convertUtcToLocal(withdrawal.created_at)}</td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </Table>
                            :
                            <div className='d-flex justify-content-center'>
                                No withdrawal performed
                            </div>
                    }
                </div>
            </div>
        </>
    );
}

export default Withdrawal