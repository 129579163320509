import { Link, useLocation,NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { socialInformation }  from "../../services/SettingService"

const Footer = () => {
  const { pathname, key } = useLocation();
  const [authUser, setAuthUser] = useState(false);
  const [socialData, setSocialData] = useState([])

  const getSocialdata = async () => {
    ///Followers API
    const response = await socialInformation();
    if (response?.status === 200) {
        setSocialData(response?.data);
    }
  };

  // check Login
  useEffect(() => {
    getSocialdata();
    if (JSON.parse(localStorage.getItem("user"))) {
        setAuthUser(true);
    } else {
        setAuthUser(false);
    }
  }, []);

  return (
    <>
      {pathname === "/customizer" || pathname === "/mugcustomizer" ? (
        <></>
      ) : (
        <footer id="footer-tag">
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <div className="footer_brand_logo mb-3">
                  <h3 className="footer-links-heading">OUR BRIEF</h3>
                </div>
                <h6 className="footer-text">
                  Express your thoughts with our unique Stick Figures
                  collection. Pick, stick, print and share!
                </h6>
                <h2
                  className="mt-3"
                  style={{
                    fontFamily: "Brittany Signature",
                    color: "#F3F3F3",
                    width: "60%",
                  }}
                >
                  Happy shopping and sharing!
                </h2>
              </div>
              <div className="col-md-2">
                <h3 className="footer-links-heading">COMPANY</h3>
                <div className="footer-lnks">
                  <ul>
                    <li className="footer-text">
                      <Link to="/about">About Stickfigures</Link>
                    </li>
                    <li className="footer-text">
                      <Link to="/blogs">Blogs</Link>
                    </li>
                    <li className="footer-text">
                      <Link to="/customizer">Customizer</Link>
                    </li>
                    <li className="footer-text">
                      <Link to="/shop/user">Shop Now</Link>
                    </li>
                    <li className="footer-text">
                      <Link to="/contact">Contact Us</Link>
                    </li>
                    <li className="footer-text">
                      <Link to="/event">Upcoming Events</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 ">
                <h3 className="footer-links-heading">KNOWLEDGE BASE</h3>
                <div className="footer-lnks">
                  <ul>
                    <li className="footer-text">
                      {" "}
                      <Link to="/owncustomization">
                        Getting Started-Customize your products
                      </Link>
                    </li>
                    <li className="footer-text">
                      {" "}
                      <Link to="/owncreation">
                        Getting Started-Create your own creations
                      </Link>
                    </li>
                    <li className="footer-text">
                      {" "}
                      <Link to="/new-kind-of-socail-media">
                        Enjoy a New Kind Of Social media
                      </Link>
                    </li>
                    <li className="footer-text">
                      <Link to="/top-products">Top Products of the Day</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4 pr-0">
                <h3 className="footer-links-heading">CONTACT INFORMATION</h3>
                <div className="footer-lnks">
                  <ul>
                    <li className="footer-text">
                      <a href="#" id="openMap">
                        <div className="d-flex justify-content-center align-items-start">
                          <img
                            className="me-2"
                            src="/assets/images/locator.svg"
                          />
                          <p style={{ marginBottom: "0px", fontSize: "1rem" }}>
                            {" "}
                            {socialData.address ? socialData.address : "25737 US Rt 11, Evans Mills NY 13637. 901 Route 110, Farmingdale NY."}
                          </p>
                        </div>
                      </a>
                    </li>
                    <li className="footer-text">
                      <a href="tel:(941) 412-4148">
                        <div className="d-flex">
                          <img
                            src="/assets/images/phone.svg"
                            className="me-2"
                          />
                          <p style={{ marginBottom: "0px", fontSize: "1rem" }}>
                            {" "}
                            {socialData.phone_number ? socialData.phone_number : "(941) 412-4148"}
                          </p>
                        </div>
                      </a>
                    </li>
                    <li className="footer-text">
                      <a href={socialData.email ? socialData.email :"info@stickfigures.com"}>
                        <div className="d-flex">
                          <img src="/assets/images/mail.svg" className="me-2" />
                          <p style={{ marginBottom: "0px", fontSize: "1rem" }}>
                            {socialData.email ? socialData.email :"info@stickfigures.com"}
                          </p>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
                <div
                  className="dropdown-divider ms-4"
                  style={{ borderTop: "1px solid #F3F3F3", opacity: "0.5" }}
                ></div>
                <h5 className="text-white  ms-4">Follow Us On</h5>
                <div className="row ">
                  <div
                    className="col-md-3 d-flex  "
                    style={{ paddingLeft: "35px" }}
                  >
                    <a href={socialData.instagram ? socialData.instagram : "https://www.instagram.com/"} target="_blank" rel="noopener noreferrer">
                      <img
                        src="/assets/images/footer_insta.svg"
                        style={{ paddingRight: "15px",minWidth:"40px" }}
                      />
                    </a>
                    <a href={socialData.facebook ? socialData.facebook : "https://www.facebook.com/"} target="_blank" rel="noopener noreferrer">
                      <img
                        src="/assets/images/footer_fb.svg"
                        style={{ paddingRight: "15px",minWidth:"30px" }}
                      />
                    </a>
                    <a href={socialData.twitter ? socialData.twitter : "https://twitter.com/"} target="_blank" rel="noopener noreferrer">
                      <img
                        src="/assets/images/footer_twitter.svg"
                        style={{ paddingRight: "15px",minWidth:"40px" }}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="dropdown-divider"
            style={{ borderTop: "1px solid #F3F3F3", opacity: "0.5" }}
          ></div>
          <div className="footer-copyright footer-text text-center">
            <span className="text-decoration-none text-white">
              {" "}
              © 2023 <Link to="/" style={{color: 'white', textDecoration: 'none'}}> stickfigures.com </Link> LLC
            </span>
          </div>
        </footer>
      )}
    </>
  );
};

export default Footer;
