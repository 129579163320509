import { useEffect, useState } from "react";
import Spinner from "react-bootstrap/esm/Spinner";
import { Link, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { constants } from "../components/Constants/constants";
import {
  CSSFunction,
  PlaceHolderFollowers,
  PlaceHolderRequests,
} from "../components/PlaceHolder";
import { getFollowingUsers } from "../services/FollowersService";
import { getFollowersById } from "../services/FollowersService";
import {
  acceptRequest,
  getFollowers,
  getFollowersRequest,
} from "../services/FollowersService";
import { searchUnique } from "../services/SearchUnique";
import {db} from "../components/Constants/Firebase";
import {collection, onSnapshot} from "firebase/firestore";


const Follower = () => {
  /// state mangements
  const { id, followingId } = useParams();
  const [followers, setFollowers] = useState([]);
  const [followerReq, setFollowerReq] = useState([]);
  const [loader, setLoader] = useState(false);
  const [reqLoader, setReqLoader] = useState(false);
  const [btnLock, setBtnLock] = useState(false);
  const [users, setUsers] = useState([]);



  const [paginateLinks, setPaginateLinks] = useState();
  const [reqpaginateLinks, setReqPaginateLinks] = useState();

  const location = useLocation();
  let pageIndex = 1;
  let reqPageIndex = 1;


  const getpiplineData = async () => {
    const unsubscribe = onSnapshot(collection(db, "Users"), (snapshot) => {
      const usersList = snapshot.docs.map((doc) => doc.data());
      setUsers(usersList);
    });
    return unsubscribe;
  }

  useEffect(() => {
    getpiplineData()
  }, [db]);

  useEffect(() => {
    if (id) {
      getFollowersData(1);
    } else {
      getFollowersData(1);
      getFollowersReq();
    }



  }, [location]);


  const getFollowersData = async (index, paginate) => {
    setLoader(true);
    ///Followers API
    const response = id ? await getFollowersById(id, index)
      : followingId  ? await getFollowingUsers(followingId, index) :
        await getFollowers(index)
    if (response.status === 200) {
      if (paginate === "paginate") {
        setFollowers([...followers, ...response?.data?.data]);
      } else {
        setFollowers(response.data.data);
      }
      setPaginateLinks(response.data.links.next);
      setLoader(false);
    } else {
      toast.error(response.data.message);
      setLoader(false);
    }
  };

  ///Get followers requests
  const getFollowersReq = async () => {
    setReqLoader(true);
    ///Followers Requests API
    const reqResponse = await getFollowersRequest(reqPageIndex);
    if (reqResponse.status === 200) {
      setFollowerReq(reqResponse.data.data);
      setReqPaginateLinks(reqResponse.data.links.next);
      setReqLoader(false);
    } else {
      toast.error(reqResponse.data.message);
      setReqLoader(false);
    }
  };

  /// search filter API
  const searchFilter = async (e) => {
    setLoader(true);
    setTimeout(async () => {
      const response = await searchUnique(id ? id : "", constants.followers, e.target.value,);
      if (response.status === 200) {
        setFollowers(response.data.data);
        setLoader(false);
      }
    }, 500);
  };

  ///accept request Api
  const acceptRequestApi = async (id, type) => {
    setBtnLock(true);
    const response = await acceptRequest(id, type);
    if (response.status === 200) {
      toast.success(response.data.message);
      getFollowersData();
      getFollowersReq();
      setBtnLock(false);
    } else {
      toast.error(response.data.message);
      setBtnLock(false);
    }
  };

  return (
    <>
      <CSSFunction loader={loader} reqLoader={reqLoader} />
      <main
        style={{ backgroundColor: "rgba(249, 249, 249, 1)" }}
        className="py-4"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-8 mt-3">
              <div className="card">
                <div className="container ">
                  <div className="card-header d-flex justify-content-between align-items-center py-4">
                    <h2 className="my-0 " style={{ fontSize: "24px" }}>
                      All Followers
                    </h2>
                    <h6
                      className="my-0"
                      style={{ fontSize: "12px", fontWeight: "600" }}
                    >
                      Total {followers[0]?.total_followers ? followers[0]?.total_followers + " " : 0 + " "}
                      Friend{followers[0]?.total_followers > 1 ? "s" : ""}
                    </h6>
                  </div>
                </div>
                <div className="card-body">
                  <div
                    className="container follower-container p-0"
                    style={{ padding: "0% !important" }}
                  >
                    <div className="row">
                      <div
                        className="col-md-8"
                        style={{ position: "relative" }}
                      >
                        <input
                          type="text"
                          className="w-100"
                          placeholder="Search your friends"
                          style={{
                            backgroundColor: "#E9E9E9",
                            height: "50px",
                            paddingLeft: "3%",
                            borderRadius: '50px',
                          }}
                          onChange={(e) => searchFilter(e)}
                        />
                        <img
                          src="/assets/images/search.png"
                          style={{
                            position: "absolute",
                            bottom: "20px",
                            right: "40px",
                            top: "13px",
                          }}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="row my-4">
                      <div className="col-md-12">
                        <ul className="list-group list-group-flush">
                          {loader ? (
                            <PlaceHolderFollowers />
                          ) : followers?.length === 0 ? (
                            <div className="text-center mt-4">
                              <h4
                                style={{ fontWeight: "700", fontSize: "12px" }}
                              >
                                No data found
                              </h4>
                            </div>
                          ) : (
                            followers?.map((i,index) => {
                              return (
                                <li className="list-group-item" key={index}>
                                  <Link to={`/profile/${i.user_name}`}>
                                    <div className="row d-flex">
                                      <div className="col-md-12 d-flex justify-content-between ">
                                        <div className="d-flex align-items-center follower-menu pb-1">
                                          <img
                                            className="me-3"
                                            src={
                                              i.photo ?
                                                  process.env.REACT_APP_apiBaseURL + "/" + i?.photo : "/assets/images/follower1.png"
                                            }
                                            alt=""
                                            style={{
                                              width: "110px",
                                              height: "110px",
                                              objectFit: "cover",
                                              borderRadius: "20px"
                                            }}
                                          />
                                          <h4>{i.name}</h4>
                                        </div>
                                        <div className=" d-flex align-items-center justify-content-center">
                                          <img
                                            src={
                                              "/assets/images/comment_icon.png"
                                            }
                                            style={{ marginRight: "10px" }}
                                            alt=""
                                          />
                                          <img
                                            src="/assets/images/menu_bar.png"
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                </li>
                              );
                            })
                          )}

                          {followers?.length === 0 ? (
                            <Link
                              to="/search"
                              className="col-md-12 d-flex justify-content-center pt-4"
                            >
                              <button className="get-started">
                                Add Followers
                              </button>
                            </Link>
                          ) : paginateLinks !== null ? (
                            <div className="col-md-12 d-flex justify-content-center pt-4">
                              <button
                                className="get-started"
                                onClick={() =>
                                  getFollowersData(pageIndex + 1, "paginate")
                                }
                              >
                                View more
                              </button>
                            </div>
                          ) : (
                            ""
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!id ? (<div
              className="col-md-4 mt-3"
              style={{
                backgroundColor: "#E9E9E9 !important",
                borderRadius: "10px",
              }}
            >
              <div className="row">
                <div className="card w-100" style={{ border: "none" }}>
                  <div className="card-header p-4">
                    <h2 className="card-title my-0">Followers Request</h2>
                  </div>
                  <ul className="list-group list-group-flush">
                    {reqLoader ? (
                      <PlaceHolderRequests />
                    ) : followerReq?.length === 0 ? (
                      <div className="text-center mt-4">
                        <h4 style={{ fontWeight: "700", fontSize: "12px" }}>
                          No requests found
                        </h4>
                      </div>
                    ) : (
                      followerReq?.map((follower,index) => {
                        return (
                          <li className="list-group-item d-flex justify-content-between align-items-center mb-2" key={index}>
                            <div className="row w-100 d-flex ">
                              <div className="col-md-12 d-flex justify-content-between ">
                                <div className="d-flex align-items-center gap-3 ">
                                  <img
                                    src={
                                      follower.photo
                                        ? process.env.REACT_APP_apiBaseURL +
                                        "/" +
                                        follower.photo
                                        : "/assets/images/avatar1.png"
                                    }
                                    style={{
                                      width: "50px",
                                      height: "50px",
                                      objectFit: "cover",
                                      borderRadius: "10px"
                                    }}
                                    alt=""
                                  />
                                  <h4 className="m-0">{follower.user_name} </h4>
                                  {users.find((user)=> user.id === follower.id)?.isOnline ? (
                                      <div
                                          className="rounded-circle d-flex align-items-center"
                                          style={{
                                            width: "10px",
                                            height: "10px",
                                            background: "rgba(95, 216, 0, 1)",
                                          }}
                                      ></div>
                                  ) : (
                                      <div className="rounded-circle d-flex align-items-center"
                                           style={{
                                             width: "10px",
                                             height: "10px",
                                             background: "#5A5A5A",
                                           }}
                                      ></div>
                                  )}
                                </div>
                                <div className=" d-flex justify-content-end align-items-center">
                                  {btnLock ? (
                                    <Spinner
                                      animation="border"
                                      style={{
                                        width: "1rem",
                                        height: "1rem",
                                      }}
                                    />
                                  ) : (
                                    <>
                                      <div
                                        className="rounded-circle image-container me-2"
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          acceptRequestApi(
                                            follower.id,
                                            constants.accept
                                          )
                                        }
                                      >
                                        <img
                                          src="/assets/images/accept.png"
                                          alt=""
                                        />
                                      </div>
                                      <div
                                        className="rounded-circle image-container"
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          acceptRequestApi(
                                            follower.id,
                                            constants.decline
                                          )
                                        }
                                      >
                                        <img
                                          src="/assets/images/cancel.png"
                                          alt=""
                                        />
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </li>
                        );
                      })
                    )}
                    {reqpaginateLinks !== null ? (
                      <div className="col-md-12 d-flex justify-content-center pt-4">
                        <button
                          className="get-started"
                          onClick={() => getFollowersReq(reqPageIndex++)}
                        >
                          View more
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </ul>
                </div>
              </div>
            </div>) : null}


          </div>
        </div>
      </main>
    </>
  );
};

export default Follower;
