import { useEffect, useState } from "react";
import Spinner from "react-bootstrap/esm/Spinner";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { constants } from "../components/Constants/constants";
import {
    CSSFunction,
    PlaceHolderCategory,
    PlaceHolderProducts,
} from "../components/PlaceHolder";
import { AddCart } from "../services/CartService";
import { getCategories, getUsersProducts } from "../services/ShopService";

const Shop = (props) => {
    //// state management
    const [categoryData, setCategoryData] = useState();
    const [productsData, setProductsData] = useState();
    const [loader, setLoader] = useState(false);
    const [categoryLoader, setCategoryLoader] = useState(false);
    const [latestCategory, setLatestCategory] = useState("");
    const [priceValue, setPriceValue] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [paginateLinks, setPaginateLinks] = useState([]);
    const [pageIndex, setPageIndex] = useState(1);
    const [pageCount, setPageCount] = useState();
    const user = JSON.parse(localStorage.getItem("user"))


    //// type from the route
    const { type } = useParams();

    ///page index for pagination

    ///functions called on page load
    useEffect(() => {
        getCategoriesData();
        if (type === constants.official) {
            getUsersProductsData(pageIndex, constants.official, "", priceValue, searchText); /// parms = pagination | product Type | category | price | search
        } else {
            getUsersProductsData(pageIndex, constants.user, "", priceValue, searchText);
        }
    }, [type]);

    useEffect(() => {
        (async () => {
            await getUsersProductsData(
                pageIndex,
                type,
                latestCategory,
                priceValue,
                searchText,
            )
        })();
    }, [searchText]);

    useEffect(() => {
        setPageIndex(1);
    }, [priceValue, searchText]);

    useEffect(() => {
        const footer = document.getElementById('footer-tag')
        const footerHeight = footer.offsetHeight;

        const scrollEvent = document.addEventListener('scroll', () => {
            const remainingScrollHeight = document.body.offsetHeight - window.innerHeight - window.scrollY;
        })

        return () => {
            document.removeEventListener('scroll', scrollEvent)
        }
    }, [])

    ///geting the admin/user Products
    const getUsersProductsData = async (
        pageIndex,
        type,
        category,
        price,
        search
    ) => {
        setLoader(true);
        window.scrollTo(0, 0)
        setTimeout(async () => {
            const response = await getUsersProducts(
                pageIndex,
                type,
                category,
                Number(price),
                search
            );
            if (response?.status === 200) {
                setProductsData(response.data.data);
                setPaginateLinks(response.data.links);
                setPageIndex(response.data.meta.current_page);
                setPageCount(response.data.meta);
                setLoader(false);
            } else {
                setLoader(false);
                toast.error(response.data.message);
            }
        }, 200);
    };

    //// geting admin categories
    const getCategoriesData = async () => {
        setCategoryLoader(true);
        const response = await getCategories();
        if (response?.status === 200) {
            setCategoryData(response.data.data);
            setCategoryLoader(false);
        }
    };

    const addToCart = async (id, cartStatus) => {
        if (cartStatus) {
            setProductsData((prev) => {
                return prev.map((product) => {
                    if (product.id === id) {
                        return {
                            ...product,
                            in_cart: false,
                        };
                    }
                    return product;
                });
            });

            props.setCartCount((prevCount) => {
                const newCount = parseInt(prevCount, 10) - 1;
                localStorage.setItem("cartCount", newCount);
                return newCount;
            });
        } else {
            setProductsData((prev) => {
                return prev.map((product) => {
                    if (product.id === id) {
                        return {
                            ...product,
                            in_cart: true,
                        };
                    }
                    return product;
                });
            });
            props.setCartCount((prevCount) => {
                const newCount = parseInt(prevCount, 10) + 1;
                localStorage.setItem("cartCount", newCount);
                return newCount;
            });
        }

        const finalData = {
            product_id: id,
        };
        const response = await AddCart(finalData);
        if (response?.status === 200) {
            if (cartStatus) {
                toast.success("product removed");
            } else {
                toast.success("product added");
            }
        } else {
            toast.error(response.data.message);
        }
    };

    return (
        <>
            <CSSFunction loader={loader} />
            <div className="container mb-4 py-4 shop--containers--color">
                <div className="row my-4">
                    <div className="col-md-12 d-flex justify-content-center">
                        <h1
                            style={{
                                textTransform: "uppercase",
                                fontWeight: "700",
                                textAlign: "center",
                            }}
                            className="Shop-heading"
                        >
                            {type === constants.official ? constants.official : "User"} Made
                            Products
                        </h1>
                    </div>
                </div>
                <div className="row d-flex justify-content-between my-4">
                    <div className="col-md-9">
                        <p>
                            Showing Results {pageCount?.from} - {pageCount?.to} of{" "}
                            {pageCount?.total} Results
                        </p>
                        <p className="text text-danger">
                            *Check latest preview(s) in few moments (~5 mints time) or open product page once.
                        </p>
                    </div>
                    <div
                        className="col-md-3 d-flex justify-content-end"
                        style={{
                            position: "relative",

                            paddingRight: "20px",
                        }}
                    >
                        <input
                            type="text"
                            placeholder="Search Here"
                            value={searchText}
                            className="search-bar shop-search"
                            style={{
                                borderRadius: "0px",
                                border: "none",
                                borderBottom: "1px solid #242424",
                                width: "290px",
                                paddingLeft: "2%",
                            }}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                        <img
                            className="shop-search"
                            src="/assets/images/search.png"
                            style={{
                                position: "absolute",
                                bottom: "12px",
                                paddingRight: "12px",
                            }}
                            alt=""
                        />
                    </div>
                </div>

                <div className="row my-4">
                    <div
                        // Style Gap
                        className="col-md-9 d-flex justify-content-center mb-5 flex-wrap px-0 "
                        style={{ gap: "20px" }}
                    >
                        {loader ? (
                            <>
                                <PlaceHolderProducts />
                            </>
                        ) : productsData?.length === 0 ? (
                            <h4 style={{ fontWeight: "700" }}>No product found</h4>
                        ) : (
                            productsData?.map((item, i) => {
                                // console.log(item.user.id , user.id)
                                return (
                                    <div key={i}
                                        className="card  col-md-3 col-sm-12 col-12"
                                        style={{ width: "290px", rowGap: "10px" }}
                                    >
                                        <div
                                            className="col-md-12 d-flex justify-content-between align-items-center" style={{ padding:"1rem 1rem 0rem 1rem" }}>
                                            <h4 className="shop--heading" style={{ textTransform: 'capitalize' }}>{item.title}</h4>
                                            <p>${item.price + item.printful_price}</p>
                                        </div>
                                        <Link to={`/product-detail/${item.id}`}>
                                            <div className="col-md-12 d-flex justify-content-center">
                                            <img
                                                src={
                                                    (item.printful_product_id === 19 || item.printful_product_id === 300)
                                                        ? (item.placement === "Center" && item.front_mockup !== null
                                                            ? item.front_mockup
                                                            : (item.placement === "Right" && item.right_mockup !== null
                                                                ? item.right_mockup
                                                                : (item.placement === "Left" && item.left_mockup !== null
                                                                    ? item.left_mockup
                                                                    : (item.image
                                                                        ? process.env.REACT_APP_apiBaseURL + "/" + item.image
                                                                        : "")
                                                                )
                                                            )
                                                        )
                                                        : (item.image
                                                            ? process.env.REACT_APP_apiBaseURL + "/" + item.image
                                                            : "")
                                                }
                                                alt=""
                                                style={{
                                                    width: "290px",
                                                    height: "290px",
                                                    objectFit: "contain",
                                                }}
                                            />

                                            </div>
                                        </Link>
                                        <div
                                            className="col-md-12 d-flex justify-content-between align-items-center p-3">
                                            {
                                                type === constants.official && item.type==0
                                                    ?
                                                    <Link
                                                        to={`/store/${item.id}/customize`}
                                                        className="custom-anchor d-flex align-items-center"
                                                        style={{ fontSize: "14px ", columnGap: "5px" }}
                                                    >
                                                        <i className="fa fa-pencil" aria-hidden="true"></i>
                                                        Customize
                                                    </Link>
                                                    :
                                                    <Link
                                                        to={`/profile/${item.user?.user_name || item.user?.id}`}
                                                        className="custom-anchor d-flex align-items-center"
                                                        style={{ fontSize: "14px ", columnGap: "5px" }}
                                                    >
                                                        {" "}
                                                        <img
                                                            src={
                                                                item?.user?.photo
                                                                    ? item?.user?.photo.includes("http") ? item?.user?.photo : process.env.REACT_APP_apiBaseURL +
                                                                        "/" +
                                                                        item.user?.photo
                                                                    : "/assets/images/avatar1.png"
                                                            }
                                                            style={{ width: "20px", height: "20px" }}
                                                            alt=""
                                                        />
                                                        {item.user?.name}
                                                    </Link>
                                            }
                                            
                                               {item.type === 0 ? (<div
                                                    className="custom-anchor d-flex align-items-center"
                                                    style={{
                                                        fontSize: "14px ",
                                                        columnGap: "5px",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    <Link to={`/product-detail/${item.id}`}>
                                                        View Product
                                                    </Link>    
                                                </div>) : (<div
                                                    className="custom-anchor d-flex align-items-center"
                                                    style={{
                                                        fontSize: "14px ",
                                                        columnGap: "5px",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    <Link to={`/product-detail/${item.id}`}>
                                                        View Product
                                                    </Link>    
                                                </div>)} 
                                        </div>
                                    </div>
                                );
                            })
                        )}
                        {paginateLinks !== null ? (
                            <div className="col-md-12 d-flex justify-content-between pt-4">
                                <button
                                    className="get-started"
                                    onClick={() => {
                                        getUsersProductsData(
                                            pageIndex - 1,
                                            type,
                                            latestCategory,
                                            priceValue,
                                            searchText
                                        )
                                    }
                                    }
                                    style={{
                                        opacity: !paginateLinks.prev ? '0.5' : '1',
                                        width: '115px'
                                    }}
                                    disabled={!paginateLinks.prev}
                                >
                                    &larr; Previous
                                </button>
                                <button
                                    className="get-started"
                                    onClick={() => {
                                        getUsersProductsData(
                                            pageIndex + 1,
                                            type,
                                            latestCategory,
                                            priceValue,
                                            searchText
                                        )
                                    }
                                    }
                                    style={{
                                        opacity: !paginateLinks.next ? '0.5' : '1',
                                        width: '115px'
                                    }}
                                    disabled={!paginateLinks.next}
                                >
                                    Next &rarr;
                                </button>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                    <div
                        className="col-md-3 filter--shop"
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        <div className="card" style={{ width: "296px", height: "150px" }}>
                            <div className="card-header p-3">
                                <h4>Filter By Price ($)</h4>
                            </div>

                            <div className=" mb-0 px-3 pb-3 ">
                                <input
                                    className="w-100 "
                                    type="range"
                                    id="price"
                                    name="range"
                                    min="0"
                                    max="100"
                                    defaultValue="0"
                                    onChange={(e) => {
                                        let c = e.target.value;
                                        setPriceValue(c);
                                    }}
                                    onMouseUp={(e) =>
                                        getUsersProductsData(
                                            pageIndex,
                                            type,
                                            latestCategory,
                                            e.target.value,
                                            searchText
                                        )
                                    }
                                    style={{ accentColor: "black" }}
                                />
                                {/* <datalist id="sliderslist">
                    <option value="0" label="$10">
                      $10
                    </option>
                    <option value="250" label="$250">
                      $250
                    </option>
                    <option value="500" label="$500">
                      $500
                    </option>
                  </datalist> */}
                                {"$" + priceValue}
                            </div>

                            {/* <div className="card-footer">
                                <h4>Filter By Products</h4>

                                <div
                                    className="form-group d-flex flex-wrap"
                                    style={{ columnGap: "10px", rowGap: "10px" }}
                                >
                                    {categoryLoader ? (
                                        <PlaceHolderCategory />
                                    ) : categoryData?.length === 0 ? (
                                        <div className="text-center">
                                            <h4 style={{ fontWeight: "700", fontSize: "10px" }}>
                                                No category found
                                            </h4>
                                        </div>
                                    ) : (
                                        categoryData?.map((item, i) => {
                                            if (i <= 5) {
                                                return <div className="d-flex gap-1 " key={i}>
                                                    <input
                                                        type="radio"
                                                        id={item?.id}
                                                        name="filter"
                                                        value={item?.id}
                                                        onChange={(e) => {
                                                            getUsersProductsData(
                                                                pageIndex,
                                                                type,
                                                                e.target.value,
                                                                priceValue,
                                                                searchText
                                                            );
                                                            setLatestCategory(e.target.value);
                                                        }}
                                                    />
                                                    <label htmlFor="cup">{item.title}</label>
                                                </div>
                                            }
                                        })
                                    )}
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Shop;