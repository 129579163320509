import * as THREE from 'three';
import { Decal, useGLTF, useTexture } from "@react-three/drei";
import { useControls } from "leva";
import React, { useEffect, useState } from "react";
import { degToRad } from "three/src/math/MathUtils.js";
import cup from "../assets/images/cup.png"

export function Mug(props) {
    
  const defaultTexture = useTexture(cup);
  const [texture, setTexture] = useState(defaultTexture);
  const [rightTexture, setRightTexture] = useState(defaultTexture);
  const [leftTexture, setLeftTexture] = useState(defaultTexture);
  const { nodes, materials } = useGLTF("/bb.glb");

  useControls({
    angle: {
      min: degToRad(30),
      max: degToRad(360),
      value: 0.1,
      step: 0.01,
      onChange: (value) => {
        const x = Math.cos(value);
        const z = Math.sin(value);
        const rot = Math.atan2(x, z);
        setRotation(() => [0, rot, 0]);
        setPos((pos) => [x, pos[1], z]);
      },
    },
    posY: {
      min: 0,
      max: 3,
      value: 1.9,
      step: 0.01,
      onChange: (value) => {
        setPos((pos) => [pos[0], value, pos[2]]);
      },
    },
    scale: {
      min: 0.5,
      max: 3,
      value: 2.7,
      step: 0.01,
      onChange: (value) => {
        setScale(() => [value, value, 1.5]);
      },
    },
  });

  const [pos, setPos] = useState([0.5, 1.9, 0.87]);
  const [rotation, setRotation] = useState([0, 2.5, 0]);
  const [scale, setScale] = useState([2.7, 2.7, 2.7]);
  console.log("pos",pos);
  console.log("rotat",rotation);
  console.log("scla",scale);
  useEffect(() => {
    const loadTexture = async () => {
      try {
        const loadedTexture = await new Promise((resolve, reject) => {
          const tempTexture = new THREE.TextureLoader().load(
            props.design,
            resolve,
            undefined,
            reject
          );

          // Optional: Set additional properties for the texture here
          tempTexture.flipY = true;
          tempTexture.roughness = 1;
          tempTexture.wrapS = THREE.RepeatWrapping;
          tempTexture.wrapT = THREE.RepeatWrapping;
          tempTexture.magFilter = THREE.NearestFilter;
          tempTexture.minFilter = THREE.NearestFilter;
          tempTexture.isCanvasTexture = true;
          tempTexture.repeat.set(1, 1);
          tempTexture.name = 'Untitled-1';
          tempTexture.encoding = THREE.sRGBEncoding;

          // Resolve the promise with the loaded texture
          resolve(tempTexture);
        });

        // Set the loaded texture to the state
        setTexture(loadedTexture);
      } catch (error) {
        console.error('Error loading texture:', error);
      }
    };
    
    loadTexture();
  }, [props.design]);
// 
useEffect(() => {
  // console.log(props);
  const loadTexture = async () => {
    try {
      const loadedTexture = await new Promise((resolve, reject) => {
        const tempTexture = new THREE.TextureLoader().load(
          props.rightDesign,
          resolve,
          undefined,
          reject
        );

        // Optional: Set additional properties for the texture here
        tempTexture.flipY = true;
        tempTexture.roughness = 1;
        tempTexture.wrapS = THREE.RepeatWrapping;
        tempTexture.wrapT = THREE.RepeatWrapping;
        tempTexture.magFilter = THREE.NearestFilter;
        tempTexture.minFilter = THREE.NearestFilter;
        tempTexture.isCanvasTexture = true;
        tempTexture.repeat.set(1, 1);
        tempTexture.name = 'Untitled-1';
        tempTexture.encoding = THREE.sRGBEncoding;

        // Resolve the promise with the loaded texture
        resolve(tempTexture);
      });

      // Set the loaded texture to the state
      setRightTexture(loadedTexture);
    } catch (error) {
      console.error('Error loading texture:', error);
    }
  };
  
  loadTexture();
}, [props.rightDesign]);
// 
useEffect(() => {
  const loadTexture = async () => {
    try {
      const loadedTexture = await new Promise((resolve, reject) => {
        const tempTexture = new THREE.TextureLoader().load(
          props.leftDesign,
          resolve,
          undefined,
          reject
        );

        // Optional: Set additional properties for the texture here
        tempTexture.flipY = true;
        tempTexture.roughness = 1;
        tempTexture.wrapS = THREE.RepeatWrapping;
        tempTexture.wrapT = THREE.RepeatWrapping;
        tempTexture.magFilter = THREE.NearestFilter;
        tempTexture.minFilter = THREE.NearestFilter;
        tempTexture.isCanvasTexture = true;
        tempTexture.repeat.set(1, 1);
        tempTexture.name = 'Untitled-1';
        tempTexture.encoding = THREE.sRGBEncoding;

        // Resolve the promise with the loaded texture
        resolve(tempTexture);
      });

      // Set the loaded texture to the state
      setLeftTexture(loadedTexture);
    } catch (error) {
      console.error('Error loading texture:', error);
    }
  };
  
  loadTexture();
}, [props.leftDesign]);
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.Arc001_1.geometry}
        material={new THREE.MeshStandardMaterial({ color: new THREE.Color(props.color || 'white') })}
      />
      <mesh
        geometry={nodes.Arc001_1_1.geometry}
        material={new THREE.MeshStandardMaterial({ color: new THREE.Color(props.color || 'white') })}
      />
      <mesh
        geometry={nodes.Arc001_1_2.geometry}
        material={new THREE.MeshStandardMaterial({ color: new THREE.Color(props.color || 'white') })}
      />
      <mesh
        geometry={nodes.Arc001_1_3.geometry}
        material={new THREE.MeshStandardMaterial({ color: new THREE.Color(props.color || 'white') })}
      />
      <mesh geometry={nodes.Arc001_1_4.geometry}>
        <meshBasicMaterial transparent opacity={0} />
        <Decal
        position={[
          -0.9985209511592662,
          1.9,
          0.054368282076909066
      ]}
        rotation={[
          0,
          -1.5164012244017009,
          0
      ]}
          scale={scale} 
        >
          <meshStandardMaterial
            map={texture}
            toneMapped={false}
            transparent
            polygonOffset
            polygonOffsetFactor={-1}
          />
        </Decal>
        <meshBasicMaterial transparent opacity={0} />
        <Decal
          position={[
            -0.13597865354865415,
            1.7899999999999998,
            0.9907117672557922
        ]} 
          rotation={[
            0,
            -0.2964012244017011,
            0
        ]}
          scale={scale} 
        >
          <meshStandardMaterial
            map={rightTexture}
            toneMapped={false}
            transparent
            polygonOffset
            polygonOffsetFactor={-1}
          />
        </Decal>
        <meshBasicMaterial transparent opacity={0} />
        <Decal
          position={[
            -0.22329296208719762,
            1.74,
            -0.9747513801387128
        ]} 
          rotation={[
            0,
            -2.8664012244017014,
            0
        ]}
          scale={scale} 
        >
          <meshStandardMaterial
            map={leftTexture}
            toneMapped={false}
            transparent
            polygonOffset
            polygonOffsetFactor={-1}
          />
        </Decal>
      </mesh>
      
      {/* <mesh 
        geometry={nodes.Cylinder.geometry}
        material={materials["cylinder"]}
      /> */}
    </group>
  );
}

useGLTF.preload("/bb.glb");
