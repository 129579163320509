import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import {useNavigate} from "react-router-dom";
import img1 from "../../assets/images/cup.png";
import add from "../../assets/images/Emoji Icon (1).svg";
import gallary from "../../assets/images/newsfeed/Group 929.svg";
import tag1 from "../../assets/images/newsfeed/Group 930.svg";
import EmojiModal from "./EmojiModal";
import TagModal from "./TagModal";
import Slider from "react-slick";
import {submitPost} from "../../services/NewsFeedsService";
import {toast} from "react-toastify";
import Spinner from "react-bootstrap/esm/Spinner";
import {BiDollar} from "react-icons/bi";

const CreateCustomizerPost = (props) => {
    const domProps = Object.assign({}, props);
    delete domProps.setEditPost;
    const [users, setUsers] = useState([]);
    const [description, setDescription] = useState("");
    const [title, setTitle] = useState("");
    const [price, setPrice] = useState(0);
    const [type, setType] = useState(0);
    const [loader, setLoader] = useState(false);

    const [isPaidPost, setIsPaidPost] = useState(false);

    ///Selected Tag users From multiSelect
    const [selectedValues, setSelectedValues] = useState([]);
    const navigate = useNavigate();

    /// tag Modal True/False
    const [tag, setTag] = useState(false);

    ///Emoji Modal true / false
    const [emoji, setEmoji] = useState(false);

    const setEmojiDropdown = () => {
        if (emoji) {
            setEmoji(false);
        } else {
            setEmoji(true);
        }
    };

    const handleChecked = () => {
        setIsPaidPost((prevState) => !prevState);
    };

    const SubmitPost = async () => {
        setLoader(true);

        let finalData = {
            attachments: [props.item],
            description: description,
        };

        if (isPaidPost) {
            finalData["title"] = title;
            finalData["amount"] = price;
            finalData["post_type"] = type;
            finalData["canvas"] = localStorage.getItem("CANVAS");
        }

        const response = await submitPost(finalData);
        if (response.status === 201) {
            toast.success("Post created");
            localStorage.removeItem("CANVAS");
            localStorage.removeItem("CANVAS_UPDATED_AT");
            navigate("/newsfeed");
            props.setEditPost(false);
            setLoader(false);
        } else {
            toast.error(response.data.message);
            props.setEditPost(false);
            setLoader(false);
        }
    };
    return (
        <>
            <Modal
                {...domProps}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="editpost--section--modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title
                        id="contained-modal-title-vcenter"
                        style={{fontSize: "16px", fontWeight: "700"}}
                    >
                        Create Post
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{height: "462px", overflowY: "auto"}}>
                    <div className="p-3">
                        <div
                            className="d-flex align-items-center "
                            style={{columnGap: "10px"}}
                        >
                            {/* <img
                                src={
                                    props.item?.user?.photo
                                        ? process.env.REACT_APP_apiBaseURL +
                                        "/" +
                                        props.item?.user?.photo
                                        : img1
                                }
                                alt=""
                                style={{
                                    width: "40px",
                                    height: "40px",
                                    objectFit: "cover",
                                    border: "1px solid #E9E9E9",
                                    borderRadius: "50%",
                                }}
                            /> */}
                            <h5 className="card-title me-2 mb-0">{props.item?.user?.name}</h5>
                        </div>
                        <div className="my-3">
                          <textarea
                              rows="2"
                              className="w-100"
                              placeholder="Share Something"
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                              autoFocus
                          ></textarea>
                        </div>

                        {/* Toggle */}

                        <div className="mt-3">
                            <div className="d-flex justify-content-between w-100">
                                <p style={{marginBottom: "0px"}}>Is it Paid Post?</p>
                                <label className="switchone">
                                    <input
                                        type="checkbox"
                                        checked={isPaidPost}
                                        onChange={handleChecked}
                                    />
                                    <span className="sliderone roundone"></span>
                                </label>
                            </div>
                            {isPaidPost && (
                                <div>
                                    {/* first */}

                                    <div
                                        className="d-flex justify-content-between align-items-baseline mb-3"
                                        style={{
                                            width: "100%",
                                            marginTop: "10px",
                                            columnGap: "40px",
                                        }}
                                    >
                                        <div>
                                            <h5
                                                className="my-0"
                                                style={{fontSize: "14px", fontWeight: "500"}}
                                            >
                                                Title{" "}
                                            </h5>
                                        </div>

                                        <div
                                            className="d-flex justify-content-between align-items-center   "
                                            style={{
                                                border: "1px solid #242424",
                                                borderRadius: "50px",
                                                fontSize: "14px",
                                                width: "100%",
                                                height: "30px",
                                            }}
                                        >
                                            <input
                                                className="px-2 py-1 "
                                                id="modal"
                                                style={{
                                                    backgroundColor: "white ",
                                                    width: "96%",
                                                    height: "20px",
                                                    marginLeft: "10px",
                                                    border: "none",
                                                }}
                                                type="text"
                                                value={title}
                                                onChange={(e) => setTitle(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    {/* End */}

                                    {/* Second */}

                                    <div
                                        className="d-flex  align-items-baseline mb-3"
                                        style={{width: "100%", columnGap: "35px"}}
                                    >
                                        <div>
                                            <h5
                                                className="my-0"
                                                style={{fontSize: "14px", fontWeight: "500"}}
                                            >
                                                Price{" "}
                                            </h5>
                                        </div>

                                        <div
                                            className="d-flex justify-content-between align-items-center   "
                                            style={{
                                                border: "1px solid #242424",
                                                borderRadius: "50px",
                                                fontSize: "14px",
                                                width: "140px",
                                                height: "30px",
                                            }}
                                            s
                                        >
                                            <BiDollar size={15}/>
                                            <input
                                                className="px-2 py-1 input__number "
                                                id="modal"
                                                style={{
                                                    backgroundColor: "white ",
                                                    width: "100%",
                                                    height: "20px ",
                                                    border: "none",
                                                    outline: "none",
                                                    marginRight: "1px",
                                                }}
                                                type="number"
                                                value={price}
                                                onChange={(e) => setPrice(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    {/* End */}

                                    {/* Third Field */}

                                    <div
                                        className="d-flex  align-items-center"
                                        style={{width: "100%", columnGap: "18px"}}
                                    >
                                        <div>
                                            <h5
                                                className="my-0"
                                                style={{fontSize: "14px", fontWeight: "500"}}
                                            >
                                                Save As
                                            </h5>
                                        </div>
                                        <div className="d-flex" style={{columnGap: "10px"}}>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="flexRadioDefault"
                                                    id="flexRadioDefault1"
                                                    value="0"
                                                    checked={type.toString() === "0" ? true : false}
                                                    onChange={(e) => setType(e.target.value)}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="flexRadioDefault1"
                                                    style={{fontSize: "14px", fontWeight: "500"}}
                                                >
                                                    Creation
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="flexRadioDefault"
                                                    id="flexRadioDefault2"
                                                    value="1"
                                                    checked={type.toString() === "1" ? true : false}
                                                    onChange={(e) => setType(e.target.value)}
                                                />
                                                <label
                                                    className="form-check-label"
                                                    htmlFor="flexRadioDefault2"
                                                    style={{fontSize: "14px", fontWeight: "500"}}
                                                >
                                                    Product
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    {/* End Third */}
                                </div>
                            )}
                        </div>

                        <Slider className="share-modal-slider" arrows={true} dots={false}>
                            <div className="d-flex justify-content-center my-3 img--div">
                                {props?.item && (
                                    <img
                                        className="post-images"
                                        src={URL.createObjectURL(props?.item)}
                                        alt=""
                                    />
                                )}
                            </div>
                        </Slider>
                    </div>
                    <div className="parent--div mx-3">
                        <div>
                            <h1 className="heading">Add to Your Post</h1>
                        </div>
                        <div
                            className="d-flex align-items-center"
                            style={{columnGap: "10px"}}
                        >
                            {/*{emoji ? (*/}
                            {/*    <div className="create--custom--emoji">*/}
                            {/*        <EmojiModal setText={setDescription} text={description}/>*/}
                            {/*    </div>*/}
                            {/*) : (*/}
                            {/*    ""*/}
                            {/*)}*/}
                            {/*<img*/}
                            {/*    src={add}*/}
                            {/*    alt=""*/}
                            {/*    onClick={setEmojiDropdown}*/}
                            {/*    style={{cursor: "pointer", width: "21px", height: "21px"}}*/}
                            {/*/>*/}

                            <img src={tag1} onClick={() => setTag(true)} alt=""/>
                        </div>
                    </div>
                    <div className="d-flex m-3 justify-content-center">
                        {loader ? (
                            <Spinner/>
                        ) : (
                            <button className="edit--post--btn" onClick={() => SubmitPost()}>
                                Save
                            </button>
                        )}
                    </div>
                </Modal.Body>
            </Modal>
            <TagModal
                show={tag}
                onHide={() => setTag(false)}
                users={users}
                setSelectedValues={setSelectedValues}
                selectedValues={selectedValues}
            />
        </>
    );
};

export default CreateCustomizerPost;
