import { useNavigate } from "react-router";

const ReadyCollection = ({ data }) => {

  const navigate = useNavigate();
  return (
    <section
      className="collection-section py-5"
      style={{ backgroundColor: "#EDEDED" }}
    >
      {data?.data?.map((i) => {
        if (i.type === "readyCollection") {
          {/* console.log(i) */ }
          return (
            <div className="container d-flex flex-column justify-content-center">
              <div className="row d-flex justify-content-center">
                <div className="pb-5 col-md-12 d-flex flex-column justify-content-center text-center">
                  <h2
                    className="mb-2"
                    style={{ fontFamily: "Brittany Signature" }}
                  >
                    {i.pre_heading}
                  </h2>
                  <h2 className="heading">{i.heading}</h2>
                  <p>{i.description}</p>
                </div>
              </div>

              <div className="row gy-4 justify-content-around">
                <div
                  className="col-sm-12 col-md-6 col-lg-5 d-flex justify-content-center align-items-center card collection"
                  style={{
                    // backgroundSize: 'cover',
                    backgroundImage: i.images.length > 0 && i?.images[0].hasOwnProperty('image') && i.images[0].image.length > 0 ? `url(${process.env.REACT_APP_apiBaseURL + '/' + i.images[0].image})` : "url('/assets/images/official-products (1).png')",
                    cursor: 'pointer'
                  }}
                  onClick={() => navigate('/shop/official')}
                ></div>
                <div
                  className="col-sm-12 col-md-6 col-lg-5 d-flex justify-content-center align-items-center card collection"
                  style={{
                    // backgroundSize: 'cover',
                    backgroundImage: i.images.length > 0 && i?.images[0].hasOwnProperty('image') && i.images[1].image.length > 0 ? `url(${process.env.REACT_APP_apiBaseURL + '/' + i.images[1].image})` : "url('/assets/images/user-made-products.png')",
                    cursor: 'pointer'
                  }}
                  onClick={() => navigate('/shop/user')}
                ></div>
              </div>
              <div className="row justify-content-around my-3">
                <div className="col-sm-12 col-md-6 col-lg-5">
                  <h1
                    style={{
                      fontSize: "28px",
                      textTransform: "uppercase",
                      textAlign: "center",
                      cursor: 'pointer'
                    }}
                    onClick={() => navigate('/shop/official')}
                  >
                    official products
                  </h1>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-5">
                  <h1
                    style={{
                      fontSize: "28px",
                      textTransform: "uppercase",
                      textAlign: "center",
                      cursor: 'pointer'
                    }}
                    onClick={() => navigate('/shop/user')}
                  >
                    user made products
                  </h1>
                </div>
              </div>
            </div>
          );
        }
      })}
    </section>
  );
};

export default ReadyCollection;
