import React, {useRef, useState} from "react";
import Modal from "react-bootstrap/Modal";
import {canvasPreview} from "../uploads/CanvasPreview";
import {useDebounceEffect} from "../uploads/useDebounceEffect";
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import {uploadProfileImage} from "../../services/ProfileService";
import {convertToFormData} from "../../services/customizerService";
import {constants} from "../Constants/constants";
import {toast} from "react-toastify";

function CoverPhotoModal(props) {
    /// state managements
    const [valid, setValid] = useState({
        imageValid: "",
        canvaPreview: "",
    })

    const [imgSrc, setImgSrc] = useState("");
    const previewCanvasRef = useRef(null);
    const imgRef = useRef(null);
    const [completedCrop, setCompletedCrop] = useState();
    const [scale, setScale] = useState(1);
    const [rotate, setRotate] = useState(0);
    const [aspect, setAspect] = useState(16 / 9);
    const [crop, setCrop] = useState();

    /// Functions
    function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
        return centerCrop(
            makeAspectCrop(
                {
                    unit: "%",
                    width: 90,
                },
                aspect,
                mediaWidth,
                mediaHeight
            ),
            mediaWidth,
            mediaHeight
        );
    }

    /// cropper Image load
    function onImageLoad(e) {
        if (aspect) {
            const {width, height} = e.currentTarget;
            props.setCrop(centerAspectCrop(width, height, aspect));
        }
    }

    /// on image upload input function
    function onSelectFile(e) {
        if (e.target.files && e.target.files.length > 0) {
            setCrop(undefined); // Makes crop preview update between images.
            const reader = new FileReader();
            reader.addEventListener("load", () =>
                setImgSrc(reader.result?.toString() || "")
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    /// onsubmit api hit and cover canvas cropped image into object
    const onSubmit = async () => {
        if (imgSrc === "") {
            setValid({
                ...valid,
                imageValid: "image is required"
            })
            return
        } else {
            setValid({
                ...valid,
                imageValid: ""
            })
        }
        if (!completedCrop) {
            setValid({
                ...valid,
                imageValid: "image is not cropped please crop first"
            })
            return
        } else {
            setValid({
                ...valid,
                imageValid: ""
            })
        }
        props.onHide();
        let output = previewCanvasRef.current.toDataURL("png");
        props.setImagData(output);
        const trimmedString = output.replace("data:image/png;base64,", "");
        const imageContent = atob(trimmedString);
        const buffer = new ArrayBuffer(imageContent.length);
        const view = new Uint8Array(buffer);
        for (let n = 0; n < imageContent.length; n++) {
            view[n] = imageContent.charCodeAt(n);
        }
        const type = "image/jpeg";
        const blob = new Blob([buffer], {type});
        let file = new File([blob], "stickfigure", {
            lastModified: new Date().getTime(),
            type,
        });
        if (file) {
            // setFileData(file)
            setImgSrc("")
            const finalData = {
                photo: file,
                type: constants.cover,
                timeline: "Uploaded His Cover Photo",
            };
            const formData = convertToFormData(finalData);

            const response = await uploadProfileImage(formData);
            if (response.status === 201) {
                setImgSrc("")
                setCompletedCrop("")
                let newPost = response.data.data;
                props.setPostsData((state) => [newPost, ...state]);
                toast.success("Cover Photo updated");
            } else {
                toast.error(response.data.message);
            }
        }
        props.onHide();
    };

    /// useEffect when the dependency of variable changes
    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    scale,
                    rotate
                );
            }
        },
        100,
        [completedCrop, scale, rotate]
    );


    const emptyImageData = () => {
        setImgSrc("")
        setCompletedCrop("")
        previewCanvasRef.current = null
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <div className="d-flex align-items-center justify-content-between m-3">
                <Modal.Title id="contained-modal-title-vcenter">
                    <h4>Upload Image</h4>
                </Modal.Title>
                <Modal.Header className=" border-0 pb-0 " closeButton onClick={() => emptyImageData()}>
                </Modal.Header>
            </div>

            <Modal.Body
                className="text-center"
                style={{background: "#FFFFFF", borderRadius: "3px"}}
            >
                {/* {!!completedCrop && ( */}
                <canvas
                    ref={previewCanvasRef}
                    style={{
                        width: "100%",
                        height: "30vh",
                        objectFit: "cover",
                        border: "1px dotted black",
                        //   width: completedCrop.width,
                        //   height: completedCrop.height,
                    }}
                />
                <input type="file" accept="image/*" onChange={onSelectFile}/>
                {!!imgSrc && (
                    <>
                        <dev id="contained-modal-title-vcenter">
                            <h4>Crop Image</h4>
                        </dev>
                        <ReactCrop
                            crop={crop}
                            onChange={(_, percentCrop) => setCrop(percentCrop)}
                            onComplete={(c) => setCompletedCrop(c)}
                            aspect={aspect}
                        >
                            <img
                                ref={imgRef}
                                alt="Crop me"
                                src={imgSrc}
                                style={{
                                    transform: `scale(${scale}) rotate(${rotate}deg)`,
                                }}
                                onLoad={onImageLoad}
                            />
                        </ReactCrop>
                    </>
                )}
            </Modal.Body>
            <Modal.Footer className="d-flex flex-column justify-content-center border-0 mb-3">
                <button
                    className="get-started"
                    onClick={onSubmit}
                    style={{width: "122px", height: "50px"}}
                >
                    Done
                </button>
                <div><p className="text-danger" style={{fontSize: "8px"}}>{valid.imageValid}</p></div>
            </Modal.Footer>
        </Modal>
    );
}

export default CoverPhotoModal;
