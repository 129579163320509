import React, { useState } from 'react'

import Modal from 'react-bootstrap/Modal';
import NewsFeedPosts from '../NewsFeedPosts';

function CommentsModal({ CommentsShow, setCommentsShow, item,
    uniqueKey,
    converttime,
    setPostsData,
    likesFormat,
    dislikeFormat,

}) {

    const [newsFeedCommentStatus, setNewsFeedCommentStatus] = useState(true)


    const closeModal = () => {
        setCommentsShow(false)
       
        setPostsData((prevState) => {
            return prevState?.map((post) => {
                const lastTwoComments = post?.comments
                    ?.filter(comment => comment.created_at)
                    ?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                    ?.slice(0, 2);
                return {
                    ...post,
                    comments: lastTwoComments,
                };
            });
        });
    }

    return (
        <>
            <Modal
                show={CommentsShow}
                onHide={() => closeModal()}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
                className="comment_modal"
            >
                <Modal.Header closeButton  >
                    <Modal.Title id="example-custom-modal-styling-title">
                        {item?.description}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="comments_modal_body">
                    <NewsFeedPosts item={item}
                        unique={uniqueKey}
                        converttime={converttime}
                        setPostsData={setPostsData}
                        likesFormat={likesFormat}
                        dislikeFormat={dislikeFormat}
                        newsFeedCommentStatus={newsFeedCommentStatus}
                        setNewsFeedCommentStatus={setNewsFeedCommentStatus}
                    />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default CommentsModal