import api from "./api";

export const getProfile = async () => {
  try {
    const response = await api.get("/api/v1/profile");
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getUserById = async (id) => {
  try {
    const response = await api.get(`/api/v1/profile/${id}`);
    return response;
  } catch (error) {
    return error.response;
  }
};
export const followUserById = async (id) => {
  try {
    const response = await api.get(`/api/v1/follow/${id}`);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const uploadProfileImage = async (payload) => {
  try {
    const response = await api.post(`/api/v1/upload/profile`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
};
export const updateProfile = async (payload) => {
  try {
    const response = await api.put(`/api/v1/profile`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
};


export const getCountries = async () => {
  try {
    const response = await api.get(`/api/v1/countries`);
    return response;
  } catch (error) {
    return error.response;
  }
};
export const getAddress = async () => {
  try {
    const response = await api.get(`/api/v1/addresses`);
    return response;
  } catch (error) {
    return error.response;
  }
};


export const addNewAddress = async (payload) => {
  try {
    const response = await api.post(`/api/v1/addresses`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
};
export const UpdateAddress = async (payload, id) => {
  try {
    const response = await api.put(`/api/v1/addresses/${id}`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const DeleteAddress = async (id) => {
  try {
    const response = await api.delete(`/api/v1/addresses/${id}`);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const change_password = async (payload) => {
    try {
        const response = await api.post(`/api/v1/change_password`, payload);
        return response;
    } catch (error) {
        return error.response;
    }
}

export const submitSellerInfo = async (payload) => {
  try {
      const response = await api.post(`/api/v1/seller_info`, payload);
      return response;
  } catch (error) {
      return error.response;
  }
}

export const getUserPrimaryAccount = async () => {
  try {
      const response = await api.get(`/api/v1/account/primary`);
      return response;
  } catch (error) {
      return error.response;
  }
}

export const getUserCards = async () => {
  try {
      const response = await api.get(`/api/v1/methods`);
      return response;
  } catch (error) {
      return error.response;
  }
}

export const AttachPaymentMethod = async (payload) => {
  try {
      const response = await api.post(`/api/v1/methods`, payload);
      return response;
  } catch (error) {
      return error.response;
  }
}

export const updateUserCard = async (id, payload) => {
  try {
      const response = await api.put(`/api/v1/methods/${id}`, payload);
      return response;
  } catch (error) {
      return error.response;
  }
}

export const deleteCard = async (id) => {
  try {
      const response = await api.delete(`/api/v1/methods/${id}`);
      return response;
  } catch (error) {
      return error.response;
  }
}


export const updateDobVisibility = async (paylaod) => {
  try {
      const response = await api.put(`/api/v1/profile`, paylaod);
      return response;
  } catch (error) {
      return error.response;
  }
}
