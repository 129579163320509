import React, {useEffect, useState} from "react";

import wall from "../assets/images/icons/theme.svg";
// import vid from "../assets/images/icons/vid.mp4";
import icon from "../assets/images/icons/ArrowCircleLeft.svg";
import {Link} from "react-router-dom";
import custom from "../assets/images/icons/custom.svg";
import gallary from "../assets/images/icons/gal.svg";
import {all_enjoy_social_media, customize_own_products} from "../services/GettingStarted";
import Accordion from "react-bootstrap/Accordion";
import plus from "../assets/images/icons/plus.svg";

const SocialMedia = () => {
    const [page, setPayload] = useState([]);
    const [banner, setBanner] = useState(null);
    const [hero, setHero] = useState(null);
    const [dummy, setDummy] = useState(null);
    const [questions, setQuestions] = useState(null);

    const getAllEnjoySocialMedia = async () => {
        const response = await all_enjoy_social_media();
        if (response?.status === 200) {
            setPayload(response.data.data);
            for (let i = 0; i < response.data.data.length; i++) {
                if (response.data.data[i].type === 'hero') {
                    setHero(response.data.data[i]);
                }
                if (response.data.data[i].type === 'banner') {
                    setBanner(response.data.data[i]);
                }
                if (response.data.data[i].type === 'dummy') {
                    setDummy(response.data.data[i]);
                }
                if (response.data.data[i].type === 'question') {
                    setQuestions(response.data.data[i]);
                }
            }
        }
    }
    useEffect(() => {
        getAllEnjoySocialMedia();
    }, []);

    return (<div>
        <div className="container mb-5">
            <div style={{display: "flex", alignItems: "center"}}>
                <Link to="/">
                    <img className="Arrow-icon" src={icon} alt="Arrow"/>{" "}
                </Link>
                <h1
                    className="py-4 footer-headings"
                    style={{marginRight: "auto", marginLeft: "auto"}}
                >
                    ENJOY A NEW KIND OF SOCIAL MEDIA
                </h1>
            </div>

            {banner !== null && banner?.images[0]?.hasOwnProperty('image') ? <img
                style={{
                    display: "flex", marginLeft: "auto", marginRight: "auto", paddingTop: "2%", paddingBottom: "2%",
                }}
                src={process.env.REACT_APP_apiBaseURL + '/' + banner.images[0]?.image}
                alt=""
            /> : <img
                style={{
                    display: "flex", marginLeft: "auto", marginRight: "auto", paddingTop: "2%", paddingBottom: "2%",
                }}
                src={wall}
                alt=""
            />}

            <p
                style={{
                    textAlign: "center", marginLeft: "5%", marginRight: "5%", paddingTop: "2%",
                }}
            >
                {banner?.description}
            </p>
            <button
                className="py-2 px-4 "
                style={{
                    borderRadius: "40px",
                    border: "1px solid #242424B ",
                    backgroundColor: "white",
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                }}
            >
                <img src={custom} style={{marginRight: "10px"}} alt=""/>
                Launch Customizer
            </button>
        </div>
        <div style={{backgroundColor: "white"}}>
            <div className="container d-flex justify-content-center">
                <div className=" row col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-5">
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 ">
                        {hero !== null && hero?.images[0]?.hasOwnProperty('image') ? <img
                            style={{
                                display: "flex",
                                marginLeft: "auto",
                                marginRight: "auto",
                                paddingTop: "2%",
                                paddingBottom: "2%",
                            }}
                            src={process.env.REACT_APP_apiBaseURL + '/' + hero.images[0]?.image}
                            alt=""
                        /> : <img
                            style={{
                                display: "flex",
                                marginLeft: "auto",
                                marginRight: "auto",
                                paddingTop: "2%",
                                paddingBottom: "2%",
                            }}
                            src={wall}
                            alt=""
                        />}
                    </div>
                    {hero !== null ? <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 ">
                        <h1 className="pt-4">{hero?.heading || ""}</h1>
                        <p
                            style={{
                                paddingTop: "2%",
                            }}
                        >
                            {hero.pre_heading}
                        </p>
                        <button
                            className="py-2 px-4"
                            style={{
                                borderRadius: "40px", border: "1px solid #242424B ", backgroundColor: "white",
                            }}
                        >
                            <img src={gallary} style={{marginRight: "10px"}} alt=""/>{" "}
                            Memes Creation
                        </button>
                    </div> : <></>}
                </div>
            </div>
        </div>
        {dummy !== null ? <div>
            <div className="container d-flex justify-content-center">
                <div className=" row col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-5">
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 ">
                        <h1 className="pt-4">{dummy.heading}</h1>
                        <p
                            style={{
                                paddingTop: "2%",
                            }}
                        >
                            {dummy?.description || ""}
                        </p>
                        <button
                            className="py-2 px-4"
                            style={{
                                borderRadius: "40px",
                                border: "1px solid #242424B ",
                                backgroundColor: "white",
                                marginBottom: '10%'
                            }}
                        >
                            <img src={gallary} style={{marginRight: "10px"}} alt=""/>{" "}
                            Memes Creation
                        </button>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 ">
                        {dummy !== null && dummy?.images[0]?.hasOwnProperty('image') ? <img
                            style={{
                                display: "flex",
                                marginLeft: "auto",
                                marginRight: "auto",
                                paddingTop: "2%",
                                paddingBottom: "2%",
                            }}
                            src={process.env.REACT_APP_apiBaseURL + '/' + dummy.images[0]?.image}
                            alt=""
                        /> : <img
                            style={{
                                display: "flex",
                                marginLeft: "auto",
                                marginRight: "auto",
                                paddingTop: "2%",
                                paddingBottom: "2%",
                            }}
                            src={wall}
                            alt=""
                        />}
                    </div>
                </div>
            </div>
        </div> : <> </>}


        {/*<div style={{backgroundColor: '#F3F3F3'}}>*/}
        {/*    <div className="py-5 container ">*/}
        {/*        <h1 className="d-flex justify-content-center">ENJOY A NEW KIND OF SOCIAL MEDIA</h1>*/}
        {/*        <p*/}
        {/*            style={{*/}
        {/*                textAlign: "center",*/}
        {/*                marginLeft: "5%",*/}
        {/*                marginRight: "5%",*/}
        {/*            }}*/}
        {/*        >*/}
        {/*            It is a long established fact that a reader will be distracted by the*/}
        {/*            readable content of a page when looking at its layout. The point of*/}
        {/*            using Lorem Ipsum is that it has a more-or-less normal distribution of*/}
        {/*            letters, as opposed to using 'Content here, content here', making it*/}
        {/*            look like readable English. Many desktop publishing packages and web*/}
        {/*            page editors now use Lorem Ipsum as their default model text, and a*/}
        {/*            search for 'lorem ipsum' will uncover many web sites still in their*/}
        {/*            infancy. Various versions have evolved over the years, sometimes by*/}
        {/*            accident, sometimes on purpose (injected humour and the like).It is a*/}
        {/*            long established fact that a reader will be distracted by the readable*/}
        {/*            content of a page when looking at its layout. The point of using Lorem*/}
        {/*            Ipsum is that it has a more-or-less normal distribution of letters, as*/}
        {/*            opposed to using 'Content here, content here', making it look like*/}
        {/*            readable English. Many desktop publishing packages and web page*/}
        {/*            editors now use Lorem Ipsum as their default model text, and a search*/}
        {/*            for 'lorem ipsum' will uncover many web sites still in their infancy.*/}
        {/*            Various versions have evolved over the years, sometimes by accident.*/}
        {/*        </p>*/}
        {/*        <button*/}
        {/*            className="py-2 px-4 "*/}
        {/*            style={{*/}
        {/*                borderRadius: "40px",*/}
        {/*                border: "1px solid #242424B ",*/}
        {/*                backgroundColor: "white",*/}
        {/*                display: "flex",*/}
        {/*                alignItems: "center",*/}
        {/*                marginLeft: "auto",*/}
        {/*                marginRight: "auto",*/}
        {/*            }}*/}
        {/*        >*/}
        {/*            <img src={gallary} style={{marginRight: "10px"}} alt=""/>*/}
        {/*            Create Your Products*/}
        {/*        </button>*/}
        {/*    </div>*/}
        {/*</div>*/}


        {/*<div style={{backgroundColor: "white"}}>*/}
        {/*    <div className="container d-flex justify-content-center">*/}
        {/*        <div className=" row col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-5">*/}
        {/*            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 ">*/}
        {/*                /!* <video width="100%" height="330" src={vid} controls /> *!/*/}
        {/*            </div>*/}
        {/*            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 ">*/}
        {/*                <h1 className="pt-4">Dummy Heading</h1>*/}
        {/*                <p*/}
        {/*                    style={{*/}
        {/*                        paddingTop: "2%",*/}
        {/*                    }}*/}
        {/*                >*/}
        {/*                    It is a long established fact that a reader will be distracted*/}
        {/*                    by the readable content of a page when looking at its layout.*/}
        {/*                    The point of using Lorem Ipsum is that it has a more-or-less*/}
        {/*                    normal distribution of letters more-or-less normal .*/}
        {/*                </p>*/}
        {/*                <p>*/}
        {/*                    eadable content of a page when looking at its layout. The point*/}
        {/*                    of using Lorem Ipsum is that it has a more-or-less normal*/}
        {/*                    distribution of*/}
        {/*                </p>*/}
        {/*                <button*/}
        {/*                    className="py-2 px-4"*/}
        {/*                    style={{*/}
        {/*                        borderRadius: "40px",*/}
        {/*                        border: "1px solid #242424B ",*/}
        {/*                        backgroundColor: "white",*/}
        {/*                    }}*/}
        {/*                >*/}
        {/*                    <img src={gallary} style={{marginRight: "10px"}} alt=""/>{" "}*/}
        {/*                    Memes Creation*/}
        {/*                </button>*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</div>*/}
        {/*<div>*/}
        {/*    <div className="container d-flex justify-content-center">*/}
        {/*        <div className=" row col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-5">*/}
        {/*            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 ">*/}
        {/*                <h1 className="pt-4">Dummy Heading</h1>*/}
        {/*                <p*/}
        {/*                    style={{*/}
        {/*                        paddingTop: "2%",*/}
        {/*                    }}*/}
        {/*                >*/}
        {/*                    It is a long established fact that a reader will be distracted*/}
        {/*                    by the readable content of a page when looking at its layout.*/}
        {/*                    The point of using Lorem Ipsum is that it has a more-or-less*/}
        {/*                    normal distribution of letters more-or-less normal .*/}
        {/*                </p>*/}
        {/*                <p>*/}
        {/*                    eadable content of a page when looking at its layout. The point*/}
        {/*                    of using Lorem Ipsum is that it has a more-or-less normal*/}
        {/*                    distribution of*/}
        {/*                </p>*/}
        {/*                <button*/}
        {/*                    className="py-2 px-4"*/}
        {/*                    style={{*/}
        {/*                        borderRadius: "40px",*/}
        {/*                        border: "1px solid #242424B ",*/}
        {/*                        backgroundColor: "white",*/}
        {/*                        marginBottom: '10%'*/}
        {/*                    }}*/}
        {/*                >*/}
        {/*                    <img src={gallary} style={{marginRight: "10px"}} alt=""/>{" "}*/}
        {/*                    Memes Creation*/}
        {/*                </button>*/}
        {/*            </div>*/}
        {/*            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 ">*/}
        {/*                /!* <video width="100%" height="330" src={vid} controls /> *!/*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</div>*/}
    </div>);
};

export default SocialMedia;
