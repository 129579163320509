import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import img1 from "../../assets/images/cup.png";
import add from "../../assets/images/Emoji Icon (1).svg";
import gallary from "../../assets/images/newsfeed/Group 929.svg";
import tag1 from "../../assets/images/newsfeed/Group 930.svg";
import EmojiModal from "./EmojiModal";
import TagModal from "./TagModal";
import Slider from "react-slick";
import { updatePost } from "../../services/NewsFeedsService";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/esm/Spinner";
import { useNavigate } from "react-router";

const EditPostModal = (props) => {
  const [images, setImages] = useState();
  const [text, setText] = useState("");
  const [users, setUsers] = useState([]);
  const [description, setDescription] = useState()
  const [deleteImage, setDeleteImage] = useState([])
  const [loader, setLoader] = useState(false)

  const navigate = useNavigate();
  ///Selected Tag users From multiSelect
  const [selectedValues, setSelectedValues] = useState([]);

  ///uplaod Files Array From input file
  const [editSelectedFiles, setEditSelectedFiles] = useState([]);

  useEffect(() => {
    setImages(props.item.attachments);
    setDescription(props.item?.description)
  }, [props.item?.description]);

  /// tag Modal True/False
  const [tag, setTag] = useState(false);

  ///Emoji Modal true / false
  const [emoji, setEmoji] = useState(false);

  // remove Img
  function removeImage(index, ImagePath) {

    if (ImagePath) {
      deleteImage.push(ImagePath)
      setImages((prevSelectedFiles) =>
        Array.from(prevSelectedFiles).filter((file, i) => i !== index)
      );
    } else {
      setEditSelectedFiles((prevSelectedFiles) =>
        Array.from(prevSelectedFiles).filter((file, i) => i !== index)
      );
    }
  }

  async function handleFileInputEdit(event) {
    setEditSelectedFiles(event.target.files);
  }

  const setEmojiDropdown = () => {
    if (emoji) {
      setEmoji(false);
    } else {
      setEmoji(true);
    }
  };


  const SubmitPost = async () => {

    let files = [];
    //// converting to arrays
    setLoader(true)
    Array.from(editSelectedFiles).map((item) => {
      files.push(item);
    });
    let finalData = {
      new_attachments: files,
      attachments: images,
      description: description,
      deleted_imgs: deleteImage
    }
    const response = await updatePost(props.item.id, finalData);
    if (response.status === 200) {
      toast.success("Post Updated");
      props.setPostsData((prevState) => {
        return prevState.map((post) => {
          if (post.id === props.item.id) {
            // use the spread operator to add the new comment to the beginning of the comments array
            return {
              ...post, ...response.data.data
            }
          } else {
            return post;
          }
        });
      });
      props.setEditPost(false)
      setLoader(false)
    } else {
      toast.error(response.data.message);
      props.setEditPost(false)
      setLoader(false)
    }
  }


  const clearState = () => {
    props.setEditPost(false)
    // setImages([])
    // setDescription("")
  }



  const handleEditPost = () => {
    if (props.item?.product_id) {
      navigate("/store/" + props.item.product_id + "/customize");
    }
  }




  return (
    <>
      <Modal
        show={props.editpost}
        onHide={() => clearState()}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="editpost--section--modal"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontSize: "16px", fontWeight: "700" }}
          >
            Edit Post
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="p-3">
            <div
              className="d-flex align-items-center "
              style={{ columnGap: "10px" }}
            >
              <img
                src={
                  props.item?.user?.photo
                    ? process.env.REACT_APP_apiBaseURL +
                    "/" +
                    props.item?.user?.photo
                    : img1
                }
                alt=""
                style={{
                  width: "40px",
                  height: "40px",
                  objectFit: "cover",
                  border: "1px solid #E9E9E9",
                  borderRadius: "50%",
                }}
              />
              <h5 className="card-title me-2 mb-0">
                {props.item?.user?.name}
              </h5>
            </div>
            <div className="my-3">
              <textarea
                rows="2"
                className="w-100"
                placeholder="Share Something"
                value={description}
                onChange={(e) => {
                  let c = [...description]
                  c = e.target.value
                  setDescription(c)
                }}
                autoFocus
              ></textarea>
            </div>
            <Slider className="share-modal-slider" arrows={true} dots={false}>
              {editSelectedFiles &&
                Array.from(editSelectedFiles)?.map((file, index) => {
                  return (
                    <div className="d-flex justify-content-center my-3 img--div" key={index}>
                      <i
                        className="fa fa-times-circle cross--icon"
                        aria-hidden="true"
                        onClick={() => removeImage(index)}
                      ></i>
                      <img
                        className="post-images"
                        key={index}
                        src={URL.createObjectURL(file)}
                        alt=""
                      />
                    </div>
                  );
                })}
              {images?.map((i, index) => {
                return (
                  <div className="d-flex justify-content-center my-3 img--div" key={index}>
                    <i
                      className="fa fa-times-circle cross--icon"
                      aria-hidden="true"
                      onClick={() => removeImage(index, i.path)}
                    ></i>
                    <img
                      className="post-images"
                      src={process.env.REACT_APP_apiBaseURL + "/" + i.path}
                      alt=""
                    />
                  </div>
                );
              })}

            </Slider>
          </div>

          <div className="parent--div mx-3">
            <div>
              <h1 className="heading">Add to Your Post</h1>
            </div>
            <div className="d-flex align-items-center" style={{ columnGap: "10px" }}>
              {/* <img
                src={add}
                alt=""
                onClick={setEmojiDropdown}
                style={{ cursor: "pointer", width: '21px', height: '21px' }}
              /> */}
              {/* {emoji ? <div className="edit--modal--emoji"><EmojiModal setText={setDescription} text={description} /> </div> : ""} */}
              <label htmlFor="image1" className="w-100">
                <img
                  src={gallary}
                  style={{ width: "21px", height: "21px", cursor: "pointer" }}
                  alt=""
                  onClick={handleEditPost}
                />
                {/* <input
                  type="file"
                  multiple
                  // onChange={handleFileInputEdit}
                  style={{
                    position: "absolute",
                    opacity: "0",
                    visibility: "hidden",
                    cursor: "pointer",
                  }}
                  accept=".jpg,.jpeg,.png,.webp"
                  name="image1"
                  id="image1"
                  size="5000000"
                /> */}
              </label>
              <img src={tag1} onClick={() => setTag(true)} alt="" style={{cursor: 'pointer'}}/>
            </div>
          </div>

          <div className="d-flex m-3 justify-content-center">
            {loader ? <Spinner /> : <button className="edit--post--btn" onClick={() => SubmitPost()}>Save</button>}
          </div>
        </Modal.Body>
      </Modal>
      <TagModal
        show={tag}
        onHide={() => setTag(false)}
        users={users}
        setSelectedValues={setSelectedValues}
        selectedValues={selectedValues}
      />

    </>
  );
};

export default EditPostModal;
