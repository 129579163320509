const UserInfo = () => {
    return (
        <>
            <ul className="list-group list-group-flush info-list">
                <li className="list-group-item d-flex justify-content-between"
                >
                    <div className="user-container">
                        <h5> Date of Birth:</h5>
                    </div>
                    <div className="rounded-circle d-flex align-items-center">
                        <h5>13/04/1990</h5>
                    </div>
                </li>
                <li className="list-group-item d-flex justify-content-between"
                >
                    <div className="user-container">
                        <h5>Likes:</h5>
                    </div>
                    <div className="rounded-circle d-flex align-items-center">
                        <h5>346</h5>
                    </div>
                </li>
                <li className="list-group-item d-flex justify-content-between"
                >
                    <div className="user-container">
                        <h5>Shares:</h5>
                    </div>
                    <div className="rounded-circle d-flex align-items-center">
                        <h5>5788</h5>
                    </div>
                </li>
                <li className="list-group-item d-flex justify-content-between"
                >
                    <div className="user-container">
                        <h5>Hobbies:</h5>
                    </div>
                    <div className="rounded-circle d-flex align-items-center text-right"
                    >
                        <h5>Playing BasketBall,
                            Creating Memes</h5>
                    </div>
                </li>
                <li className="list-group-item d-flex justify-content-between"
                >
                    <div className="user-container">
                        <h5>Lives In:</h5>
                    </div>
                    <div className="rounded-circle d-flex align-items-center text-right">
                        <h5>California, United States</h5>
                    </div>
                </li>
                <li className="list-group-item d-flex justify-content-between"
                >
                    <div className="user-container">
                        <h5>Language</h5>
                    </div>
                    <div className="rounded-circle d-flex align-items-center">
                        <h5>English</h5>
                    </div>
                </li>
            </ul>
        </>
    );
}

export default UserInfo