import { collection, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { FaBars } from "react-icons/fa";
import { RiCloseFill } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getProfile, uploadProfileImage } from "../../services/ProfileService";
import { convertToFormData } from "../../services/customizerService";
import { db } from "../Constants/Firebase";
import { constants } from "../Constants/constants";
import SidebarMobile from "../customizer/SidebarMobile";
import CartModal from "../modal/CartModal";
import ConnectionModal from "../modal/ConnectionModal";
import CreateCustomizerPost from "../modal/CreateCustomizerPost";
import { useSnapshot } from 'valtio'
import { state } from "../../valtio";


const CustomizerNav = ({
    fileData,
    canvasState,
    product,
    canvas,
    timestamp,
    setDownload,
    handleUndo,
    handleRedo,
    setCurrentStep,
    currentStep,
    history,
    selectedProduct,
    mode,
    setMode,
    printfulPrice,
    toggleSlider
}) => {
    const [open, setOpen] = useState(true);
    const [authUser, setUser] = useState(null);
    const [editpost, setEditPost] = useState();
    const [modalShow, setModalShow] = useState(false);
    const [enablePayment, setEnablePayment] = useState(false);
    const [connectStripe, setConnectStripe] = useState(false);
    const snap =  useSnapshot(state)

    const navigate = useNavigate();
    const checkIfPaymentEnable = async () => {
        const response = await getProfile();
        if (response?.status === 200 && !response?.data?.data?.payments_enabled) {
            setEnablePayment(true);
        }
    };
    useEffect(() => {
        checkIfPaymentEnable();
        let user = JSON.parse(localStorage.getItem("user"));
        setUser(user);
    }, []);

    const postModal = () => {
        setDownload(true);
        setEditPost(true);
    };

    const modalOpen = () => {
        
        if (canvas != null) {
            setModalShow(true);
            setDownload(true);
        }
    };

    const setProfileImage = async () => {
        if (fileData) {
            const finalData = {
                photo: fileData, type: constants.profile, timeline: "Updated His Profile Photo",
            };
            const formData = convertToFormData(finalData);

            const response = await uploadProfileImage(formData);
            if (response.status === 201) {
                toast.success("Profile Photo updated");

                //// updating the local storage
                const currentData = JSON.parse(localStorage.getItem('user')) || {};

                // Update the object with the specific property
                currentData.photo = response.data.data.attachments[0].path;

                // Store the updated object in local storage
                localStorage.setItem('user', JSON.stringify(currentData));


                ///updating the firebase followers users profile image
                const userRef = doc(db, "Users", JSON.parse(localStorage.getItem("user")).id);
                const userSnapshot = await getDoc(userRef);
                await updateDoc(userRef, {
                    "imageUrl": response.data.data.attachments[0].path
                });

                const collectionRef = collection(db, "ChatRooms");

                // Query the documents that have the custom id in either the senderUser or recieveUser fields


                ////updating the image on firebase in chatrooms
                const querySnapshot = await getDocs(query(collectionRef, where("senderUser.id", "==", JSON.parse(localStorage.getItem("user")).id)));

                Promise.all(querySnapshot.docs.map(async (i) => {
                    const docRef = doc(collectionRef, i.id);

                    // Update the isChatOpen field for both the senderUser and recieveUser objects
                    await updateDoc(docRef, {
                        "senderUser.imageUrl": response.data.data.attachments[0].path,
                    });
                }));
                const querySnapshot1 = await getDocs(query(collectionRef, where("recieveUser.id", "==", JSON.parse(localStorage.getItem("user")).id)));
                Promise.all(querySnapshot1.docs.map(async (i) => {
                    const docRef = doc(collectionRef, i.id);
                    // Update the isChatOpen field for both the senderUser and recieveUser objects
                    await updateDoc(docRef, {
                        "recieveUser.imageUrl": response.data.data.attachments[0].path,
                    });
                }))
                    .then(() => {
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            } else {
                toast.error(response.data.message);
            }
        } else {
            toast.info("Please create an image first");
        }
    }

    const setProfileBanner = async () => {
        if (fileData) {
            const finalData = {
                photo: fileData, type: constants.cover, timeline: "Uploaded His Cover Photo",
            };
            const formData = convertToFormData(finalData);

            const response = await uploadProfileImage(formData);
            if (response.status === 201) {
                toast.success("Cover Photo updated");
            } else {
                toast.error(response.data.message);
            }
        } else {
            toast.info("Please create an image first");
        }
    }

    // const handleUndoo = () => {
    //   if (currentStep > 0) {
    //     const canvasState = history[currentStep - 1];
    //     setCurrentStep((step) => step - 1);
    //     canvas.off('object:added', customFun);
    //     canvas.loadFromJSON(canvasState, () => {
    //       canvas.renderAll();
    //       canvas.on('object:added', customFun); // Re-attach event listener
    //     });
    //   }
    // };

    // const handleRedoo = () => {
    //   if (currentStep < history.length - 1) {
    //     setCurrentStep((step) => step + 1);
    //     const canvasState = history[currentStep + 1];
    //     canvas.off('object:added', customFun);
    //     canvas.loadFromJSON(canvasState, () => {
    //       canvas.renderAll();
    //       canvas.on('object:added', customFun); // Re-attach event listener
    //     });
    //   }
    // };
    
    return (<>
        <nav
            className="navbar navbar-expand-lg navbar-light bg-white py-4"
            style={{ paddingRight: "0px" }}
        >
            <div className="container-fluid">
                <div className="row w-100">
                    <div className="col-lg-12 d-flex justify-content-between">
                        <div className="col-lg-4">
                            <Link
                                className="navbar-brand navbar-custom"
                                style={{ paddingLeft: "40px" }}
                                to="/"
                            >
                                <img
                                    src="/assets/images/SF Logo.png"
                                    style={{ width: "194px", height: "25px" }}
                                    alt=""
                                />
                            </Link>

                            <div className="customize-icon">
                                {open ? (<FaBars
                                    onClick={toggleSlider}
                                    className="logo-Icon"
                                    style={{ cursor: "pointer" }}
                                    size={30}
                                />) : (<>
                                    <RiCloseFill
                                        onClick={toggleSlider}
                                        className="logo-Icon "
                                        style={{ cursor: "pointer" }}
                                        size={30}
                                    />
                                    {/* <SidebarMobile className="pb-5" /> */}
                                </>)}
                            </div>
                        </div>
                        <div
                            className="col-lg-4 d-flex justify-content-center align-items-center gap-2"
                            style={{
                                margin: "auto", bottom: "10px",
                            }}
                        >
                            <div className="wrapper mx-4 d-flex align-items-center saved-text">
                                <img src="/assets/images/FloppyDisk.svg" alt="" />
                                <small className="changes-text"
                                    style={{
                                        fontSize: "14px",
                                        marginBottom: "0px",
                                        color: "rgba(36, 36, 36, 1)",
                                        marginLeft: "10px",
                                    }}
                                >
                                    {timestamp === null ? "No Changes to Save" : "All Changes Saved at " + timestamp}
                                </small>
                            </div>
                            <div className="wrapper d-flex gap-2">
                                <img
                                    className="mr-2"
                                    src="/assets/images/undo.png"
                                    alt="undo_icon"
                                    style={{
                                        cursor: canvas !== null && canvas.getObjects().length !== 0 ? "pointer" : "",
                                        opacity: canvas !== null && canvas.getObjects().length !== 0 ? "1" : "0.4",
                                    }}
                                    onClick={handleUndo}
                                />
                                <img
                                    src="/assets/images/redo.png"
                                    alt="redo_icon"
                                    style={{ cursor: "pointer" }}
                                    onClick={handleRedo}
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 profiler-Custom">
                            <div
                                className="d-flex justify-content-end align-items-center"
                                style={{ columnGap: "40px" }}
                            >
                                <div className="col-lg-2">
                                    <div
                                        className="d-flex justify-content-center align-items-center"
                                        style={{ width: "10vw" }}
                                    >
                                        <div className="btn-group customizer--btn">
                                            <Dropdown>
                                                <Dropdown.Toggle
                                                    className="btn btn-secondary three--dots"
                                                    id="dropdown-basic"
                                                >
                                                    <img
                                                        src="/assets/images/customizer/setimg.svg"
                                                        alt="img"
                                                    />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={setProfileImage}>
                                                        {" "}
                                                        <img
                                                            src="/assets/images/customizer/profileimg.svg"
                                                            alt="img"
                                                        />
                                                        Profile Image
                                                    </Dropdown.Item>
                                                    <Dropdown.Item onClick={setProfileBanner}>
                                                        {" "}
                                                        <img
                                                            src="/assets/images/customizer/banner.svg"
                                                            alt="img"
                                                        />
                                                        Profile Banner
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <img
                                            className=""
                                            src="/assets/images/add-post.png"
                                            alt=""
                                            onClick={postModal}
                                            style={{ cursor: "pointer", width: '30px', height: '30px'  ,paddingLeft: '8px'}}
                                        />
                                    </div>
                                </div>
                                <div className="d-flex justify-content-around">

                                    <div
                                        className="d-flex justify-content-center align-items-center user-profile-icon"
                                        style={{ width: "10vw" }}
                                    >
                                        {authUser !== null && (<>
                                            <img
                                                style={{
                                                    marginRight: "5px",
                                                    width: "40px",
                                                    height: "40px",
                                                    borderRadius: "50%",
                                                    objectFit: "cover",
                                                    cursor: 'pointer'
                                                }}
                                                src={authUser.photo !== null ? authUser.photo.includes("http") ? authUser.photo : process.env.REACT_APP_apiBaseURL + "/" + authUser.photo : "/assets/images/Vector.svg"}
                                                alt={authUser.name}
                                                onClick={() => navigate(`/profile/${authUser.user_name}`)}
                                            />
                                            <h6 className="my-0 customizer--username"
                                                onClick={() => navigate(`/profile/${authUser.user_name}`)}
                                                style={{ cursor: 'pointer' }}>
                                                {authUser.name}
                                            </h6>
                                        </>)}
                                    </div>

                                    <div className="col-lg-2 d-flex align-items-center" style={{ columnGap: '15px' }}>

                                        {/* {{-- < a href="#"  data-toggle="modal" data-target="#cartModal"> --}} */}
                                        <img
                                            src="/assets/images/Storefront.svg"
                                            alt=""
                                            style={{ cursor: "pointer" }}
                                            id="opencartModal"
                                            onClick={() => {
                                                snap.dottedLine === true ? toast("You can only place items in the center. Please ensure they stay within the dotted lines.") : modalOpen()
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
        <CreateCustomizerPost
            show={editpost}
            onHide={() => setEditPost(false)}
            setEditPost={setEditPost}
            item={fileData}
        />

        {modalShow ? (<CartModal
            show={modalShow}
            mode={mode}
            setMode={setMode}
            onHide={() => setModalShow(false)}
            fileData={fileData}
            canvasState={canvasState}
            product={product}
            selectedProduct={selectedProduct}
            printfulPrice={printfulPrice}
        />) : (<ConnectionModal
            show={connectStripe}
            onHide={() => setConnectStripe(false)}
        />)}
    </>);
};

export default CustomizerNav;
