import { Link } from "react-router-dom";
import Slider from "react-slick";

const AlsoLike = ({ products, user }) => {
    
    // Assuming 'data' is your array of slide items
    const totalSlides = products ? products.length : 0;

    // Helper function to determine the slides to show based on total slides and the desired maximum
    const getSlidesToShow = (maxSlides) => {
    return totalSlides < maxSlides ? totalSlides : maxSlides;
    };

    // Helper function to determine if the slider should scroll infinitely
    const isInfinite = (maxSlides) => {
    return totalSlides > maxSlides;
    };

    // Define the settings
    const settings = {
    arrow: false,
    infinite: isInfinite(4), // Only infinite if more than 4 slides
    speed: 500,
    slidesToShow: getSlidesToShow(4),
    slidesToScroll: getSlidesToShow(4), // Assuming you want the 'slidesToScroll' to match 'slidesToShow'
    initialSlide: 0,
    responsive: [
        {
        breakpoint: 1400,
        settings: {
            slidesToShow: getSlidesToShow(3),
            slidesToScroll: getSlidesToShow(3), // Assuming you want the 'slidesToScroll' to match 'slidesToShow'
            infinite: isInfinite(3),
            dots: false
        }
        },
        {
        breakpoint: 1000,
        settings: {
            slidesToShow: getSlidesToShow(2),
            slidesToScroll: getSlidesToShow(2), // Assuming you want the 'slidesToScroll' to match 'slidesToShow'
            initialSlide: 2,
            dots: false
        }
        },
        {
        breakpoint: 480,
        settings: {
            slidesToShow: getSlidesToShow(1),
            slidesToScroll: getSlidesToShow(1), // Assuming you want the 'slidesToScroll' to match 'slidesToShow'
            dots: false
        }
        }
    ]
    };

    return (
        <section className="collection-section py-5 " style={{ backgroundColor: '#F3F3F3' }}>
            <div className="container d-flex flex-column justify-content-center">
                <div className="row d-flex justify-content-center">
                    <div className="pb-5 col-md-12 d-flex flex-column justify-content-center text-center">
                        <h2 className="heading">You may Also Like!</h2>
                    </div>
                    <div className="row justify-content-center">
                        <Slider {...settings}>
                            {
                                products.map((product, index) => {
                                    return <div className="slide-wrap" key={product.id}>
                                        <div className="product-card" style={{ position: "relative" }}>
                                            <div className="col-md-12 d-flex justify-content-between align-items-center p-3">
                                                <h4>{product.title}</h4>
                                                <p>${product.price + product.printful_price}</p>
                                            </div>
                                            <a href={`/product-detail/${product.id}`} className="custom-anchor"> 
                                                <div className="col-md-12 d-flex justify-content-center">
                                                    <img 
                                                    src={
                                                        product.printful_product_id === 19 || product.printful_product_id === 300
                                                            ? (
                                                                product.placement === 'Right'
                                                                    ? (product.right_mockup ? product.right_mockup : process.env.REACT_APP_apiBaseURL + '/' + product.image)
                                                                    : (product.placement === 'Left' ? (product.left_mockup ? product.left_mockup : process.env.REACT_APP_apiBaseURL + '/' + product.image) : (product.front_mockup ? product.front_mockup : process.env.REACT_APP_apiBaseURL + '/' + product.image))
                                                            )
                                                            : (product.image.includes("http") ? product.image : process.env.REACT_APP_apiBaseURL + '/' + product.image)
                                                    }
                                                    
                                                    style={{
                                                            width: "190px",
                                                            height: "190px",
                                                            objectFit: "contain",
                                                        }}
                                                    />
                                                </div>
                                            </a>
                                            
                                            {/* <div className="col-md-12 d-flex justify-content-between align-items-center p-3"
                                                style={{ position: "absolute", bottom: "0px", width: '100% ' }}>
                                                <a href={`/product-detail/${product.id}`} className="custom-anchor">view product</a>
                                                
                                            </div> */}
                                        </div>
                                    </div>
                                })
                            }
                        </Slider>
                    </div>
                </div>
                {/* <div className="row justify-content-center my-4">
                    <button className="get-started"><Link to='/top-products' style={{ textDecoration: 'none' }}> View More</Link></button>
                </div> */}
            </div>
        </section >
    );
}

export default AlsoLike