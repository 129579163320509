import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getBlogPosts } from "../services/BlogPostService";

const Blog = () => {
    const banners = [
        "/assets/images/blog_banner.png", "/assets/images/blog_banner1.png", "/assets/images/blog_banner2.png", "/assets/images/blog_banner4.png", "/assets/images/blog_banner5.png", "/assets/images/blog_banner6.png", "/assets/images/blog_banner6.png", "/assets/images/blog_banner7.png", "/assets/images/blog_banner8.png"
    ];

    const [loader, setLoader] = useState(false);
    const [postsData, setPostsData] = useState();
    const [paginateLinks, setPaginateLinks] = useState([]);
    const [pageIndex, setPageIndex] = useState(1);
    const [pageCount, setPageCount] = useState();

    const getPosts = async (pageIndex) => {
        setLoader(true);
        const response = await getBlogPosts(pageIndex);
        if (response.status === 200) {
            setPostsData(response.data.data);
            setPageIndex(response.data.meta.current_page);
            setPageCount(response.data.meta);
            setPaginateLinks(response.data.links);
            setLoader(false);
        } else {
            console.log(response.data.message);
        }
    };

    const truncateContent = (content, maxLength) => {
        if (content.length > maxLength) {
          return content.substring(0, maxLength) + '...'; // Truncate and add an ellipsis
        }
        return content;
    }
    useEffect(() => {
        getPosts(pageIndex)
    }, []);
    return (
        <>
            <div className="container px-4 py-4">
                <div className="row">
                    <div className="col-md-12">
                        <h1>Blogs</h1>
                    </div>
                </div>
                {loader ? (
                    <div className="d-flex justify-content-center">
                        <div className="spinner-grow" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                ) : (
                    
                        postsData?.length > 0 ? (
                            postsData?.map((item, key) => (
                                key === 0 ? (
                                    <div className="row" key={item.id}>
                                        <div className="col-md-12 d-flex gap-4">
                                        <div className="col-md-4 pl-0 d-flex">
                                            <div>
                                                <img src={item.blog_image} alt=""  style={{width: "350px",height: "350px",objectFit: "contain",border:"0.5px solid rgba(36, 36, 36, 0.5)",}} />
                                            </div>
                                        </div>
                                        <div className="col-md-8 col-sm-12 col-12 py-2 d-flex flex-column justify-content-center">
                                            <h6 className="mb-1">{item.date}</h6>
                                            <h3>{item.title}</h3>
                                            <p dangerouslySetInnerHTML={{ __html: truncateContent(item.content, 1000) }}></p>
                                            <Link to={`/blog-detail/${item.slug}`}>Read more</Link>
                                        </div>
                                        </div>
                                    </div>
                                ) : null
                            ))
                        ) : "No Post Available"
                    )}
                
                

                <div className="row my-4">
                    <div className="col-md-12 d-flex justify-content-between flex-wrap blogs ">
                        {postsData?.map((item, key) => (
                             key > 0  ? (
                             <div className="card my-4 col-md-4" key={item.id} style={{width: '24rem'}} >
                                <Link to={`/blog-detail/${item.slug}`}>
                                    <img src={item.blog_image} className="card-img-top" alt="..." />
                                    <div className="">
                                        <div className="card-body">
                                            <div className="row my-2">
                                                <div className="col-md-12 d-flex justify-content-between">
                                                    <div className="col-md-6 pl-0" style={{paddingLeft: "10px"}}>
                                                        <h6 className="mb-0">{item.date}</h6>
                                                    </div>
                                                    {/* <div className="col-md-6 d-flex justify-content-end align-items-center pr-0">
                                                        <div className="col-md-12 d-flex justify-content-end pr-0 " style={{columnGap: '10px'}}>
                                                            <a href="">
                                                                <img className="rounded-circle" style={{border: "1px solid black"}}
                                                                    src="/assets/images/blog_fb.png" />
                                                            </a>

                                                            <a href="">
                                                                <img className="rounded-circle"  style={{border: "1px solid black"}}
                                                                    src="/assets/images/blog_insta.png" />
                                                            </a>
                                                            <a href="">
                                                                <img className="rounded-circle"  style={{border: "1px solid black"}}
                                                                    src="/assets/images/blog_twitter.png" />
                                                            </a>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h4 className="card-title">{item.title}</h4>
                                                </div>
                                                <div className="col-md-12">
                                                <p dangerouslySetInnerHTML={{ __html: truncateContent(item.content, 200) }}></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                             </div>
                            ) : "" 
                        ))}


                    </div>
                </div>

                {paginateLinks !== null ? ( <div className="row my-4">
                    <div className="col-md-12 d-flex justify-content-center">
                        <button className="get-started" onClick={() => {
                                        getPosts(
                                            pageIndex + 1
                                        )
                                    }
                                    } style={{
                                        opacity: !paginateLinks.next ? '0.5' : '1',
                                        width: '115px'
                                    }}
                                    disabled={!paginateLinks.next}
                        >Next</button>
                    </div>
                </div> ): (
                            ""
                        )}
            </div>
        </>
    );
}

export default Blog