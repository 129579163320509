import React from "react";
import PostDetail from "../components/PostDetail";

const Posts = () => {
  return (
    <>
      <PostDetail/>
    </>
  );
};

export default Posts;
