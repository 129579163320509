import HeroSection from "../components/home/HeroSection";
import TopCreation from "../components/home/TopCreation";
import TopUser from "../components/home/TopUser";
import ReadyCollection from "../components/home/ReadyCollection";
import TopProduct from "../components/home/TopProduct";
import UpComingEvent from "../components/home/UpComingEvent";
import OurBlog from "../components/home/OurBlog";
import AboutStickFigure from "../components/home/AboutStickFigure";
import YouDesignWeDeliver from "../components/home/YouDesignWeDeliver";
import Testimonial from "../components/home/Testimonial";
import NewSocialMedia from "../components/home/NewSocialMedia";
import { toast } from "react-toastify";

import React, { useEffect, useState } from "react";
import { getHomeData } from "../services/HomeService";

function Home() {
  const [data, setData] = useState();

  useEffect(() => {
    getdata();
  }, []);

  const getdata = async () => {
    ///Followers API
    const response = await getHomeData();
    if (response.status === 200) {
      setData(response.data);
    } else {
      toast.error(response.data.message);
    }
  };

  return (
    <>
      <HeroSection data={data} />
      <TopCreation data={data} />
      <TopUser  data={data} />
      {/* <YouDesignWeDeliver data={data} /> */}
      {/* <ReadyCollection  data={data}/> */}
      <NewSocialMedia data={data} />
      <TopProduct data={data} />
      <AboutStickFigure data={data} />
      <UpComingEvent />
      {/* <Testimonial /> */}
      <OurBlog />
    </>
  );
}

export default Home;
