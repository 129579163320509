import api from "./api";

export const customize_own_products = async () => {
    try {
        const response = await api.get(`/api/v1/all-customize-products`);
        return response;
    } catch (error) {
        return error.response;
    }
};

export const customize_own_creations = async () => {
    try {
        const response = await api.get(`/api/v1/all-customize-creations`);
        return response;
    } catch (error) {
        return error.response;
    }
};

export const all_enjoy_social_media = async () => {
    try {
        const response = await api.get(`/api/v1/all-enjoy-social-media`);
        return response;
    } catch (error) {
        return error.response;
    }
};
