// import { useEffect, useRef, useState } from "react";
import Spinner from "react-bootstrap/esm/Spinner";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
// import { toast } from "react-toastify";
// import { getProfilePosts } from "../../services/NewsFeedsService";
import PostTextArea from "../PostTextArea.jsx";

import { constants } from "../Constants/constants";

import CreationCard from "../CreationCard";
import NewsFeedPosts from "../NewsFeedPosts";
import { PlaceHolder, ProfilePlaceHolder } from "../PlaceHolder";
import {
  doc,
  getDoc,
  updateDoc,
  collection,
  query,
  getDocs,
  where,
} from "firebase/firestore";
import { db } from "../Constants/Firebase";

import { convertTime, LikesToK } from "../TimeFormat";
import { useEffect, useState } from "react";

const Timeline = ({
  profileData,
  productsData,
  memesData,
  loader,
  id,
  paginateLinks,
  loaderpost,
  postsData,
  getDataPost,
  pageIndex,
  setPostsData,
}) => {
  useEffect(() => {
    checkUserIsOnline();
  }, []);

  const [isChecked, setIsChecked] = useState();
  const [authProfile, setAuthProfile] = useState(JSON.parse(localStorage.getItem("user")));

  const checkUserIsOnline = async () => {
    const userRef = doc(
      db,
      "Users",
      authProfile.id
    );
    const userSnapshot = await getDoc(userRef);
    const userData = userSnapshot.data();
    if (!userData.setBy) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  };

  const Active = async (e) => {
    const userRef = doc(
      db,
      "Users",
      authProfile.id
    );
    const userSnapshot = await getDoc(userRef);
    if (userSnapshot.exists()) {
      setIsChecked(false);
      if (isChecked) {
        await updateDoc(userRef, {
          setBy: authProfile.id,
          isOnline: false
        });
        dbQueryFalse();
      } else {
        setIsChecked(true);
        await updateDoc(userRef, {
          setBy: null,
          isOnline: true
        });
        dbQueryTrue();
      }
    } else {
      console.log("user not found in firebase");
    }
  };

  async function dbQueryFalse() {
    const collectionRef = collection(db, "ChatRooms");

    // Query the documents that have the custom id in either the senderUser or recieveUser fields
    const querySnapshot = await getDocs(
      query(
        collectionRef,
        where(
          "senderUser.id",
          "==",
          authProfile.id
        )
      )
    );

    Promise.all(
      querySnapshot.docs.map(async (i) => {
        const docRef = doc(collectionRef, i.id);

        // Update the isChatOpen field for both the senderUser and recieveUser objects
        await updateDoc(docRef, {
          "senderUser.isOnline": false,
        });
      })
    );
    const querySnapshot1 = await getDocs(
      query(
        collectionRef,
        where(
          "recieveUser.id",
          "==",
          authProfile.id
        )
      )
    );
    Promise.all(
      querySnapshot1.docs.map(async (i) => {
        const docRef = doc(collectionRef, i.id);
        // Update the isChatOpen field for both the senderUser and recieveUser objects
        await updateDoc(docRef, {
          "recieveUser.isOnline": false,
        });
      })
    )
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
  }

  async function dbQueryTrue() {
    const collectionRef = collection(db, "ChatRooms");

    // Query the documents that have the custom id in either the senderUser or recieveUser fields
    const querySnapshot = await getDocs(
      query(
        collectionRef,
        where(
          "senderUser.id",
          "==",
          authProfile.id
        )
      )
    );

    Promise.all(
      querySnapshot.docs.map(async (i) => {
        const docRef = doc(collectionRef, i.id);

        // Update the isChatOpen field for both the senderUser and recieveUser objects
        await updateDoc(docRef, {
          "senderUser.isOnline": true,
        });
      })
    );
    const querySnapshot1 = await getDocs(
      query(
        collectionRef,
        where(
          "recieveUser.id",
          "==",
          authProfile.id
        )
      )
    );
    Promise.all(
      querySnapshot1.docs.map(async (i) => {
        const docRef = doc(collectionRef, i.id);
        // Update the isChatOpen field for both the senderUser and recieveUser objects
        await updateDoc(docRef, {
          "recieveUser.isOnline": true,
        });
      })
    )
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
  }

  return (
      <div className="col-md-8">
        <div className="row mt-3">
          <div className="col-md-12 d-flex align-items-center">
            <div className="col-md-4 d-flex align-items-center pl-0 online-logo">
              {authProfile.user_name === id || authProfile.id === id ? (
                <>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={(e) => Active(e)}
                    />
                    <span className="slider round"></span>
                  </label>
                  <p style={{ marginBottom: "0px", marginLeft: "0.4rem" }}>
                    Online{" "}
                  </p>
                </>
              ) : (
                ""
              )}
            </div>
            <div className="col-md-8 col-sm-12 col-12 d-flex justify-content-between align-items-center">
              <Link
                to={`/followers${id ? `/${id}` : ""}`}
                className="d-flex flex-column align-items-center text-decoration-none "
                style={{ rowGap: "4px" }}
              >
                <b>
                  {profileData?.total_followers
                    ? profileData?.total_followers
                    : 0}
                </b>{" "}
                Followers
              </Link>
              <Link
                to={`/following${id ? `/${id}` : ""}`}
                className="d-flex flex-column align-items-center text-decoration-none"
                style={{ rowGap: "4px" }}
              >
                <b>
                  {profileData?.total_following
                    ? profileData?.total_following
                    : 0}
                </b>{" "}
                Following
              </Link>
              <Link
                to={`/store${id ? `/${id}` : ""}?type=meme`}
                className="d-flex flex-column align-items-center text-decoration-none"
                style={{ rowGap: "4px" }}
              >
                <b>
                  {profileData?.total_creations
                    ? profileData?.total_creations
                    : 0}
                </b>{" "}
                Creation
              </Link>
              <Link
                to={`/store${id ? `/${id}` : ""}?type=product`}
                className="d-flex flex-column align-items-center text-decoration-none"
                style={{ rowGap: "4px" }}
              >
                <b>
                  {profileData?.total_products
                    ? profileData?.total_products
                    : 0}
                </b>{" "}
                Products
              </Link>
            </div>
          </div>
        </div>
        {/* <CreatePost /> */}
        {(authProfile.user_name === id || authProfile.id === id) ? 
        <PostTextArea setPostsData={setPostsData} />
        :(
          <>
            <div className="row py-4" style={{ maxHeight: "309px" }}>
              <div className="col-md-12">
                <div
                  className="container-fluid py-4"
                  style={{ backgroundColor: "#F3F3F3", borderRadius: "10px" }}
                >
                  <div className="container-header d-flex justify-content-between">
                    <h3 style={{ fontFamily: "My Font" }}>Creations</h3>
                    <Link to={id ? `/store/${id}` : "/store"}>View all</Link>
                  </div>
                  <div className="item-container d-flex gap-2 ">
                    {loader ? (
                      <ProfilePlaceHolder />
                    ) : memesData?.length === 0 ? (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <h4 style={{ fontWeight: "700", fontSize: "12px" }}>
                          Not created yet
                        </h4>
                      </div>
                    ) : (
                      memesData?.map((item, index) => {
                        if (item.post_type === constants.meme) {
                          return <CreationCard item={item} key={index} />;
                        }
                      })
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row my-4" style={{ maxHeight: "309px" }}>
              <div className="col-md-12">
                <div
                  className="container-fluid py-4"
                  style={{ backgroundColor: "#F3F3F3", borderRadius: "10px" }}
                >
                  <div className="container-header d-flex justify-content-between">
                    <h3 style={{ fontFamily: "My Font" }}>Products</h3>
                    <Link to={id ? `/store/${id}` : ""}>View all</Link>
                  </div>
                  <div className="item-container d-flex gap-2">
                    {loader ? (
                      <ProfilePlaceHolder />
                    ) : productsData?.length === 0 ? (
                      <h4 style={{ fontWeight: "700", fontSize: "12px" }}>
                        Not created yet
                      </h4>
                    ) : (
                      productsData?.map((item, index) => {
                        if (item.post_type === constants.product) {
                          return <CreationCard item={item} key={index} />;
                        }
                      })
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <div
          className="p-4 my-4 "
          style={{ backgroundColor: "#F3F3F3", borderRadius: "10px" }}
        >
          <div className="d-flex justify-content-between">
            <h1 style={{ fontSize: "28px", fontWeight: "700" }}>POSTS</h1>
            <img
              src="/assets/images/Group 966.svg"
              style={{ width: "20px", height: "20px" }}
            />
          </div>
          {/* Post  with comments */}
          <InfiniteScroll
            dataLength={postsData.length}
            next={() => getDataPost(pageIndex, "paginate")}
            hasMore={paginateLinks !== null ? true : false}
            loader={
              postsData?.length === 0 ? (
                <div className="col-md-12 d-flex justify-content-center pt-4">
                  <h4 style={{ fontWeight: "700", fontSize: "12px" }}>
                    No post found
                  </h4>
                </div>
              ) : paginateLinks !== null ? (
                <PlaceHolder />
              ) : (
                ""
              )
            }
          >
            {loaderpost ? (
              <PlaceHolder />
            ) : (
              postsData?.map((item, key) => {
                const converttime = convertTime(item?.created_at);
                const likesFormat = LikesToK(item?.total_reactions);
                const dislikeFormat = LikesToK(item.total_dislikes);
                return (
                    <NewsFeedPosts
                      item={item}
                      key={key}
                      converttime={converttime}
                      setPostsData={setPostsData}
                      likesFormat={likesFormat}
                      dislikeFormat={dislikeFormat}
                    />
                );
              })
            )}
          </InfiniteScroll>
          {postsData?.length === 0 ? (
            <div className="col-md-12 d-flex justify-content-center pt-4">
              <h4 style={{ fontWeight: "700", fontSize: "12px" }}>
                No post found
              </h4>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
  );
};

export default Timeline;
