import { useEffect, useState } from "react";

import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { constants } from "../components/Constants/constants";
import {
    CSSFunction,
    PlaceHolderFollowers,

} from "../components/PlaceHolder";
import { getFollowingUsers } from "../services/FollowersService";


import { searchUnique } from "../services/SearchUnique";


const Following = () => {
    /// state mangements
    const { id } = useParams();
    const [followers, setFollowers] = useState([]);

    const [loader, setLoader] = useState(false);





    const [paginateLinks, setPaginateLinks] = useState();



    let pageIndex = 1;


    useEffect(() => {
        getFollowersData(1);
    }, []);




    const getFollowersData = async (index, paginate) => {
        setLoader(true);
        ///Followers API
        const response = await getFollowingUsers(id ? id : "", index)
        if (response.status === 200) {
            if (paginate === "paginate") {
                setFollowers([...followers, ...response?.data?.data]);
            } else {
                setFollowers(response.data.data);
            }
            setPaginateLinks(response.data.links.next);
            setLoader(false);
        } else {
            toast.error(response.data.message);
            setLoader(false);
        }
    };



    /// search filter API
    const searchFilter = async (e) => {
        setLoader(true);
        setTimeout(async () => {
            const response = await searchUnique(id ? id : "", constants.following, e.target.value,);
            if (response.status === 200) {
                setFollowers(response.data.data);
                setLoader(false);
            }
        }, 500);
    };



    return (
        <>
            <CSSFunction loader={loader} />
            <main
                style={{ backgroundColor: "rgba(249, 249, 249, 1)" }}
                className="py-4"
            >
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 mt-3">
                            <div className="card">
                                <div className="container ">
                                    <div className="card-header d-flex justify-content-between align-items-center py-4">
                                        <h2 className="my-0 " style={{ fontSize: "24px" }}>
                                            Following
                                        </h2>
                                        <h6
                                            className="my-0"
                                            style={{ fontSize: "12px", fontWeight: "600" }}
                                        >
                                            Total {followers[0]?.total_followers ? followers[0]?.total_followers + " " : 0 + " "}
                                            Friend{followers[0]?.total_followers < 1 ? "s" : ""}
                                        </h6>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div
                                        className="container follower-container p-0"
                                        style={{ padding: "0% !important;" }}
                                    >
                                        <div className="row">
                                            <div
                                                className="col-md-8"
                                                style={{ position: "relative" }}
                                            >
                                                <input
                                                    type="text"
                                                    className="w-100"
                                                    placeholder="Search your friends"
                                                    style={{
                                                        backgroundColor: "#E9E9E9",
                                                        height: "50px",
                                                        paddingLeft: "3%",
                                                        borderRadius: '50px',
                                                    }}
                                                    onChange={(e) => searchFilter(e)}
                                                />
                                                <img
                                                    src="/assets/images/search.png"
                                                    style={{
                                                        position: "absolute",
                                                        bottom: "20px",
                                                        right: "40px",
                                                        top: "13px",
                                                    }}
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                        <div className="row my-4">
                                            <div className="col-md-12">
                                                <ul className="list-group list-group-flush">
                                                    {loader ? (
                                                        <PlaceHolderFollowers />
                                                    ) : followers?.length === 0 ? (
                                                        <div className="text-center mt-4">
                                                            <h4
                                                                style={{ fontWeight: "700", fontSize: "12px" }}
                                                            >
                                                                No data found
                                                            </h4>
                                                        </div>
                                                    ) : (
                                                        followers?.map((i) => {
                                                            return (
                                                                <li className="list-group-item">
                                                                    <Link to={`/profile/${i.id}`}>
                                                                        <div className="row d-flex">
                                                                            <div className="col-md-12 d-flex justify-content-between ">
                                                                                <div className="d-flex align-items-center follower-menu pb-1">
                                                                                    <img
                                                                                        className="me-3"
                                                                                        src={
                                                                                            i.photo
                                                                                                ? process.env
                                                                                                    .REACT_APP_apiBaseURL +
                                                                                                "/" +
                                                                                                i?.photo
                                                                                                : "/assets/images/follower1.png"
                                                                                        }
                                                                                        alt=""
                                                                                        style={{
                                                                                            width: "110px",
                                                                                            height: "110px",
                                                                                            objectFit: "cover",
                                                                                            borderRadius: "20px"
                                                                                        }}
                                                                                    />
                                                                                    <h4>{i.name}</h4>
                                                                                </div>
                                                                                <div className=" d-flex align-items-center justify-content-center">
                                                                                    <img
                                                                                        src={
                                                                                            "/assets/images/comment_icon.png"
                                                                                        }
                                                                                        alt=""
                                                                                    />
                                                                                    <img
                                                                                        src="/assets/images/menu_bar.png"
                                                                                        alt=""
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                </li>
                                                            );
                                                        })
                                                    )}

                                                    {followers?.length === 0 ? (
                                                        <Link
                                                            to="/search"
                                                            className="col-md-12 d-flex justify-content-center pt-4"
                                                        >
                                                            <button className="get-started">
                                                                Add Followers
                                                            </button>
                                                        </Link>
                                                    ) : paginateLinks !== null ? (
                                                        <div className="col-md-12 d-flex justify-content-center pt-4">
                                                            <button
                                                                className="get-started"
                                                                onClick={() =>
                                                                    getFollowersData(pageIndex + 1, "paginate")
                                                                }
                                                            >
                                                                View more
                                                            </button>
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </main>
        </>
    );
};

export default Following;
