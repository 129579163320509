import React, {Component} from 'react';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false, error: null, errorInfo: null};
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        this.setState({error: error, errorInfo: errorInfo});
    }

    render() {
        if (this.state.hasError) {
            console.log("Error is here  ::::: ", this.state.error) 
        }
        return this.props.children;
    }
}

export default ErrorBoundary;