import React from "react";
import {useNavigate} from "react-router";

export const NotificationsToast = ({toastRef, notification}) => {
    const navigate = useNavigate();

    const notificationClicked = () => {
        switch (notification?.notifiable_type) {
            case 'user':
                navigate(`/profile/${notification.senderID}`);
                break;
            case 'tag':
                navigate(`/posts/${notification.childID}`);
                break;
            // case 'invite':
            //     navigate(`/invite/${notification.childID}`);
            //     break;
            case 'post':
                navigate(`/posts/${notification.childID}`);
                break;
            case 'product':
                navigate(`/product-detail/${notification.childID}`);
                break;
            case 'reaction':
                navigate(`/posts/${notification.childID}`)
                break;
            case 'follower':
                navigate(`/followers`);
                break;
            case 'payment':
                navigate(`/payment/${notification.childID}`);
                break;
            case 'comment':
                navigate(`/posts/${notification.childID}`);
                break;
            case 'reply':
                navigate(`/posts/${notification.childID}`);
                break;
            default:
                break;
        }
    }

    return (
        notification && <div className="toast-container position-fixed bottom-0 end-0 p-3 toaster--container"
             style={{cursor: 'pointer'}}
             onClick={notificationClicked}>
            <div
                className="toast"
                id="liveToast"
                role="alert"
                aria-live="assertive"
                aria-atomic="true"
                ref={toastRef}
            >
                <div className="toast-header">
                    <img
                        src="../assets/images/Vector.svg"
                        className=" me-2"
                        alt="..."
                        style={{
                            width: "18px",
                            objectFit: "contain",
                            height: "18px",
                            borderRadius: "4px",
                            backgroundColor: 'white'
                        }}
                    />
                    <strong className="me-auto">{notification?.title}</strong>
                    <small>Just Now</small>
                    <button
                        type="button"
                        className="btn-close btn-close-white"
                        data-bs-dismiss="toast"
                        aria-label="Close"

                    ></button>
                </div>
                <div className="toast-body">{notification?.body}</div>
            </div>
        </div>
    );
};
