
import * as THREE from 'three';
import {
    ContactShadows,
    Environment,
    Float,
    OrbitControls,
    Center
  } from "@react-three/drei";
  import { Mug } from "./Mug";
  
  export const Experience = ({design,rightDesign,leftDesign,color}) => {
    return (
        <>
            <OrbitControls/>
            {/* <Center> */}
                <Mug design={design} rightDesign={rightDesign} leftDesign={leftDesign} scale={[1,1,1]} color={color}/>
                <ambientLight intensity={0.5} position={[0, 0, 0]}/>
                <directionalLight intensity={0.5} position={[5, 15, 15]} />
                <Environment preset="sunset"  blur={4} />
            {/* </Center> */}
        </>
    );
  };