import React from "react";
import Spinner from "react-bootstrap/esm/Spinner";

function SpinnerLoader() {
  return (
    <div style={{display: "flex" , justifyContent: "center", height: "80vh" , alignItems: "center"} }>
      <Spinner  style={{width: "100px" , height: "100px"}}/>
    </div>
  );
}

export default SpinnerLoader;
