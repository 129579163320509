import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundry';

// import('react-error-overlay').then(m => {
//    m.stopReportingRuntimeErrors();
//  });
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
   // <React.StrictMode>
   // <ErrorBoundary>
      <App />  
   // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
