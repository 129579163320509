import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";

const TopUser = ({ data }) => {
  const getSlidesToShow = (minSlides, maxSlides) => {
    if (!data || !data.top_users) return minSlides;
    const length = data.top_users.length;
    return length >= maxSlides ? maxSlides : (length === 0 ? minSlides : length);
  };
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: getSlidesToShow(1, 5),
    slidesToScroll: 5,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: getSlidesToShow(1, 4),
          slidesToScroll: 4,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: getSlidesToShow(1, 2),
          slidesToScroll: 2,
          initialSlide: 2,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: getSlidesToShow(1, 2),
          slidesToScroll: 1
        },
      },
    ],
  };

  const navigate = useNavigate();
  const redirectToProfile = (userID) => {
    let user = JSON.parse(localStorage.getItem("user"));
    if(user.id !== userID) {
      navigate('/profile/'+userID);
    } else {
      navigate('/profile');
    }

  }

  return (
    <section className="mt-5" style={{ backgroundColor: "#F9F9F9" }}>
      <div className="container">
        <div className="row">
          <div className="pb-5 col-md-12 text-center">
            <h2 className="heading"> TOP PROFILES </h2>
            <p style={{ fontSize: "24px" }}>Browse some of the best profiles of <Link to='/'>Stickfigures.com</Link></p>
            {/* <small>
              Let’s applaud our Top Users of the Day for their creativity
            </small> */}
          </div>
        </div>
        <div className="row top-user-container">
          <Slider {...settings} className="slider-btn">
            {data?.top_users.length === 0 ? (
              <>
                <div className="d-flex justify-content-center">
                  <div className="d-flex justify-content-center align-items-center flex-column ">
                    <p>No User Found</p>
                  </div>
                </div>
              </>
            ) : (
              data?.top_users?.map((i) => {
                return (
                  <div className="slide-wrap" key={i.id}>
                    <div
                      className="card"
                      style={{
                        border: "none",
                        borderRadius: "12px",
                        backgroundColor: "black",
                      }}
                    >
                      <div
                        className="card-body"
                        style={{
                          backgroundColor: "#FFFFFF",
                          borderRadius: "10px",
                        }}
                      >
                        <div
                          className="row flex-column text-center align-items-center justify-content-center mt-3 top-user-avator"
                          style={{
                            marginLeft: "0px !important",
                            marginRight: "0px !important",
                          }}
                        >
                          <img
                            className="img-fluid img-thumbnail gm-image"
                            style={{
                              border: "none",
                              borderRadius: "50%",
                              objectFit: "cover",
                              width: "98px",
                              height: "98px",
                              padding: "0px",
                              margin: "5px 0px",
                              cursor: 'pointer'
                            }}
                            src={
                              i.photo
                                ? i.photo.includes("http") ? i.photo : process.env.REACT_APP_apiBaseURL +
                                  "/" +
                                  i.photo
                                : "/assets/images/Rectangle 33.png"
                            }
                            alt=""
                            onClick={(e) => redirectToProfile(i.user_name)}
                          />

                          <h4
                            style={{
                              marginBottom: "0px",
                              fontWeight: "700",
                              display: " inline-block",
                              width: "130px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              cursor: 'pointer'
                            }}
                            onClick={(e) => redirectToProfile(i.user_name)}
                          >
                            {i.name}
                          </h4>
                          <div className="row d-flex justify-content-center">
                            <div className="col-md-4 d-flex flex-column align-items-center text-center">
                              <span>{i.total_followers}</span>
                              <p
                                style={{
                                  margin: "0px",
                                  fontWeight: "700",
                                  fontSize: "10px",
                                }}
                              >
                                Follower
                              </p>
                            </div>
                            <div className="col-md-4 d-flex flex-column align-items-center text-center">
                              <span>{i.total_following}</span>
                              <p
                                style={{
                                  margin: "0px",
                                  fontWeight: "700",
                                  fontSize: "10px",
                                }}
                              >
                                Following
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="footer d-flex justify-content-center align-items-center card-footer-edges "
                        style={{
                          backgroundColor: "#242424",
                          borderTopRightRadius: "0px",
                          borderTopLeftRadius: "0px",
                          height: "53px",
                          alignItems: "center",
                        }}
                      >
                        <Link
                          to={`/profile/${i.user_name}`}
                          className="btn btn-primary home-profile-btn"
                          style={{ backgroundColor: "#242424", border: "none" }}
                        >
                          View Profile
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </Slider>
        </div>
        <div className="container d-flex justify-content-center mt-4">
          <p>
            P.s. You can be our next user of the day!! All you have to do is to
            pick, stick, print and share!
          </p>
        </div>
        <div className="row justify-content-center mb-4">
          <Link
            to="/search"
            className="get-started text-decoration-none d-flex justify-content-center align-items-center"
          >
            View All
          </Link>
        </div>
      </div>
    </section>
  );
};

export default TopUser;
