import Modal from "react-bootstrap/Modal";
import foto from "../../assets/images/Rectangle 197.png";
import { Link } from "react-router-dom";
import dollar from "../../assets/images/CurrencyDollar.svg";
import Dropdown from "react-bootstrap/Dropdown";
import { BsChevronDown } from "react-icons/bs";
import { useState } from "react";
import { toast } from "react-toastify";
import { convertToFormData, updateProduct } from "../../services/customizerService"
import Spinner from "react-bootstrap/esm/Spinner";

const EditModal = (props) => {

  const domProps = Object.assign({}, props);
  delete domProps.editModalData;
  delete domProps.customizerId;
  delete domProps.setEditModalData;
  delete domProps.setPayload;

  const [validation, setValidation] = useState({
    price: "",
    description: "",
    title: ""
  })

  const [loader, setLoader] = useState(false)

  const submitData = async (e) => {


    e.preventDefault();
    setLoader(true)
    if (props.editModalData?.title === "") {
      setValidation((validation) => ({
        ...validation,
        title: "title is required",
      }));
      return;
    }

    if (props.editModalData?.price === "") {
      setValidation((validation) => ({
        ...validation,
        price: "price is required",
      }));
      return;
    }
    if (props.editModalData?.description === "") {
      setValidation((validation) => ({
        ...validation,
        description: "description is required",
      }));
      return;
    }
    let finalData = {
      type: props.editModalData.type,
      price: parseFloat(props.editModalData.price),
      title: props.editModalData.title,
      description: props.editModalData.description,
      _method: 'PUT',
    };
    let formData = convertToFormData(finalData);
    const response = await updateProduct(props.editModalData.id, formData);
    if (response.status === 200) {
      props.setPayload((prevState) => {
        return prevState?.map((i) => {
         
          if (i.id === props.editModalData.id) {
          
            // use the spread operator to add the new comment to the beginning of the comments array
            return {
              ...i, ...response.data.data
            }
          } else {
            return i;
          }
        });
      });
      toast.success("Product updated");
      setLoader(false)
      props.onHide();
    } else {
      toast.error(response.data.message);
      setLoader(false)
    }
  };

  return (
    <>
      <Modal
        {...domProps}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="Edit--section--modal"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontSize: "16px", fontWeight: "700" }}
          >
            {props.editModalData?.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex align-items-center">
          <div className="col-lg-12 ">
            <div className="row">
              <div className="col-lg-4 p-0 d-flex justify-content-center">
                <img className="edit--img" src={
                  process.env.REACT_APP_apiBaseURL + "/" + props.editModalData?.image
                } alt="" />
              </div>

              <div className="col-lg-8 d-flex flex-column gap-2 justify-content-center">
                <input
                  className="edit--modal--inputs "
                  type="text"
                  value={props.editModalData?.title}
                  style={{
                    fontSize: "16px",
                    fontWeight: "700",
                    color: "black",
                  }}
                  onChange={(e) => {
                    const c = { ...props.editModalData };
                    c.title = e.target.value;
                    props.setEditModalData(c);
                  }}

                />
                <div className="text-center">
                  {validation.title !== "" && (
                    <span className="text-danger">{validation.title}</span>
                  )}
                </div>
                <textarea
                  id="text-feild"
                  name="w3review"
                  value={props.editModalData?.description}
                  rows="4"
                  cols="20"
                  style={{ fontSize: "14px", color: "black" }}
                  onChange={(e) => {
                    const c = { ...props.editModalData };
                    c.description = e.target.value;
                    props.setEditModalData(c);
                  }}
                ></textarea>
                <div className="text-center">
                  {validation.description !== "" && (
                    <span className="text-danger">{validation.description}</span>
                  )}
                </div>

                <div style={{ position: "relative" }}>
                  <input
                    className="edit--modal--inputs1  "
                    value={props.editModalData?.price}
                    type="number"
                    placeholder=""
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                      color: "black",
                    }}
                    onChange={(e) => {
                      const c = { ...props.editModalData };
                      c.price = e.target.value;
                      props.setEditModalData(c);
                    }}
                  />
                  <Dropdown className="aboslute--class">
                    <Dropdown.Toggle id="dropdown-basic">
                      <div className="d-flex justify-content-center align-items-center ">
                        <img src={dollar} />{" "}
                        <BsChevronDown size={10} style={{ color: "black" }} />
                      </div>
                    </Dropdown.Toggle>
                  </Dropdown>
                </div>
                <div className="text-center">
                  {validation.price !== "" && (
                    <span className="text-danger">{validation.price}</span>
                  )}
                </div>
                <div className="mb-2 mt-1 d-flex justify-content-between">
                  <Link to={`/store/${props.customizerId}/customize`} className="edit--buttons">Edit In Customizer </Link>
                  {loader ? <div className="d-flex align-items-center justify-content-center w-50"><Spinner /> </div> : <Link className="edit--buttons" onClick={(e) => submitData(e)} > Save Changes </Link>}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditModal;
