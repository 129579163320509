import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { constants } from "../components/Constants/constants";
import {
  CSSFunction,
  PlaceHolderNotification,
} from "../components/PlaceHolder";
import { searchApi } from "../services/GlobalSearchService";

const Search = () => {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [radioType, setRadioType] = useState();
  const [paginateLinks, setPaginateLinks] = useState();

  const [pageIndex, setPageIndex] = useState(1);

  useEffect(() => {
    searchFilter("", constants.all);
  }, []);

  /// search filter API
  const searchFilter = async (value, type) => {
    setLoader(true);

    setTimeout(async () => {
      const response = await searchApi(value, type);
      if (response?.status === 200) {
        
        ///setting data if its type is products
        if (type === constants.products || type === "creation") {
          setData(response.data.data.products);

          setLoader(false);
        } else if (type === constants.all) {
          const newArray = response.data.data.products.concat(
            response.data.data.users
          );
          setData(newArray);

          setRadioType(constants.all);
          setLoader(false);
        } else {
          setData(response.data.data.users);
          setLoader(false);
        }
        if (response.data.links?.next) {
          setPageIndex(pageIndex + 1);
        }
        setPaginateLinks(response.data?.links?.next);
      }
    }, 500);
  };

  const getpaginateData = async (value, type) => {
    const response = await searchApi(value, type, pageIndex);
    if (response?.status === 200) {
      console.log(response);
      ///setting data if its type is products
      if (type === constants.products) {
        setData([...data, ...response.data.data.products]);
      } else if (type === constants.all) {
        const finalArray = response.data.data.products.concat(
          response.data.data.users
        );
        setData([...data, ...finalArray]);
        setRadioType(constants.all);
        setLoader(false);
      } else {
        setData(response.data.data.users);
        setLoader(false);
      }
      if (response.data.links?.next) {
        setPageIndex(pageIndex + 1);
      }
      setPaginateLinks(response.data?.links?.next);
    }
  };

  return (
    <>
      <CSSFunction loader={loader} />
      <main
        className="my-4"
        style={{ backgroundColor: "rgba(249, 249, 249, 1)" }}
      >
        <div className="container search1--container">
          <div className="row d-flex justify-content-center">
            <div className="col-md-8 mt-3">
              <div className="card" style={{ background: "#E9E9E9" }}>
                <div
                  className="container p-3"
                  style={{
                    position: "relative",
                    background: "#E9E9E9",
                    borderRadius: "10px",
                  }}
                >
                  <input
                    type="text"
                    placeholder="search"
                    className="px-3"
                    onChange={(e) => searchFilter(e.target.value, radioType)}
                    style={{
                      width: "100%",
                      backgroundColor: "#F3F3F3",
                      color: "black",
                    }}
                  />
                  <img
                    src="/assets/images/search.png"
                    style={{
                      width: "25px",
                      position: "absolute",
                      right: "35px",
                      bottom: "0px",
                      top: "22px",
                    }}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="container">
                <div className="row">
                  <div className="col-md-8 d-flex justify-content-around pt-3">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="All"
                        // checked
                        onChange={(e) => {
                          searchFilter("", e.target.value);
                          setRadioType(e.target.value);
                          setPageIndex(1);
                        }}
                        value={constants.all}
                      />
                      <label className="form-check-label" htmlFor="All">
                        All
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="users"
                        value={constants.user}
                        onChange={(e) => {
                          searchFilter("", e.target.value);
                          setRadioType(e.target.value);
                          setPageIndex(1);
                        }}
                      />
                      <label className="form-check-label" htmlFor="users">
                        User
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="creations"
                        value={"creation"}
                        onChange={(e) => {
                          searchFilter("", e.target.value);
                          setRadioType(e.target.value);
                          setPageIndex(1);
                        }}
                      />
                      <label className="form-check-label" htmlFor="creations">
                        Creations
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="products"
                        value={constants.products}
                        onChange={(e) => {
                          searchFilter("", e.target.value);
                          setRadioType(e.target.value);
                        }}
                      />
                      <label className="form-check-label" htmlFor="products">
                        Products
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8 mt-3">
              <div
                className="card"
                style={{ background: loader ? "white" : "#E9E9E9" }}
              >
                <div
                  className="container seacr--kolor"
                  style={{ backgroundColor: "#E9E9E9" }}
                >
                  <div
                    className="card-header d-flex justify-content-between align-items-center"
                    style={{
                      padding: "1rem 1.5rem",
                    }}
                  >
                    <h2>Search Results</h2>
                  </div>
                </div>
                <div
                  className="card-body search--container"
                  style={{ backgroundColor: "#E9E9E9" }}
                >
                  <div className="container">
                    <div className="row ">
                      <div className="col-md-12">
                        <ul className="list-group list-group-flush">
                          {loader ? (
                            <PlaceHolderNotification />
                          ) : data?.length === 0 ? (
                            <div className="text-center mt-4">
                              <h4
                                style={{ fontWeight: "700", fontSize: "12px" }}
                              >
                                No data found
                              </h4>
                            </div>
                          ) : (
                            data?.map((item,index) => {
                              return (
                                <li className="list-group-item" key={index}>
                                  <Link
                                  className='text-decoration-none'
                                    to={
                                      item?.user_name
                                        ? `/profile/${item.user_name}`
                                        : item?.user?.id
                                        ? `/product-detail/${item?.id}`
                                        : ""
                                    }
                                  >
                                    <div className="row d-flex">
                                      <div className="col-md-10 col-10 d-flex align-items-center">
                                        <img
                                          className="mr-3"
                                          src={
                                            item.photo
                                              ? item.photo.includes("http") ? item.photo : process.env
                                                  .REACT_APP_apiBaseURL +
                                                "/" +
                                                item.photo
                                              : item.image
                                              ? process.env
                                                  .REACT_APP_apiBaseURL +
                                                "/" +
                                                item.image
                                              : "/assets/images/notification4.png"
                                          }
                                          style={{
                                            width: "100px",
                                            height: "100px",
                                            borderRadius: "10px",
                                            objectFit: "cover",
                                          }}
                                          alt=""
                                        />
                                        <div className="text-container" style={{marginLeft: '12px'}}>
                                          <h4 style={{ marginBottom: "0px" }}>
                                            {item.name || item.title}
                                          </h4>
                                          <h6 style={{ marginTop: "0px" }}>
                                            {item.name
                                              ? item.total_followers + ' Friends'
                                              : item.user?.store_name ? item.user?.store_name : item.type === 0 ? 'Meme' : 'Product'}
                                          </h6>
                                        </div>
                                      </div>
                                      <div className="col-md-2 col-2 d-flex justify-content-end align-items-center">
                                        <div
                                          className="d-flex flex-column align-items-center"
                                          style={{
                                            width: "36px",
                                            height: "36px",
                                          }}
                                        >
                                          {item?.user_name && item?.am_i_following ? (
                                            <img
                                              src="/assets/images/comment_icon.png"
                                              alt=""
                                            />
                                          ) : item?.user_name ? (
                                            <img
                                                src="/assets/images/follow_icon.svg"
                                                alt=""
                                            />
                                              ) : item?.price ? (
                                                  <img
                                                      src="/assets/images/shopping_cart.svg"
                                                      alt=""
                                                  />
                                                ) : (
                                                    ""
                                                    )}
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                </li>
                              );
                            })
                          )}
                        </ul>
                      </div>
                    </div>
                    {paginateLinks !== null && (
                      <div className="row mb-3">
                        <div className="col-md-12 d-flex justify-content-center">
                          <button
                            className="get-started"
                            style={{ width: "332px" }}
                            onClick={() =>
                              getpaginateData("", radioType, pageIndex)
                            }
                          >
                            View More
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Search;
