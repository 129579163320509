import React, { useCallback, useMemo, useState } from "react";
import { RxDotsHorizontal } from "react-icons/rx";
import chara from "../assets/images/Vector.png";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Dropdown from "react-bootstrap/Dropdown";
import msg from "../assets/images/newsfeed/ChatCircleText.svg";
import share1 from "../assets/images/newsfeed/share.svg";
import { toast } from "react-toastify";
import { deletePostData, postReact } from "../services/NewsFeedsService";
import ShareModal from "./modal/ShareModal";
import PostImagegallery from "./PostImagegallery";
import { constants } from "./Constants/constants";
import Comment from "./Comment";

import { AiFillDislike, AiOutlineDislike } from "react-icons/ai";
import EditPostModal from "./modal/EditPostModal";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CommentsModal from "./modal/CommentsModal";
import DeleteAccount from "./modal/DeleteAccount";
import { AddCart } from "../services/CartService";
import ReportModal from "./modal/ReportModal";
import { convertTime } from "./TimeFormat";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

function NewsFeedPosts({
    item,
    // key,
    unique,
    converttime,
    postsData,
    setPostsData,
    likesFormat,
    dislikeFormat,
    newsFeedCommentStatus,
    setNewsFeedCommentStatus,
    setItemToCart,
}) {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    /// states management
    const [open, setOpen] = React.useState(false);
    const [editpost, setEditPost] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);
    const [CommentsShow, setCommentsShow] = useState(false);
    const [share, setShare] = useState(false);
    const [reportShow, setReportShow] = useState(false);
    const [seeMore, setSeeMore] = useState(false);
    const [seeMoreSharedPost, setSeeMoreSharedPost] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deletePostId, setDeletePostId] = useState();
    const [reportPostId, setReportPostId] = useState();
    const [deleteBtnLock, setDeleteBtnLock] = useState(false);

    const isLoggedInUserProfile =
        pathname.includes("profile") && pathname.split("/").length === 2;

    ////react on post
    const react = async (id, type) => {
        setPostsData((prevState) => {
            return prevState.map((post) => {
                if (post.id === id) {
                    if (type === constants.love) {
                        if (post.reaction === 0) {
                            return {
                                ...post,
                                have_i_reacted: 1,
                                total_reactions: post.total_reactions + 1,
                                reaction: constants.love,
                            };
                        } else {
                            if (post.reaction === 2) {
                                return {
                                    ...post,
                                    have_i_reacted: 1,
                                    total_reactions: post.total_reactions + 1,
                                    total_dislikes: post.total_dislikes - 1,
                                    reaction: constants.love,
                                };
                            }
                            return {
                                ...post,
                                have_i_reacted: 0,
                                total_reactions: post.total_reactions - 1,
                                reaction: 0,
                            };
                        }
                    } else {
                        if (post.reaction === 0 || post.reaction === 1) {
                            if (post.reaction === 1) {
                                return {
                                    ...post,
                                    have_i_reacted: 1,
                                    total_dislikes: post.total_dislikes + 1,
                                    total_reactions: post.total_reactions - 1,
                                    reaction: constants.dislike,
                                };
                            }
                            return {
                                ...post,
                                have_i_reacted: 1,
                                total_dislikes: post.total_dislikes + 1,
                                reaction: constants.dislike,
                            };
                        } else {
                            return {
                                ...post,
                                have_i_reacted: 0,
                                total_dislikes: post.total_dislikes - 1,
                                reaction: 0,
                            };
                        }
                    }
                } else {
                    return post;
                }
            });
        });
        let fianlData = {
            reaction: type,
            id: id,
            type: constants.post,
        };

        const audio = new Audio("/assets/sounds/facebook_likes.mp3");
        audio.play();
        const response = await postReact(fianlData);

        if (response.status === 200) {
            
        } else {
            toast.error(response.data.message);
        }
    };

    const deletePostModal = async (postId) => {
        setDeleteModal(true);
        setDeletePostId(postId);
    };

    const deletePost = async () => {
        setDeleteBtnLock(true);
        setPostsData((prev) => prev.filter((i) => i.id !== deletePostId));
        const response = await deletePostData(deletePostId);
        if (response.status === 200) {
            setDeleteModal(false);
            setDeleteBtnLock(false);
            toast.success("Post eradicated");
        } else {
            toast.error(response.data.message);
            setDeleteModal(false);
            setDeleteBtnLock(false);
        }
    };

    const commentModal = () => {
        setCommentsShow(true);
    };

    const addToCart = async (item) => {
        if (item.in_cart) {
            navigateToCart();
            return;
        }
        setItemToCart(item.product_id);
    };

    const buyIt = (item) => {
        navigate("/checkout/" + item.product_id);
    };

    const navigateToCart = () => {
        navigate("/carts");
    };

    const reportPost = (postId) => {
        setReportShow(true);
        setReportPostId(postId);
    };
    // Share to Facebook
    function shareToFacebook(url) {
        const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
        window.open(facebookShareUrl, 'newwindow', 'width=600,height=400');
        return false;
    }

    const tooltip = useCallback((type) => {
        type = type === 0 ? 'meme' : 'product';
        return (
            <Tooltip id="tooltip">
                <strong>You purchased this {type}!</strong>
            </Tooltip>
        );
    }, []);

    return (
        <>
            <div
                className="card mt-2 newsfeed--post" key={unique}
                style={{ backgroundColor: "#E9E9E9 !important" }}
            >
                <div
                    className="card-body "
                    style={{ backgroundColor: "#E9E9E9", borderRadius: "10px" }}
                >
                    <div className="container-fluid p-0">
                        <div className="row pt-3" style={{ position: "relative" }}>
                            <div
                                className="col-md-1 col-1 d-flex align-items-center justify-content-center p-0"
                                style={{ width: "60px" }}
                            >
                                <Link to={`/profile/${item?.user?.user_name}`}>
                                    <img
                                        src={
                                            item?.user?.photo ? item?.user?.photo.includes("http") ? item?.user?.photo
                                                : process.env.REACT_APP_apiBaseURL +
                                                "/" +
                                                item?.user?.photo
                                                : chara
                                        }
                                        alt=""
                                        style={{
                                            width: "40px",
                                            height: "40px",
                                            objectFit: "cover",
                                            borderRadius: "50%",
                                        }}
                                    />
                                </Link>
                            </div>
                            <div className="col-md-9 col-9 d-flex flex-column p-0">
                                <h5
                                    style={{ fontWeight: "700" }}
                                    className="card-title me-2 mb-0 d-flex align-items-center"
                                >
                                    {item?.user?.name}{" "} {item.user.verified && (<span className="me-1"><img src="/assets/images/Verify.svg" style={{ width: '14px' }} /></span>)}
                                    <small className="ms-1" style={{ fontWeight: "500", fontSize: "18px" }}
                                        dangerouslySetInnerHTML={{ __html: item?.timeline }}>
                                    </small>
                                </h5>
                                <p className="card-text">
                                    <small
                                        className="text-muted"
                                        style={{ fontWeight: "500", fontSize: "14px" }}
                                    >
                                        {converttime}
                                    </small>
                                </p>
                            </div>
                            <div className="btn-group btn--group--three ">
                                <Dropdown>
                                    <Dropdown.Toggle
                                        className="btn btn-secondary three--dots"
                                        id="dropdown-basic"
                                    >
                                        <RxDotsHorizontal size={20} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {item.user.id ===
                                            JSON.parse(localStorage.getItem("user")).id ? (
                                            <>
                                                <Dropdown.Item onClick={() => setEditPost(true)}>
                                                    Edit
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={() => deletePostModal(item.id)}>
                                                    Delete
                                                </Dropdown.Item>
                                                {/* <Dropdown.Item onClick={() => shareToFacebook(`${window.location.origin}/posts/${item.id}`)}>
                                                    Share on fb
                                                </Dropdown.Item> */}
                                            </>
                                        ) : (
                                            <>
                                                <Dropdown.Item onClick={() => reportPost(item.id)}>
                                                    Report
                                                </Dropdown.Item>
                                            </>
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        <div className="row my-2">

                            {item?.description ? (
                                <div className="col-md-11 p-0">
                                    <p
                                        className="card-text small--newsfeed"
                                        style={{ whiteSpace: "pre-wrap" }}
                                    >
                                        {!seeMore
                                            ? item?.description.slice(0, 40 * 4)
                                            : item?.description}{" "}
                                        {item?.description.length > 40 * 4 ? (
                                            <a
                                                onClick={(e) => setSeeMore(!seeMore)}
                                                style={{ fontWeight: "bold", cursor: "pointer" }}
                                            >
                                                {" "}
                                                {seeMore ? "... See less" : "... See more"}
                                            </a>
                                        ) : (
                                            ""
                                        )}
                                    </p>
                                </div>
                            ) : (
                                <div
                                    className="col-md-11 p-0"
                                    style={{ display: "none" }}
                                ></div>
                            )}
                        </div>

                        {/* Share Section */}

                        {item.shared && <div className="row  " style={{ position: "relative", paddingTop: '10px' }}>
                            <div
                                className="col-md-1 col-1 d-flex align-items-center justify-content-center p-0"
                                style={{ width: "60px", marginLeft: '1px' }}
                            >
                                <Link to={`/profile/${item?.user?.id}`}>
                                    <img
                                        src={
                                            item?.user?.photo
                                                ? item?.user?.photo.includes("http") ? item?.user?.photo : process.env.REACT_APP_apiBaseURL +
                                                    "/" +
                                                    item.shared?.user?.photo
                                                : chara
                                        }
                                        alt=""
                                        style={{
                                            width: "30px",
                                            height: "30px",
                                            objectFit: "cover",
                                            borderRadius: "50%",
                                        }}
                                    />
                                </Link>
                            </div>
                            <div className="col-md-9 col-9 d-flex flex-column p-0">
                                <h5
                                    style={{ fontWeight: "700", fontSize: "14px" }}
                                    className="card-title me-2 mb-0 d-flex align-items-center"
                                >
                                    {item.shared?.user?.name} {item.shared.user.verified && (<span className="me-1"><img src="/assets/images/Verify.svg" style={{ width: '12px' }} /></span>)}
                                </h5>
                                <p className="card-text">
                                    <small
                                        className="text-muted"
                                        style={{ fontWeight: "500", fontSize: "12px" }}
                                    >
                                        {convertTime(item.shared?.created_at) == -1 ? '0 min ago' : convertTime(item.shared?.created_at) }
                                    </small>
                                </p>
                            </div>
                            <div className="row">

                                {item.shared?.description ? (
                                    <div className="col-md-11 p-0 py-2">
                                        <p
                                            className="card-text small--newsfeed"
                                            style={{ whiteSpace: "pre-wrap" }}
                                        >
                                            {!seeMoreSharedPost
                                                ? item.shared?.description.slice(0, 40 * 4)
                                                : item.shared?.description}{" "}
                                            {item.shared?.description.length > 40 * 4 ? (
                                                <a
                                                    onClick={(e) => setSeeMoreSharedPost(!seeMoreSharedPost)}
                                                    style={{ fontWeight: "bold", cursor: "pointer" }}
                                                >
                                                    {" "}
                                                    {seeMoreSharedPost ? "... See less" : "... See more"}
                                                </a>
                                            ) : (
                                                ""
                                            )}
                                        </p>
                                    </div>
                                ) : (
                                    <div
                                        className="col-md-11 p-0"
                                        style={{ display: "none" }}
                                    ></div>
                                )}
                            </div>
                        </div>}

                        {/* End */}

                        <div className="container mt-3 p-0">
                            <div style={{ marginBottom: "20px" }}>
                                <PostImagegallery
                                    item={item}
                                    setOpen={setOpen}
                                    setCurrentImage={setCurrentImage}
                                >
                                    <div
                                        className="card-footer"
                                        style={{ border: "none", padding: "0" }}
                                    >
                                        {
                                            item.product_id !== null ?

                                                <div
                                                    className="col-md-12 d-flex justify-content-between align-items-center"
                                                    style={{
                                                        width: "100%",
                                                        position: "absolute",
                                                        bottom: "0",
                                                        padding: "0 11px 9px 8px",
                                                    }}
                                                >
                                                    <p
                                                        className="px-2 d-flex align-items-center"
                                                        style={{
                                                            marginBottom: "0px",
                                                            backgroundColor: "#D9D9D9",
                                                            borderRadius: "10px",
                                                            height: "36px",
                                                        }}
                                                    >
                                                        ${item.amount} 
                                                    </p> 
                                                    <p 
                                                        className="px-2 d-flex align-items-center"
                                                        style={{
                                                            marginBottom: "0px",
                                                            backgroundColor: "#D9D9D9",
                                                            borderRadius: "10px",
                                                            height: "36px",
                                                        }}>
                                                        <Link to={`/store/${item.user.user_name}`}>View in Store</Link>
                                                    </p>
                                                    {
                                                        item.have_i_paid ?
                                                            <OverlayTrigger placement="top" overlay={tooltip(item.post_type)}>
                                                                <img src="/assets/images/info_icon.svg" style={{width: '22px'}}/>
                                                            </OverlayTrigger>
                                                            : !isLoggedInUserProfile &&
                                                                item.post_type === constants.meme ?
                                                                null

                                                                : !isLoggedInUserProfile &&
                                                                    item.post_type === constants.product ?
                                                                    <button
                                                                        className="get-started"
                                                                        style={{ width: "120px", height: "42px" }}
                                                                        onClick={() => addToCart(item)}
                                                                    >
                                                                        {item.in_cart ? "View Cart" : "Add To Cart"}
                                                                    </button>
                                                                    : <></>
                                                    }
                                                </div>
                                                : <></>
                                        }
                                    </div>
                                </PostImagegallery>
                                <Lightbox
                                    open={open}
                                    close={() => setOpen(false)}
                                    slides={item?.attachments.map((item, index) => {
                                        return {
                                            src: process.env.REACT_APP_apiBaseURL + "/" + item.path,
                                            caption: `Image ${index}`,
                                        };
                                    })}
                                    currentImage={currentImage}
                                    setCurrentImage={setCurrentImage}
                                    plugins={[
                                        Captions,
                                        Fullscreen,
                                        Slideshow,
                                        Thumbnails,
                                        Video,
                                        Zoom,
                                    ]}
                                />
                            </div>

                            <div
                                className="row"
                                style={{
                                    borderTop: " 1px solid rgba(0, 0, 0, 0.125) ",
                                    borderBottom: "1px solid rgba(0, 0, 0, 0.125) ",
                                    marginBottom: "20px",
                                }}
                            >
                                <div
                                    className="col-md-6 d-flex  icon--div--news"
                                    style={{ justifyContent: "space-between" }}
                                >
                                    {/* {{-- <img src="/assets/images/Group 699.png" /> --}} */}
                                    <div
                                        className="like--folow"
                                        style={{
                                            cursor: "pointer",
                                            display: "flex",
                                            gap: "5px",
                                            alignItems: "center",
                                        }}
                                    >
                                        {item?.have_i_reacted === 1 &&
                                            constants.love === item.reaction ? (
                                            <i
                                                className="fa fa-heart heart-icon animated"
                                                aria-hidden="true"
                                                style={{ color: "red", cursor: "pointer" }}
                                                onClick={() => react(item?.id, constants.love)}
                                            ></i>
                                        ) : (
                                            <i
                                                className="fa fa-heart-o heart-icon"
                                                aria-hidden="true"
                                                style={{ color: "red", cursor: "pointer" }}
                                                onClick={() => react(item?.id, constants.love)}
                                            ></i>
                                        )}
                                        {likesFormat}
                                    </div>
                                    <div
                                        className="like--folow d-flex align-items-center "
                                        style={{ columnGap: "2px" }}
                                    >
                                        {item?.have_i_reacted === 1 &&
                                            constants.dislike === item.reaction ? (
                                            <AiFillDislike
                                                style={{
                                                    color: "black",
                                                    width: "26px",
                                                    height: "26px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => react(item?.id, constants.dislike)}
                                            />
                                        ) : (
                                            <AiOutlineDislike
                                                style={{
                                                    color: "black",
                                                    width: "26px",
                                                    height: "26px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => react(item?.id, constants.dislike)}
                                            />
                                        )}
                                        {dislikeFormat}
                                    </div>
                                    <div className="like--folow">
                                        <button
                                            style={{ border: "none", background: "none" }}
                                            disabled={newsFeedCommentStatus ? true : false}
                                            onClick={() => commentModal()}
                                        >
                                            <img
                                                src={msg}
                                                style={{
                                                    color: "blue",
                                                    width: "26px",
                                                    height: "26px",
                                                    cursor: "pointer",
                                                }}
                                                alt=""
                                            />
                                        </button>
                                        {item?.total_comments}
                                    </div>
                                    {/* Share Modal */}
                                    <div
                                        onClick={() => {
                                            setShare(true);
                                        }}
                                        className="like--folow d-flex align-items-center "
                                        style={{ cursor: "pointer", columnGap: "2px" }}
                                    >
                                        <img
                                            src={share1}
                                            aria-hidden="true"
                                            style={{ color: "blue", width: "21px", height: "21px" }}
                                            alt=""
                                        />{" "}
                                        {item.total_shares}
                                    </div>
                                </div>
                            </div>
                            <Comment
                                item={item}
                                setPostsData={setPostsData}
                                newsFeedCommentStatus={newsFeedCommentStatus}
                                setNewsFeedCommentStatus={setNewsFeedCommentStatus}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <ReportModal
                postId={reportPostId}
                show={reportShow}
                onHide={() => setReportShow(false)}
            />

            <ShareModal
                show={share}
                item={item}
                onHide={() => setShare(false)}
                setShare={setShare}
                setPostsData={setPostsData}
            />

            <EditPostModal
                editpost={editpost}
                setEditPost={setEditPost}
                setPostsData={setPostsData}
                item={item}
            />
            <CommentsModal
                CommentsShow={CommentsShow}
                setCommentsShow={setCommentsShow}
                item={item}
                uniqueKey={item.id}
                converttime={converttime}
                setPostsData={setPostsData}
                likesFormat={likesFormat}
                dislikeFormat={dislikeFormat}
            />
            <DeleteAccount
                show={deleteModal}
                onHide={() => setDeleteModal(false)}
                name={"Post"}
                deletePost={deletePost}
                deleteBtnLock={deleteBtnLock}
            />
        </>
    );
}

export default NewsFeedPosts;
