import React, { useEffect, useState } from "react";
import StripeCheckout from "./StripeCheckout";
import { getCart, submitCart } from "../services/CartService";
import { useNavigate, useParams,useSearchParams  } from "react-router-dom";
import { toast } from "react-toastify";
import { getProduct } from "../services/storeService";
import { getUserPrimaryAccount } from "../services/ProfileService";
import CardDetail from "../components/card/CardDetail";
import { createPaymentIntent } from "../services/StripeService";
import Spinner from 'react-bootstrap/Spinner';

const CheckOut = () => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    const [payload, setPayload] = useState([]);
    const [subTotal, setSubTotal] = useState(0);
    const [printFullTotal, setPrintFullTotal] = useState(0);
    const [paymentIntent, setPaymentIntent] = useState(false);
    const [loader, setLoader] = useState(false);
    const [userCard, setUserCard] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [clientSecret, setClientSecret] = useState(null);
    const [isPaymentMethodSelected, setPaymentSelected] = useState("");
    const [loadingDetail, setLoadingDetail] = useState(false);
    const [toastId, setToasterId] = useState(null);
    const [cartQuantity,setCartQuantity] = useState([]);

    const navigate = useNavigate();
    
    const [searchParams] = useSearchParams();
    const [variantId,setVariantId] = useState(searchParams.get('variantId')!=null ? searchParams.get('variantId') : 0);
    const [varaintIdsArray,setVaraintIdsArray] = useState([searchParams.get('variantId')!=null ? searchParams.get('variantId') : 0])
    const [error, setValidationError] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        address: '',
        zip: '',
        city: '',
        country: '',
        payment_intent_id: ''
    });

    const [checkoutUser, setCheckoutUser] = useState({
        first_name: user?.name.split(" ")[0],
        last_name: user?.name.split(" ")[1],
        email: user?.email,
        phone: user?.phone,
        address: '',
        zip: '',
        city: '',
        country: '',
        payment_intent_id: '',
        payment_method_id: null
    });
    
    const { id } = useParams();
    useEffect(() => {
        if (!id) {
            getCartData();
        } else {
            getProductById();
        }
        getPaymentMethod();
        setUser(JSON.parse(localStorage.getItem('user')));
    }, []);

    useEffect(() => {
        if (subTotal > 0) {
            getPaymentIntent();
        }
    }, [subTotal])

    const getPaymentMethod = async () => {
        setLoadingDetail(true);
        const response = await getUserPrimaryAccount();
        if (response.status === 200) {
            setUserCard(response.data.data);
        } else if (response.status === 404) {
            setPaymentSelected("create-new");
        }
        setLoadingDetail(false);
    }
    const getCartData = async () => {
        const response = await getCart();
        if (response.status === 200) {
            setPayload(response.data.data);
            let subTotal = 0;
            let printFullTotal = 0;
            let carts = response.data.data;
            let looplength = carts.length
            let newrray = varaintIdsArray
            for (let i = 0; i < looplength; i++) {
                subTotal += carts[i].price * carts[i].quantity+ carts[i].printful_price * carts[i].quantity;
                printFullTotal += carts[i].printful_price * carts[i].quantity;
                cartQuantity.push(carts[i].quantity);
                setVariantId(carts[i].variant_id);

                if(varaintIdsArray[i] !== 0){
                    newrray.push(carts[i].variant_id )
                }
                else {
                    newrray.shift();
                     newrray.push(carts[i].variant_id )
                }
                setVaraintIdsArray(newrray);
            }
            setSubTotal(subTotal);
            setPrintFullTotal(printFullTotal);

        } else {
            console.log(response.data.message);
        }
    };
    
    const getProductById = async () => {
        const response = await getProduct(id);
        if (response.status === 200) {
            let arr = [];
            arr[0] = response.data.data;
            setPayload(arr);
            if (arr?.length > 0) {
                cartQuantity.push(1);
                let subTotal = response.data.data?.price + response.data.data?.printful_price * response.data.data?.quantity
                let printFullTotal = response.data.data?.printful_price
                setSubTotal(subTotal);
                setPrintFullTotal(printFullTotal);
            }
        }
    }

    // const validatePayload = () => {
    //     if (checkoutUser.first_name === '') {
    //         setValidationError(preState => ({
    //             ...preState,
    //             first_name: 'First name is required'
    //         }))
    //         return true;
    //     } else {
    //         setValidationError(preState => ({
    //             ...preState,
    //             first_name: ''
    //         }))
    //     }
    //     if (checkoutUser.last_name === '') {
    //         setValidationError(preState => ({
    //             ...preState,
    //             last_name: 'Last name is required'
    //         }))
    //         return true;
    //     } else {
    //         setValidationError(preState => ({
    //             ...preState,
    //             last_name: ''
    //         }))
    //     }
    //     if (checkoutUser.email === '') {
    //         setValidationError(preState => ({
    //             ...preState,
    //             email: 'email is required'
    //         }))
    //         return true;
    //     } else {
    //         setValidationError(preState => ({
    //             ...preState,
    //             email: ''
    //         }))
    //     }
    //     if (checkoutUser.phone === '') {
    //         setValidationError(preState => ({
    //             ...preState,
    //             phone: 'Phone is required'
    //         }))
    //         return true;
    //     } else {
    //         setValidationError(preState => ({
    //             ...preState,
    //             phone: ''
    //         }))
    //     }
    //     if (checkoutUser.address === '') {
    //         setValidationError(preState => ({
    //             ...preState,
    //             address: 'address is required'
    //         }))
    //         return true;
    //     }
    //     if (checkoutUser.address.length < 10) {
    //         setValidationError(preState => ({
    //             ...preState,
    //             address: 'address must be at least 10 character longs'
    //         }))
    //         return true;
    //     } else {
    //         setValidationError(preState => ({
    //             ...preState,
    //             address: ''
    //         }))
    //     }
    //     if (checkoutUser.zip === '') {
    //         setValidationError(preState => ({
    //             ...preState,
    //             zip: 'Zip code is required'
    //         }))
    //         return true;
    //     } else {
    //         setValidationError(preState => ({
    //             ...preState,
    //             zip: ''
    //         }))
    //     }
    //     if (checkoutUser.city === '') {
    //         setValidationError(preState => ({
    //             ...preState,
    //             city: 'city is required'
    //         }))
    //         return true;
    //     } else {
    //         setValidationError(preState => ({
    //             ...preState,
    //             city: ''
    //         }))
    //     }
    //     return false;
    // }


    const getPaymentIntent = async () => {
        const response = await createPaymentIntent({ amount: subTotal });
        if (response.status === 200) {
            setClientSecret(response.data);
            setCheckoutUser((preState) => ({
                ...preState,
                payment_intent_id: response.data.payment_intent_id,
            }));
        }
    };

    const makeStripePayment = () => {
        setPaymentIntent(!paymentIntent);
    }

    const submitOrder = async (event) => {
        event.preventDefault();
        if (isPaymentMethodSelected === "pre-card") {
            createOrder();
        } else {
            makeStripePayment();
        }
    }
    
    const createOrder = async (paymentMethodId = null, toasterId = null) => {        
        const id = toasterId === null ? toast.loading("Placing order...") : toasterId;
        setLoader(true);
        var orderPayload = {}
        orderPayload.product_ids = payload
        orderPayload.payment_intent_id = checkoutUser.payment_intent_id
        orderPayload.user = checkoutUser
        orderPayload.price = subTotal
        orderPayload.quantity = cartQuantity
        orderPayload.variant_id = varaintIdsArray
        orderPayload.printful_charges = printFullTotal
        orderPayload.paymentMethod = paymentMethodId
        orderPayload.payment_method_id = checkoutUser.payment_method_id;
        const response = await submitCart(orderPayload);
        if (response.status === 200) {
            if (varaintIdsArray.includes(0)) {
                (async () => {
                    await toast.update(id, { 
                        render: "Meme purchased successfully", 
                        type: "success", 
                        isLoading: false
                    });
                
                    localStorage.setItem("cartCount", 0);
                    window.location.href = '/customizer';
                })();
            } else {
                (async () => {
                    await toast.update(id, { 
                        render: "Product purchased successfully", 
                        type: "success", 
                        isLoading: false
                    });
                
                    localStorage.setItem("cartCount", 0);
                    window.location.href = '/shop/user';
                })();
            }
            
        }else if(response.status === 204){

            (async () => {
                await toast.update(id, { 
                    render: "You have already purchased this Meme", 
                    type: "success", 
                    isLoading: false
                });
            
                window.location.href = '/customizer';
            })();

        } else if (response.status === 500) {
            toast.update(id, { render: response.data.message, type: "error", isLoading: false });
        }
        setTimeout(() => {
            toast.dismiss(id);
        }, 5000);
        // setLoader(false);
    }
    useEffect(()=>{
        console.log("VaraintiArray is here ",varaintIdsArray)
    },[varaintIdsArray])
    const handlePaymentMethod = () => {
        setPaymentSelected("create-new");
    }

    return (
        <>
            <div className="container check--out--container">
                <div className="row gap-4">
                    <div
                        className="col-lg-7 col-md-7  col-sm-12 col-12 mt-4 p-4 rounded d-flex flex-column justify-content-center align-items-center"
                        style={{ backgroundColor: "white" }}
                    >
                        <form style={{ width: '100%' }}>
                            {
                                loadingDetail ?
                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                        <Spinner animation="grow" variant="dark" />
                                        <small>verifying</small>
                                    </div>
                                    :
                                    subTotal > 0 && isPaymentMethodSelected === "create-new" ?
                                        <StripeCheckout
                                            paymentIntent={paymentIntent}
                                            setCheckoutUser={setCheckoutUser}
                                            subTotal={subTotal}
                                            setLoader={setLoader}
                                            setOrder={createOrder}
                                            setPaymentMethod={setPaymentMethod}
                                            clientSecret={clientSecret}
                                            setToasterId={setToasterId}
                                        />
                                        :
                                        <div className="d-flex justify-content-around">
                                            <CardDetail account={userCard} setCheckoutUser={setCheckoutUser} setPaymentSelected={setPaymentSelected} />
                                            <div className="d-flex flex-column justify-content-center align-items-center ms-3"
                                                style={{ width: '336px', height: '192px', border: '2px solid #242424', borderRadius: '12px', cursor: 'pointer' }}
                                                onClick={handlePaymentMethod}>
                                                <img src="/assets/images/add_method.svg" alt="payment method" style={{ width: '48px', height: '48px' }} />
                                                <p style={{ marginBottom: '0px' }}>Payment Method</p>
                                                <p style={{ marginBottom: '0px' }}>Click + to create new one</p>
                                            </div>
                                        </div>
                            }
                        </form>
                    </div>

                    <div
                        className="col-lg-4 col-md-4  col-sm-12 col-12 mt-4 p-4 rounded"
                        style={{
                            backgroundColor: "white",
                            maxHeight: "600px",
                            height: "100%",
                        }}
                    >
                        <div>
                            <h1>Cart Summary</h1>
                            {
                                payload.map((item, key) => {
                                    return (
                                        <div className="d-flex align-items-center gap-3">
                                            <img
                                                src={
                                                    item.printful_product_id === 19 || item.printful_product_id === 300
                                                        ? (
                                                            item.placement === 'Right'
                                                                ? (item.right_mockup ? item.right_mockup : (process.env.REACT_APP_apiBaseURL + '/' + item.image))
                                                                : (item.placement === 'Left' ? (item.left_mockup ? item.left_mockup : (process.env.REACT_APP_apiBaseURL + '/' + item.image)) : (item.front_mockup ? item.front_mockup : (process.env.REACT_APP_apiBaseURL + '/' + item.image)))
                                                        )
                                                        : (process.env.REACT_APP_apiBaseURL + '/' + item.image)
                                                }
                                                alt="CheckOut"
                                                className="img--check"
                                            />
                                            <div className="d-flex flex-column">
                                                <p className="mb-1 lable--font">{item.title}</p>
                                                <p className="mb-1 lable--font">
                                                    <strong>By: </strong> {item.user.user_name}{" "}
                                                </p>
                                                {item.type == 1 ? (<p className="mb-1 lable--font">Price : ${item.price } <span className="printfull-prices"> & Printful Charge : ${ item.printful_price}</span><p>Quantity: {item.quantity != '' ? item.quantity : 1}</p></p>) : (<p className="mb-1 lable--font">Price : ${item.price} <p>Qunatity: {item.quantity}</p></p>) }
                                            </div>
                                        </div>
                                    )
                                })
                            }

                            <div className="mt-5 border--bbb">
                                <div className="d-flex justify-content-between px-3">
                                    <strong>Subtotal</strong>
                                    <p>${(subTotal - printFullTotal).toFixed(2)}</p>
                                </div>
                                <div className="d-flex justify-content-between px-3">
                                    <strong>Printful Charges</strong>
                                    <p>${(printFullTotal).toFixed(2)}</p>
                                </div>
                            </div>

                            <div>
                                <div className="d-flex justify-content-between px-3">
                                    <strong>Total Price</strong>
                                    <p>${(subTotal).toFixed(2)}</p>
                                </div>
                            </div>

                            <div>
                                <button className="w-100 btn--checkout d-flex align-items-center justify-content-center"
                                    onClick={submitOrder}
                                    disabled={loader || !isPaymentMethodSelected} style={{ opacity: loader || isPaymentMethodSelected === "" ? '0.5' : '' }}>
                                    {/* {
                                        loader && (
                                            <div className="spinner-border me-2" role="status"
                                                style={{ width: '1.5rem', height: '1.5rem' }}>
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        )
                                    } */}
                                    Pay Now
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CheckOut;
