import React, { useState, useRef, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import { MdDone } from "react-icons/md";
import { updateProfile } from "../services/ProfileService";

const SocialMediaHandler = ({ data, setData, id, authUser }) => {
  const [facebook, setFacebook] = useState(false);
  const [instagram, setInstagram] = useState(false);
  const [twitter, setTwitter] = useState(false);
  const [facebookTarget, setFacebookTarget] = useState(null);
  const [instagramTarget, setInstagramTarget] = useState(null);
  const [twitterTarget, setTwitterTarget] = useState(null);
  const facebookRef = useRef(null);
  const instagramRef = useRef(null);
  const twitterRef = useRef(null);

  const handleFacebookClick = (event) => {
    setFacebook(!facebook);
    setFacebookTarget(event.target);
  };

  const handleInstagramClick = (event) => {
    setInstagram(!instagram);
    setInstagramTarget(event.target);
  };

  const handleTwitterClick = (event) => {
    setTwitter(!twitter);
    setTwitterTarget(event.target);
  };

  const handleFacebookHide = () => {
    setFacebook(false);
  };
  const handleInstagramHide = () => {
    setInstagram(false);
  };
  const handleTwitterHide = () => {
    setTwitter(false);
  };

  const facebookSubmit = () => {
    setFacebook(false);
    let finalData = {
      facebook: data.facebook,
    };

    const response = updateProfile(finalData);

    if (response.status === 200) {
      console.log("face Data submitted");
    }
  };
  const instagramSubmit = () => {
    setInstagram(false);
    let finalData = {
      instagram: data.instagram,
    };

    const response = updateProfile(finalData);

    if (response.status === 200) {
      console.log("insta Data submitted");
    }
  };
  const twitterSubmit = () => {
    setTwitter(false);
    let finalData = {
      twitter: data.twitter,
    };

    const response = updateProfile(finalData);

    if (response.status === 200) {
      console.log("twiiter Data submitted");
    }
  };

  const handleShareProfile = async () => {
    try {
      await navigator.share({
        title: data?.user_name,
        text: 'Share Profile',
        url: data?.user_name
      });
    } catch (error) {
      console.error('Sharing failed:', error);
    }
  }

  return (
    <>
      <div className="container mb-3 py-3 socialmedia--handler">
        <div className="row">
          <div className="col-md-12 px-0">
            <div className="card bg-white pt-3">
              <div className="cartd-title text-center mb-0">
                <h6 style={{ fontWeight: "700" }}>Social Media Handle(s)</h6>
              </div>
              <div className="card-body d-flex justify-content-center">
                <ul className="list-group list-group-flush d-flex flex-row gap-3">
                  <li className="p-1 list-group-item d-flex justify-content-between align-items-center">
                    <div ref={facebookRef}>
                      {authUser.user_name === id || authUser.id === id ? (
                        
                          <img
                            onClick={handleFacebookClick}
                            className="social--img"
                            src="/assets/images/facebook.png"
                            alt="Facebook"
                          />
                      ) : (
                        <a href={data?.facebook} target="_blank">
                          <img
                            className="social--img"
                            src="/assets/images/facebook.png"
                            alt="Facebook"
                          />
                        </a>
                      )}

                      {(authUser.user_name === id || authUser.id === id) && (
                        <Overlay
                          show={facebook}
                          target={facebookTarget}
                          placement="bottom"
                          container={facebookRef.current}
                          containerPadding={20}
                          onHide={handleFacebookHide}
                          rootClose={true} // add rootClose to close overlay when clicking outside of it
                        >
                          <Popover id="popover-contained">
                            <Popover.Header as="h3">Facebook</Popover.Header>
                            <Popover.Body>
                              <div className="d-flex gap-2 align-items-center">
                                <input
                                  type="text"
                                  className="px-2"
                                  placeholder="Link"
                                  value={data?.facebook}
                                  onChange={(e) => {
                                    let c = { ...data };
                                    c.facebook = e.target.value;
                                    setData(c);
                                  }}
                                />

                                <button
                                  className="save--btn"
                                  onClick={() => facebookSubmit()}
                                >
                                  <MdDone style={{ color: "white" }} />
                                </button>
                              </div>
                            </Popover.Body>
                          </Popover>
                        </Overlay>
                      )}
                    </div>
                  </li>

                  <li className="p-1 list-group-item d-flex justify-content-between align-items-center">
                    <div ref={instagramRef}>
                      {(id === authUser.user_name || id === authUser.id) ? (
                        
                          <img
                            onClick={handleInstagramClick}
                            className="social--img"
                            src="/assets/images/instagram.png"
                            alt="Instagram"
                          />
                      ) : (
                        <a href={data?.instagram} target="_blank">
                          <img
                            className="social--img"
                            src="/assets/images/instagram.png"
                            alt="Instagram"
                          />
                        </a>
                      )}

                      {(authUser.user_name === id || authUser.id === id) && (
                        <Overlay
                          show={instagram}
                          target={instagramTarget}
                          placement="bottom"
                          container={instagramRef.current}
                          containerPadding={20}
                          onHide={handleInstagramHide}
                          rootClose={true}
                        >
                          <Popover id="popover-contained">
                            <Popover.Header as="h3">Instagram</Popover.Header>
                            <Popover.Body>
                              <div className="d-flex gap-2 align-items-center">
                                <input
                                  type="text"
                                  className="px-2"
                                  placeholder="Link"
                                  value={data?.instagram}
                                  onChange={(e) => {
                                    let c = { ...data };
                                    c.instagram = e.target.value;
                                    setData(c);
                                  }}
                                />

                                <button
                                  className="save--btn"
                                  onClick={() => instagramSubmit()}
                                >
                                  <MdDone style={{ color: "white" }} />
                                </button>
                              </div>
                            </Popover.Body>
                          </Popover>
                        </Overlay>
                      )}
                    </div>
                  </li>

                  <li className="p-1 list-group-item d-flex justify-content-between align-items-center">
                    <div ref={twitterRef}>
                      {(authUser.user_name === id || authUser.id === id) ? (
                        
                          <img
                            onClick={handleTwitterClick}
                            className="social--img"
                            src="/assets/images/twitter.png"
                            alt="Twitter"
                          />
                        
                      ) : (
                        <a href={data?.twitter} target="_blank">
                          <img
                            className="social--img"
                            src="/assets/images/twitter.png"
                            alt="Twitter"
                          />
                        </a>
                      )}

                      {(authUser.user_name === id || authUser.id === id) && (
                        <Overlay
                          show={twitter}
                          target={twitterTarget}
                          placement="bottom"
                          container={twitterRef.current}
                          containerPadding={20}
                          onHide={handleTwitterHide}
                          rootClose={true}
                        >
                          <Popover id="popover-contained">
                            <Popover.Header as="h3">Twitter</Popover.Header>
                            <Popover.Body>
                              <div className="d-flex gap-2 align-items-center">
                                <input
                                  type="text"
                                  className="px-2"
                                  placeholder="Link"
                                  value={data?.twitter}
                                  onChange={(e) => {
                                    let c = { ...data };
                                    c.twitter = e.target.value;
                                    setData(c);
                                  }}
                                />

                                <button
                                  className="save--btn"
                                  onClick={() => twitterSubmit()}
                                >
                                  <MdDone style={{ color: "white" }} />
                                </button>
                              </div>
                            </Popover.Body>
                          </Popover>
                        </Overlay>
                      )}
                    </div>
                  </li>

                  <li className="p-1 list-group-item d-flex justify-content-between align-items-center">
                    <div>
                        <img
                          onClick={handleShareProfile}
                          className="social--img"
                          src="/assets/images/favicon.jpg"
                          alt="Twitter"
                        />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SocialMediaHandler;
