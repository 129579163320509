import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { submitSellerInfo } from "../services/ProfileService";
import { toast } from "react-toastify";
import { getCountries } from "../services/AuthService";
import { check_verification_status } from "../services/KycService";
import VerificationPending from "./VerificationPending";
import ErrorMsg from "../components/modal/ErrorMsg";

const Verify = () => {
    const navigate = useNavigate();
    const [countries, setCountries] = useState([]);
    const [statusLoading, setStatusLoading] = useState(true);
    const [verificationPending, setVerificationPending] = useState(false);
    const [loader, setLoader] = useState(false);
    const [errorModal, setRespErrorModal] = useState(false);
    const [ageCheck, setAgeCheck] = useState(false);
    const [errorRes, setRespError] = useState('');
    const [userInfo, setUserInfo] = useState({
        firstName: "",
        lastName: "",
        phone_code: "",
        countryId: "",
        mobileNo: "",
        ssn: "",
        mcc: "",
        dob: "",
        line1: "",
        line2: "",
        city: "",
        state: "",
        postal_code: "",
    });
    const [error, setError] = useState({
        firstName: "",
        lastName: "",
        phone_code: "",
        countryId: "",
        mobileNo: "",
        ssn: "",
        mcc: "",
        dob: "",
        line1: "",
        line2: "",
        city: "",
        state: "",
        postal_code: "",
    });

    const validateInfo = () => {
        if (userInfo.firstName === "") {
            setError(error => ({
                ...error,
                firstName: "First is required"
            }));
            return false;
        } else {
            setError(error => ({
                ...error,
                firstName: ""
            }));
        }
        if (userInfo.lastName === "") {
            setError(error => ({
                ...error,
                lastName: "Last is required"
            }));
            return false;
        } else {
            setError(error => ({
                ...error,
                lastName: ""
            }));
        }
        if (userInfo.phone_code === "") {
            setError(error => ({
                ...error,
                phone_code: "Country code is required"
            }));
            return false;
        } else {
            setError(error => ({
                ...error,
                phone_code: ""
            }));
        }
        if (userInfo.mobileNo === "") {
            setError(error => ({
                ...error,
                mobileNo: "Phone number is required"
            }));
            return false;
        } else {
            setError(error => ({
                ...error,
                mobileNo: ""
            }));
        }
        if (userInfo.dob === "") {
            setError(error => ({
                ...error,
                dob: "Date of birth is required"
            }));
            return false;
        } else {
            setError(error => ({
                ...error,
                dob: ""
            }));
        }
        if (userInfo.mcc === "") {
            setError(error => ({
                ...error,
                mcc: "Mcc is required"
            }));
            return false;
        } else {
            setError(error => ({
                ...error,
                mcc: ""
            }));
        }
        if (userInfo.ssn === "") {
            setError(error => ({
                ...error,
                ssn: "Social Security Number required"
            }));
            return false;
        } else {
            setError(error => ({
                ...error,
                ssn: ""
            }));
        }
        if (userInfo.line1 === "") {
            setError(error => ({
                ...error,
                line1: "Address line is required"
            }));
            return false;
        } else {
            setError(error => ({
                ...error,
                line1: ""
            }));
        }
        if (userInfo.city === "") {
            setError(error => ({
                ...error,
                city: "City is required"
            }));
            return false;
        } else {
            setError(error => ({
                ...error,
                city: ""
            }));
        }
        if (userInfo.state === "") {
            setError(error => ({
                ...error,
                state: "State is required"
            }));
            return false;
        } else {
            setError(error => ({
                ...error,
                state: ""
            }));
        }
        if (userInfo.postal_code === "") {
            setError(error => ({
                ...error,
                postal_code: "Postal code is required"
            }));
            return false;
        } else {
            setError(error => ({
                ...error,
                postal_code: ""
            }));
        }
        if (userInfo.countryId === "") {
            setError(error => ({
                ...error,
                countryId: "Country is required"
            }));
            return false;
        } else {
            setError(error => ({
                ...error,
                countryId: ""
            }));
        }
        return true;
    }
    const [inputType, setInputType] = useState('text');

    useEffect(() => {
        (async () => {
            const response = await check_verification_status();
            if (response?.status === 200) {
                if (response.data.hasStripe) {
                    navigate("/verify/payment_method");
                }
                if (response.data.verified) {
                    navigate("/wallet");
                } else if (response.data.status === "pending") {
                    setVerificationPending(true);
                }
            }
            setStatusLoading(false);
        })()
    }, []);

    // load countries list
    useEffect(() => {
        const loadCountryList = async () => {
            await getCountries().then((response) => {
                setCountries(response?.data?.data);
            });
        };
        loadCountryList();
    }, []);

    const handleInputType = () => {
        setInputType('text');
        validateInfo();
        calculateAge();
    }

    const continueClicked = async () => {
        if (!validateInfo()) {
            return;
        }
        setLoader(true);
        const response = await submitSellerInfo(userInfo);
        if (response?.status === 201) {
            navigate("/verify/payment_method");
        }

        if (response?.status === 500) {
            setRespError(response.data.message);
            setRespErrorModal(true);
        } else {
            console.log(response);
        }
        setLoader(false);
    };

    const calculateAge = () => {
        const birthdateObj = new Date(userInfo.dob);
        const today = new Date();
        const ageDiff = today - birthdateObj;
        const ageDate = new Date(ageDiff);
        const calculatedAge = Math.abs(ageDate.getUTCFullYear() - 1970);
        setAgeCheck(calculatedAge < 18);
        setRespErrorModal(calculatedAge < 18);
        if(calculatedAge < 18) {
            setRespError('You must be at least 18 years old.');
        }
      };

    return (statusLoading ? <div style={{ minHeight: '100vh' }}></div> : verificationPending ? <VerificationPending /> : <>
        {/* STEPPER 1 */}
        <section
            className="collection-section py-5"
            style={{ backgroundColor: "#F9F9F9" }}
        >
            <div className="container d-flex flex-column justify-content-center">
                <div className="row d-flex justify-content-center">
                    <div className="pb-5 col-md-12 d-flex flex-column justify-content-center text-center">
                        <h2
                            className="heading"
                            style={{ fontFamily: "RockoFLF Regular" }}
                        >
                            Verify Your Identity
                        </h2>
                    </div>
                </div>

                <div className="row ">
                    <div className="col-md-6 d-flex flex-column justify-content-start">
                        <div className="row d-flex justify-content-center">
                            <div className="pb-4 col-md-12">
                                <h2
                                    className="heading"
                                    style={{
                                        fontFamily: "RockoFLF Regular", textTransform: "unset",
                                    }}
                                >
                                    Review your Information
                                </h2>
                                <p style={{ marginBottom: "0px !important" }}>
                                    please review information, for further verification process
                                </p>
                            </div>
                        </div>
                        <div className="form-style" style={{ maxWidth: '100%' }}>
                            <form>
                                <div className="row mb-3  " style={{ rowGap: "1rem" }}>
                                    <div className="col-md-6" style={{ paddingRight: "0px" }}>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                placeholder="First Name"
                                                value={userInfo.firstName}
                                                onChange={(e) => setUserInfo({
                                                    ...userInfo, firstName: e.target.value,
                                                })}
                                                className="form-control Widths-tags layoyt--input"
                                                onBlur={validateInfo}
                                            />
                                            <small className="text-danger">{error.firstName}</small>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                placeholder="Last Name"
                                                value={userInfo.lastName}
                                                onChange={(e) => setUserInfo({
                                                    ...userInfo, lastName: e.target.value,
                                                })}
                                                className="form-control Widths-tags layoyt--input"
                                                onBlur={validateInfo}
                                            />
                                            <small className="text-danger">{error.lastName}</small>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{ rowGap: "1rem" }}>
                                    <div className="col-md-6" style={{ paddingRight: "0px" }}>
                                        <div className="form-group">
                                            <select
                                                className=" w-100 px-2 Widths-tags layoyt--input"
                                                value={userInfo.phone_code}
                                                onChange={(e) => setUserInfo({
                                                    ...userInfo, phone_code: e.target.value,
                                                })}
                                                style={{
                                                    backgroundColor: "#E9E9E9",
                                                    height: "calc(2.25rem + 15px)",
                                                    borderRadius: "10px",
                                                }}
                                                onBlur={validateInfo}
                                            >
                                                <option defaultValue disabled value="">
                                                    Country Code
                                                </option>
                                                {countries.map((country, index) => {
                                                    return (<option key={index} value={country.id}>
                                                        {country.phonecode}
                                                    </option>);
                                                })}
                                            </select>
                                            <small className="text-danger">{error.phone_code}</small>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                placeholder="Mobile No"
                                                value={userInfo.mobileNo}
                                                onChange={(e) => setUserInfo({
                                                    ...userInfo, mobileNo: e.target.value,
                                                })}
                                                className="form-control Widths-tags layoyt--input"
                                                onBlur={validateInfo}
                                            />
                                            <small className="text-danger">{error.mobileNo}</small>
                                        </div>
                                    </div>
                                </div>
                                <div className="row my-3" style={{ rowGap: "1rem" }}>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <input
                                                type={inputType}
                                                placeholder="Date of birth"
                                                value={userInfo.dob}
                                                onChange={(e) => setUserInfo({
                                                    ...userInfo, dob: e.target.value,
                                                })}
                                                onFocus={(e) => setInputType('date')}
                                                onBlur={(e) => handleInputType()}
                                                className="form-control Widths-tags layoyt--input"
                                            />
                                            <small className="text-danger">{error.dob}</small>
                                        </div>
                                    </div>
                                    <div className="col-md-4" style={{ paddingRight: "0px" }}>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                placeholder="Merchent category code"
                                                value={userInfo.mcc}
                                                onChange={(e) => setUserInfo({
                                                    ...userInfo, mcc: e.target.value,
                                                })}
                                                className="form-control Widths-tags layoyt--input"
                                                onBlur={validateInfo}
                                            />
                                            <small className="text-danger">{error.mcc}</small>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                placeholder="Last 4 digits Social Security Number"
                                                value={userInfo.ssn}
                                                onChange={(e) => setUserInfo({
                                                    ...userInfo, ssn: e.target.value,
                                                })}
                                                className="form-control Widths-tags layoyt--input"
                                                onBlur={validateInfo}
                                            />
                                            <small className="text-danger">{error.ssn}</small>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{ rowGap: "1rem" }}>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <textarea placeholder="Address line1" className="form-control Widths-tags layoyt--input" style={{ paddingRight: '0px' }}
                                                onBlur={validateInfo}
                                                onChange={(e) => setUserInfo({
                                                    ...userInfo, line1: e.target.value
                                                })}></textarea>
                                            <small className="text-danger">{error.line1}</small>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <textarea placeholder="Address line2 (Optional)" className="form-control Widths-tags layoyt--input" style={{ paddingRight: '0px' }}
                                                onBlur={validateInfo}
                                                onChange={(e) => setUserInfo({
                                                    ...userInfo, line2: e.target.value
                                                })}>
                                            </textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="row my-3" style={{ rowGap: "1rem" }}>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <input
                                                type="city"
                                                placeholder="City"
                                                value={userInfo.city}
                                                onChange={(e) => setUserInfo({
                                                    ...userInfo, city: e.target.value,
                                                })}
                                                className="form-control Widths-tags layoyt--input"
                                                onBlur={validateInfo}
                                            />
                                            <small className="text-danger">{error.city}</small>
                                        </div>
                                    </div>
                                    <div className="col-md-3" style={{ paddingRight: "0px" }}>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                placeholder="State"
                                                value={userInfo.state}
                                                onChange={(e) => setUserInfo({
                                                    ...userInfo, state: e.target.value,
                                                })}
                                                className="form-control Widths-tags layoyt--input"
                                                onBlur={validateInfo}
                                            />
                                            <small className="text-danger">{error.state}</small>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                placeholder="Postal code"
                                                value={userInfo.postal_code}
                                                onChange={(e) => setUserInfo({
                                                    ...userInfo, postal_code: e.target.value,
                                                })}
                                                className="form-control Widths-tags layoyt--input"
                                                onBlur={validateInfo}
                                            />
                                            <small className="text-danger">{error.postal_code}</small>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <select
                                                className=" w-100 px-2 Widths-tags layoyt--input"
                                                value={userInfo.countryId}
                                                onChange={(e) => setUserInfo({
                                                    ...userInfo, countryId: e.target.value,
                                                })}
                                                style={{
                                                    backgroundColor: "#E9E9E9",
                                                    height: "calc(2.25rem + 15px)",
                                                    borderRadius: "10px",
                                                }}
                                                onBlur={validateInfo}
                                            >
                                                <option defaultValue disabled value="">
                                                    Country
                                                </option>
                                                {countries.map((country, index) => {
                                                    return (<option key={index} value={country.id}>
                                                        {country.name}
                                                    </option>);
                                                })}
                                            </select>
                                            <small className="text-danger">{error.countryId}</small>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="py-2 text-center">
                                        <button
                                            type="button"
                                            onClick={continueClicked}
                                            className="get-started mt-2 d-flex justify-content-center align-items-center"
                                            style={{
                                                width: "140px", height: "50px",
                                            }}
                                            disabled={ageCheck}
                                        >
                                            {
                                                loader && (
                                                    <div className="spinner-border me-2" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                )
                                            }
                                            Continue
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="col-md-6 d-flex justify-content-end">
                        <div className="col-md-11 ">
                            <div
                                className="wallet-banner"
                                style={{
                                    backgroundImage: "url('/assets/images/wallet_banner.png')",
                                }}
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <ErrorMsg
            show={errorModal} onHide={() => setRespErrorModal(false)}
            message={errorRes}
        />
    </>);
};

export default Verify;
