import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {CreateReport} from "../../services/ReportService";
import {useLocation} from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";

const ReportModal = (props) => {
    const [msg, setMsg] = useState('');
    const [showLoading, setShowLoading] = useState(false);
    const [isValid, setIsValid] = useState(true);

    const {pathname} = useLocation();

    useEffect(() => {
        if (props.show) {
            setMsg("");
            setIsValid(true);
        }
    }, [props.show]);

    const submitReport = async () => {
        if (msg.length < 20) {
            setIsValid(false);
            return;
        } else {
            setIsValid(true);
        }
        setShowLoading(true);
        let response;
        if (props.postId) {
            response = await CreateReport({
                type: "post", message: msg, post_id: props.postId
            })
        } else if (props.productId) {
            response = await CreateReport({
                type: "product", message: msg, product_id: props.productId
            })
        } else if (props.notificationId) {
            let data = {
                type: props.notificationType,
                message: msg
            }
            if (props.notificationType === "follower") {
                data['user_id'] = props.notificationId
            } else {
                data[props.notificationType + "_id"] = props.notificationId
            }
            response = await CreateReport(data)
        } else if(props.profileId) {
            response = await CreateReport({
                type: "user", message: msg, user_id: props.profileId
            })
        } else {
            response = await CreateReport({
                type: "user", message: msg, user_id: pathname.split('/')[2]
            })
        }
        setShowLoading(false);
        if (response.status === 200) {
            toast.success("Reported success");
            setMsg('')
            props.onHide();
        } else {
            toast.error(response.data.message);            
        }
    }

    return (<>
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            id="modal-dialog"
            className="report--modal"
        >
            <Modal.Header className="justify-content-center" closeButton>
                <Modal.Title id="contained-modal-title-vcenter w-100">
                    <h5
                        className="text-center"
                        style={{fontFamily: "RockoFLF Bold", marginBottom: "0px"}}
                    >
                        Why are you reporting this?
                    </h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{background: "#FFFFFF", borderRadius: "3px"}}>
                <Form>
                    <div
                        className="m-3"
                        style={{background: "white", borderRadius: "10px"}}
                    >
                        <h2></h2>
                        <form>
                            <div className="form-group mb-3">
                              <textarea
                                  style={{background: "#E9E9E9", resize: "none"}}
                                  className="form-control suggestionInput"
                                  rows="4"
                                  id="message-text"
                                  placeholder="Enter your report here"
                                  onChange={(e) => setMsg(e.target.value)}
                              >
                                  {msg}
                              </textarea>
                                {!isValid && msg.length < 20 && (
                                    <div className="text-danger mt-1" style={{fontSize: '11px'}}>
                                        Message must be at least 20 characters
                                    </div>)}
                            </div>
                        </form>
                        <div className="mt-3 d-flex justify-content-end gap-3">
                            <button
                                type="button"
                                style={{backgroundColor: "black", color: "white"}}
                                className="btn  get-started"
                                onClick={submitReport}
                                disabled={showLoading}
                            >
                                {showLoading && (<Spinner
                                    animation="border me-2"
                                    style={{width: "15px", height: "15px"}}
                                />)}
                                {showLoading ? 'Submitting...' : 'Submit'}
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary get-started"
                                data-dismiss="modal"
                                onClick={props.onHide}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    </>);
};

export default ReportModal;
