import api from "./api";


export const createPaymentIntent = async (payload) => {
    try {
        const response = await api.post('api/v1/get_payment_intent', payload);
        return response;
    } catch (e) {
        return e.response;
    }
}