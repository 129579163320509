import { useEffect, useRef, useState } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { constants } from "../components/Constants/constants";
import CoverPhotoModal from "../components/modal/CoverPhotoModal";

import Sidebar from "../components/profile/Sidebar";
import Timeline from "../components/profile/Timeline";

import {
  followUserById,
  getProfile,
  getUserById,
  getUserPrimaryAccount,
} from "../services/ProfileService";
import { getProducts, getProductsByUserId } from "../services/storeService";
import follow from "../assets/images/icons/Group 131.svg";
import cancel from "../assets/images/cancel.svg";
import msg from "../assets/images/icons/ChatCircleText.svg";
import Spinner from "react-bootstrap/esm/Spinner";
import {
  getProfilePosts,
  getUserPaidPosts,
} from "../services/NewsFeedsService";
import { db } from "../components/Constants/Firebase";
import {
  collection,
  addDoc,
  query,
  where,
  getDocs,
  getDoc,
  doc,
} from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import { Dropdown } from "react-bootstrap";
import { RxDotsVertical } from "react-icons/rx";
import ReportModal from "../components/modal/ReportModal";
import { cancelRequest, blockProfile } from "../services/FollowersService";
import Deleteitem from "../components/modal/Deleteitem";

const Profile = () => {
  ///state
  const [profileData, setProfileData] = useState();
  const [productsData, setProductsData] = useState();
  const [memesData, setMemesData] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [imageData, setImagData] = useState();
  const [loader, setLoader] = useState(true);
  const [btnLock, setBtnLock] = useState(false);
  const [messageBtnLock, setBtnMessageLock] = useState(false);
  const [reportShow, setReportShow] = useState(false);
  const [blockModal, setBlockModal] = useState(false);
  const [blockUserBtnLock, setBlockUserBtnLock] = useState(false);
  const [authUser, setAuthUser] = useState(
    JSON.parse(localStorage.getItem("user"))
  );
  const location = useLocation();
  const navigate = useNavigate();

  /// another user  id
  const { id } = useParams();
  /// onpageload function runs
  useEffect(() => {
    // get another user profile function
    if (id) {
      getDataById();
      getDataPost(1, "", id);
    } else {
      ///user profile function which is loggedIn
      let user = JSON.parse(localStorage.getItem("user"));
      getData();
      getDataPost(1, "", user.id);
    }
  }, [location]);

  ///functions
  const getData = async () => {
    ///profile Api
    
    const profileResponse = await getProfile();

    if (profileResponse.status === 200) {
      // setting the profile data
      setProfileData(
        profileResponse.data.data,
        "----------------------profile data------------"
      );

      ///products Api
      const productsResponse = await getProducts(1);
      if (productsResponse.status === 200) {
        /// filtering the product type 0 = meme 1 = product
        setLoader(true);
        const productList = productsResponse.data.data.filter(
          (el) => el.type === constants.product
        );
        const memeslist = productsResponse.data.data.filter(
          (el) => el.type === constants.meme
        );

        // slicing the array and setting to state
        setProductsData(productList.slice(0, 3));
        setMemesData(memeslist.slice(0, 3));
        setLoader(false);
      }
    } else {
      toast.error(profileResponse.data.message);
    }
  };

  /// get by id function
  const getDataById = async () => {
    setBtnMessageLock(true);
    setBtnLock(true);
    const response = await getUserById(id);
    const productsResponse = await getUserPaidPosts(id);
    if (response.status === 200 && productsResponse.status === 200) {
      // setting the profile data
      setProfileData(response.data.data);
      setLoader(true);
      /// filtering the product type 0 = meme 1 = product
      const productList = productsResponse.data.products;
      const memeslist = productsResponse.data.creations;

      // slicing the array and setting to state
      setProductsData(productList.slice(0, 3));
      setMemesData(memeslist.slice(0, 3));
      setLoader(false);
      setBtnMessageLock(false);
      setBtnLock(false);
    } else {
      if (response.status === 404) {
        navigate("/404");
      } else {
        toast.error(response.data.message);
        setBtnMessageLock(false);
        setBtnLock(false);
      }
    }
  };

  const cancelFollowRequest = async () => {
    setBtnLock(true);
    const response = await cancelRequest(id);
    if (response.status === 200) {
      toast.success(response.data.message);
      setProfileData({
        ...profileData,
        is_already_request_sent: false,
      });
      setBtnLock(false);
    } else {
      toast.error(response.data.message);
      setBtnLock(false);
    }
  };
// console.log(JSON.parse(localStorage.getItem("user")).id)
  /// following user function
  const followUser = async (isFollowing) => {
    ///same api for follow and unfollow user
    setBtnLock(true);
    const response = await followUserById(profileData?.id);
    // const response = await followUserById(JSON.parse(localStorage.getItem("user")).id);

    if (response.status === 200) {
      if (isFollowing) {
        setProfileData({
          ...profileData,
          am_i_following: false,
          is_already_request_sent: false,
        });
      } else {
        setProfileData({
          ...profileData,
          is_already_request_sent: true,
        });
      }
      toast.success(response.data.message);
      setBtnLock(false);
    } else {
      toast.error(response.data.message);
      setBtnLock(false);
    }
  };

  const [paginateLinks, setPaginateLinks] = useState(null);
  const [postsData, setPostsData] = useState([]);
  const [loaderpost, setLoaderPost] = useState(false);

  const [pageIndex, setPageIndex] = useState(1);

  /// geting data from an API
  const getDataPost = async (pageIndex, paginate, userId) => {
    setLoaderPost(true);
    const response = await getProfilePosts(pageIndex, userId);
    if (response.status === 200) {
      if (paginate === "paginate") {
        setPostsData([...postsData, ...response?.data?.data]);
      } else {
        setPostsData(response.data.data);
      }
      setPaginateLinks(response.data.links?.next);
      if (response.data.links?.next) {
        setPageIndex(pageIndex + 1);
      }
      setLoaderPost(false);
    } else {
      toast.error(response.data.message);
      setLoaderPost(false);
    }
  };

  ////creating firebase room
  const createRoom = async () => {
    try {
      setBtnMessageLock(true);
      const userRef = doc(
        db,
        "Users",
        JSON.parse(localStorage.getItem("user")).id
      );
      const userSnapshot = await getDoc(userRef);
      const userData = userSnapshot.data();

      const query3 = query(
        collection(db, "ChatRooms"),
        where("UserIds", "in", [
          [JSON.parse(localStorage.getItem("user")).id, profileData.id],
        ])
      );
      const querySnapshot3 = await getDocs(query3);

      if (querySnapshot3.docs.length === 0) {
        await addDoc(collection(db, "ChatRooms"), {
          id: uuidv4(),
          lastmessage: "",
          timeStampOfLastMessage: "",
          UserIds: [
            JSON.parse(localStorage.getItem("user")).id,
            profileData.id,
          ],
          recieveUser: {
            email: profileData.email,
            id: profileData.id,
            imageUrl: profileData.photo,
            name: profileData.name,
            isRead: false,
            isChatOpen: false,
            totalUnreadMessages: 0,
            isOnline: true,
          },
          senderUser: {
            email: JSON.parse(localStorage.getItem("user")).email,
            id: JSON.parse(localStorage.getItem("user")).id,
            imageUrl: JSON.parse(localStorage.getItem("user")).photo,
            name: JSON.parse(localStorage.getItem("user")).name,
            isRead: false,
            isChatOpen: false,
            totalUnreadMessages: 0,
            isOnline: userData.isOnline ? true : false,
          },
        });
      }
      navigate("/chat");
      setBtnMessageLock(false);
    } catch (error) {
      console.log("Error creating/checking user document:", error);
    }
  };

  const blockUser = async () => {
    setBlockModal(false);
    setBlockUserBtnLock(true);
    const id = toast.loading(
      (profileData?.is_blocked ? "Unrestricting " : "Restricting ") +
        "access to the profile"
    );
    const response = await blockProfile({ profile_id: profileData?.id });
    if (response.status === 200) {
      setProfileData({
        ...profileData,
        is_blocked: !profileData?.is_blocked,
      });
      toast.update(id, {
        render: response.data.message,
        type: "success",
        isLoading: false,
      });
    } else if (response.status === 422) {
      toast.update(id, {
        render: response.data.message,
        type: "info",
        isLoading: false,
      });
    } else {
      toast.update(id, {
        render: response.data.message,
        type: "error",
        isLoading: false,
      });
    }
    setBlockUserBtnLock(false);
    setTimeout(() => {
      toast.dismiss(id);
    }, 4000);
    return;
  };

  return (
    <>
      <main style={{ backgroundColor: "rgba(249, 249, 249, 1)" }}>
        <div className="row profile--dic" style={{margin: "0"}}>
          <div className="col-md-12" style={{ position: "relative" }}>
            <div
              className="container d-flex justify-content-end "
              style={{
                position: "absolute",
                columnGap: "10px",
                right: "9%",
                bottom: "5%",
              }}
            >
              {authUser?.user_name === id || authUser?.id === id || !id ? (
                <></>
              ) : (
                <>
                  {profileData?.is_blocked ? (
                    <button
                      className="public--profile--link"
                      disabled={profileData?.is_blocked}
                    >
                      {"Blocked"}
                    </button>
                  ) : profileData?.am_i_following ? (
                    <button
                      className="public--profile--link"
                      onClick={() => followUser(profileData?.am_i_following)}
                      disabled={btnLock}
                    >
                      {btnLock ? <Spinner /> : "Unfollow"}
                    </button>
                  ) : profileData?.is_already_request_sent ? (
                    <button
                      className="public--profile--link"
                      onClick={() => cancelFollowRequest()}
                      disabled={btnLock}
                    >
                      {btnLock ? (
                        <Spinner />
                      ) : (
                        <>
                          <img src={cancel} alt="" /> Cancel{" "}
                        </>
                      )}
                    </button>
                  ) : (
                    <button
                      className="public--profile--link"
                      onClick={() => followUser(profileData?.am_i_following)}
                      disabled={btnLock}
                    >
                      {btnLock ? (
                        <Spinner />
                      ) : profileData?.am_i_being_followed ? (
                        <>
                          <img src={follow} alt="" /> Follow Back{" "}
                        </>
                      ) : (
                        <>
                          <img src={follow} alt="" /> Follow{" "}
                        </>
                      )}
                    </button>
                  )}

                  <button
                    className="public--profile--link1"
                    onClick={(e) => createRoom(e)}
                  >
                    {messageBtnLock ? (
                      <Spinner />
                    ) : (
                      <>
                        <img src={msg} alt="" /> Message{" "}
                      </>
                    )}
                  </button>
                </>
              )}
            </div>
            <img
              className="w-100"
              src={
                imageData
                  ? imageData
                  : profileData?.cover_photo
                  ? process.env.REACT_APP_apiBaseURL +
                    "/" +
                    profileData?.cover_photo
                  : "/assets/images/cover_photo.png"
              }
              alt=""
              style={{
                // height: "45vh",
                minHeight: "300px",
                objectFit: "cover",
              }}
            />

            {authUser.id === id || authUser.user_name === id ? (
              <img
                src="/assets/images/edit_cover.png"
                style={{
                  position: "absolute",
                  top: "30px",
                  right: "104px",
                  cursor: "pointer",
                }}
                alt=""
                title="Go to Creator"
                onClick={() => navigate("/customizer")}
              />
            ) : (
              <Dropdown
                className="position-absolute"
                style={{ right: "104px", top: "30px" }}
              >
                <Dropdown.Toggle
                  className="btn btn-secondary three--dots"
                  id="dropdown-basic"
                >
                  <RxDotsVertical
                    size={25}
                    style={{
                      backgroundColor: "#E9E9E9",
                      borderRadius: "4px",
                      padding: "4px",
                    }}
                  />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setReportShow(true)}>
                    Report
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setBlockModal(true)}>
                    {" "}
                    {profileData?.is_blocked ? "Unblock" : "Block"}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        </div>
        <div className="container">
          <div className="row">
            <Sidebar
              profileData={profileData}
              id={id}
              setPostsData={setPostsData}
            />
            <Timeline
              profileData={profileData}
              productsData={productsData}
              memesData={memesData}
              loader={loader}
              id={id}
              paginateLinks={paginateLinks}
              loaderpost={loaderpost}
              postsData={postsData}
              getDataPost={getDataPost}
              setPostsData={setPostsData}
              pageIndex={pageIndex}
            />
          </div>
        </div>
      </main>
      <CoverPhotoModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        setImagData={setImagData}
        setPostsData={setPostsData}
      />
      <ReportModal
        show={reportShow}
        onHide={() => setReportShow(false)}
        profileId={profileData?.id}
      />
      <Deleteitem
        show={blockModal}
        onHide={() => setBlockModal(false)}
        deleteText={
          "You want to " +
          (profileData?.is_blocked ? "unblock" : "block") +
          " this profile?"
        }
        deleteItem={blockUser}
        deleteBtnLock={blockUserBtnLock}
        btnname={profileData?.is_blocked ? "unblock" : "block"}
      />
    </>
  );
};

export default Profile;
