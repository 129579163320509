import { Card, Dropdown } from "react-bootstrap";
import { HiOutlineDotsVertical } from "react-icons/hi";
import EditPaymentCard from "../modal/EditPaymentCard";
import { useState } from "react";
import { deleteCard } from "../../services/ProfileService";
import { toast } from "react-toastify";

const PaymentCard = ({ account, cards, setCards }) => {

    const [editModal, setEditModal] = useState(false);
    const [detail, setDetail] = useState(null);

    const handleEdit = (account) => {
        setDetail(account);
        setEditModal(true);
    }

    const handleDelete = async(account) => {
        const id = toast.loading("Deleting card...");
        const response = await deleteCard(account.id);
        if(response.status === 200) {
            const updatedCards = cards.filter(card => card.id !== account.id);
            setCards(updatedCards);
            toast.update(id, { render: response.data.message, type: "success", isLoading: false });
        } else {
            toast.update(id, { render: response.data.message, type: "error", isLoading: false });
        }
    }

    return (
        <>
            <Card className='bg-dark justify-content-center px-3 m-2' style={{ width: '336px', height: '192px', border: "2px solid #242424", borderRadius: '12px', backgroundColor: 'white', cursor: 'pointer', position: 'relative' }}>
                <div className="menu-bar btn-group btn--group--three " style={{ position: 'absolute', right: '0px', top: '5px' }}>
                    <Dropdown>
                        <Dropdown.Toggle
                            className="btn btn-secondary three--dots"
                            id="dropdown-basic"
                        >
                            <HiOutlineDotsVertical size={20} style={{ color: 'white' }} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={(e) => handleEdit(account)}>
                                {" "}
                                Edit
                            </Dropdown.Item>
                            <Dropdown.Item onClick={(e) => handleDelete(account)}>
                                {" "}
                                Detach
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div className="d-flex mt-2">
                    <input
                        type="text"
                        placeholder="1234 5678 9101 1213"
                        className="px-2 debit--card"
                        value={"**** **** **** " + account?.last4}
                        style={{
                            height: "30px",
                            width: "100%",
                            border: "1px solid #ACACAC",
                            letterSpacing: "7px",
                            color: 'white'
                        }}
                        readOnly
                    />
                </div>
                <div className="d-flex justify-content-between align-items-center mt-3 ">
                    <input
                        type="text"
                        placeholder="Name"
                        className="px-2 debit--card"
                        value={account?.name}
                        style={{
                            height: "30px",
                            width: "150px",
                            border: "1px solid #ACACAC",
                            color: 'white',
                            textTransform: 'capitalize'
                        }}
                        readOnly
                    />
                    <div className="gap-2">
                        <input
                            type="text"
                            placeholder="25/28"
                            className="ps-2 debit--card"
                            value={(account?.exp_month <= 10 && account?.exp_month.length !== 2 ? "0" + account?.exp_month : account?.exp_month) + "/" + (account?.exp_year.length === 4 ? account?.exp_year.slice(-2) : account?.exp_year)}
                            style={{
                                height: "30px",
                                width: "60px",
                                border: "1px solid #ACACAC",
                                color: 'white'
                            }}
                            readOnly
                        />
                    </div>
                </div>
                <div className="mt-3 d-flex justify-content-end">
                    <img src={"/assets/images/" + account?.brand.toLowerCase() + ".svg"} style={{ width: '30px' }} />
                </div>
            </Card>
            <EditPaymentCard
                show={editModal}
                onHide={(e) => setEditModal(false)}
                detail={detail}
                cards={cards}
                setCards={setCards}
            />
        </>
    );
}

export default PaymentCard