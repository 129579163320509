import React, {useEffect, useMemo, useState} from "react";
import NewsFeedPosts from "./NewsFeedPosts";
import {useLocation} from "react-router";
import {convertTime, LikesToK} from "./TimeFormat";
import {getPosts} from "../services/NewsFeedsService";

const PostDetail = () => {
    const {pathname} = useLocation()

    const [postsData, setPostsData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const post = useMemo(() => {
        return postsData.find((item) => item.id === pathname.split('/')[2])
    } , [postsData, pathname])

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            const response = await getPosts();
            if (response?.status === 200) {
                setPostsData(response.data.data);
            }
            setIsLoading(false);
        } )()
    } , [])

    return (
        isLoading ? <div style={{minHeight: '100vh'}}></div> :
        <>
            {/* <div className={'text-center pt-3'}>
                <h1>
                    {post?.description}
                </h1>
            </div> */}
            <div className={'p-5 pt-1'}>
                {post && <NewsFeedPosts
                    item={post}
                    converttime={convertTime(post?.created_at)}
                    postsData={postsData}
                    setPostsData={setPostsData}
                    likesFormat={LikesToK(post?.total_reactions)}
                    dislikeFormat={LikesToK(post?.total_dislikes)}
                />}
            </div>
        </>
    );
};

export default PostDetail;
