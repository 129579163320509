import Modal from "react-bootstrap/Modal";
import foto from "../../assets/images/Rectangle 69.png";
import { Link } from "react-router-dom";
import { sharePost } from "../../services/NewsFeedsService";
import { toast } from "react-toastify";
import { useState } from "react";
import Slider from "react-slick";
import Dropdown from "react-bootstrap/Dropdown";

const ShareModal = (props) => {
  
  const divProps = Object.assign({}, props);
  delete divProps.setShare;
  delete divProps.setPostsData;
  const [btnLock, setBtnLock] = useState(false);

  const [description, setDescription] = useState();
  const [selectedOption, setSelectedOption] = useState('');
  const [timeline, setTimeline] = useState(false);

  const share = async () => {
    setBtnLock(true);
    let payload = {
      share_post_id: props.item.id,
      description: description,
      timeline: `shared a post from <b>${props?.item?.user?.user_name}</b>'s profile`,
      attachments: props.item.attachments,
    };

    const response = await sharePost(payload);
    if (response.status === 201) {

      toast.success("Post shared");
      props.setShare(false);
      setBtnLock(false);

      let sharePost = response.data.data;
      props.setPostsData((prevState) => {
        return prevState.map((post) => {
          if (post.id === props.item.id) {
            return {
              ...post,
              total_shares: post.total_shares + 1,
            };
          } else {
            return post;
          }
        }).concat([sharePost]);
      });
    } else {
      toast.error(response.data.message);
      props.setShare(false);
      setBtnLock(false);
    }
  };
  
  const handleOptionChange = (event) => {
      setSelectedOption(event.target.value);
      if (event.target.value === 'timeline') {
        setTimeline(true);
      }
      if (event.target.value === 'facebook') {

          const url = `${window.location.origin}/posts/${props.item.id}`;
          shareToFacebook(url);
          props.setShare(false);
          setBtnLock(false);
      }
      if (event.target.value === 'copy') {

        const url = `${window.location.origin}/posts/${props.item.id}`;
        copyToClipboard(url);
      }
  };

  // Share to facebook
  function shareToFacebook(url) {
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
    window.open(facebookShareUrl, 'newwindow', 'width=600,height=400');
    return false;
  }
  // Copy to clipboard
  const copyToClipboard = async (text) => {
    try {
        await navigator.clipboard.writeText(text);
        toast.success("Link copied to clipboard successfully");
        props.setShare(false);
        setBtnLock(false);
    } catch (err) {
        console.error('Failed to copy: ', err);
    }
  };

  return (
    <>
      <Modal
        {...divProps}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="share--section--modal"
      >
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ fontSize: "20px", fontWeight: "500" }}
          >
            <i
              className="fa fa-share-square-o "
              aria-hidden="true"
              style={{
                color: "black",
                paddingRight: "8px",
                width: "20px",
                height: "20px",
              }}
            ></i>
            Share to my timelines
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p
            className="m-0 pt-2"
            style={{ color: "#242424", fontSize: "14px" }}
          >
            Choose where you want to share
          </p>
          <div className="d-flex">
            <div className="form-check form-check-inline">
                <input 
                    className="form-check-input" 
                    type="radio" 
                    name="exampleRadios" 
                    id="exampleRadios1" 
                    value="copy" 
                    checked={selectedOption === "copy"} 
                    onChange={handleOptionChange} 
                />
                <label className="form-check-label" htmlFor="copy">
                    Copy to clipboard
                </label>
            </div>
            <div className="form-check form-check-inline">
                <input 
                    className="form-check-input" 
                    type="radio" 
                    name="exampleRadios" 
                    id="facebook" 
                    value="facebook" 
                    checked={selectedOption === "facebook"} 
                    onChange={handleOptionChange} 
                />
                <label className="form-check-label" htmlFor="facebook">
                    Facebook
                </label>
            </div>
            <div className="form-check form-check-inline">
                <input 
                    className="form-check-input" 
                    type="radio" 
                    name="exampleRadios" 
                    id="timeline" 
                    value="timeline" 
                    checked={selectedOption === "timeline"} 
                    onChange={handleOptionChange} 
                />
                <label className="form-check-label" htmlFor="timeline">
                    Share on Timeline
                </label>
            </div>
          </div>
          {timeline && (
            <>
              <div className=" px-4 share_modal">
                <Slider className="share-modal-slider" arrows={true} dots={true}>
                  {props.item?.attachments?.map((i,index) => {
                    return (
                      <div className="d-flex align-items-center h-100" key={index}>
                        <img
                          style={{
                            width: "100%",
                            height: "auto",
                            maxHeight: "250px",
                            objectFit: "contain",
                          }}
                          src={process.env.REACT_APP_apiBaseURL + "/" + i.path}
                          alt=""
                        />
                      </div>
                    );
                  })}
                </Slider>
              </div>

              <div className="w-100" style={{ position: "relative" }}>
                <input
                  className="w-100 px-3 input--share"
                  type="text"
                  placeholder="Write Something"
                  onChange={(e) => setDescription(e.target.value)}
                />
                <i
                  className="fa fa-paper-plane-o"
                  aria-hidden="true"
                  style={{
                    position: "absolute",
                    top: "25%",
                    right: "3%",
                  }}
                ></i>
              </div>

              <div className="w-100">
                <button
                  className="w-100 modal--share--btn"
                  disabled={btnLock}
                  onClick={() => share()}
                >
                  {" "}
                  Share Now
                </button>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ShareModal;
