import React, { useState } from 'react';
import FabricCanvas from './ThreeCanvas';
import ThreeDCubePreview from './ThreeGpl';
import { Canvas } from "@react-three/fiber";
import { Experience } from "./Experience";

const Customizer = () => {
  const [text, setText] = useState('Custom Text');
  const [color, setColor] = useState('black');
  const [design, setDesign] = useState('');

  return (
    <div className="container">
      <div className='row'>
        <div className='col-sm-8'>
          <input type="text" value={text} onChange={(e) => setText(e.target.value)} />
          <input type="color" value={color} onChange={(e) => setColor(e.target.value)} />
          <FabricCanvas text={text} color={color} onDesignUpdate={setDesign} />
        </div>
        <div className='col-sm-4'>
        <Canvas shadows camera={{ position: [1, 2, 8],fov: 30,zoom: 0.5 }}>
          <color attach="background" args={["#ececec"]} />
          <Experience design={design} />
        </Canvas>
        </div>
      </div>
    </div>
  );
};

export default Customizer;
