import React, {useEffect, useRef, useState} from "react";
import {toast} from "react-toastify";
import {convertToFormData} from "../services/customizerService";

import {submitPost} from "../services/NewsFeedsService";
import emoji1 from "../assets/images/Emoji Icon (1).svg";
import gallary from "../assets/images/newsfeed/Group 929.svg";
import tag1 from "../assets/images/newsfeed/Group 930.svg";
import arrow from "../assets/images/newsfeed/Group 931.svg";
import TagModal from "./modal/TagModal";
import EmojiModal from "./modal/EmojiModal";
import {getFollowers} from "../services/FollowersService.js";
import imageCompression from "browser-image-compression";
import {useNavigate} from "react-router";

function PostTextArea({setPostsData}) {
    const navigate = useNavigate();
    ///uplaod Files Array From input file
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [fileLoading, setFileLoading] = useState(false);
    const fileRef = useRef()

    ///Selected Tag users From multiSelect
    const [selectedValues, setSelectedValues] = useState([]);

    const [followersData, setFollowers] = useState();
    const [followersPaginateLinks, setFollowersPaginateLinks] = useState();

    ///Btn disable and enable
    const [btnLock, setBtnLock] = useState(false);
    /// tag Modal True/False
    const [tag, setTag] = useState(false);

    ///Emoji Modal true / false
    const [emoji, setEmoji] = useState(false);

    ////////////////////////
    const onSelect = (option, prefix) => {
        console.log("Select:", prefix, "-", option.value);
    };

    const onFocus = () => {
        console.log("onFocus");
    };

    const onBlur = () => {
        console.log("onBlur");
    };
    ///////////////////////////////////

    useEffect(() => {
        // getUsersData();
        getFollowersData();
    }, []);

    //-------------------------On @ special character users populated-------------------------------//
    const [users, setUsers] = useState([]);
    const [text, setText] = useState("");
    const [atPosition, setAtPosition] = useState(null);

    const inputRef = useRef(null);

    const handleTextChange = (event) => {
        setText(event.target.value);
        setAtPosition(getLastIndexOfAtSymbol(event.target.value));
    };
    const handleUserSelect = (username) => {
        setText(
            text.slice(0, atPosition) +
            `@${username} ` +
            text.slice(inputRef.current.selectionEnd)
        );

        setAtPosition(null);
        inputRef.current.focus();
    };

    const filteredUsers = users.filter((user) =>
        user.user_name
            .toLowerCase()
            .includes(text.slice(atPosition + 1)?.toLowerCase() ?? "")
    );

    const getLastIndexOfAtSymbol = (value) => {
        const lastIndex = value.lastIndexOf("@");
        return lastIndex === -1 ? null : lastIndex;
    };

    //-------------------------End-------------------------------//

    /// setting the multiple images data
    async function handleFileInput(event) {
        setFileLoading(true);
        const inputFiles = event.target.files;
        const modifiedFiles = [];

        // Check file size, if large then compress it
        for (let i = 0; i < inputFiles.length; i++) {
            if (inputFiles[i].type.split("/")[0] === "image") {
                const maxAllowedSizeInMB = 3;
                if (inputFiles[i].size > maxAllowedSizeInMB * 1024 * 1024) {
                    const options = {
                        maxSizeMB: maxAllowedSizeInMB,
                        useWebWorker: true,
                    };
                    const compressedFile = await imageCompression(inputFiles[i], options);
                    modifiedFiles.push(compressedFile);
                } else {
                    modifiedFiles.push(inputFiles[i]);
                }
            } else if (inputFiles[i].type.split("/")[0] === "video") {
                const maxAllowedSizeInMB = 20;
                if (inputFiles[i].size > maxAllowedSizeInMB * 1024 * 1024) {

                    toast('Video is too large to upload. Please upload a video less than 20MB.')

                    // todo video compression
                    // const compressedFile =
                    // modifiedFiles.push(compressedFile);
                } else {
                    modifiedFiles.push(inputFiles[i]);
                }
            }
        }
        setSelectedFiles(modifiedFiles);
        setFileLoading(false);
    }

    ////Remove Images
    function removeImage(index) {
        setSelectedFiles((prevSelectedFiles) =>
            Array.from(prevSelectedFiles).filter((file, i) => i !== index)
        );
    }

    ///Get Users Api
    // const getUsersData = async () => {
    //   const response = await searchApi("", "Users");
    //   if (response.status === 200) {
    //     setUsers(response.data.data.users);
    //     console.log(response.data.data);
    //   } else {
    //     // toast(response.data.message)
    //     console.log(response);
    //   }
    // };

    const setEmojiDropdown = () => {
        if (emoji) {
            setEmoji(false);
        } else {
            setEmoji(true);
        }
    };

    //// submiting the post data
    const submitData = async () => {
        console.log("files", selectedFiles);
        setBtnLock(true);
        let files = [];
        //// converting to arrays
        Array.from(selectedFiles).map((item) => {
            files.push(item);
        });

        console.log(files);


        // /// finalData
        // let finalData = {
        //     attachments: files,
        //     description: text,
        //     tagged_usernames: selectedValues,
        // };

        // console.log(finalData);

        // //// converting to formData
        // const formData = convertToFormData(finalData);


        const formData = new FormData();

        const selectedUserIds = selectedValues.map((v) => v.value)
        console.log(selectedUserIds)

        formData.append('description', text);
        if(files.length>0){
            formData.append('attachments[]', files);
        }
        if(selectedUserIds.length>0){
            formData.append('tagged_usernames', JSON.stringify(selectedUserIds));
        }

        ////sending data into formData
        const response = await submitPost(formData);

        if (response.status === 201) {
            toast.success(response.data.message);
            console.log(response.data.data);
            let newPost = response.data.data;
            setPostsData((state) => [newPost, ...state]);

            ///clearing the input
            const textarea = document.getElementById("description_text");
            textarea.value = "";

            ///clearing the states
            setText("");
            setSelectedFiles([]);
            setSelectedValues([]);
            /// btn disable false
            setBtnLock(false);
        } else {
            setBtnLock(false);
            toast.error(response.data.message);
        }
    };

    let followersIndex = 1;

    ////getting Followers data
    const getFollowersData = async () => {
        ///Followers API
        const response = await getFollowers(followersIndex);
       
        if (response.status === 200) {
            setFollowers(response.data.data);
            setFollowersPaginateLinks(response.data.links.next);
        } else {
            toast.error(response.data.message);
        }
    };

    return (
        <>
            <div className="card mt-3 ">
                <div className="card-body p-0" style={{position: "relative"}}>
          <textarea
              placeholder="Share something"
              rows="4"
              id="description_text"
              style={{
                  backgroundColor: "rgba(233, 233, 233, 1)",
                  borderRadius: "10px 10px 0px 0px",
                  boxShadow: "none",
                  outline: "none",
                  resize: "none",
                  height: "120px",
                  whiteSpace: "pre-wrap",
                  color: 'black'
              }}
              ref={inputRef}
              value={text}
              onChange={handleTextChange}
          />
                </div>

                <div
                    className="card-footer newsfeed-footer "
                    style={{
                        backgroundColor: "rgba(233, 233, 233, 1) !important",
                        borderRadius: "0px 0px 10px 10px",
                    }}
                >
                    <div className="gap-2 d-flex flex-wrap">
                        {
                            fileLoading ? (
                                <div className="spinner-border my-2" role="status" style={{width: '2rem', height: '2rem'}}>
                                    <span className="sr-only">Loading...</span>
                                </div>
                            ) : selectedFiles &&
                            Array.from(selectedFiles).map((file, index) => (
                                <div className="input__images" key={index}>
                                    <img
                                        key={index}
                                        src={URL.createObjectURL(file)}
                                        alt={file.name}
                                        style={{
                                            height: "150px",
                                            borderRadius: "10px",
                                            objectFit: "contain",
                                        }}
                                    />
                                    <i
                                        className="fa fa-times-circle multi_images_cross"
                                        aria-hidden="true"
                                        onClick={() => removeImage(index)}
                                    ></i>
                                </div>
                            ))}
                    </div>
                    <div className="row">
                        <div className="col-md-12 d-flex justify-content-between">
                            {/* Md-3 if icons are more then three */}
                            <div className="col-md-1 col-3 d-flex align-items-center ms-1">
                                {/*<label htmlFor="upload" className="w-100">*/}
                                {/*    <img*/}
                                {/*        src={emoji1}*/}
                                {/*        style={{width: "21px", height: "21px"}}*/}
                                {/*        onClick={setEmojiDropdown}*/}
                                {/*        alt=""*/}
                                {/*    />*/}
                                {/*</label>*/}

                                {/*<label htmlFor="image" className="w-100">*/}
                                {/*    <img*/}
                                {/*        src={gallary}*/}
                                {/*        style={{width: "21px", height: "21px"}}*/}
                                {/*        alt=""*/}
                                {/*        title={"Go to Creator"}*/}
                                {/*        onClick={() => {*/}
                                {/*        //    fileRef.current.click()*/}
                                {/*        } }*/}
                                {/*    />*/}
                                {/*    <input*/}
                                {/*        type="file"*/}
                                {/*        multiple*/}
                                {/*        onChange={handleFileInput}*/}
                                {/*        style={{*/}
                                {/*           position: "absolute",*/}
                                {/*            opacity: "0",*/}
                                {/*           visibility: "hidden",*/}
                                {/*            cursor: "pointer",*/}
                                {/*       }}*/}
                                {/*        ref = {fileRef}*/}
                                {/*        accept=".jpg,.jpeg,.png,.webp"*/}
                                {/*        name="image"*/}
                                {/*        id="image"*/}
                                {/*        size="5000000"*/}
                                {/*    />*/}
                                {/*</label>*/}

                                {/* tag Modal */}
                                <label htmlFor="tag" className="w-100">
                                    <img
                                        src={tag1}
                                        style={{width: "21px", height: "21px"}}
                                        onClick={() => setTag(true)}
                                        alt=""
                                    />
                                </label>
                            </div>
                            <button
                                style={{
                                    cursor: "pointer",
                                    border: "none",
                                    background: "none",
                                    padding: "0",
                                }}
                                className="col-md-1"
                                onClick={() => submitData()}
                                disabled={btnLock}
                            >
                                <img
                                    src={arrow}
                                    style={{
                                        width: "21px",
                                        height: "21px",
                                        cursor: "pointer",
                                    }}
                                    alt=""
                                />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <TagModal
                show={tag}
                onHide={() => setTag(false)}
                followersData={followersData}
                followersPaginateLinks={followersPaginateLinks}
                setSelectedValues={setSelectedValues}
                selectedValues={selectedValues}
            />

            {/*{emoji ? <EmojiModal setText={setText} text={text}/> : ""}*/}
        </>
    );
}

export default PostTextArea;
