import {CSSFunction, PlaceHolderCategory, PlaceHolderProducts} from "../components/PlaceHolder";
import {constants} from "../components/Constants/constants";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import {getCategories, getUsersProducts} from "../services/ShopService";
import {AddCart} from "../services/CartService";
import {toast} from "react-toastify";

const TopProducts = (props) => {
    const [products, setProducts] = useState();
    const [productLoader, setProductLoader] = useState(false);
    const [categories, setCategories] = useState();
    const [categoryLoader, setCategoryLoader] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [priceValue, setPriceValue] = useState(10);
    const [searchText, setSearchText] = useState("");
    const [paginateLinks, setPaginateLinks] = useState(null);
    const [pageIndex, setPageIndex] = useState(1);
    const [pageCount, setPageCount] = useState();

    useEffect(() => {
        getCategoriesData();
        getUsersProductsData(pageIndex, constants.official, "", priceValue, searchText);
    }, []);

    useEffect(() => {
        (async () => {
            await getUsersProductsData(
                pageIndex,
                selectedCategory,
                priceValue,
                searchText,
            )
        })();
    }, [searchText]);

    useEffect(() => {
        setPageIndex(1);
    }, [priceValue, searchText, selectedCategory]);

    const getUsersProductsData = async (
        pageIndex,
        category,
        price,
        search,
        paginate
    ) => {
        setProductLoader(true);
        setTimeout(async () => {
            const response = await getUsersProducts(
                pageIndex,
                "official",
                category,
                Number(price),
                search
            );
            if (response?.status === 200) {
                if (paginate === "paginate") {
                    setProducts([...products, ...response.data.data]);
                } else {
                    setProducts(response.data.data);
                }

                setPaginateLinks(response.data.links?.next);

                setPageCount(response.data.meta);
                if (response.data.links?.next) {
                    setPageIndex(pageIndex + 1);
                }
                setProductLoader(false);
            } else {
                setProductLoader(false);
                toast.error(response.data.message);
            }
        }, 200);
    };

    const getCategoriesData = async () => {
        setCategoryLoader(true);
        const response = await getCategories();
        if (response?.status === 200) {
            setCategories(response.data.data);
            setCategoryLoader(false);
        }
    };

    const addToCart = async (id, cartStatus) => {
        if (cartStatus) {
            setProducts((prev) => {
                return prev.map((product) => {
                    if (product.id === id) {
                        return {
                            ...product,
                            in_cart: false,
                        };
                    }
                    return product;
                });
            });

            props.setCartCount((prevCount) => {
                const newCount = parseInt(prevCount, 10) - 1;
                localStorage.setItem("cartCount", newCount);
                return newCount;
            });
        } else {
            setProducts((prev) => {
                return prev.map((product) => {
                    if (product.id === id) {
                        return {
                            ...product,
                            in_cart: true,
                        };
                    }
                    return product;
                });
            });
            props.setCartCount((prevCount) => {
                const newCount = parseInt(prevCount, 10) + 1;
                localStorage.setItem("cartCount", newCount);
                return newCount;
            });
        }

        const finalData = {
            product_id: id,
        };
        const response = await AddCart(finalData);
        if (response?.status === 200) {
            if (cartStatus) {
                toast.success("Product removed");
            } else {
                toast.success("Product added");
            }
        } else {
            toast.error(response.data.message);
        }
    };

    return (
        <>
            <CSSFunction loader={productLoader}/>
            <div className="container mb-4 py-4 shop--containers--color">
                <div className="row my-4">
                    <div className="col-md-12 d-flex justify-content-center">
                        <h1
                            style={{
                                textTransform: "uppercase",
                                fontWeight: "700",
                                textAlign: "center",
                            }}
                            className="Shop-heading"
                        >
                            TOP PRODUCTS OF THE DAY!
                        </h1>
                    </div>
                </div>
                <div className="row d-flex justify-content-between my-4">
                    <div className="col-md-9">
                        <p>
                            Showing Results {pageCount?.from} - {pageCount?.to} of{" "}
                            {pageCount?.total} Results
                        </p>
                    </div>
                    <div
                        className="col-md-3 d-flex justify-content-end"
                        style={{
                            position: "relative",

                            paddingRight: "20px",
                        }}
                    >
                        <input
                            type="text"
                            placeholder="Search your products"
                            value={searchText}
                            className="search-bar shop-search"
                            style={{
                                borderRadius: "0px",
                                border: "none",
                                borderBottom: "1px solid #242424",
                                width: "290px",
                                paddingLeft: "2%",
                            }}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                        <img
                            className="shop-search"
                            src="/assets/images/search.png"
                            style={{
                                position: "absolute",
                                bottom: "12px",
                                paddingRight: "12px",
                            }}
                            alt=""
                        />
                    </div>
                </div>

                <div className="row my-4">
                    <div
                        // Style Gap
                        className="col-md-9 d-flex justify-content-center mb-5 flex-wrap px-0 "
                        style={{gap: "20px"}}
                    >
                        {productLoader ? (
                            <>
                                <PlaceHolderProducts/>
                            </>
                        ) : products?.length === 0 ? (
                            <h4 style={{fontWeight: "700"}}>No product found</h4>
                        ) : (
                            products?.map((item, i) => {
                                return (
                                    <div key={i}
                                         className="card  col-md-3 col-sm-12 col-12"
                                         style={{width: "260px", rowGap: "10px"}}
                                    >
                                        <div
                                            className="col-md-12 d-flex justify-content-between align-items-center p-3">
                                            <h4 className="shop--heading">{item.title}</h4>
                                            <p>${item.price}</p>
                                        </div>
                                        <Link to={`/product-detail/${item.id}`}>
                                            <div className="col-md-12 d-flex justify-content-center">
                                                <img
                                                    src={
                                                        item.image
                                                            ? process.env.REACT_APP_apiBaseURL +
                                                            "/" +
                                                            item.image
                                                            : ""
                                                    }
                                                    alt=""
                                                    style={{
                                                        width: "190px",
                                                        height: "190px",
                                                        objectFit: "contain",
                                                    }}
                                                />
                                            </div>
                                        </Link>
                                        <div
                                            className="col-md-12 d-flex justify-content-between align-items-center p-3">
                                            <Link
                                                to={
                                                    item?.user?.id ===
                                                    JSON.parse(localStorage.getItem("user")).id
                                                        ? `/profile`
                                                        : `/profile/${item.user?.id}`
                                                }
                                                className="custom-anchor d-flex align-items-center"
                                                style={{fontSize: "14px ", columnGap: "5px"}}
                                            >
                                                {" "}
                                                <img
                                                    src={
                                                        item?.user?.photo
                                                            ? process.env.REACT_APP_apiBaseURL +
                                                            "/" +
                                                            item.user?.photo
                                                            : "/assets/images/avatar1.png"
                                                    }
                                                    style={{width: "20px", height: "20px"}}
                                                    alt=""
                                                />
                                                {item.user?.name}
                                            </Link>
                                            <div
                                                className="custom-anchor d-flex align-items-center"
                                                style={{
                                                    fontSize: "14px ",
                                                    columnGap: "5px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => addToCart(item.id, item.in_cart)}
                                            >
                                                <i className="fa fa-cart-plus" aria-hidden="true"></i>
                                                {item.in_cart ? "Remove from cart" : "Add to cart"}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        )}
                        {paginateLinks !== null ? (
                            <div className="col-md-12 d-flex justify-content-center pt-4">
                                <button
                                    className="get-started"
                                    onClick={() =>
                                        getUsersProductsData(
                                            pageIndex,
                                            selectedCategory,
                                            priceValue,
                                            searchText,
                                            "paginate"
                                        )
                                    }
                                >
                                    View more
                                </button>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                    <div
                        className="col-md-3 filter--shop"
                        style={{display: "flex", justifyContent: "center"}}
                    >
                        <div className="card" style={{width: "296px", height: "250px"}}>
                            <div className="card-header p-3">
                                <h4>Filter By Price ($)</h4>
                            </div>

                            <div className=" mb-0 px-3 pb-3 ">
                                <input
                                    className="w-100 "
                                    type="range"
                                    id="price"
                                    name="range"
                                    min="0"
                                    max="100"
                                    defaultValue="10"
                                    onChange={(e) => {
                                        let c = e.target.value;
                                        setPriceValue(c);
                                    }}
                                    onMouseUp={(e) =>
                                        getUsersProductsData(
                                            pageIndex,
                                            selectedCategory,
                                            e.target.value,
                                            searchText
                                        )
                                    }
                                    style={{accentColor: "black"}}
                                />
                                {"$" + priceValue}
                            </div>

                            <div className="card-footer">
                                <h4>Filter By Products</h4>

                                <div
                                    className="form-group d-flex flex-wrap"
                                    style={{columnGap: "10px", rowGap: "10px"}}
                                >
                                    {categoryLoader ? (
                                        <PlaceHolderCategory/>
                                    ) : categories?.length === 0 ? (
                                        <div className="text-center">
                                            <h4 style={{fontWeight: "700", fontSize: "10px"}}>
                                                No product found
                                            </h4>
                                        </div>
                                    ) : (
                                        categories?.map((item, i) => {
                                            return (
                                                <div className="d-flex gap-1 " key={i}>
                                                    <input
                                                        type="radio"
                                                        id={item?.id}
                                                        name="filter"
                                                        value={item?.id}
                                                        onChange={(e) => {
                                                            getUsersProductsData(
                                                                pageIndex,
                                                                e.target.value,
                                                                priceValue,
                                                                searchText
                                                            );
                                                            setSelectedCategory(e.target.value);
                                                        }}
                                                    />
                                                    <label htmlFor="cup">{item.title}</label>
                                                </div>
                                            );
                                        })
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TopProducts;
