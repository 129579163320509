import React, { useState } from "react";

function PostImagegallery({ item, setOpen, setCurrentImage, children }) {
  const [numImages, setNumImages] = useState(4);

  const imagesLength = item?.attachments?.length;

  const openLightbox = (index) => {
    setCurrentImage(index);
    setOpen(true);
  };

  return (
    <div>
      {imagesLength === 1 ? (
        <div className={'position-relative'} style={{width: 'fit-content'}}>
          {item?.attachments?.map((item, key) => {
            return (
                <div
                  className="d-flex"
                  style={{ cursor: "pointer"}}
                  key={key}
                >
                  <img
                    src={process.env.REACT_APP_apiBaseURL + "/" + item?.path}
                    alt=""
                    key={key}
                    onClick={() => setOpen(true)}
                    style={{  borderRadius: "10px", objectFit: 'contain' }}
                  />
                </div>
            );
          })}
          {children}
        </div>
      ) : imagesLength === 2 ? (
        <>
          <div
            className="w-100 d-flex lengthImg1div"
            style={{ cursor: "pointer" }}
          >
            {item?.attachments?.map((attachment, index) => {
              return (
                <>
                  <div className="w-50">
                    <img
                      src={
                        process.env.REACT_APP_apiBaseURL +
                        "/" +
                        attachment?.path
                      }
                      key={index}
                      alt={`image ${index}`}
                      onClick={() => openLightbox(index)}
                      className="length1image"
                    />
                  </div>
                </>
              );
            })}
          </div>
        </>
      ) : imagesLength === 3 ? (
        <>
          <div className="d-flex " style={{ gap: "10px", cursor: "pointer" }}>
            {item?.attachments?.map((item, key) => {
              return (
                <>
                  {key === 0 && (
                    <img
                      src={process.env.REACT_APP_apiBaseURL + "/" + item?.path}
                      alt=""
                      key={key}
                      onClick={() => setOpen(true)}
                      className="length3image1st"
                    />
                  )}
                </>
              );
            })}
            <div>
              {item?.attachments?.map((item, key) => {
                return (
                  <>
                    {key === 1 && (
                      <img
                        src={
                          process.env.REACT_APP_apiBaseURL + "/" + item?.path
                        }
                        alt=""
                        key={key}
                        onClick={() => setOpen(true)}
                        className="length3image2nd"
                      />
                    )}
                    {key === 2 && (
                      <img
                        src={
                          process.env.REACT_APP_apiBaseURL + "/" + item?.path
                        }
                        alt=""
                        key={key}
                        onClick={() => setOpen(true)}
                        className="length3image3rd"
                      />
                    )}
                  </>
                );
              })}
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className="d-flex gap-3"
            style={{ paddingBottom: "10px", cursor: "pointer" }}
          >
            {item?.attachments?.slice(0, numImages).map((item, key) => {
              return (
                <>
                  {key === 0 && (
                    <img
                      src={process.env.REACT_APP_apiBaseURL + "/" + item?.path}
                      alt=""
                      key={key}
                      onClick={() => setOpen(true)}
                      className="multi__images"
                    />
                  )}
                  {key === 1 && (
                    <img
                      src={process.env.REACT_APP_apiBaseURL + "/" + item?.path}
                      alt=""
                      key={key}
                      onClick={() => setOpen(true)}
                      className="multi__images"
                    />
                  )}
                </>
              );
            })}
          </div>
          <div className="d-flex gap-3">
            {item?.attachments?.slice(0, numImages).map((item, key) => {
              let finalNum = imagesLength - numImages;
              return (
                <>
                  {key === 2 && (
                    <img
                      src={process.env.REACT_APP_apiBaseURL + "/" + item?.path}
                      alt=""
                      key={key}
                      onClick={() => setOpen(true)}
                      className="multi__images"
                    />
                  )}
                  {key === 3 ? (
                    <>
                      {numImages === imagesLength && (
                        <img
                          src={
                            process.env.REACT_APP_apiBaseURL + "/" + item?.path
                          }
                          alt=""
                          key={key}
                          onClick={() => setOpen(true)}
                          className="multi__images"
                        />
                      )}
                      {numImages < imagesLength && (
                        <div className="overlay--img">
                          <div className="img--div">
                            <img
                              src={
                                process.env.REACT_APP_apiBaseURL +
                                "/" +
                                item?.path
                              }
                              alt=""
                              key={key}
                              onClick={() => setOpen(true)}
                              style={{
                                height: "200px",
                                width: "200px",
                                objectFit: "cover",
                                borderRadius: "10px",
                              }}
                            />
                          </div>

                          <div
                            className="para--dev"
                            onClick={() => setOpen(true)}
                          >
                            <p
                              className="para--tag"
                              style={{ cursor: "pointer" }}
                            >
                              {`+${finalNum}`}{" "}
                            </p>
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}

export default PostImagegallery;
