import React from 'react'
import { Link } from 'react-router-dom'
import creation from '../../assets/images/Group 52.svg'
import custom from '../../assets/images/Group 24.svg'
import love from '../../assets/images/Vector.svg'

const AboutStickFigure = ({data}) => {
    return (
        <>
        {
            data?.data?.map((i, index) =>{
                if(i.type === 'about' ){
                    return(
                        <div className='container about--container my-5' key={index}>
                <div className='d-flex justify-content-center'>
                    <h1 className='text-text-capitalize'> {i.heading}</h1>
                </div>

                <div className='d-flex justify-content-center mt-2 '>
                    <p className='w-75 text-center'>{i.description}</p>
                </div>

                <div className='d-flex justify-content-center align-items-center'>
                    <Link className='btn my-2'>Know More</Link>
                </div>

                <div className='d-flex justify-content-between align-items-center my-4'>
                    <Link className='btn1 d-flex align-items-center justify-content-center' style={{width: '30%', textAlign: 'center'}} to="/shop/user"><img src={creation}  className="about--img--home"/>Customized Product</Link>
                    <Link className='btn2 d-flex align-items-center justify-content-center' style={{width: '30%', textAlign: 'center'}} to="/customizer"><img src={custom}  className="about--img--home"/>Creations</Link>
                    <Link className='btn2 d-flex align-items-center justify-content-center' style={{width: '30%', textAlign: 'center'}} to="/newsfeed"><img src={love} className="about--img--home"/>Social Media</Link>

                </div>
            </div>
                    )
                }
            })
        }
          
        </>
    )
}

export default AboutStickFigure