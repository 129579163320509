import React, {useEffect, useState} from "react";
// import api from "../../services/api";
import {toast} from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import {signup, getCountries} from "../../services/AuthService";

export default function Register() {
    const [showPasswordFields, setPasswordField] = useState(false);
    const [showVerificationLayout, setShowVerificationLayout] = useState(false);
    const [countries, setCountries] = useState([]);
    const [showLoading, setLoading] = useState(false);
    let [count, setCount] = useState(0);
    const [showPassword, setShowPassword] = useState(false);

    const [user, setUser] = useState({
        first_name: "",
        last_name: "",
        user_name: "",
        email: "",
        country_id: "",
        phone: "",
        password: "",
        password_confirmation: "",
    });

    const [validation, setValidation] = useState({
        first_name: "",
        last_name: "",
        user_name: "",
        email: "",
        country_id: "",
        phone: "",
        password: null,
        password_confirmation: null,
    });

    // load countries list
    useEffect(() => {
        const loadCountryList = async () => {
            await getCountries().then((response) => {
                setCountries(response.data.data);
            });
        };
        loadCountryList();
    }, []);

    // check validation
    // useEffect(() => {

    //   console.log(validation);
    // }, [user, validation]);

    // user's data mutation
    const setUserFirstName = (e) =>
        setUser({...user, first_name: e.target.value});
    const setUserLastName = (e) =>
        setUser({...user, last_name: e.target.value});
    const setUserName = (e) => setUser({...user, user_name: e.target.value});
    const setUserEmail = (e) => setUser({...user, email: e.target.value});
    const setUserCountryId = (e) =>
        setUser({...user, country_id: e.target.value});
    const setUserPhoneNo = (e) => setUser({...user, phone: e.target.value});
    const setUserPassword = (e) => setUser({...user, password: e.target.value});
    const setUserPasswordConfirmation = (e) =>
        setUser({...user, password_confirmation: e.target.value});

    const check = () => {
        const emailCond =
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/;
        const usernameRegex = /^[A-Za-z][A-Za-z0-9_.]{5,25}$/;
        if (user.user_name === "") {
            setValidation((validation) => ({
                ...validation,
                user_name: "username is required",
            }));
            return;
        }
        if (user.user_name.length < 6) {
            setValidation((validation) => ({
                ...validation,
                user_name: "username must be atleast 6 letters",
            }));
            return;
        }
        if (!usernameRegex.test(user.user_name)) {
            setValidation((validation) => ({
                ...validation,
                user_name: "Special Character are not allowed",
            }));
            return;
        } else {
            setValidation((validation) => ({
                ...validation,
                user_name: "",
            }));
        }
        if (user.first_name === "") {
            setValidation((validation) => ({
                ...validation,
                first_name: "first name is required",
            }));
            return;
        } else {
            setValidation((validation) => ({
                ...validation,
                first_name: "",
            }));
        }
        if (user.last_name === "") {
            setValidation((validation) => ({
                ...validation,
                last_name: "last name is required",
            }));
            return;
        } else {
            setValidation((validation) => ({
                ...validation,
                last_name: "",
            }));
        }

        if (user.email === "") {
            setValidation((validation) => ({
                ...validation,
                email: "email is required",
            }));
            return;
        }
        if (!emailCond.test(user.email)) {
            setValidation((validation) => ({
                ...validation,
                email: "Please enter a valid email address",
            }));
            return;
        } else {
            setValidation((validation) => ({
                ...validation,
                email: "",
            }));
        }
        if (user.country_id === "") {
            validation.country_id = "";
            setValidation((validation) => ({
                ...validation,
                country_id: "country code is required",
            }));
            return;
        } else {
            setValidation((validation) => ({
                ...validation,
                country_id: "",
            }));
        }
        if (showPasswordFields && user.password === "") {
            setValidation((validation) => ({
                ...validation,
                password: "password is required",
            }));
            return true;
        }
        if (showPasswordFields && user.password.length < 6) {
            setValidation((validation) => ({
                ...validation,
                password: "password must be atleast 6 letters",
            }));
            return true;
        }
        if (showPasswordFields && user.password_confirmation !== user.password) {
            setValidation((validation) => ({
                ...validation,
                password_confirmation: "password does not matched",
            }));
            return true;
        }

        if (user.phone === "") {
            setValidation((validation) => ({
                ...validation,
                phone: "phone number is required",
            }));
            return;
        } else if(user.phone.length < 10){
            setValidation((validation) => ({
                ...validation,
                phone: "phone must be contains 10 numbers",
            }));
        } else {
            setValidation((validation) => ({
                ...validation,
                phone: "",
            }));
        }
        if (count !== 0) {
            setPasswordField(false);
            return;
        } else {
            setPasswordField(true);
        }
    };

    const handleRegister = async () => {
        // validate user info fields
        if (check()) {
            return;
        }
        const termsCheckbox = document.getElementById("termsCheckbox");
        if (!termsCheckbox.checked) {
         toast.warn("You must agree to the Terms and Conditions to log in.");
          return;
        }
        setLoading(true);
        //  let registerBtn = document.getElementById("register");
        // let isValidated = Object.values(validation).every((v) => v !== "");
        // console.log(isValidated);
        // if(!isValidated) {
        //     return;
        // }
        const finalUser = {
            user_name: user.user_name,
            email: user.email,
            country_id: "",
            name: user.first_name + " " + user.last_name,
            first_name: user.first_name,
            last_name: user.last_name,
            phone: parseInt(user.phone),
            password: user.password,
            password_confirmation: user.password_confirmation,
        };
        const response = await signup(finalUser);
        setLoading(false);

        if (response.status === 201) {
            toast.success(response.data.message);
            setShowVerificationLayout(true);
        } else {
            if (response.status === 422) {
                setPasswordField(!showPasswordFields);
                toast.info(response.data.message);
            } else {
                toast.error(response.data.message);
            }
        }
    };
    const togglePassword = () => setShowPassword(!showPassword);

    return (
        <>
            <div className="container">
                <div className="row m-3 no-gutters">
                    <div
                        className="col-md-6 d-md-block banner-container"
                        style={{
                            backgroundImage: "url('/assets/images/Images/register.png')",
                        }}
                    ></div>
                    {showVerificationLayout ? (
                        <div
                            className="col-md-5  d-flex flex-column align-items-center justify-content-center bg-white p-3"
                            style={{height: "70vh"}}
                        >
                            <h3 className="d-flex justify-content-center align-items-center">
                                Verification Code Sent
                            </h3>
                        </div>
                    ) : (
                        <div
                            className="col-md-4 my-4 d-flex flex-column justify-content-center bg-white p-3 py-5"
                            style={{borderRadius: "5px"}}
                        >
                            {!showPasswordFields && (
                                <h1
                                    style={{
                                        fontFamily: "Brittany Signature",
                                        alignSelf: "start",
                                    }}
                                >
                                    Lets Get Started
                                </h1>
                            )}
                            <h3 className="signin font-sizing-login-heading pb-2 justify-content-start">
                                {showPasswordFields ? "Choose Password" : "sign up to account"}
                            </h3>
                            <div className="form-style ">
                                {/* <form> */}
                                {showPasswordFields ? (
                                    <>
                                        <div
                                            className="form-group"
                                            style={{marginBottom: "0px", position: "relative"}}
                                        >
                                            <input
                                                type={showPassword ? "text" : "password"}
                                                placeholder="Enter your password"
                                                className="form-control passowr"
                                                id="exampleInputPassword"
                                                onChange={setUserPassword}
                                                value={user.password}

                                                // autoComplete={false}
                                            />
                                            <label htmlFor="exampleInputPassword">
                        <span className="text-danger">
                          {validation.password}
                        </span>
                                            </label>
                                            <img
                                                src="/assets/images/passwordIcon.svg"
                                                style={{
                                                    position: "absolute",
                                                    top: "16px",
                                                    right: "20px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={togglePassword}
                                                alt=""
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="password"
                                                placeholder="Confirm Password"
                                                className="form-control"
                                                id="exampleInputConfirmPassword"
                                                onChange={setUserPasswordConfirmation}
                                                autoComplete={false}
                                            />
                                            <label htmlFor="exampleInputConfirmPassword">
                        <span className="text-danger">
                          {validation.password_confirmation}
                        </span>
                                            </label>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                placeholder="Choose Username"
                                                className="form-control feild-width"
                                                id="exampleInputEmail1"
                                                aria-describedby="emailHelp"
                                                onChange={setUserName}
                                                style={{height: "50px", borderRadius: "10px"}}
                                            />
                                            <label>
                        <span className="text-danger">
                          {validation.user_name}
                        </span>
                                            </label>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6" style={{}}>
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        placeholder="First Name"
                                                        className="form-control feild-width1"
                                                        id="exampleInputFirst1"
                                                        aria-describedby="emailHelp"
                                                        onChange={setUserFirstName}
                                                        style={{height: "50px", borderRadius: "10px"}}
                                                    />
                                                    <label>
                            <span className="text-danger">
                              {validation.first_name}
                            </span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-6" style={{}}>
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        placeholder="Last Name"
                                                        className="form-control feild-width"
                                                        id="exampleInputSecond1"
                                                        aria-describedby="emailHelp"
                                                        onKeyUp={setUserLastName}
                                                        style={{height: "50px", borderRadius: "10px"}}
                                                    />
                                                    <label>
                            <span className="text-danger">
                              {validation.last_name}
                            </span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="email"
                                                placeholder="Email"
                                                className="form-control feild-width"
                                                id="exampleInputEmail1"
                                                aria-describedby="emailHelp"
                                                onChange={setUserEmail}
                                                style={{height: "50px", borderRadius: "10px"}}
                                            />
                                            <label>
                                                <span className="text-danger">{validation.email}</span>
                                            </label>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6" style={{}}>
                                                <div className="form-group">
                                                    <select
                                                        className="form-control feild-width1"
                                                        style={{
                                                            backgroundColor: "#E9E9E9",
                                                            height: "calc(2.25rem + 15px)",
                                                            borderRadius: "10px",
                                                        }}
                                                        onChange={setUserCountryId}
                                                    >
                                                        <option selected disabled value="">
                                                            Country Code
                                                        </option>
                                                        {countries.map((country, index) => {
                                                            return (
                                                                <option value={country.id}>
                                                                  {country.phonecode} {country.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                    <label>
                            <span className="text-danger">
                              {validation.country_id}
                            </span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-6" style={{}}>
                                                <div className="form-group feild-width">
                                                    <input
                                                        type="text"
                                                        placeholder="Mobile No"
                                                        className="form-control"
                                                        id="exampleInputSecond1"
                                                        aria-describedby="emailHelp"
                                                        onChange={setUserPhoneNo}
                                                        style={{height: "50px", borderRadius: "10px"}}
                                                    />
                                                    <label>
                            <span className="text-danger">
                              {validation.phone}
                            </span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex align-items-center font-sizing-login" style={{ marginTop: '-1rem' }}>
                <input
                  id="termsCheckbox"
                  name="terms"
                  type="checkbox"
                  value=""
                  style={{ accentColor: "black", }}
                />
                <div className="font-sizing-login" style={{paddingLeft:"2px"}}>
                  I agree to the{" "}
                  <a href="https://signinapp.com/legal/terms/terms-and-conditions" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>
                </div>
              </div>
                                    </>
                                )}

                                <div className="pb-2">
                                    {showPasswordFields ? (
                                        <button
                                            type="submit"
                                            className="get-started loginBtn mt-3 d-flex align-items-center justify-content-center "
                                            id="register"
                                            onClick={handleRegister}
                                            style={{border: "1px solid #A3A3A3"}}
                                            disabled={showLoading}
                                        >
                                            {showLoading && <Spinner animation="border me-2"/>}
                                            Continue
                                        </button>
                                    ) : (
                                        <button
                                            type="submit"
                                            className="get-started loginBtn  d-flex mt-3 align-items-center justify-content-center "
                                            id="register"
                                            onClick={check}
                                            style={{border: "1px solid #A3A3A3"}}
                                            disabled={showLoading}
                                        >
                                            {showLoading && <Spinner animation="border me-2"/>}
                                            Next
                                        </button>
                                    )}
                                </div>

                                {/* </form> */}
                                <div className="sideline">Or you can use</div>
                                <div className="row justify-content-center pt-2">
                                    <div  className="col-md-6 d-flex justify-content-center"
                                          style={{gap: "20px"}}>
                                        <button
                                            type='button'
                                            className="social"
                                            style={{
                                                backgroundColor: 'white',
                                                background: "url('/assets/images/social/google.svg')"
                                            }}
                                        ></button>
                                        <button
                                            type='button'
                                            className="social"
                                            style={{
                                                backgroundColor: 'white',
                                                backgroundImage: "url('/assets/images/social/fb.svg')",
                                            }}
                                        ></button>
                                        {/*<button*/}
                                        {/*    type='button'*/}
                                        {/*    className="social"*/}
                                        {/*    style={{*/}
                                        {/*      backgroundImage: "url('/assets/images/instagram.png')",*/}
                                        {/*    }}*/}
                                        {/*></button>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>


        </>
    );
}
