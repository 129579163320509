// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {isSupported,getMessaging, getToken, onMessage} from "firebase/messaging";
import {Savefcmtoken} from "../../services/NotificationService";
import {getFirestore} from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
    apiKey: "AIzaSyBO61bKUpUndAVNHfmS8YZ8SpCzbDOmXGQ",
    authDomain: "stick-8426e.firebaseapp.com",
    projectId: "stick-8426e",
    storageBucket: "stick-8426e.appspot.com",
    messagingSenderId: "5839394732",
    appId: "1:5839394732:web:c4717dee269b09f1610c09"
  };
// Initialize Firebase
const app = initializeApp(firebaseConfig);
let messaging = null;

// Check Firebse Push Notification service & register service worker if support

isSupported()
  .then((supported) => {
    if (supported) {
      // Messaging is supported
        messaging = getMessaging(app);

        navigator.serviceWorker.register('/firebase-messaging-sw.js')
        .then((registration) => {
            // Successfully registered service worker
            // console.log('Service Worker registered with scope:', registration.scope);
        })
        .catch((error) => {
        
            // console.error('Service Worker registration failed:', error);
        });
    } else {
      
      console.warn("Firebase Messaging is not supported in this environment.");
    }
  })
  .catch((error) => {
    // console.error("Error checking Firebase Messaging support:", error);
  });
  
export const db = getFirestore(app);

export const getNewToken = (setTokenFound) => {
    const user = JSON.parse(localStorage.getItem("user"));

    return getToken(messaging, {
        vapidKey: "BCuKMN0TNJZQ0uc5CfjHedcaSoH_XwIOsaAe3FMxnNXnN5_QascajuQtgjFIAAo4H9XyWw0ruZmpw4vBgT5IJP8",
    })
        .then(async (currentToken) => {
            if (currentToken && user) {

                const prevToken = localStorage.getItem("FCM_TOKEN");
                if (currentToken !== prevToken) {
                    const payload = {
                        token: currentToken,
                    };
                    // Send the token to your server and update the UI if necessary
                    const res = await Savefcmtoken(payload)
                    if (res.status === 200) {
                        localStorage.setItem("FCM_TOKEN", currentToken);

                    } else {
                        console.log(res);
                    }

                }
                // console.log("current token for client: ", currentToken);

                // Track the token -> client mapping, by sending to backend server
                // show on the UI that permission is secured
            } else {
                // console.log("No registration token available. Request permission to generate one.");

                // shows on the UI that permission is required
            }
        })
        .catch((err) => {
            console.log("An error occurred while retrieving token. ", err);
            // catch error while creating client token
        });
};

export const onMessageListener = () => new Promise((resolve) => {
    onMessage(messaging, (payload) => {
        setTimeout(() => {
            console.log("payload", payload);
            resolve(payload);
        }, 100);
    });
});
