import api from "./api";

export const AddBankAccount = async (payload) => {
    try {
        const response = await api.post("/api/v1/accounts", payload);
        return response;
    } catch (error) {
        return error.response;
    }
};

export const GetBankAccounts = async () => {
    try {
        const response = await api.get("/api/v1/accounts");
        return response;
    } catch (error) {
        return error.response;
    }
}

export const EditBankAccount = async (payload, id) => {
    try {
        const response = await api.put(`/api/v1/accounts/${id}`, payload);
        return response;
    } catch (error) {
        return error.response;
    }
}

export const DeleteBankAccount = async (id) => {
    try {
        const response = await api.delete(`/api/v1/accounts/${id}`);
        return response;
    } catch (error) {
        return error.response;
    }
}

export const SetPrimaryBankAccount = async (id) => {
    try {
        const response = await api.get(`/api/v1/accounts/${id}/primary`);
        return response;
    } catch (error) {
        return error.response;
    }
}

export const GetWithdrawals = async (id) => {
    try {
        const response = await api.get(`/api/v1/withdraws`);
        return response;
    } catch (error) {
        return error.response;
    }
}