import React from "react";

const VerificationPending = () => {
    return (
        <>
            <div>
                <h1 className="text-center mt-4 mb-5">VERIFY YOUR IDENTITY</h1>
                <div className="my-5 w-100 d-flex justify-content-center ">
                    <div
                        className="text-center "
                        style={{background: "#FFFFFF", borderRadius: "3px", width: "551px", height: "400px"}}
                    >
                        <div className="row justify-content-center">
                            <div className="my-5">
                                <img
                                    src="/assets/images/successIcon.svg"
                                    style={{maxWidth: "100px", maxHeight: "100px"}}/>
                            </div>
                            <div className="col-md-8">
                                <h2>We’re on it!</h2>
                                <h4>
                                    Please check your email for updates on your verification
                                    status. This can take upto 48-72 hours.
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default VerificationPending;
