import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../services/AuthService';
import { setAuthorizationHeader } from '../services/api';

const withAdminCheck = (Component) => {
  return function WrappedComponent(props) {
    const [isLoginComplete, setIsLoginComplete] = useState(false); // Added this line
    const navigate = useNavigate();

    useEffect(() => {
      const token = localStorage.getItem('token');
      if (!token) {
        const payload = {
          email: 'admin@stickfigure.com',
          password: '11223344',
        };
        login(payload)
          .then(response => {
            if (response.status === 200) {
                
              localStorage.setItem('token', response.data.token);
              localStorage.setItem("user", JSON.stringify(response.data.user));
              window.dispatchEvent(new Event("storage"));
              setAuthorizationHeader(localStorage.getItem("token"));
              setIsLoginComplete(true); // Added this line

              window.location.reload();
            }
          })
          .catch(error => {
            console.error(error);
          });
      } else {
        setIsLoginComplete(true); // Added this line
        // Assuming you can decode the token to get the role
        // Decode token and check for role
        // if (decodedToken.role !== 'admin') {
        //   navigate('/non-admin-route');
        // }
      }
    }, []);

    if (!isLoginComplete) {
      return <div>Loading...</div>; // Added this line
    }

    return <Component {...props} />;
  };
};

export default withAdminCheck;
