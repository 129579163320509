import Modal from "react-bootstrap/Modal";

const ErrorMsg = (props) => {
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="delelte--modal"
      >
        <div className="container mb-4">
          <Modal.Header className="justify-content-center border-0 pb-0 mt-3">
            <Modal.Title id="contained-modal-title-vcenter">
            <img src="/assets/images/Cross.svg" style={{ width: '80px' }} />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            className="text-center"
            style={{ fontWeight: '700', fontSize: '20px', color: 'black', overflowWrap: 'break-word' }}
          >
            <div>
              {props.message}
            </div>
          </Modal.Body>          
        </div>
      </Modal>
    </>
  );
};

export default ErrorMsg;
