import { useNavigate } from "react-router";

function NotFound()
{
    const navigate = useNavigate();
    return (
        <div className="container d-flex flex-column justify-content-center align-items-center py-4">
            <h1 style={{fontSize: '48px'}}>Error 404!</h1>
            <p style={{fontSize: '24px', fontWeight: '500'}}>We can’t seem to find the page you’re looking for</p>
            <img src="/assets/images/404.svg" alt="404"/>
            <h3 className="pt-3">Go Back To</h3>
            <button className="btn btn-primary home-profile-btn" style={{ backgroundColor: "#242424", border: "none", borderRadius: '30px', width: '305px', height: '51px' }}
                onClick={(e) => navigate('/')}>Home</button>
        </div>
    );
}

export default NotFound