import Button from 'react-bootstrap/Button';
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { getCountries } from "../../services/AuthService";
import { AddBankAccount } from '../../services/AccountService';
import { useNavigate } from 'react-router';
import ErrorMsg from '../modal/ErrorMsg';
import { toast } from 'react-toastify';

function AddPaymentMethod(props) {

    const [isValid, setIsValid] = useState(true);
    const [isLoader, setLoader] = useState(false);
    const [countries, setCountries] = useState([]);
    const [errorModal, setRespErrorModal] = useState(false);
    const [errorRes, setRespError] = useState('');
    const [accountInfo, setAccountInfo] = useState({
        account_type: 'individual',
        account_name: 'Test User',
        routing_no: '110000000',
        account_no: '000123456789',
        confirm_account_no: '000123456789',
        phone_no: '1234567890',
        country_code: '+1'
    });
    const [error, setValidationError] = useState({
        account_type: '',
        account_name: '',
        routing_no: '',
        account_no: '',
        confirm_account_no: '',
        phone_no: '',
        country_code: ''
    });

    const navigate = useNavigate();

    // load countries list
    useEffect(() => {
        const loadCountryList = async () => {
            await getCountries().then((response) => {
                setCountries(response.data.data);
            });
        };
        loadCountryList();
    }, []);

    const validate = () => {
        if (accountInfo.account_name === "") {
            setValidationError(error => ({
                ...error,
                account_name: 'Account name is required'
            }));
            return false;
        } else {
            setValidationError(error => ({
                ...error,
                account_name: ""
            }));
        }
        if (accountInfo.routing_no === "") {
            setValidationError(error => ({
                ...error,
                routing_no: 'Routing number is required'
            }));
            return false;
        } else {
            setValidationError(error => ({
                ...error,
                routing_no: ""
            }));
        }
        if (accountInfo.account_no === "") {
            setValidationError(error => ({
                ...error,
                account_no: 'Account number is required'
            }));
            return false;
        } else {
            setValidationError(error => ({
                ...error,
                account_no: ""
            }));
        }
        if (accountInfo.confirm_account_no === "") {
            setValidationError(error => ({
                ...error,
                confirm_account_no: 'Confirm account number is required'
            }));
            return false;
        } else {
            setValidationError(error => ({
                ...error,
                confirm_account_no: ""
            }));
        }
        if (accountInfo.account_no !== accountInfo.confirm_account_no) {
            setValidationError(error => ({
                ...error,
                account_no: 'Account number does not match with confirm account'
            }));
            return false;
        }
        if (accountInfo.country_code === "") {
            setValidationError(error => ({
                ...error,
                country_code: 'Phone code is required'
            }));
            return false;
        } else {
            setValidationError(error => ({
                ...error,
                country_code: ""
            }));
        }
        if (accountInfo.phone_no === "") {
            setValidationError(error => ({
                ...error,
                phone_no: 'Phone number is required'
            }));
            return false;
        } else {
            setValidationError(error => ({
                ...error,
                phone_no: ""
            }));
        }
        return true;
    }

    const createAccount = async () => {
        if (validate()) {
            setIsValid(true)
            setLoader(true)
            const response = await AddBankAccount({
                account_type: "individual",
                account_name: accountInfo.account_name,
                routing_no: accountInfo.routing_no,
                account_no: accountInfo.account_no,
                account_confirm: accountInfo.account_no,
                phone_no: accountInfo.phone_no,
                country_code: accountInfo.country_code
            })
            if (response.status === 201) {
                navigate('/verify/docs');
            } else if (response.status === 500) {
                setRespError(response.data.message);
                setRespErrorModal(true);
            } else {
                toast.info(response.data.message)
            }
            setLoader(false)
        } else {
            setIsValid(false);
        }
    }

    return (
        <section
            className="collection-section py-4"
            style={{ backgroundColor: "#F9F9F9" }}
        >
            <div className='container'>
                <div className='d-flex justify-content-center align-items-center flex-column'>
                    <h1 className="mb-0">Add Payment Method</h1>
                    <Form.Text className="text-muted mt-0">
                        We'll never share your account detail with anyone else.
                    </Form.Text>
                </div>
                <div className='row py-2'>
                    <Form className='col-md-6 mt-2'>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Account Holder Name</Form.Label>
                            <Form.Control type="text" placeholder="Confirm account number" value={accountInfo.account_name} onChange={(e) => setAccountInfo(state => ({
                                ...state,
                                account_name: e.target.value
                            }))}  onBlur={validate}/>
                            <small className="text-danger">{error.account_name}</small>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Routing Number</Form.Label>
                            <Form.Control type="text" placeholder="Routing Number" value={accountInfo.routing_no} onChange={(e) => setAccountInfo(state => ({
                                ...state,
                                routing_no: e.target.value
                            }))} onBlur={validate}/>
                            <small className="text-danger">{error.routing_no}</small>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Account Number</Form.Label>
                            <Form.Control type="text" placeholder="Enter account number" value={accountInfo.account_no} onChange={(e) => setAccountInfo(state => ({
                                ...state,
                                account_no: e.target.value
                            }))} onBlur={validate}/>
                            <small className="text-danger">{error.account_no}</small>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Confirm Account Number</Form.Label>
                            <Form.Control type="text" placeholder="Confirm account number" value={accountInfo.confirm_account_no} onChange={(e) => setAccountInfo(state => ({
                                ...state,
                                confirm_account_no: e.target.value
                            }))} onBlur={validate}/>
                            <small className="text-danger">{error.confirm_account_no}</small>
                        </Form.Group>

                        <div className='d-flex'>
                            <Form.Group className="col-md-6 mb-3" controlId="formBasicPassword">
                                <Form.Label>Country</Form.Label>
                                <Form.Select value={accountInfo.country_code} onChange={(e) => setAccountInfo({
                                    ...accountInfo, country_code: e.target.value
                                })} style={{ height: '40px' }} className='form-control' onBlur={validate}>
                                    <option defaultValue disabled value="">
                                        Country Code
                                    </option>
                                    {countries.map((country, index) => {
                                        return (<option key={index} value={country.id}>
                                            {country.phonecode}
                                        </option>);
                                    })}
                                </Form.Select>
                                <small className="text-danger">{error.country_code}</small>
                            </Form.Group>
                            <Form.Group className="col-md-6 mb-3 ms-2" controlId="formBasicPassword">
                                <Form.Label>Phone</Form.Label>
                                <Form.Control type="text" placeholder="Enter phone number" value={accountInfo.phone_no} onChange={(e) => setAccountInfo(state => ({
                                    ...state,
                                    phone_no: e.target.value
                                }))} onBlur={validate}/>
                                <small className="text-danger">{error.phone_no}</small>
                            </Form.Group>
                        </div>

                        <div className='d-flex justify-content-center'>
                            <button
                                type="button"
                                onClick={createAccount}
                                className="get-started mt-2 d-flex align-items-center justify-content-center"
                                disabled={isLoader}
                                style={{
                                    width: "140px", height: "50px",
                                }}
                            >
                                {
                                    isLoader && (
                                        <div className="spinner-border me-2" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    )
                                }
                                Continue
                            </button>
                        </div>
                    </Form>
                    <div className="col-md-6 d-flex justify-content-end">
                        <div className="col-md-11 ">
                            <div
                                className="wallet-banner"
                                style={{
                                    backgroundImage: "url('/assets/images/payment_banner.svg')",
                                }}
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
            <ErrorMsg
                show={errorModal} onHide={() => setRespErrorModal(false)}
                message={errorRes}
            />
        </section>

    );
}

export default AddPaymentMethod