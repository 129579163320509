import React from "react";

function Nointernet() {
  return (
    <div>
      <img
        src="../assets/images/rag-doll-pulling-blue-wire.jpg"
        alt=""
        style={{ width: "100%", height: "100vh" }}
      />
    </div>
  );
}

export default Nointernet;
