import api from "./api";

export const getPostComments = async (postId, pageIndex) => {
  try {
    const response = await api.get(`/api/v1/${postId}/comments?page=${pageIndex}`);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const submitCommentData = async (payload) => {
  try {
    const response = await api.post(`/api/v1/comments`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
};
export const deleteComment = async (commentId) => {
  try {
    const response = await api.delete(`/api/v1/comments/${commentId}`);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const updateComment = async (commentId , commentData) => {
  try {
    const response = await api.put(`/api/v1/comments/${commentId}`, commentData);
    return response;
  } catch (error) {
    return error.response;
  }
};
