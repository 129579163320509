import React, { useEffect } from 'react';
import { fabric } from 'fabric';

const FabricCanvas = ({ text, color, onDesignUpdate }) => {
  useEffect(() => {
    const canvas = new fabric.Canvas('custom-canvas');
    const textObj = new fabric.IText(text, { left: 50, top: 50, fill: color, fontSize: 20 });
    canvas.add(textObj);

    canvas.on('object:modified', () => {  
      const elements = canvas.getObjects();
      elements.forEach((element, index) => {
        // Calculate the effective width range of the element
        const elementRightEdge = element.left + element.width * element.scaleX;
        let positionLabel;

        // Determine the position label based on element's horizontal position
        if (element.left >= 1 && elementRightEdge <= 249) {
            positionLabel = 'Right';
        } else if (element.left >= 250 && elementRightEdge <= 449) {
            positionLabel = 'Center';
        } else if (element.left >= 500 && elementRightEdge <= 749) {
            positionLabel = 'Left';
        }
      });
      const design = canvas.toDataURL();
      onDesignUpdate(design);
    });

    canvas.on('text:changed', () => {
      const design = canvas.toDataURL();
      onDesignUpdate(design);
    });

    return () => canvas.dispose();
  }, [text, color, onDesignUpdate]);

  return <canvas id="custom-canvas" width="750" height="400" />;
};

export default FabricCanvas;