import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";

import Select from "react-select";
import { toast } from "react-toastify";
import { getFollowers } from "../../services/FollowersService";

const TagModal = (props) => {



  /// state mangements
  const handleSelect = (selectedList, selectedItem) => {
    props.setSelectedValues(selectedList);
  };

  const formatOptionLabel = ({ label, image }) => {

    console.log(image)

    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src={image ? process.env.REACT_APP_apiBaseURL +
            "/" + image : "/assets/images/notification4.png"}
          alt={""}
          style={{ marginRight: "8px", width: "40px", height: "40px", objectFit: "cover" }}
        />
        <div>{label}</div>
      </div>
    )
  };



  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="tag--section--modal"
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ fontSize: "16px", fontWeight: "700" }}
        >
          Tag People
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Select
            options={props?.followersData?.map((option) => ({
              value: option.id,
              label: option.name,
              image: option.photo,
            }))}
            value={props.selectedValues}
            onChange={handleSelect}
            isMulti
            formatOptionLabel={formatOptionLabel}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default TagModal;
