


const UpComingEvent = () => {
    return (
        <section className="black-friday-section">
            <div className="d-flex flex-column justify-content-center" >
                <div className="row pt-4 w-100" style={{  width: "90%" , 
                marginLeft: "0 !important", marginRight: '0 !important',paddingRight:'0px !important' }}>
                    <div className="pb-5 col-md-12 text-center">
                        <h1 className="heading">Upcoming Events</h1>
                        <p style={{fontSize: '14px'}}>
                            Don’t miss out on our upcoming events and competitions! Stay updated with our events section.
                        </p>
                        <button className="get-started" style={{marginBottom: '20px'}}>Shop Now</button>
                        <div className="pt-5 black_friday">
                            <h1 className="black-friday">
                                Black Friday</h1>
                        </div>
                    </div>
                </div>
                <div className="row g-0">
                    {/* <img src="/assets/images/black_friday.png" className="w-100" alt="" /> */}
                </div>
            </div>
         
        </section>
    );
}

export default UpComingEvent