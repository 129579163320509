export const constants = {
  products: "products",
  user: "users",
  meme: 0,
  product: 1,
  all: "all",
  accept: "accept",
  decline: "decline",
  official: "official",
  typeUser: "user",
  post: "post",
  comment: "comment",
  love: 1,
  dislike: 2,
  profile: "profile",
  cover: "cover",
  followers: "followers",
  following: "following",
};


export const MAX_FILE_SIZE =  5 * 1024 * 1024;
