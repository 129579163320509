const DeliveryOption = () => {
  return (
    <>
      <div
        className="py-3"
        style={{ backgroundColor: "#F3F3F3 !important", borderRadius: "10px" }}
      >
        <div className="row">
          <div className="col-md-12 px-0">
            <div className="card p-2" style={{ border: "none !important" }}>
              <div className="card-title p-3 d-flex justify-content-between">
                <h4 style={{ fontWeight: "700", marginBottom: "0px" }}>
                  Delivery Option
                </h4>
                <a className="under--line" style={{TextDecoder: 'underline'}}> Edit</a>
              </div>
              <div className="card-body d-flex justify-content-center">
                <ul className="list-group list-group-flush delivery-option">
                  <li
                    className="list-group-item d-flex justify-content-between align-items-center"
                    style={{
                      border: "1px solid #A3A3A3 !important",
                      borderRadius: "10px",
                    }}
                  >

                    <div className="form-check">
                      <label className="form-check-label" htmlFor="flexRadioDefault1">
                        #534, Silver Street, New york City,
                        Lorem Ipsum, 112987
                      </label>
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />

                    </div>
                  </li>
                  <li
                    className="list-group-item d-flex justify-content-between align-items-center mt-2"
                    style={{
                      border: "1px solid #A3A3A3 !important",
                      borderRadius: "10px",
                    }}
                  >

                    <div className="form-check">
                    
                    
                      <label className="form-check-label" htmlFor="flexRadioDefault2">
                        #534, Silver Street, New york City,
                        Lorem Ipsum, 112987
                      </label>
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />

                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className='card p-2 bg--dark mt-4 '>
 

      <div  className='delivry--card' style={{padding: '1rem'}}>
      <div>
       
        <h4 style={{ fontWeight: "700", marginBottom: "0px" }}>
        Add Your Address
                </h4>
      </div>
      <input className="w-100 mt-2 px-3" type="text" placeholder='Country' id="contry" name="contry"/>
      <input className="w-100 mt-2 px-3" type="text" placeholder='State' id="state" name="state"/>
      <input className="w-100 mt-2 px-3" type="text" placeholder='City' id="city" name="city"/>
      <input className="w-100 mt-2 px-3" type="text" placeholder='Pincode' id="pincod" name="pincod"/>
      <textarea className='mt-2'> Address Line</textarea>
      <div className='d-flex justify-content-center gap-3 my-3'> 
      <button className='delivry--btn'>Save Address </button>
      <button className='delivry--btn1'>Cancel </button>
      </div>
      </div>



      </div>
          </div>
        </div>
      </div>

     
    </>
  );
};

export default DeliveryOption;
