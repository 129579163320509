import { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import BankModal from "../modal/BankModal";
import DeleteItem from "../modal/Deleteitem";
import { DeleteBankAccount, SetPrimaryBankAccount } from "../../services/AccountService";
import { toast } from "react-toastify";

const PaymentMethod = (props) => {
    const [modalShow, setModalShow] = useState(false);
    const [removeModalShow, setRemoveModalShow] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [bankAccounts, setBankAccounts] = useState([]);
    const [selectedBankAccount, setSelectedBankAccount] = useState(null);



    const insertBankAccount = (bankAccount, bankAccountId) => {
        if (bankAccounts.length === 0) {
            bankAccount.primary = true;
        }
        if (bankAccountId) {
            setBankAccounts(bankAccounts.map((account) => {
                if (account.id === bankAccountId) {
                    return bankAccount
                }
                return account
            }))
        } else {
            setBankAccounts([bankAccount, ...bankAccounts])
        }
    }

    const deleteBankAccount = async () => {
        const response = await DeleteBankAccount(selectedBankAccount.id);
        if (response.status === 200) {
            setBankAccounts(bankAccounts.filter((account) => account.id !== selectedBankAccount.id))
            toast.success("Bank account deleted")
        } else {
            toast.error(response.data.message)
        }
        setRemoveModalShow(false)
    }

    useEffect(() => {
        setBankAccounts(props.bankAccounts)
    }, [props.bankAccounts])

    const setPrimaryBankAccount = async (bankAccount) => {
        if (!bankAccount.primary) {
            const response = await SetPrimaryBankAccount(bankAccount.id);
            if (response.status === 200) {
                toast.success("The primary bank account has been successfully activated.")
                setBankAccounts(bankAccounts.map((account) => {
                    if (account.id === bankAccount.id) {
                        account.primary = true
                    } else {
                        account.primary = false
                    }
                    return account
                }))
            } else {
                toast.error(response.data.message)
            }
        }
    }

    return (<>
        <div style={{ minHeight: "50vh", }}>
            <div className="row">
                {bankAccounts.length > 0 && <div className="col-md-9 ">
                    {bankAccounts.map((bankAccount, index) => (<div key={index}
                        className="d-flex justify-content-between py-3 px-3 mb-2   "
                        style={{ border: "1px solid #A3A3A3" }}
                    >
                        <div className="col-md-8 d-flex  align-items-center ">
                            <h3 className="bank">Bank</h3>
                            <h3 className="bank1">Bank - {bankAccount.bank_name}</h3>
                        </div>
                        <div className="col-md-4 d-flex justify-content-end align-items-center">
                            <div className="d-flex flex-column">
                                <button onClick={() => {
                                    setSelectedBankAccount(bankAccount)
                                    setPrimaryBankAccount(bankAccount)
                                }} className="d-flex align-items-center gap-2 payment--btn bdr">
                                    {!!bankAccount.primary && <img src="/assets/images/prime.svg" />}
                                    Primary
                                </button>
                                <button onClick={() => {
                                    setSelectedBankAccount(bankAccount)
                                    setIsEdit(true)
                                    setModalShow(true)
                                }} className="d-flex align-items-center gap-2 payment--btn bdr">
                                    <img src="/assets/images/pen.svg" />
                                    Edit
                                </button>
                                <button
                                    className="d-flex align-items-center gap-2 payment--btn"
                                    onClick={() => {
                                        setSelectedBankAccount(bankAccount)
                                        setRemoveModalShow(true)
                                    }}
                                >
                                    <img src="/assets/images/remove.svg" />
                                    Remove
                                </button>
                            </div>
                        </div>
                    </div>))}
                </div>}

                <div
                    className="col-md-3 d-flex justify-content-evenly"
                    style={{ textAlign: "center" }}
                >
                    {bankAccounts.length > 0 && <div
                        style={{
                            backgroundColor: "#A3A3A3", width: "1px", minHeight: "50vh",

                        }}
                    >
                        {" "}
                    </div>}
                    <Dropdown className="get-started1">
                        <Dropdown.Toggle variant="get-started" id="dropdown-basic">
                            Add Transfer Method
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item
                                style={{ backgroundColor: "#F3F3F3" }}
                                onClick={() => {
                                    setIsEdit(false)
                                    setModalShow(true)
                                }}
                            >
                                Add Bank Account
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
        </div>
        <BankModal
            show={modalShow}
            isEdit={isEdit}
            bankAccount={selectedBankAccount}
            insertBankAccount={insertBankAccount}
            onHide={() => setModalShow(false)}
        />

        <DeleteItem show={removeModalShow} onHide={() => setRemoveModalShow(false)} deleteItem={deleteBankAccount} />
    </>);
};

export default PaymentMethod;
