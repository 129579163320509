import api from "./api";

export const getPosts = async (pageIndex) => {
  try {
    const response = await api.get(`/api/v1/newsfeed?page=${pageIndex}`);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const submitPost = async (payload) => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  try {
    const response = await api.post(`/api/v1/posts`, payload, {
      headers: headers,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};
export const postReact = async (payload) => {
  try {
    const response = await api.post(`/api/v1/react`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getProfilePosts = async (pageIndex, userId) => {
  try {
    const response = await api.get(
      `/api/v1/${userId}/posts/?page=${pageIndex}`
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getUserPaidPosts = async (userId) => {
  try {
    const response = await api.get(
        `/api/v1/${userId}/paid/posts`
    );
    return response;
  } catch (error) {
    return error.response;
  }
};

export const sharePost = async (payload) => {
  try {
    const response = await api.post(`/api/v1/post/share`, payload);
    return response;
  } catch (error) {
    return error.response;
  }
};
export const deletePostData = async (postId) => {
  try {
    const response = await api.delete(`/api/v1/posts/${postId}`);
    return response;
  } catch (error) {
    return error.response;
  }
};
export const updatePost = async (id, payload) => {

  payload._method = 'PUT';
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  try {
    const response = await api.post(`/api/v1/posts/${id}`, payload, {
      headers: headers,
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getPostDetail = async (postId) => {
    try {
        const response = await api.get(`/api/v1/posts/${postId}`);
        return response;
    } catch (error) {
        return error.response;
    }
}