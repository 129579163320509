import api from "./api";

export const Savefcmtoken = async (payload) => {
    try {
        const response = await api.post("/api/v1/fcm_token", payload);
        return response;
    } catch (error) {
        return error.response;
    }
};
export const getNotifications = async (pageIndex) => {
    try {
        const response = await api.get(`/api/v1/notifications?page=${pageIndex}`);
        return response;
    } catch (error) {
        return error.response;
    }
};
export const sendNotification = async (payLoad) => {
    try {
        const response = await api.post(`/api/v1/message/notify`, payLoad);
        return response;
    } catch (error) {
        return error.response;
    }
};
export const updateNotification = async (id, body) => {
    try {
        const response = await api.put(`/api/v1/notifications/${id}`, body);
        return response;
    } catch (error) {
        return error.response;
    }
}
export const deleteNotification = async (id) => {
    try {
        const response = await api.delete(`/api/v1/notifications/${id}`);
        return response;
    } catch (error) {
        return error.response;
    }
}