import React from "react";
import { GiCheckMark } from "react-icons/gi";
import { Link } from "react-router-dom";

const Verification = () => {
  return (
    <>
      <div className="container my-4">
        <div className="row">
            <div className="col-md-6  d-flex justify-content-center">
              <img src="/assets/images/Images/register.png" style={{height: '600px', objectFit:'contain'}} />
            </div>
            <div className="col-md-6 d-flex justify-content-center align-items-center ">
              <div className="d-flex align-items-center justify-content-center flex-column w-100 h-100 rounded" style={{backgroundColor: 'white'}} >
                <div className=" mb-3 d-flex justify-content-center align-items-center rounded" style={{backgroundColor: '#242424', width:'80px', height:'80px'}}>
                <GiCheckMark size={30} style={{color: 'white' }} />
                </div>
                <h1>Verification Successfull</h1>
                <Link to='/login' className="mt-3 text-decoration-none get-started d-flex justify-content-center align-items-center " style={{backgroundColor: '#242424', color: '#F3F3F3'}}> Login</Link>
              </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Verification;
