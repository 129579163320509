import api from "./api";

export const get_pushnotifications_settings = async () => {
    try {
        const response = await api.get(`/api/v1/settings`);
        return response;
    } catch (error) {
        return error.response;
    }
}

export const update_pushnotifications_settings = async (id, body) => {
    try {
        const response = await api.put(`/api/v1/settings/${id}`, body);
        return response;
    } catch (error) {
        return error.response;
    }
}

export const socialInformation = async () => {

    try{

        const response = await api.get(`/api/v1/contact`);
        return response;

    } catch (error){


    }
}