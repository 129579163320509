
import Slider from "react-slick";
import img1 from '../../assets/images/Rectangle 74.svg'

const Testimonial = () => {

    const settings = {
        arrow: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    dots: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false
                }
            }
        ]
    };

    return (
        <section className="collection-section py-5 ">
            <div className="container d-flex flex-column justify-content-center">
                <div className="row d-flex justify-content-center">
                    <div className="pb-5 col-md-12 d-flex flex-column justify-content-center text-center">
                        <h2 className="heading">Look At What Our Customers Say!</h2>

                    </div>
                    <div className="row justify-content-center testimonial-slider-section">
                        <Slider {...settings} arrows={false} >
                            <div className="slide-wrap">
                                <div className="product-card" style={{ position: "relative" }}>

                                    <div className="col-md-12 d-flex justify-content-center">
                                        <p className="pt-4 px-5 m-0">Stick Figures is truly a remarkable platform to make your own content and share it with people. I just recently came across this amazing site, and I loved their collection of Stick Figures. 
                                        I have already designed several memes through their tools and it’s very easy to use! They definitely deserve an appreciation for coming up with this unique idea!" </p>
                                    </div>
                                    <div className="col-md-12 d-flex  align-items-center pt-2 px-5 gap-3">
                                     <img src={img1} style={{width:'60px' , height: '60px', objectFit: 'contain'}} />
                                     
                                    <div className="flex-column">
                                     <p className="m-0" style={{fontSize: '14px', fontWeight: '700'}}>James Blunt</p>
                                   
                                     <div className="d-flex gap-3">
                                        <p className="m-0" style={{fontSize: '14px', color: '#242424'}}>682 Followers</p>
                                        <p className="m-0" style={{fontSize: '14px', color: '#242424'}}>67 Following</p>
                                     </div>

                                     </div>
                                     
                                     
                                    </div>
                                </div>
                            </div>

                             <div className="slide-wrap">
                                <div className="product-card" style={{ position: "relative" }}>

                                    <div className="col-md-12 d-flex justify-content-center">
                                        <p className="pt-4 px-5 m-0">Stick Figures is truly a remarkable platform to make your own content and share it with people. I just recently came across this amazing site, and I loved their collection of Stick Figures. 
                                        I have already designed several memes through their tools and it’s very easy to use! They definitely deserve an appreciation for coming up with this unique idea!" </p>
                                    </div>
                                    <div className="col-md-12 d-flex  align-items-center pt-2 px-5 gap-3">
                                     <img src={img1} style={{width:'60px' , height: '60px', objectFit: 'contain'}} />
                                     
                                    <div className="flex-column">
                                     <p className="m-0" style={{fontSize: '14px', fontWeight: '700'}}>James Blunt</p>
                                   
                                     <div className="d-flex gap-3">
                                        <p className="m-0" style={{fontSize: '14px', color: '#242424'}}>682 Followers</p>
                                        <p className="m-0" style={{fontSize: '14px', color: '#242424'}}>67 Following</p>
                                     </div>

                                     </div>
                                     
                                     
                                    </div>
                                </div>
                            </div>

                           <div className="slide-wrap">
                                <div className="product-card" style={{ position: "relative" }}>

                                    <div className="col-md-12 d-flex justify-content-center">
                                        <p className="pt-4 px-5 m-0">Stick Figures is truly a remarkable platform to make your own content and share it with people. I just recently came across this amazing site, and I loved their collection of Stick Figures. 
                                        I have already designed several memes through their tools and it’s very easy to use! They definitely deserve an appreciation for coming up with this unique idea!" </p>
                                    </div>
                                    <div className="col-md-12 d-flex  align-items-center pt-2 px-5 gap-3">
                                     <img src={img1} style={{width:'60px' , height: '60px', objectFit: 'contain'}} />
                                     
                                    <div className="flex-column">
                                     <p className="m-0" style={{fontSize: '14px', fontWeight: '700'}}>James Blunt</p>
                                   
                                     <div className="d-flex gap-3">
                                        <p className="m-0" style={{fontSize: '14px', color: '#242424'}}>682 Followers</p>
                                        <p className="m-0" style={{fontSize: '14px', color: '#242424'}}>67 Following</p>
                                     </div>

                                     </div>
                                     
                                     
                                    </div>
                                </div>
                            </div>


                             <div className="slide-wrap">
                                <div className="product-card" style={{ position: "relative" }}>

                                    <div className="col-md-12 d-flex justify-content-center">
                                        <p className="pt-4 px-5 m-0">Stick Figures is truly a remarkable platform to make your own content and share it with people. I just recently came across this amazing site, and I loved their collection of Stick Figures. 
                                        I have already designed several memes through their tools and it’s very easy to use! They definitely deserve an appreciation for coming up with this unique idea!" </p>
                                    </div>
                                    <div className="col-md-12 d-flex  align-items-center pt-2 pb-3 px-5 gap-3">
                                     <img src={img1} style={{width:'60px' , height: '60px', objectFit: 'contain'}} />
                                     
                                    <div className="flex-column">
                                     <p className="m-0" style={{fontSize: '14px', fontWeight: '700'}}>James Blunt</p>
                                   
                                     <div className="d-flex gap-3">
                                        <p className="m-0" style={{fontSize: '14px', color: '#242424'}}>682 Followers</p>
                                        <p className="m-0" style={{fontSize: '14px', color: '#242424'}}>67 Following</p>
                                     </div>

                                     </div>
                                     
                                     
                                    </div>
                                </div>
                            </div>


                           <div className="slide-wrap">
                                <div className="product-card" style={{ position: "relative" }}>

                                    <div className="col-md-12 d-flex justify-content-center">
                                        <p className="pt-4 px-5 m-0">Stick Figures is truly a remarkable platform to make your own content and share it with people. I just recently came across this amazing site, and I loved their collection of Stick Figures. 
                                        I have already designed several memes through their tools and it’s very easy to use! They definitely deserve an appreciation for coming up with this unique idea!" </p>
                                    </div>
                                    <div className="col-md-12 d-flex  align-items-center pt-2 pb-3 px-5 gap-3">
                                     <img src={img1} style={{width:'60px' , height: '60px', objectFit: 'contain'}} />
                                     
                                    <div className="flex-column">
                                     <p className="m-0" style={{fontSize: '14px', fontWeight: '700'}}>James Blunt</p>
                                   
                                     <div className="d-flex gap-3">
                                        <p className="m-0" style={{fontSize: '14px', color: '#242424'}}>682 Followers</p>
                                        <p className="m-0" style={{fontSize: '14px', color: '#242424'}}>67 Following</p>
                                     </div>

                                     </div>
                                     
                                     
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>

            </div>
        </section >
    );
}

export default Testimonial