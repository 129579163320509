import api from "./api";

export const searchApi = async (search, type, pageIndex) => {
  var productType = 1;
  if (type === "creation") {
    type = "products";
    productType = 0;
  }
  try {
    const response = await api.get(
      `/api/v1/search?q=${search}&type=${type}&products=${productType}&page=${pageIndex}`
    );
    return response;
  } catch (error) {
    return error.response;
  }
};
