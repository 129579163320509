import Accordion from 'react-bootstrap/Accordion';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import DeleteAccount from '../components/modal/DeleteAccount';
import { useEffect, useState } from 'react';
import { get_pushnotifications_settings, update_pushnotifications_settings } from "../services/SettingService";
import { toast } from "react-toastify";
import { change_password, updateProfile, updateDobVisibility } from "../services/ProfileService";
import Spinner from "react-bootstrap/Spinner";


function Setting() {
    const [modalShow, setModalShow] = useState(false);
    const [pushNotificationUpdating, setPushNotificationUpdating] = useState(false);
    const [accountInfoUpdating, setAccountInfoUpdating] = useState(false);
    const [passwordUpdating, setPasswordUpdating] = useState(false);
    const [isBtnLock, setIsBtnLock] = useState(false);
    const [dobVisibility, setDobVisibility] = useState(JSON.parse(localStorage.getItem("user"))?.dob_visibility || 2);
    const [accountInfo, setAccountInfo] = useState({
        name: '',
        user_name: '',
        bio: '',
        email: '',
        phone: ''
    });
    const [password, setPassword] = useState({
        old_password: '',
        password: '',
        password_confirmation: ''
    });
    const [pushNotification, setPushNotification] = useState({
        likes: 2,
        product_comments: 2,
        meme_comments: 2,
        comment_likes: 1,
        new_follower: 1,
        follower_posts: 0,
        messages: 0
    });

    const [fieldErrors, setFieldErrors] = useState({
        name: '',
        user_name: '',
        bio: '',
        email: '',
        phone: '',
        old_password: '',
        password: '',
        password_confirmation: ''
    })

    const setAccountInfoData = (e) => {
        setAccountInfo({
            ...accountInfo,
            [e.target.name]: e.target.value
        })
    }

    const setPasswordData = (e) => {
        setPassword({
            ...password,
            [e.target.name]: e.target.value
        })
    }

    const setPushNotificationData = async (e) => {
        let data = {
            ...pushNotification,
            [e.target.name]: Number(e.target.value)
        }
        setPushNotification(data)
        await updatePushNotificationSettings(data);
    }

    useEffect(() => {
        (async () => {
            const user = JSON.parse(localStorage.getItem('user'));
            setAccountInfo({
                name: user.name,
                user_name: user.user_name,
                bio: user.bio,
                email: user.email,
                phone: user.phone,
                dob_visibility: user.dob_visibility
            })

            const response = await get_pushnotifications_settings();
            if (response?.status === 200) {
                setPushNotification(response.data.data);
            } else {
                toast.error("Something went wrong!")
            }
        })();
    }, []);

    const updatePushNotificationSettings = async (data) => {
        setPushNotificationUpdating(true)
        const response = await update_pushnotifications_settings(data.id, data);
        if (response?.status === 200) {
        } else {
            toast.error(response.data.message)
        }
        setPushNotificationUpdating(false)
    }

    const validateAccountInfo = () => {
        let error = {
            ...fieldErrors,
            name: '',
            user_name: '',
            bio: '',
            email: '',
            phone: ''
        }
        if (accountInfo.name === '') {
            error.name = 'Name is required'
        }
        if (accountInfo.user_name === '') {
            error.user_name = 'Username is required'
        }
        if (accountInfo.email === '') {
            error.email = 'Email is required'
        } else if (!/\S+@\S+\.\S+/.test(accountInfo.email)) {
            error.email = 'Email address is invalid'
        }
        if (accountInfo.phone === '') {
            error.phone = 'Phone is required'
        }
        setFieldErrors(error)
        return !(error.name !== '' || error.user_name !== '' || error.bio !== '' || error.email !== '' || error.phone !== '');

    }

    const updateAccountInfo = async () => {
        if (!validateAccountInfo()) {
            return;
        }
        setIsBtnLock(true)
        setAccountInfoUpdating(true)
        const response = await updateProfile(accountInfo);
        if (response?.status === 200) {
            toast.success("Profile updated!")
            localStorage.setItem('user', JSON.stringify(response.data.data));
        } else {
            toast.error(response.data.message)
        }
        setAccountInfoUpdating(false)
        setIsBtnLock(false)
    }

    const validatePassword = () => {
        let error = {
            ...fieldErrors,
            old_password: '',
            password: '',
            password_confirmation: ''
        }
        if (password.old_password === '') {
            error.old_password = 'Old password is required'
        }
        if (password.password === '') {
            error.password = 'New password is required'
        }
        if (password.password_confirmation === '') {
            error.password_confirmation = 'Confirm password is required'
        } else if (password.password !== password.password_confirmation) {
            error.password_confirmation = 'Confirm password does not match'
        }
        setFieldErrors(error)
        return !(error.old_password !== '' || error.password !== '' || error.password_confirmation !== '');
    }

    const updatePassword = async () => {
        if (!validatePassword()) {
            return;
        }
        setIsBtnLock(true)
        setPasswordUpdating(true)
        const response = await change_password(password);
        if (response?.status === 200) {
            toast.success("Password updated!")
        } else {
            toast.error(response.data.message)
        }
        setPasswordUpdating(false)
        setIsBtnLock(false)
    }

    const changeUserDobVisibility = async () => {
        setPushNotificationUpdating(true);
        const response = await updateDobVisibility({ dob_visibility: dobVisibility });
        if (response?.status !== 200) {
            toast.warn(response.data.message);
        } else {
            let user = JSON.parse(localStorage.getItem("user"))
            user.dob_visibility = response.data.data?.dob_visibility;
            localStorage.setItem("user",JSON.stringify(user));
        }
        setPushNotificationUpdating(false);
    }
    useEffect(() => {
        changeUserDobVisibility();
    }, [dobVisibility])

    return (
        <>
            <section className="collection-section py-5" style={{ backgroundColor: '#F9F9F9' }}>
                <div className="container d-flex flex-column justify-content-center">
                    <div className="row d-flex justify-content-center">
                        <div className="pb-4 col-md-12 d-flex flex-column justify-content-center text-center">
                            <h2 className="heading"
                                style={{ fontFamily: 'RockoFLF Regular', fontSize: '34px' }}>Settings</h2>
                        </div>
                        <div className='col-md-9 p-3' style={{ background: "#F3F3F3" }}>
                            <Accordion defaultActiveKey="0" style={{ borderRadius: '10px' }}
                                className='setting--accordion'>
                                <Accordion.Item eventKey="0" className="mt-0">
                                    <Accordion.Header className='form-Header' style={{ fontSize: '28px !important' }}>Account
                                        Information</Accordion.Header>
                                    <Accordion.Body className="p-4" style={{ filter: pushNotificationUpdating ? "blur(2px)" : "blur(0px)" }}>
                                        <Form>
                                            <Form.Group as={Row} className="mb-3">
                                                <Form.Label column sm={2}>
                                                    Name
                                                </Form.Label>
                                                <Col sm={10}>
                                                    <Form.Control type="text" name={'name'} value={accountInfo.name}
                                                        onChange={setAccountInfoData} />
                                                    {
                                                        fieldErrors.name !== '' &&
                                                        <span className="text-danger">{fieldErrors.name}</span>
                                                    }
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3">
                                                <Form.Label column sm={2}>
                                                    Username
                                                </Form.Label>
                                                <Col sm={10}>
                                                    <Form.Control type="text" name={'user_name'}
                                                        value={accountInfo.user_name}
                                                        onChange={setAccountInfoData} />
                                                    {
                                                        fieldErrors.user_name !== '' &&
                                                        <span className="text-danger">{fieldErrors.user_name}</span>
                                                    }
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3">
                                                <Form.Label column sm={2}>
                                                    Biography
                                                </Form.Label>
                                                <Col sm={10}>
                                                    <Form.Control type="text" name={'bio'}
                                                        value={accountInfo.bio}
                                                        onChange={setAccountInfoData} />
                                                    {
                                                        fieldErrors.bio !== '' &&
                                                        <span className="text-danger">{fieldErrors.bio}</span>
                                                    }
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3">
                                                <Form.Label column sm={2}>
                                                    Email
                                                </Form.Label>
                                                <Col sm={10}>
                                                    <Form.Control type="email" readOnly name={'email'}
                                                        value={accountInfo.email} />
                                                    {
                                                        fieldErrors.email !== '' &&
                                                        <span className="text-danger">{fieldErrors.email}</span>
                                                    }
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3">
                                                <Form.Label column sm={2}>
                                                    Mobile Number
                                                </Form.Label>
                                                <Col sm={10}>
                                                    <Form.Control type="text" name={'phone'}
                                                        value={accountInfo.phone}
                                                        onChange={setAccountInfoData} />
                                                    {
                                                        fieldErrors.phone !== '' &&
                                                        <span className="text-danger">{fieldErrors.phone}</span>
                                                    }
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3">
                                                <Form.Label column sm={2}>
                                                    Date of birth
                                                </Form.Label>
                                                <Col sm={10}>
                                                    <Form.Check
                                                        type="radio"
                                                        label="Hide"
                                                        name="likes"
                                                        id="likes1"
                                                        value={0}
                                                        checked={dobVisibility === 0}
                                                        onChange={(e) => setDobVisibility(0)}
                                                    />
                                                    <Form.Check
                                                        type="radio"
                                                        label="People I follow"
                                                        name="likes"
                                                        id="likes2"
                                                        value={1}
                                                        checked={dobVisibility === 1}
                                                        onChange={(e) => setDobVisibility(1)}
                                                    />
                                                    <Form.Check
                                                        type="radio"
                                                        label="Show everyone"
                                                        name="likes"
                                                        id="likes3"
                                                        value={2}
                                                        checked={dobVisibility === 2}
                                                        onChange={(e) => setDobVisibility(2)}
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3">
                                                <Col sm={{ span: 12, offset: 0 }} className="text-center">
                                                    <button className="get-started" type="button"
                                                        style={{ width: '164px', height: '60px' }}
                                                        disabled={isBtnLock}
                                                        onClick={updateAccountInfo}>
                                                        {accountInfoUpdating && (<Spinner
                                                            animation="border me-2"
                                                            style={{ width: "15px", height: "15px" }}
                                                        />)}
                                                        {accountInfoUpdating ? 'Submitting...' : 'Submit'}
                                                    </button>
                                                </Col>
                                            </Form.Group>
                                        </Form>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Change Password</Accordion.Header>
                                    <Accordion.Body>
                                        <Form>
                                            <Form.Group as={Row} className="mb-3">
                                                <Form.Label column sm={3}>
                                                    Old Password
                                                </Form.Label>
                                                <Col sm={9}>
                                                    <Form.Control type="password" name={'old_password'}
                                                        value={password.old_password}
                                                        onChange={setPasswordData} />
                                                    {
                                                        fieldErrors.old_password !== '' &&
                                                        <span className="text-danger">{fieldErrors.old_password}</span>
                                                    }
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3">
                                                <Form.Label column sm={3}>
                                                    New Password
                                                </Form.Label>
                                                <Col sm={9}>
                                                    <Form.Control type="password" name={'password'}
                                                        value={password.password}
                                                        onChange={setPasswordData} />
                                                    {
                                                        fieldErrors.password !== '' &&
                                                        <span className="text-danger">{fieldErrors.password}</span>
                                                    }
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-3">
                                                <Form.Label column sm={3}>
                                                    Confirm Password
                                                </Form.Label>
                                                <Col sm={9}>
                                                    <Form.Control type="password" name={'password_confirmation'}
                                                        value={password.password_confirmation}
                                                        onChange={setPasswordData} />
                                                    {
                                                        fieldErrors.password_confirmation !== '' &&
                                                        <span className="text-danger">{fieldErrors.password_confirmation}</span>
                                                    }
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3">
                                                <Col sm={{ span: 12, offset: 0 }} className="text-center">
                                                    <div className='text-center'>
                                                        <button className="get-started" type="button"
                                                            style={{ width: '164px', height: '60px' }}
                                                            disabled={isBtnLock}
                                                            onClick={updatePassword}>
                                                            {passwordUpdating && (<Spinner
                                                                animation="border me-2"
                                                                style={{ width: "15px", height: "15px" }}
                                                            />)}
                                                            {passwordUpdating ? 'Submitting...' : 'Submit'}
                                                        </button>
                                                        <Link to="/forgot_password"
                                                            style={{ float: 'right', color: 'black' }}>
                                                            Forgort Password?
                                                        </Link>
                                                    </div>

                                                </Col>
                                            </Form.Group>
                                        </Form>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>Push Notification</Accordion.Header>
                                    <Accordion.Body
                                        style={{ filter: pushNotificationUpdating ? "blur(2px)" : "blur(0px)" }}>
                                        <Accordion>
                                            <div className="parent-accordion">
                                                <Accordion.Item className="mt-0" eventKey="3">
                                                    <Accordion.Header className='sub--setting'>Likes and
                                                        Comments</Accordion.Header>
                                                    <Accordion.Body>

                                                        <fieldset>
                                                            <Form.Group as={Row} className="mb-3 flex-column">
                                                                <Form.Label as="legend" column sm={3}>
                                                                    Likes
                                                                </Form.Label>
                                                                <Col sm={10}>
                                                                    <Form.Check
                                                                        type="radio"
                                                                        label="Off"
                                                                        name="likes"
                                                                        id="likes1"
                                                                        value={0}
                                                                        checked={pushNotification.likes === 0}
                                                                        onChange={setPushNotificationData}
                                                                    />
                                                                    <Form.Check
                                                                        type="radio"
                                                                        label="People I follow"
                                                                        name="likes"
                                                                        id="likes2"
                                                                        value={1}
                                                                        checked={pushNotification.likes === 1}
                                                                        onChange={setPushNotificationData}
                                                                    />
                                                                    <Form.Check
                                                                        type="radio"
                                                                        label="From Everyone"
                                                                        name="likes"
                                                                        id="likes3"
                                                                        value={2}
                                                                        checked={pushNotification.likes === 2}
                                                                        onChange={setPushNotificationData}
                                                                    />
                                                                </Col>
                                                            </Form.Group>
                                                        </fieldset>
                                                        <fieldset>
                                                            <Form.Group as={Row} className="mb-3 flex-column">
                                                                <Form.Label as="legend" column sm={3}>
                                                                    Comments on Products
                                                                </Form.Label>
                                                                <Col sm={10}>
                                                                    <Form.Check
                                                                        type="radio"
                                                                        label="Off"
                                                                        name="product_comments"
                                                                        id="comments1"
                                                                        value={0}
                                                                        checked={pushNotification.product_comments === 0}
                                                                        onChange={setPushNotificationData}
                                                                    />
                                                                    <Form.Check
                                                                        type="radio"
                                                                        label="People I follow"
                                                                        name="product_comments"
                                                                        id="comments2"
                                                                        value={1}
                                                                        checked={pushNotification.product_comments === 1}
                                                                        onChange={setPushNotificationData}
                                                                    />
                                                                    <Form.Check
                                                                        type="radio"
                                                                        label="From Everyone"
                                                                        name="product_comments"
                                                                        id="comments3"
                                                                        value={2}
                                                                        checked={pushNotification.product_comments === 2}
                                                                        onChange={setPushNotificationData}
                                                                    />
                                                                </Col>
                                                            </Form.Group>
                                                        </fieldset>
                                                        <fieldset>
                                                            <Form.Group as={Row} className="mb-3 flex-column">
                                                                <Form.Label as="legend" column sm={3}>
                                                                    Comments on Meme
                                                                </Form.Label>
                                                                <Col sm={10}>
                                                                    <Form.Check
                                                                        type="radio"
                                                                        label="Off"
                                                                        name="meme_comments"
                                                                        id="commentonmeme1"
                                                                        value={0}
                                                                        checked={pushNotification.meme_comments === 0}
                                                                        onChange={setPushNotificationData}
                                                                    />
                                                                    <Form.Check
                                                                        type="radio"
                                                                        label="People I follow"
                                                                        name="meme_comments"
                                                                        id="commentonmeme2"
                                                                        value={1}
                                                                        checked={pushNotification.meme_comments === 1}
                                                                        onChange={setPushNotificationData}
                                                                    />
                                                                    <Form.Check
                                                                        type="radio"
                                                                        label="From Everyone"
                                                                        name="meme_comments"
                                                                        id="commentonmeme3"
                                                                        value={2}
                                                                        checked={pushNotification.meme_comments === 2}
                                                                        onChange={setPushNotificationData}
                                                                    />
                                                                </Col>
                                                            </Form.Group>
                                                        </fieldset>
                                                        <fieldset>
                                                            <Form.Group as={Row} className="mb-3 flex-column">
                                                                <Form.Label as="legend" column sm={3}>
                                                                    Comment Like
                                                                </Form.Label>
                                                                <Col sm={10}>
                                                                    <Form.Check
                                                                        type="radio"
                                                                        label="On"
                                                                        name="comment_likes"
                                                                        id="commentlike1"
                                                                        value={1}
                                                                        checked={pushNotification.comment_likes === 1}
                                                                        onChange={setPushNotificationData}
                                                                    />
                                                                    <Form.Check
                                                                        type="radio"
                                                                        label="Off"
                                                                        name="comment_likes"
                                                                        id="commentlike2"
                                                                        value={0}
                                                                        checked={pushNotification.comment_likes === 0}
                                                                        onChange={setPushNotificationData}
                                                                    />
                                                                </Col>
                                                            </Form.Group>
                                                        </fieldset>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="5">
                                                    <Accordion.Header className='sub--setting'>Following and
                                                        Followers</Accordion.Header>
                                                    <Accordion.Body>
                                                        <Form>
                                                            <fieldset>
                                                                <Form.Group as={Row} className="mb-3 flex-column">
                                                                    <Form.Label as="legend" column sm={3}>
                                                                        New Follower
                                                                    </Form.Label>
                                                                    <Col sm={10}>
                                                                        <Form.Check
                                                                            type="radio"
                                                                            label="On"
                                                                            name="new_follower"
                                                                            id="newFollower1"
                                                                            value={1}
                                                                            checked={pushNotification.new_follower === 1}
                                                                            onChange={setPushNotificationData}
                                                                        />
                                                                        <Form.Check
                                                                            type="radio"
                                                                            label="Off"
                                                                            name="new_follower"
                                                                            id="newFollower2"
                                                                            value={0}
                                                                            checked={pushNotification.new_follower === 0}
                                                                            onChange={setPushNotificationData}
                                                                        />
                                                                    </Col>
                                                                </Form.Group>
                                                            </fieldset>

                                                            <fieldset>
                                                                <Form.Group as={Row} className="mb-3 flex-column">
                                                                    <Form.Label as="legend" column sm={3}>
                                                                        Follower’s Posts
                                                                    </Form.Label>
                                                                    <Col sm={10}>
                                                                        <Form.Check
                                                                            type="radio"
                                                                            label="On"
                                                                            name="follower_posts"
                                                                            id="followerPost1"
                                                                            value={1}
                                                                            checked={pushNotification.follower_posts === 1}
                                                                            onChange={setPushNotificationData}
                                                                        />
                                                                        <Form.Check
                                                                            type="radio"
                                                                            label="Off"
                                                                            name="follower_posts"
                                                                            id="followerPost2"
                                                                            value={0}
                                                                            checked={pushNotification.follower_posts === 0}
                                                                            onChange={setPushNotificationData}
                                                                        />
                                                                    </Col>
                                                                </Form.Group>
                                                            </fieldset>
                                                        </Form>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="6">
                                                    <Accordion.Header
                                                        className='sub--setting'>Messages</Accordion.Header>
                                                    <Accordion.Body>
                                                        <Form>
                                                            <fieldset>
                                                                <Form.Group as={Row} className="mb-3 flex-column">
                                                                    <Form.Label as="legend" column sm={3}>
                                                                        Messages
                                                                    </Form.Label>
                                                                    <Col sm={10}>
                                                                        <Form.Check
                                                                            type="radio"
                                                                            label="On"
                                                                            name="messages"
                                                                            id="messages1"
                                                                            value={1}
                                                                            checked={pushNotification.messages === 1}
                                                                            onChange={setPushNotificationData}
                                                                        />
                                                                        <Form.Check
                                                                            type="radio"
                                                                            label="Off"
                                                                            name="messages"
                                                                            id="messages2"
                                                                            value={0}
                                                                            checked={pushNotification.messages === 0}
                                                                            onChange={setPushNotificationData}
                                                                        />
                                                                    </Col>
                                                                </Form.Group>
                                                            </fieldset>

                                                        </Form>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </div>
                                        </Accordion>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>Delete Account</Accordion.Header>
                                    <Accordion.Body>
                                        <h3>This will delete your account!</h3>
                                        <p>You’re about to start the process of delete your Stickfigure account. Your
                                            display name,
                                            @username, and public profile will no longer be viewable on
                                            Stickfigure.com.</p>

                                        <a href="#" className="text-danger" style={{ textDecoration: 'none' }}
                                            onClick={() => setModalShow(true)}>Delete account</a>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </section>

            <DeleteAccount show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>

    );
}

export default Setting;