import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DecalGeometry } from 'three/examples/jsm/geometries/DecalGeometry';

const ThreeDModelPreview = ({ design }) => {
  const mountRef = useRef(null);
  const modelRef = useRef();
  const isRotatingRef = useRef(false);
  const lastMousePosition = useRef({ x: 0, y: 0 });
  const renderer = useRef(new THREE.WebGLRenderer({ antialias: true, alpha: true }));
  const scene = useRef(new THREE.Scene());
  const camera = useRef(new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000));

  useEffect(() => {
    initRenderer();
    initLights();
    loadModel();

    const cleanUp = () => {
      removeEventListeners();
      removeRenderer();
    };

    return cleanUp;
  }, []);

  useEffect(() => {
    if (modelRef.current && design) {
      updateModelTextureWithDecal(design);
    }
  }, [design]);

  const initRenderer = () => {
    renderer.current.setSize(window.innerWidth / 2, window.innerHeight / 2);
    mountRef.current.appendChild(renderer.current.domElement);
  };

  const initLights = () => {
    const ambientLight = new THREE.AmbientLight(0xffffff, 1);
    scene.current.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
    directionalLight.position.set(1, 1, 1);
    scene.current.add(directionalLight);
  };

  const loadModel = () => {
    const loader = new GLTFLoader();
    loader.load(
      'mug.glb',
      (gltf) => {
        modelRef.current = gltf.scene;
        modelRef.current.position.set(0, 0, 0);
        modelRef.current.scale.set(45, 45, 45);
        scene.current.add(modelRef.current);
        camera.current.position.z = 10;
        animate();

        modelRef.current.traverse((child) => {
          if (child.isMesh) {
            console.log('Mesh:', child);
            console.log('Material:', child.material);
          }
        });
      },
      undefined,
      (error) => {
        console.error('Error loading GLTF model:', error);
      }
    );

    addEventListeners();
  };

  const addEventListeners = () => {
    renderer.current.domElement.addEventListener('mousedown', onCanvasMouseDown, false);
    renderer.current.domElement.addEventListener('mouseup', onCanvasMouseUp, false);
    renderer.current.domElement.addEventListener('mousemove', onCanvasMouseMove, false);
  };

  const removeEventListeners = () => {
    renderer.current.domElement.removeEventListener('mousedown', onCanvasMouseDown);
    renderer.current.domElement.removeEventListener('mouseup', onCanvasMouseUp);
    renderer.current.domElement.removeEventListener('mousemove', onCanvasMouseMove);
  };

  const onCanvasMouseDown = (event) => {
    if (event.button === 0) {
      isRotatingRef.current = true;
      lastMousePosition.current = {
        x: event.clientX,
        y: event.clientY,
      };
    }
  };

  const onCanvasMouseUp = () => {
    isRotatingRef.current = false;
  };

  const onCanvasMouseMove = (event) => {
    if (isRotatingRef.current) {
      const deltaX = event.clientX - lastMousePosition.current.x;
      const deltaY = event.clientY - lastMousePosition.current.y;
      lastMousePosition.current = {
        x: event.clientX,
        y: event.clientY,
      };

      if (modelRef.current) {
        modelRef.current.rotation.y += deltaX * 0.01;
        modelRef.current.rotation.x += deltaY * 0.01;
      }
    }
  };

  const removeRenderer = () => {
    mountRef.current.removeChild(renderer.current.domElement);
    renderer.current.dispose();
  };

  const animate = () => {
    requestAnimationFrame(animate);
    renderer.current.render(scene.current, camera.current);
  };

  const updateModelTextureWithDecal = (design) => {
    const loader = new THREE.TextureLoader();
    const placeholderTexture = new THREE.TextureLoader().load(
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAIAAAD8GO2jAAAA00lEQVR42mP8/wfAAwAB/AB7rERBAAAAAElFTkSuQmCC'
    );

    modelRef.current.traverse((child) => {
      if (child.isMesh && child.material) {
        child.material.color.set(0xffffff);
        child.material.map = placeholderTexture;
        child.material.needsUpdate = true;
      }
    });

    let isTextureLoaded = false;

    loader.load(
      design,
      (newTexture) => {
        newTexture.flipY = false;
        newTexture.roughness = 1;
        isTextureLoaded = true;
        newTexture.wrapS = THREE.RepeatWrapping;
        newTexture.wrapT = THREE.RepeatWrapping;
        newTexture.magFilter = THREE.NearestFilter;
        newTexture.minFilter = THREE.NearestFilter;
        newTexture.isCanvasTexture = true;
        newTexture.repeat.set(1, 1);
        newTexture.name = 'Untitled-1';
        newTexture.encoding = THREE.sRGBEncoding;

        

      let existingDecal;

      modelRef.current.traverse((child) => {
        if (child.isMesh && child.material) {
          // Check if the mesh already has a decal
          const decalIndex = scene.current.children.findIndex((obj) => obj instanceof THREE.Mesh && obj.userData.decal);
          existingDecal = decalIndex !== -1 ? scene.current.children[decalIndex] : null;

          const originalMaterial = child.material;

          // Log or store the material information
          console.log('Material Name:', originalMaterial.name);
          console.log('Material Type:', originalMaterial.type);
          console.log('Material Properties:', originalMaterial);

          // Create a new material based on the original type
          let newMaterial;

          if (originalMaterial instanceof THREE.MeshStandardMaterial) {
            newMaterial = new THREE.MeshStandardMaterial({
              color: 0xffffff,
              side: THREE.FrontSide,
              name: 'Material.001',
            });
          }

          child.material = newMaterial;

          // Get the world position of the model
          const modelPosition = modelRef.current.position;

          // Convert the world position to the local position of the mesh
          const localModelPosition = child.worldToLocal(modelPosition.clone());

          // Create or update a decal geometry
          const decalGeometry = existingDecal
            ? existingDecal.geometry // Use the existing decal's geometry
            : new DecalGeometry(child, localModelPosition, new THREE.Euler(), new THREE.Vector3(22, 0, 0), 0.1);

          // Create a decal material
          const decalMaterial = new THREE.MeshStandardMaterial({
            map: newTexture,
            transparent: true,
          });

          // Create or update a decal mesh
          const decalMesh = existingDecal ? existingDecal : new THREE.Mesh(decalGeometry, decalMaterial);

          // Add or update the decal mesh in the scene
          if (existingDecal) {
            existingDecal.geometry = decalGeometry;
            existingDecal.material = decalMaterial;
          } else {
            decalMesh.userData.decal = true; // Add a flag to identify this mesh as a decal
            scene.current.add(decalMesh);
          }
        }
      });

        // Render the scene again to update with new lights and decal
        renderer.current.render(scene.current, camera.current);
      },
      undefined,
      (error) => {
        console.error('Error loading texture:', error);
      },
      {
        premultipliedAlpha: false,
      }
    );

    const checkTextureLoaded = () => {
      if (!isTextureLoaded) {
        requestAnimationFrame(checkTextureLoaded);
        renderer.current.render(scene.current, camera.current);
      }
    };

    checkTextureLoaded();
  };

  return <div ref={mountRef} />;
};

export default ThreeDModelPreview;
