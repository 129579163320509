import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const ComingSoon = () => {
  return (
    <>
      <div className="container comming-contan " >
        <div
          className="d-flex justify-content-center align-items-center flex-column my-28 class--a"
          
        >
          <h1
            className="text-[80px/120px] font-extrabold uppercase"
            style={{ color: "#242424" }}
          >
            Coming Soon
          </h1>
          <p className="m-0" style={{ color: "#242424" }}>
            We are currently working on new features.
          </p>
          <p style={{ color: "#242424", textAlign:'center' }}>
            This section will be launched soon, Something really exciting is
            coming.
          </p>
          <Link to="/" className=" py-2 px-5 text-decoration-none comming--soon" >
            Go to home page
          </Link>
        </div>
      </div>
    </>
  );
};

export default ComingSoon;
