import api from "./api";

export const addProduct = async (body) => {
    try {
        const response = await api.post("/api/v1/products", body, {
            headers: {
                "oauth-token": JSON.parse(localStorage.getItem("printfulToken"))
                    ?.access_token,
            },
        });
        return response;
    } catch (error) {
        return error.response;
    }
};

export const updateProduct = async (id, body) => {
    try {
        const response = await api.post("/api/v1/products/" + id, body);
        return response;
    } catch (error) {
        return error.response;
    }
};

export const getProductById = async (id) => {
    try {
        const response = await api.get("/api/v1/products/" + id);
        return response;
    } catch (error) {
        return error.response;
    }
};

export const getCreations = async (searchText) => {
    try {
        const response = await api.get("/api/v1/creations", {
            params: {
                q: searchText
            }
        });
        return response;
    } catch (error) {
        return error.response;
    }
};

export const getPublicCreations = async (searchText) => {
    try {
        const response = await api.get("/api/v1/admin/creations", {
            params: {
                q: searchText
            }
        });
        return response;
    } catch (error) {
        return error.response;
    }
};

export function convertToFormData(payload) {
    const formData = new FormData();
    for (const key in payload) {
        if (Array.isArray(payload[key])) {
            for (let i = 0; i < payload[key].length; i++) {
                formData.append(key + "[]", payload[key][i]);
            }
        } else formData.append(key, payload[key]);
    }
    return formData;
}

export const connectStripeAccount = async () => {
    try {
        const response = await api.get("/api/v1/connect_stripe_account");
        return response;
    } catch (error) {
        return error.response;
    }
};


export const getPurchaseCreation = async () => {
    try {
        const response = await api.get("/api/v1/purchased_creations");
        return response;
    } catch (error) {
        return error.response;
    }
};

export const saveDownload = async (payload) => {
    try {
        const response = await api.post("/api/v1/downloads", payload);
        return response;
    } catch (error) {
        return error.response;
    }
};