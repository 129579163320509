import "./App.css";
import "./assets/css/style.css";
import "./NewSheet.css";

// import "./assets/css/style1.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Navbar from "./components/layouts/Navbar";
import Home from "./pages/Home";
import Verify from "./pages/Verify";
import ThreeAndFabric from "./pages/Test";
// import Newsfeed from "./components/Newsfeed";
import {
    BrowserRouter,
    Route,
    Routes
} from "react-router-dom";
import Footer from "./components/layouts/Footer";
import Profile from "./pages/Profile";
import VerifyDocs from "./pages/VerifyDocs";

import React, { lazy,useRef, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ForgotPassword from "./components/auth/Forgot-password";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import ResetPassword from "./components/auth/Reset-password";
import Nointernet from "./components/Nointernet";
import Protected from "./components/Protected";
import ScrollToTop from "./components/ScrollToTop";
import About from "./pages/About";
import Blog from "./pages/Blog";
import BlogDetail from "./pages/BlogDetail";
import Cart from "./pages/Cart";
import Contact from "./pages/Contact";
import Download from "./pages/Download";
import Editor from "./pages/Editor";
import Follower from "./pages/Follower";
import Notification from "./pages/Notification";
import OwnCreation from "./pages/OwnCreations";
import OwnCustomization from "./pages/OwnCustomization";
import ProductDetail from "./pages/ProductDetail";
import Search from "./pages/Search";
import Setting from "./pages/Setting";
import Shop from "./pages/Shop";
import SocialMedia from "./pages/SocialMedia";
import Store from "./pages/Store";
import TopProducts from "./pages/TopProducts";
import UpComingEvent from "./pages/UpcominEvent";
import WalletListing from "./pages/WalletListing";

import { doc, getDoc, updateDoc } from "firebase/firestore";
import ComingSoon from "./components/commingsoon/ComingSoon";
import { db } from "./components/Constants/Firebase";
import NotFound from "./components/NotFound";
import AccountSuspended from "./components/profile/AccountSuspended";
import SpinnerLoader from "./components/SpinnerLoader";
import AddPaymentMethod from "./components/verification/AddPaymentMethod";
import AdminComponent from "./pages/adminCustomizer";
import CheckOut from "./pages/CheckOut";
import Following from "./pages/Following";
import Invoices from "./pages/Invoices";
import Posts from "./pages/Posts";
import Verification from "./pages/Verification";
import { getProfile } from "./services/ProfileService";
import withAdminCheck from "./hooks/withAdminCheck";


const mugCustomizer = lazy(() => import("./pages/MugCustomizer"));
const Customizer = lazy(() => import("./pages/Customizer"));
const Newsfeed = lazy(() => import("./components/Newsfeed"));
const Chat = lazy(() => import("./pages/Chat"));
// const AdminComponent = withAdminCheck(AdminCustomizer);

const App = () => {
    console.log(useRef(window.innerWidth), useRef(window.innerHeight));
    const userState = localStorage.getItem("user") || false;
    const [authUser, setAuthUser] = useState(userState);
    const [authProfile, setAuthProfile] = useState(null);

    const handleUserAuthentication = () => {
    };

    const [cartCount, setCartCount] = useState(
        localStorage.getItem("cartCount") || 0
    );
    const [alertsCount, setAlertsCount] = useState(0);

    const getAuthProfile = async () => {
        const response = await getProfile();
        if (response.status === 200) {
            setAuthProfile(response.data.data);
        }
    }

    useEffect(() => {
        if (JSON.parse(localStorage.getItem("user"))) {
            getAuthProfile();
        }
    }, []);

    const toggleOnlineStatus = () => {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user) {
            const docRef = doc(db, "Users", user.id);
            getDoc(docRef)
                .then((docSnap) => {
                    if (docSnap.exists()) {
                        let data = docSnap.data();
                        if (!data?.setBy) {
                            updateDoc(docRef, {
                                isOnline: !document.hidden
                            });
                        }
                    }
                })
                .catch((error) => {
                    console.log("Error getting document:", error);
                });
        }
    }

    useEffect(() => {
        window.addEventListener("visibilitychange", toggleOnlineStatus);
        return () => {
            window.removeEventListener("visibilitychange", toggleOnlineStatus);
        }
    }, []);

    useEffect(() => {
        function logErrorToMyService(error) {
          // Implement logging functionality
          console.error(error);
        }
    
        window.addEventListener('error', function (event) {
          logErrorToMyService(event.error);
        });
    
        window.addEventListener('unhandledrejection', function (event) {
          logErrorToMyService(event.reason);
        });
    
        // Cleanup the event listeners
        return () => {
          window.removeEventListener('error', logErrorToMyService);
          window.removeEventListener('unhandledrejection', logErrorToMyService);
        };
      }, []);


    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />

            <BrowserRouter>
            {
                window.location.pathname === '/admin-customizer' ? <></> :
                <div className=" row text-white use-promo g-0">
                    <p
                        className="my-3 text-center alert-top"
                        style={{fontSize: "14px", margin: "0"}}
                    >
                        Use Code: AMPAY15 for 15% Off Orders Over $99 When You Use Amazon Pay.
                    </p>
                </div>
            }
                {/* <HashRouter> */}
                <ScrollToTop/>
               {
                 window.location.pathname === '/admin-customizer' ? <></> :
                 <Navbar
                     isUserLoggedIn={authUser}
                     useAuthentication={handleUserAuthentication}
                     cartCount={cartCount}
                     alertsCount={alertsCount}
                     setAlertsCount={setAlertsCount}
                     setCartCount={setCartCount}
                     authProfile={authProfile}
                 />
               }
                <Routes>
                    <Route path="/" element={<Protected Component={Home}/>}/>
                    <Route
                        path={`/customizer`}
                        element={
                            <React.Suspense fallback={<SpinnerLoader/>}>
                                <Protected Component={Customizer}/>
                            </React.Suspense>
                        }
                    />
                    <Route
                        path={`/mugcustomizer`}
                        element={
                            <React.Suspense fallback={<SpinnerLoader/>}>
                                <Protected Component={mugCustomizer}/>
                            </React.Suspense>
                        }
                    />
                    <Route
                        path={`/admin-customizer`}
                        element={
                            <React.Suspense fallback={<SpinnerLoader/>}>
                                <AdminComponent/>
                            </React.Suspense>
                        }
                    />
                    <Route
                        path={`/store/:id/customize`}
                        element={
                            <React.Suspense fallback={<SpinnerLoader/>}>
                                <Protected Component={Customizer}/>
                            </React.Suspense>
                        }
                    />
                    <Route
                        path={`/newsfeed`}
                        element={
                            <React.Suspense fallback={<SpinnerLoader/>}>
                                <Protected setCartCount={setCartCount} Component={Newsfeed}/>
                            </React.Suspense>
                        }
                    />
                    <Route path={`/verify`} element={<Protected Component={Verify}/>}/>
                    <Route path={`/verify/docs`} element={<VerifyDocs/>}/>
                    <Route path={`/tester`} element={<ThreeAndFabric/>}/>
                    <Route path={`/verify/payment_method`} element={<AddPaymentMethod/>}/>
                    <Route
                        path={`/chat`}
                        element={
                            <React.Suspense fallback={<SpinnerLoader/>}>
                                <Protected Component={Chat}/>
                            </React.Suspense>
                        }
                    />
                    
                    <Route path={`/notification`} element={<Notification setAlertsCount={setAlertsCount}/>}/>
                    <Route path={`/followers`} element={<Follower/>}/>
                    <Route path={`/followers/:id`} element={<Follower/>}/>
                    <Route path={`/following/:id`} element={<Following/>}/>
                    <Route path={`/following`} element={<Following/>}/>
                    <Route path={`/wallet`} element={<WalletListing authProfile={authProfile}/>}/>
                    <Route
                        path={`/login`}
                        element={
                            <Login
                                isUserLoggedIn={authUser}
                                useAuthentication={handleUserAuthentication}
                            />
                        }
                    />
                    <Route path={`/signup`} element={<Register/>}/>
                    <Route path={`/forgot_password`} element={<ForgotPassword/>}/>
                    <Route path={`/reset_password`} element={<ResetPassword/>}/>
                    <Route
                        path={`/profile`}
                        element={<Protected Component={Profile}/>}
                    />
                    <Route
                        path={`/profile/:id`}
                        element={<Protected Component={Profile}/>}
                    />
                    <Route
                        path={`/setting`}
                        element={<Setting/>}
                    />
                    <Route path={`/store`} element={<Protected Component={Store}/>}/>
                    <Route
                        path={`/store/:id`}
                        element={<Protected Component={Store}/>}
                    />
                    <Route path={`/search`} element={<Protected Component={Search}/>}/>
                    <Route
                        path={`/carts`}
                        element={<Cart setCartCount={setCartCount}/>}
                    />
                    <Route path={`/blogs`} element={<Protected Component={Blog}/>}/>
                    <Route path={`/about`} element={<About/>}/>
                    <Route path={`/event`} element={<UpComingEvent/>}/>
                    <Route path={`/contact`} element={<Contact/>}/>
                    <Route path={`/owncreation`} element={<OwnCreation/>}/>
                    <Route path={`/owncustomization`} element={<OwnCustomization/>}/>
                    <Route path={`/new-kind-of-socail-media`} element={<SocialMedia/>}/>
                    <Route path={`/blog-detail/:slug`} element={<BlogDetail/>}/>
                    <Route
                        path={`/shop/:type`}
                        element={<Protected setCartCount={setCartCount} Component={Shop}/>}
                    />
                    <Route path={`/top-products`} element={<TopProducts setCartCount={setCartCount}/>}/>
                    <Route path={`/editor`} element={<Editor/>}/>
                    <Route path={`/NoInternetConection`} element={<Nointernet/>}/>
                    <Route path={`/download`} element={<Download/>}/>
                    <Route path={`/product-detail/:id`} element={<ProductDetail setCartCount={setCartCount}/>}/>
                    <Route path={`/comingsoon`} element={<ComingSoon/>}/>
                    <Route path={`/verified`} element={<Verification/>}/>
                    <Route path={`/checkout/:id`} element={<CheckOut/>}/>
                    <Route path={`/checkout`} element={<CheckOut/>}/>
                    <Route path={`/posts/:id`} element={<Posts/>}/>
                    <Route path={`/payments`} element={<Invoices/>}/>
                    <Route path={`/account_suspended`} element={<AccountSuspended/>}/>
                    <Route path="/404" element={<NotFound/>}/>
                    <Route path="*" element={<NotFound/>}/>
                </Routes>
                <Footer/>
            </BrowserRouter>
        </>
    );
};

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(<Home />);
export default App;
