import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {ResetPasswordApi} from "../../services/AuthService";
import {useNavigate} from "react-router-dom";

const ResetPassword = () => {
    const [showLoading, setLoading] = useState(false);
    const [user, setUser] = useState({
        email: "",
        password: "",
        password_confirmation: ""
    });

    const navigate = useNavigate();
    const [validation, setValidation] = useState({
        email: "",
        password: "",
        password_confirmation: ""
    });

    const query = new URLSearchParams(window.location.search);

    const submit = async (e) => {
        e.preventDefault();
        if (check()) {
            return;
        }
        setLoading(true);
        //  let registerBtn = document.getElementById("register");
        // let isValidated = Object.values(validation).every((v) => v !== "");
        // console.log(isValidated);
        // if(!isValidated) {
        //     return;
        // }
        const finalUser = {
            email: query.get('email'),
            token: query.get('token'),
            password: user.password,
            password_confirmation: user.password_confirmation,
        };
        const response = await ResetPasswordApi(finalUser);
        setLoading(false);
        if (response.status === 200) {
            toast.success(response.data.message);
            navigate("/login");
        } else {
            toast.error(response.data.message);
        }
    }


    const check = () => {
        setValidation({
            email: "",
            password: "",
            password_confirmation: ""
        })

        if (user.password === "") {
            setValidation((validation) => ({
                ...validation,
                password: "Password is required",
            }));
            return true;
        }
        if (user.password.length < 6) {
            setValidation((validation) => ({
                ...validation,
                password: "Password must be at least 6 characters",
            }));
            return true;
        }
        if (!user.password.match(/[A-Z]/)) {
            setValidation((validation) => ({
                ...validation,
                password: "At-least One Capital Letter",
            }));
            return true;
        }
        if (!user.password.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/)) {
            setValidation((validation) => ({
                ...validation,
                password: "At-least One Special Character",
            }));
            return true;
        }
        if (user.password_confirmation !== user.password) {
            setValidation((validation) => ({
                ...validation,
                password_confirmation: "Password does not matched",
            }));
            return true;
        }
    }

    return (
        <>
            <div className="container">
                <div className="row m-5 no-gutters">
                    <div className="col-md-6 d-none d-md-block banner-container"
                         style={{backgroundImage: "url('/assets/images/Images/reset-password.png')"}}>
                    </div>
                    <div className="col-md-6 bg-white p-5">
                        <h3 className="signin pb-3">Reset Password</h3>
                        <div className="reset-form-style">
                            <form>
                                <div className="form-group">
                                    <input type="password" placeholder="Enter Your New Password"
                                           className="form-control" id="exampleInputPassword1"
                                           onChange={(e) => setUser(state => ({
                                               ...state,
                                               password: e.target.value
                                           }))}/>
                                    <label>
                                        <span className="text-danger">
                                            {validation.password}
                                        </span>
                                    </label>
                                </div>
                                <div className="form-group">
                                    <input type="password" placeholder="Re-Enter Password" className="form-control"
                                           id="exampleInputPassword1"
                                           onChange={(e) => setUser(state => ({
                                               ...state,
                                               password_confirmation: e.target.value
                                           }))}/>
                                    <label>
                                        <span className="text-danger">
                                            {validation.password_confirmation}
                                        </span>
                                    </label>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-md-7">
                                        <button type="submit"
                                                className="get-started w-100 mt-2" onClick={submit}
                                        >Submit
                                        </button>
                                    </div>
                                </div>
                            </form>
                            <div className="row mt-5">
                                <div className="col-md-12">
                                    <h5>Include the following:</h5>
                                    <ul>
                                        <li>Atleast One Capital Letter</li>
                                        <li>Atleast One Special Character</li>
                                        <li>Atleast 6 Characters in your password</li>
                                    </ul>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default ResetPassword