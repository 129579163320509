import api from "./api";

export const AddCart = async (payload) => {
  try {
    const response = await api.post("/api/v1/carts", payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getCart = async () => {
  try {
    const response = await api.get("/api/v1/carts");
    return response;
  } catch (error) {
    return error.response;
  }
};
export const submitCart = async (payload) => {
  try {
    const response = await api.post("/api/v1/create-order",payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const getSimilarProducts = async () => {
  try {
    const response = await api.get("/api/v1/similar_products");
    return response;
  } catch (error) {
    return error.response;
  }
};
