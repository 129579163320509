import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import { login, loginWithFacebook, loginWithGoogle } from "../../services/AuthService";
import { db } from "../../components/Constants/Firebase";
import { getDoc, doc, setDoc } from "firebase/firestore";
import { setAuthorizationHeader } from "../../services/api";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";

export default function Login(props) {
    ///states
    var token = localStorage.getItem("token");

    if (token) {
        window.location.href = "/";
    }
    const [showLoading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const windowRef = useRef();

    const [user, setUser] = useState({
        email: "",
        password: "",
    });

    const [validation, setValidation] = useState({
        email: "",
        password: "",
    });

    // user's data mutation
    const setUserEmail = (e) => setUser({ ...user, email: e.target.value });
    const setUserPassword = (e) => setUser({ ...user, password: e.target.value });

    const handleLogin = async () => {
        // validate user info fields
        const emailCond = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$|^[a-zA-Z0-9_]+$/;

        if (user.email == "") {
            setValidation((validation) => ({
                ...validation,
                email: "Email is required",
            }));
            return;
        } else if (!emailCond.test(user.email)) {
            setValidation((validation) => ({
                ...validation,
                email: "Please enter a valid email",
            }));
            return;
        } else {
            setValidation((validation) => ({
                ...validation,
                email: "",
            }));
        }
        if (user.password == "") {
            setValidation((validation) => ({
                ...validation,
                password: "Password is required",
            }));
            return;
        } else {
            setValidation((validation) => ({
                ...validation,
                password: "",
            }));
        }

        let loginBtn = document.getElementById("login");
        loginBtn.style.opacity = "0.5";
        setLoading(true);
        const response = await login(user);
        setLoading(false);
        loginBtn.style.opacity = "1";
        if (response?.status === 200) {
            /// setting the data in localstorage
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("user", JSON.stringify(response.data.user));
            try {
                const userId = response.data.user.id;
                const userRef = doc(db, "Users", userId);
                const userSnapshot = await getDoc(userRef);

                if (!userSnapshot.exists()) {
                    await setDoc(userRef, {
                        email: response.data.user.email,
                        id: userId,
                        user_name: response.data.user.user_name,
                        imageUrl: response.data.user.photo,
                        name: response.data.user.name,
                        isOnline: true,
                        setBy: userId
                    });
                }

                console.log("User document(s) created/checked");
            } catch (error) {
                console.log("User document(s) created/checked");
            }

            /// creating the new event of localstorage
            window.dispatchEvent(new Event("storage"));
            toast.success("Login success");
            props.useAuthentication(true);
            setAuthorizationHeader(localStorage.getItem("token"));
            /// navigating
            // navigate("/");

            window.location.reload();
        }
        if (response.status === 401) {
            toast.warn(response.data.message);
        } else {
            toast.error(response.data.message);
            console.log(response.data.message);
        }
    };

    const togglePassword = () => setShowPassword(!showPassword);

    function loadGoogleScript() {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = 'https://accounts.google.com/gsi/client';
            script.onload = () => {
                resolve();
            };
            document.body.appendChild(script);
        });
    }

    const handleAuthenticate = async (data) => {
        let loginBtn = document.getElementById("login");
        loginBtn.style.opacity = "0.5";
        setLoading(true);
        const response = await loginWithGoogle({ token: data.credential });
        setLoading(false);
        loginBtn.style.opacity = "1";
        if (response.status === 200) {
            /// setting the data in localstorage
            localStorage.setItem("token", response.data.data.token);
            localStorage.setItem("user", JSON.stringify(response.data.data.user));
            try {
                const userId = response.data.data.user.id;
                const userRef = doc(db, "Users", userId);
                const userSnapshot = await getDoc(userRef);

                if (!userSnapshot.exists()) {
                    await setDoc(userRef, {
                        email: response.data.data.user.email,
                        id: userId,
                        user_name: response.data.data.user.user_name,
                        imageUrl: response.data.data.user.photo,
                        name: response.data.data.user.name,
                        isOnline: true,
                        setBy: userId
                    });
                }

                console.log("User document(s) created/checked");
            } catch (error) {
                console.log("User document(s) created/checked");
            }

            /// creating the new event of localstorage
            window.dispatchEvent(new Event("storage"));
            toast.success("Login success");
            props.useAuthentication(true);
            setAuthorizationHeader(localStorage.getItem("token"));
            /// navigating
            navigate("/");

            window.location.reload();
        }
    }

    async function handleGoogleSignIn() {
        const onSuccess = async (response) => {
            console.log('Login Success', response);
            handleAuthenticate(response);
        };

        const onFailure = (error) => {
            console.log('Login Failure', error);
            // Handle login failure logic here
        };

        if (window.google && window.google.accounts) {
            window.google.accounts.id.initialize({
                client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                callback: onSuccess,
                cancel_callback: onFailure,
                redirect_uri: process.env.REACT_APP_baseURL,
                auto_select: true,
            });
            window.google.accounts.id.prompt();
        }
    }

    const receiveMessage = (event) => {
        // Verify that the event origin is the same as your website's URL
        if (event.origin === window.location.origin) {
            // Access the response data from the event
            const response = event.data;

            // Handle the response from the Google login window
            // console.log('Login Response:', response);

            // Close the Google login window
            windowRef?.current?.close();

            // Remove the event listener
            window.removeEventListener('message', receiveMessage);
        }
    };

    useEffect(() => {
        loadGoogleScript().then(() => {
            handleGoogleSignIn();
        });

        window.addEventListener('message', receiveMessage);
        return () => {
            // Clean up by removing the event listener when the component unmounts
            window.removeEventListener('message', receiveMessage);
        };
    }, []);

    const handleFbLogin = async (token) => {
        const payload = {
            fb_token: token,
            // app_url: 'stickfigure.local.com'
        }

        const response = await loginWithFacebook(payload);
        console.log(response);
        if (response.status === 200) {
            localStorage.setItem("token", response.data.data.token);
            localStorage.setItem("user", JSON.stringify(response.data.data.user));
            try {
                const userId = response.data.user.id;
                const userRef = doc(db, "Users", userId);
                const userSnapshot = await getDoc(userRef);

                if (!userSnapshot.exists()) {
                    await setDoc(userRef, {
                        email: response.data.user.email,
                        id: userId,
                        user_name: response.data.user.user_name,
                        imageUrl: response.data.user.photo,
                        name: response.data.user.name,
                        isOnline: true,
                        setBy: userId
                    });
                }

                console.log("User document(s) created/checked");
            } catch (error) {
                console.log("User document(s) created/checked");
            }
            /// creating the new event of localstorage
            window.dispatchEvent(new Event("storage"));
            toast.success("Login success");
            props.useAuthentication(true);
            setAuthorizationHeader(localStorage.getItem("token"));
            /// navigating
            navigate("/");

            window.location.reload();
        }
        console.log(response);
    }


    const LoginWithFacebook = () => {
        window.FB.init({
            appId: '231310286212022',
            version: 'v12.0',
        });
        window.FB.login(function (response) {
            if (response.authResponse) {
                const accessToken = response.authResponse.accessToken;
                // Access the access token or any other necessary information
                console.log(accessToken);
                handleFbLogin(accessToken);
            } else {
                console.log('Facebook login failed.');
            }
        });
    }

    return (
        <>
            <div className="container">
                <div className="row m-3 no-gutters">
                    <div
                        className="col-md-6 d-md-block banner-container"
                        style={{
                            backgroundImage: "url('/assets/images/Images/login.png')",
                        }}
                    ></div>
                    <div
                        className="col-md-4 my-4 d-flex flex-column justify-content-center bg-white p-3 py-5"
                        style={{ borderRadius: "5px" }}
                    >
                        <h1
                            style={{ fontFamily: "Brittany Signature", alignSelf: "start" }}
                        >
                            Welcome
                        </h1>
                        <h3
                            className="signin pb-3 justify-content-start mb-0 font-sizing-login-heading"
                            style={{ fontFamily: "My Font" }}
                        >
                            sign in to account
                        </h3>
                        <div className="form-style login-wrapper">
                            {/* <form> */}
                            <div className="form-group mb-3">
                                <label>
                                    <span className="text-danger">{validation.email}</span>
                                </label>
                                <input
                                    type="email"
                                    placeholder="Email or Username"
                                    className="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    onChange={setUserEmail}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            handleLogin();
                                        }
                                    }}
                                />
                            </div>
                            <div
                                className="form-group mt-3"
                                style={{ marginBottom: "0px", position: "relative" }}
                            >
                                <label>
                                    <span className="text-danger">{validation.password}</span>
                                </label>
                                <input
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Password"
                                    className="form-control"
                                    id="exampleInputPassword1"
                                    onChange={setUserPassword}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            handleLogin();
                                        }
                                    }}
                                />
                                <img
                                    src="/assets/images/passwordIcon.svg"
                                    style={{
                                        position: "absolute",
                                        top: "40px",
                                        right: "20px",
                                        cursor: "pointer",
                                    }}
                                    onClick={togglePassword}
                                    alt=""
                                />
                            </div>
                            <div className="d-flex align-items-center justify-content-between font-sizing-login">
                                <div>
                                    <Link to="/forgot_password">Forget Password</Link>
                                </div>
                                <div className="d-flex align-items-center">
                                    <input
                                        name=""
                                        type="checkbox"
                                        value=""
                                        style={{ accentColor: "black", marginRight: "5px" }}
                                    />
                                    <div className="font-sizing-login">Remember Me</div>
                                </div>
                            </div>
                            <div className="pb-2">
                                <button
                                    className="get-started loginBtn mt-2 d-flex justify-content-center align-items-center"
                                    onClick={handleLogin}
                                    id="login"
                                >
                                    {showLoading && <Spinner animation="border me-2 d-flex justify-content-center align-items-center" />}
                                    Sign In
                                </button>
                                <div className="sideline">or</div>
                                <div className="row justify-content-center pt-2">
                                    <div
                                        className="col-md-6 d-flex justify-content-center"
                                        style={{ gap: "20px" }}
                                    >
                                        {/*<GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>*/}
                                        {/*    <GoogleLogin*/}
                                        {/*        onSuccess={credentialResponse => {*/}
                                        {/*            console.log(credentialResponse);*/}
                                        {/*            handleAuthenticate(credentialResponse);*/}
                                        {/*        }}*/}
                                        {/*        width="149"*/}
                                        {/*        // size="large"*/}
                                        {/*        type="icon"*/}
                                        {/*        shape="circle"*/}
                                        {/*        onError={() => {*/}
                                        {/*            console.log('Login Failed');*/}
                                        {/*        }}*/}
                                        {/*    />*/}
                                        {/*</GoogleOAuthProvider>*/}
                                        <button
                                            type='button'
                                            className="social fb-login-button"
                                            onClick={LoginWithFacebook}
                                            style={{
                                                backgroundColor: 'white',
                                                backgroundImage: "url('/assets/images/social/fb.svg')",
                                                backgroundSize: '50% 50%'
                                            }}
                                        ></button>
                                        {/*<button*/}
                                        {/*    type='button'*/}
                                        {/*  className="social"*/}
                                        {/*  style={{*/}
                                        {/*    backgroundImage: "url('/assets/images/instagram.png')",*/}
                                        {/*  }}*/}
                                        {/*></button>*/}
                                    </div>
                                </div>
                            </div>
                            {/* </form> */}
                            <div className="pt-4 text-center">
                                <Link to="/signup">
                                    <span style={{ color: "#A3A3A3", fontSize: "18px" }}>
                                        Don’t have an account?
                                    </span>
                                    Sign Up
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}