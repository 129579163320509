import React, {useMemo, useState} from "react";
import Slider from "react-slick";
import {useNavigate} from "react-router";

export default function HeroSection({data}) {
    const navigate = useNavigate();

    const settings = {
        arrow: false,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const slides = useMemo(() => {
        const heroSection = data?.data.find(item => item.type === 'heroSection');
        return heroSection?.accordians.map((item, index) => {
            return {
                heading1: item.heading1,
                heading2: item.heading2,
                heading3: item.heading3,
                url     : item.URL,
                image1: heroSection?.images[(2 * index)].image,
                image2: heroSection?.images[(2 * index) + 1].image,
            }
        })
    }, [data])

    return (
        <>
            <div className="hero--section" style={{backgroundColor: "#E9E9E9"}}>
                <Slider {...settings} className="hero--arrows" arrows={false}>
                    {slides?.map((item, index) => {
                        return <div key={index} className="slide-wrap hero-section">
                            <div className="row pt-3 pb-2 mb-4">
                                <div className="col-md-12 d-flex justify-content-center">
                                    <img
                                        src={process.env.REACT_APP_apiBaseURL + '/' + item.image1}
                                        style={{width: "50%", maxHeight: '280px', objectFit: 'contain'}}
                                    />
                                </div>
                            </div>
                            <div className="container py-3">
                                <div className="row align-items-center">
                                    <div className="col-md-4 res--hero">
                                        <h1 className="create_and mb-0">{item.heading1}</h1>
                                        <p className="py-2">{item.heading2}</p>
                                        <button className="get-started" onClick={()=>{
                                            window.location.href = item.url
                                        }}>Get Started</button>
                                    </div>
                                    <div className="col-md-4 d-flex justify-content-center">
                                        <img
                                            src={process.env.REACT_APP_apiBaseURL + '/' + item.image2}
                                            style={{maxWidth: "100%", width: "70%"}}
                                        />
                                    </div>
                                    <div className="col-md-4 d-flex align-items-center text-capitalize px-4 mb-3">
                                        <h6>{item.heading3}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    } )}
                </Slider>
            </div>
        </>
    );
}
